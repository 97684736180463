/**
 * 场站编辑
 */

import { get } from "lodash";
import { Form, Input, Modal, Row, Button } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import NsSelect from "../../containers/common/ns-select";

const { confirm } = Modal;

/**
 * styled
 */
const formItemLayout = {
  labelCol: { xxl: 7, xl: 8, lg: 9, md: 10, sm: 11 },
};

const StyledFilterForm = styled(Form)`
  margin-top: 12px !important;
  padding: 0 30px 12px 30px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .pack-filter {
    display: flex;
    justify-content: center;

    .ant-btn {
      border: 0;
    }
  }
`;

function FormItem({ label, name, form, rules, initialValue, children }) {
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, {
        initialValue,
        rules,
      })(children)}
    </Form.Item>
  );
}

@connect()
@Form.create()
export default class StationEditor extends Component {
  handleCancel = () => {
    const { onClose, form } = this.props;
    if (onClose) onClose();
    form.resetFields();
  };

  handleOk = () => {
    const { station, form, onSubmit, onClose } = this.props;

    form.validateFields((errors, values) => {
      if (!Boolean(errors)) {
        if (onSubmit) onSubmit(station.id, values);
        if (onClose) onClose();
        form.resetFields();
      }
    });
  };

  handleDelete = () => {
    const { station, onDeleted, onClose, form } = this.props;
    confirm({
      title: `确定删除${station.name}及所有相关信息？`,
      content: (
        <span style={{ color: "red" }}>删除后该充电站相关信息都将删除</span>
      ),
      onOk: () => {
        if (onDeleted) onDeleted(station.id);
        if (onClose) onClose();
        form.resetFields();
      },
    });
  };

  render() {
    const { title, station, form } = this.props;

    return (
      <Modal
        title={title}
        visible={!!station}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        footer={[
          <Button
            key="back"
            style={{ position: "absolute", left: "20px" }}
            onClick={this.handleDelete}
            type="danger"
          >
            删除充电站
          </Button>,
          <Button key="cancel" onClick={this.handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOk}>
            确定
          </Button>,
        ]}
      >
        <StyledFilterForm {...formItemLayout}>
          <Row>
            <FormItem
              label="场站编码"
              form={form}
              name="hahahahaha"
              initialValue={get(station, "no")}
            >
              <Input disabled />
            </FormItem>
          </Row>
          <Row>
            <FormItem
              label="场站名称"
              form={form}
              name="name"
              initialValue={get(station, "name")}
              rules={[{ required: true, message: "请输入名称" }]}
            >
              <Input placeholder="请输入名称" allowClear />
            </FormItem>
          </Row>
          <Row>
            <FormItem
              label="服务公司"
              form={form}
              name="ns"
              initialValue={get(station, "ns.id")}
            >
              <NsSelect onlySub allowClear />
            </FormItem>
          </Row>
          <Row>
            <FormItem
              label="场站地址"
              form={form}
              name="address"
              initialValue={get(station, "address")}
            >
              <Input placeholder="请输入场站地址" allowClear />
            </FormItem>
          </Row>
        </StyledFilterForm>
      </Modal>
    );
  }
}
