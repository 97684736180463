import React, { PureComponent } from "react";
import { Select } from "antd";

export default class StatusSelect extends PureComponent {
  render() {
    const { status, labelPropName, ...rest } = this.props;
    return (
      <Select placeholder="请选择" {...rest}>
        {Object.keys(status).map(key => {
          return (
            <Select.Option value={key} key={key}>
              {labelPropName ? status[key][labelPropName] : status[key]}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
