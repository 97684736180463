import { all, fork, takeLatest, put } from "redux-saga/effects";
import { watchXlsx } from "@36node/redux-xlsx";
import { watchApis, successOf } from "@36node/redux-api";
import { watchCron } from "@36node/redux-cron";
import { message } from "antd";

import watchTrailPath from "./trail-path";
import { timelineSaga as watchTimeline } from "../modules/timeline";
import { NS } from "../constants";
import { systemActions, globalActions } from "../actions";
import watchSession from "./session";
import watchHistory from "./history";
import watchTickets from "./ticket";
import watchAlertAudio from "./alert-audio";
import watchVehicle from "./vehicle";
import watchMonitors from "./monitor";

import { saga as watchWS } from "../modules/ws";
import watchArchives from "./archives";
import watchReport from "./report-form";
import watchConnectors from "./connector";
import watchStations from "./station";

function* listUsers({ payload = {} }) {
  yield put(systemActions.listUsers.refresh());
}

function* listNamespaces({ payload = {} }) {
  yield put(globalActions.listNamespaces());
}

function* refreshTemplate({ payload = {} }) {
  message.success("操作成功");
  yield put(systemActions.listTemplates.refresh());
}

export default function* root() {
  yield all([
    fork(watchApis),
    fork(watchTrailPath),
    fork(watchTimeline),
    fork(watchXlsx),
    fork(watchSession),
    fork(watchHistory),
    fork(watchTickets),
    fork(watchCron),
    yield takeLatest(successOf(NS.SYSTEM.CREATE_USER), listUsers),
    yield takeLatest(successOf(NS.SYSTEM.UPDATE_USER), listUsers),
    yield takeLatest(successOf(NS.SYSTEM.DELETE_USER), listUsers),
    yield takeLatest(successOf(NS.SYSTEM.CREATE_NAMESPACE), listNamespaces),
    yield takeLatest(successOf(NS.SYSTEM.UPDATE_NAMESPACE), listNamespaces),
    yield takeLatest(successOf(NS.SYSTEM.DELETE_NAMESPACE), listNamespaces),
    yield takeLatest(successOf(NS.SYSTEM.CREATE_TEMPLATE), refreshTemplate),
    yield takeLatest(successOf(NS.SYSTEM.UPDATE_TEMPLATE), refreshTemplate),
    yield takeLatest(successOf(NS.SYSTEM.DELETE_TEMPLATE), refreshTemplate),
    fork(watchAlertAudio),
    fork(watchVehicle),
    fork(watchMonitors),
    fork(watchWS),
    fork(watchArchives),
    fork(watchReport),
    fork(watchConnectors),
    fork(watchStations),
  ]);
}
