import React from "react";

import { ymd, ymdhms } from "../../lib/time";

const isDefAndNotNull = v => typeof v !== "undefined" && v !== null;

export function Format({
  value,
  isValid = isDefAndNotNull,
  defaultValue,
  formatter,
  fallback = "-",
  suffix = "",
  prefix = "",
  children,
}) {
  if (!isValid(value)) {
    value = defaultValue;
  }
  if (!isValid(value)) {
    return <React.Fragment>{fallback}</React.Fragment>;
  }

  const result = formatter(value);

  if (children) {
    return children(`${prefix}${result}${suffix}`);
  }
  return <React.Fragment>{`${prefix}${result}${suffix}`}</React.Fragment>;
}

export const FormatDate = props => {
  return (
    <Format
      {...props}
      isValid={d => d !== undefined && !isNaN(Date.parse(d))}
      formatter={ymdhms}
    />
  );
};

export const FormatShortDate = props => {
  return (
    <Format
      {...props}
      isValid={d => d !== undefined && !isNaN(Date.parse(d))}
      formatter={ymd}
    />
  );
};
