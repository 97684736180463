/**
 * 处理archives 导入导出
 */

import { createAssignActions } from "@36node/redux-ui";
import { createXlsxActions } from "@36node/redux-xlsx";
import {
  successOf,
  failureOf,
  isSuccess,
  createApiActions,
  isFailure,
} from "@36node/redux-api";
import { put, take, select } from "redux-saga/effects";
import { get, isEmpty } from "lodash";
import { message } from "antd";
import validate from "validate.js";
import moment from "moment";

import { mekong } from "../sdk";
import { NS } from "../constants";
import { mekongVehicleSchema } from "../schemas";
import {
  globalSelectors,
  archivesSelectors,
  stationSelectors,
} from "../selectors";
import { globalActions } from ".";

/**
 * list vehicles api for table
 */
const listVehicles = createApiActions(NS.ARCHIVES.LIET_VEHICLES, {
  schema: [mekongVehicleSchema],
  endpoint: mekong.listVehicles,
});

/**
 * upsert vehicle
 */
const createVehicle = createApiActions(NS.ARCHIVES.CREATE_VEHICLE, {
  schema: mekongVehicleSchema,
  endpoint: mekong.createVehicle,
});

const upsertVehicle = createApiActions(NS.ARCHIVES.UPSERT_VEHICLE, {
  schema: mekongVehicleSchema,
  endpoint: mekong.upsertVehicle,
});

const updateVehicle = createApiActions(NS.ARCHIVES.UPDATE_VEHICLE, {
  schema: mekongVehicleSchema,
  endpoint: mekong.updateVehicle,
});

const deleteVehicle = createApiActions(NS.ARCHIVES.DELETE_VEHICLE, {
  endpoint: mekong.deleteVehicle,
});
/**
 * list vehicles api for import
 */
const listVehiclesForExport = createApiActions(NS.ARCHIVES.VEHICLES_XLSX, {
  schema: [mekongVehicleSchema],
  endpoint: mekong.listVehicles,
});

const importModalState = createAssignActions(NS.ARCHIVES.VEHICLES_XLSX);

// 当前表单中的ns
const editorFormNs = createAssignActions(NS.ARCHIVES.EDITOR_FORM_NS);

const deleteArchives = (vehicleIds = []) => {
  return {
    type: NS.ARCHIVES.DELETE_ARCHIVES,
    payload: {
      vehicleIds,
    },
  };
};

export const ImportModalStatus = {
  IMPORTING: "IMPORTING", // 尚未开始
  VALIDATE_FAIL: "VALIDATE_FAIL", // 验证失败
  API_FAIL: "API_FAIL", // api 请求错误
  FINISHED: "FINISHED", // 导入完成
};

/**
 * 车辆档案验证
 * @param {*} records 车辆档案记录
 * @param {*} namespaces 所有命名空间
 * @param {*} lines 所有线路
 */
function validateRecords(records = [], namespaces = []) {
  const departments = namespaces.map(n => n.pathname);
  const subDepartments = namespaces.map(n => n.name);

  const constraints = {
    vin: { presence: { message: "^VIN码不能为空" } },
    ns: {
      presence: { message: "^营运公司不能为空" },
      inclusion: {
        within: departments,
        message: value => `^营运公司: ${value} 不存在`,
      },
    },
    subDepartment: {
      presence: { message: "^分公司不能为空" },
      inclusion: {
        within: subDepartments,
        message: value => `^分公司: ${value} 不存在`,
      },
    },
    line: {
      presence: { message: "^线路不能为空" },
    },
    modelDetail: {
      presence: { message: "^车型全称不能为空" },
    },
    model: {
      presence: { message: "^车型简称不能为空" },
    },
    plate: {
      presence: { message: "^车牌不能为空" },
    },
    no: { presence: { message: "^自编号不能为空" } },
    plateAt: {
      presence: { message: "^上牌日期不能为空" },
      format: {
        pattern: /^\d{4}-\d{2}-\d{2}$|^\d{4}\/\d{2}\/\d{2}$|^\d{4}年\d{2}月\d{2}日$/,
        message:
          "^上牌日期必须为YYYY-MM-DD、YYYY年MM月DD日或YYYY/MM/DD的文本格式",
      },
    },
  };

  return records
    .map((r, row) => {
      if (typeof r.plateAt === "object" && moment(r.plateAt).isValid()) {
        r.plateAt = moment(r.plateAt)
          .add(10, "hour")
          .format("YYYY-MM-DD");
      }

      if (typeof r.plateAt === "string") {
        r.plateAt = r.plateAt.trim();
      }

      const errors = validate(r, constraints, { format: "flat" });
      if (errors) {
        return {
          row,
          errors,
        };
      }

      if (typeof r.plateAt === "string") {
        r.plateAt = r.plateAt.replace(/年|月/g, "-");
        r.plateAt = r.plateAt.replace(/日/g, "");
      }

      return undefined;
    })
    .filter(r => r);
}

/**
 * 车辆导出数据源
 * @param {*} params
 */
function* exportDataSouce(params = {}) {
  const apiRequest = listVehiclesForExport.request;

  yield put(apiRequest(params));

  const apiResultAction = yield take([
    successOf(NS.ARCHIVES.VEHICLES_XLSX),
    failureOf(NS.ARCHIVES.VEHICLES_XLSX),
  ]);

  if (isSuccess(apiResultAction)) {
    const apiResult = yield select(archivesSelectors.listVehiclesForExport);

    return apiResult.result;
  } else {
    message.error("车辆档案导出失败");
    return;
  }
}

/**
 * 导入前验证数据
 * @param {*} records
 */
function* beforeImport(records = []) {
  if (records.length === 0) {
    return false;
  }
  const namespaces = yield select(globalSelectors.namespaces);
  const results = validateRecords(records, namespaces);

  const hasError = !isEmpty(results);

  if (hasError) {
    yield put(
      importModalState.set({
        status: ImportModalStatus.VALIDATE_FAIL,
        results,
      })
    );

    // 停止import进程
    return false;
  } else {
    yield put(
      importModalState.set({
        status: ImportModalStatus.IMPORTING,
      })
    );
  }
}

/**
 * 处理单条导入进度
 * @param {*} row 当前row
 * @param {*} record 当前record
 */
function* handleImportRecord(row, record) {
  const namespaces = yield select(globalSelectors.namespaces);
  const allStationsState = yield select(stationSelectors.listAllPileStations);
  const allStations = get(allStationsState, "result", []);
  const {
    ns,
    subDepartment,
    lifeYear,
    stations: stationNames = "",
    ...rest
  } = record;
  const namespace =
    namespaces.find(n => n.pathname === `${ns}/${subDepartment}`) || {};
  const stationIds = stationNames
    .trim()
    .split(",")
    .filter(s => s)
    .map(n => allStations.find(s => s.name === n.trim()))
    .filter(s => s)
    .map(s => s.id);
  yield put(
    upsertVehicle.request(
      {
        body: {
          ...rest,
          ns: namespace.id,
          lifeYear: lifeYear || 8, // 使用年限默认8年
          stations: stationIds,
        },
      },
      {}
    )
  );
  const key = NS.ARCHIVES.UPSERT_VEHICLE;
  const apiResultAction = yield take([successOf(key), failureOf(key)]);
  if (isFailure(apiResultAction)) {
    yield put(
      importModalState.set({
        status: ImportModalStatus.API_FAIL,
      })
    );
    yield put(vehiclesXlsx.importPause());
  }
}

/**
 * 导入成功后，重新刷新api
 */
function* onImportFinished() {
  yield put(
    importModalState.set({
      status: ImportModalStatus.FINISHED,
    })
  );
  yield put(listVehicles.refresh());
  yield put(globalActions.listProducers.refresh());
  yield put(globalActions.listLines());
}

const vehiclesXlsx = createXlsxActions(NS.ARCHIVES.VEHICLES_XLSX, {
  exportOpts: {
    dataSource: exportDataSouce,
  },
  importOpts: {
    beforeImport,
    handleRecord: handleImportRecord,
    onError: () => {
      message.error("文件读取失败，请检查导入文件");
    },
    onFinished: onImportFinished,
    workerCount: 10,
  },
});

export const archivesActions = {
  listVehicles,
  vehiclesXlsx,
  importModalState,
  deleteArchives,
  deleteVehicle,
  createVehicle,
  updateVehicle,
  editorFormNs,
};
