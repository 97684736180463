import { HISTORY_NS } from "../../constants";
import tyreHistorySelectors from "../../selectors/history/tyre";
import makeHistoryActions from "./base";

const tyreHistoryActions = makeHistoryActions(
  HISTORY_NS.TYRE.LIST_WARNINGS,
  HISTORY_NS.TYRE.WARNINGS_XLSX,
  tyreHistorySelectors
);

export default tyreHistoryActions;
