export const TicketHandleProps = {
  待维修: {
    textTitle: "车辆状态",
    showHandleAt: false,
    defaultStage: "待维修",
    handleByTitle: "处置人",
    handleAtTitle: "处置时间",
  },
  维修中: {
    textTitle: "故障原因",
    handleByTitle: "维修人",
    showHandleAt: true,
    handleAtTitle: "维修时间",
    defaultStage: "维修中",
  },
  已完成: {
    textTitle: "维修内容",
    handleByTitle: "检验人",
    handleAtTitle: "完工时间",
    showHandleAt: true,
    showHandleResult: true,
    handleResultTitle: "维修结果",
    defaultStage: "已完成",
    submitTitle: "提交并完成工单",
  },
};

export const TicketStages = Object.keys(TicketHandleProps);

const defaultProps = {
  textTitle: "处理意见",
  handleByTitle: "提交人",
  handleAtTitle: "提交时间",
  submitTitle: "提交",
};

export function getTicketHandleProps(stage) {
  const props = TicketHandleProps[stage] || {};
  return {
    ...defaultProps,
    ...props,
  };
}
