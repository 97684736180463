import { createApiSelector } from "@36node/redux-api";
import { TICKETS_NS } from "../constants";
import {
  ticketSchema,
  userSchema,
  vehicleSchema,
  ticketStageSchema,
  alertSchema,
} from "../schemas";
import { createSelector } from "reselect";
import { createAssignSelector } from "@36node/redux-ui";

/**
 * 工单相关selector
 */

const listTickets = createApiSelector(TICKETS_NS.LIST_TICKETS, [ticketSchema]);

const listStages = createApiSelector(TICKETS_NS.LIST_STATGES, [
  ticketStageSchema,
]);

// listStageTickets 的内容在获取了stage以后saga里添加
const listStageTickets = {};
const listStageTicketsForExport = {};

const listUsers = createApiSelector(TICKETS_NS.LIST_USERS, [userSchema]);

const listVehicles = createApiSelector(TICKETS_NS.LIST_VEHICLES, [
  vehicleSchema,
]);

const stageEvent = createApiSelector(TICKETS_NS.STAGE_EVENT, ticketSchema);
const commentEvent = createApiSelector(TICKETS_NS.COMMENT_EVENT, ticketSchema);
const bindEvent = createApiSelector(TICKETS_NS.BIND_EVENT, ticketSchema);

const ticketQuery = createAssignSelector(TICKETS_NS.TICKETS_QUERY);

const getTicket = createApiSelector(TICKETS_NS.GET_TICKET, ticketSchema);

const getUser = createApiSelector(TICKETS_NS.GET_USER, userSchema);

const getVehicle = createApiSelector(TICKETS_NS.GET_VEHICLE, vehicleSchema);

const getAlert = createApiSelector(TICKETS_NS.GET_ALERT, alertSchema);

const selectdTicket = createAssignSelector(TICKETS_NS.SELETED_TICKET);

const eventLoading = createSelector(
  stageEvent,
  commentEvent,
  bindEvent,
  (...resps) => {
    return resps.reduce((acc, cur) => {
      return acc || cur.loading;
    }, false);
  }
);

const allLoading = createSelector(
  getTicket,
  getUser,
  getVehicle,
  listTickets,
  listUsers,
  listVehicles,
  listStages,
  (...resps) => {
    return resps.reduce((acc, cur) => {
      return acc || cur.loading;
    }, false);
  }
);

const initCreateLoading = createSelector(
  getAlert,
  getVehicle,
  (...resps) => {
    return resps.reduce((acc, cur) => {
      return acc || cur.loading;
    }, false);
  }
);

const ticket = createSelector(
  getTicket,
  getRep => {
    const result = getRep.result || {};
    return result;
  }
);

/**
 * 获取ticket 所有api 的loading 状态
 */
const ticketsLoading = createSelector(
  listTickets,
  listUsers,
  listVehicles,
  (...resps) => {
    return resps.reduce((acc, cur) => {
      return acc || cur.loading;
    }, false);
  }
);

const tickets = createSelector(
  listTickets,
  listRep => {
    const results = listRep.result || [];
    return results;
  }
);

const getTicketStatistics = createApiSelector(TICKETS_NS.STATISTICS);
const getTicketStatisticsByStage = createApiSelector(
  TICKETS_NS.STATISTICS_STAGE
);

/**
 * 选择stages
 */
const stages = s => {
  return [{ name: "待维修" }, { name: "维修中" }, { name: "已完成" }];
};

const createTicket = createApiSelector(TICKETS_NS.CREATE_TICKET, ticketSchema);

const listAlerts = createApiSelector(TICKETS_NS.LIST_ALERTS, [alertSchema]);

const listUIState = createAssignSelector(TICKETS_NS.LIST_UI_STATE);

const listTicketsForExport = createApiSelector(
  TICKETS_NS.LIST_TICKETS_FOR_EXPORT,
  [ticketSchema]
);

const TicketSelectors = {
  listTickets,
  getTicketStatistics,
  getTicketStatisticsByStage,
  getTicket,
  stageEvent,
  commentEvent,
  bindEvent,
  eventLoading,
  listStages,
  listStageTickets,
  listStageTicketsForExport,
  listTicketsForExport,
  listUsers,
  listVehicles,
  ticketsLoading,
  stages,
  tickets,
  ticketQuery,
  ticket,
  getAlert,
  selectdTicket,
  getVehicle,
  createTicket,
  initCreateLoading,
  allLoading,
  listAlerts,
  listUIState,
};

export default TicketSelectors;
