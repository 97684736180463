import React, { PureComponent } from "react";
import { List, Card, Icon } from "antd";
import styled from "styled-components";
import { get, reverse } from "lodash";

import { getPathname, ymdhms } from "../../lib";
import { getTicketHandleProps } from "../../lib/ticket";

export default class EventsList extends PureComponent {
  renderContent = event => {
    const lines = [];

    const {
      textTitle,
      handleAtTitle,
      handleByTitle,
      handleResultTitle,
      showHandleResult,
    } = getTicketHandleProps(event.stage);

    lines.push(
      <Line key="name">
        修改状态:<Value>{get(event, "stage", "--")}</Value>
      </Line>
    );
    lines.push(
      <Line key="content">
        {textTitle}:
        <Value>
          {(event.desc || "").split("\n").map((c, index) => (
            <div key={index}>{c}</div>
          ))}
        </Value>
      </Line>
    );

    showHandleResult &&
      lines.push(
        <Line key="handleResult">
          {handleResultTitle}:<Value>{get(event, "title", "--")}</Value>
        </Line>
      );

    lines.push(
      <Line key="time">
        {handleAtTitle}:<Value>{ymdhms(event.at)}</Value>
        <span style={{ padding: "0 32px" }} />
        {handleByTitle}:<Value>{event.by}</Value>
      </Line>
    );

    return lines;
  };

  render() {
    const { ticket = {}, loading } = this.props;
    const handleResults = reverse(ticket.handleResults || []);

    return (
      <Card title={<div style={{ fontWeight: 600 }}>历史事件记录</div>}>
        <List
          loading={loading}
          dataSource={handleResults}
          renderItem={item => (
            <List.Item>
              <ItemBox>
                <User>
                  <Icon type="user" />
                  <div>{getPathname(item.ns)}</div>
                </User>

                <Content>{this.renderContent(item)}</Content>
              </ItemBox>
            </List.Item>
          )}
        />
      </Card>
    );
  }
}

const Value = styled.div`
  color: black;
  margin-left: 12px;
`;

const Line = styled.div`
  color: #aaa;
  padding: 6px 0;
  display: flex;
`;

const ItemBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;

  .anticon {
    font-size: 48px;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding-left: 64px;
`;
