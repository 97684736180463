import { HISTORY_NS } from "../../constants";
import alertHistorySelectors from "../../selectors/history/alert";
import makeHistoryActions from "./base";
import { alertSchema, userSchema } from "../../schemas";
import { createApiActions } from "@36node/redux-api";
import { createCronActions } from "@36node/redux-cron";
import { put } from "redux-saga/effects";
import { authV1Sdk } from "../../sdk";

const alertHistoryActions = makeHistoryActions(
  HISTORY_NS.ALERT.LIST_ALERTS,
  HISTORY_NS.ALERT.WARNINGS_XLSX,
  alertHistorySelectors,
  [alertSchema]
);

export const listUsers = createApiActions(HISTORY_NS.ALERT.LIST_USERS, {
  endpoint: authV1Sdk.user.listUsers,
  schema: [userSchema],
});

export const alertHistoryCron = createCronActions(
  HISTORY_NS.ALERT.LIST_ALERTS_CRON,
  {
    onTick: function*() {
      yield put(alertHistoryActions.listRecords.refresh());
    },
  }
);

export default alertHistoryActions;
