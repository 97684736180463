/**
 * Ticket info
 */

import React, { PureComponent } from "react";
import styled from "styled-components";
import { Row, Col, Spin, Tag } from "antd";
import { get } from "lodash";
import { ymdhms, getPathname } from "../../lib";
import VehicleLink from "../vehicle-link";

const stageColors = {
  待维修: "magenta",
  维修中: "blue",
  已完成: "green",
};

export default class TicketInfo extends PureComponent {
  renderVehicleNo() {
    const { alert = {} } = this.props;

    const vehicleNo = get(alert, "device.no");
    const vin = get(alert, "vin");

    if (vehicleNo) {
      return <VehicleLink title={vehicleNo} vin={vin} />;
    }
    return "--";
  }

  render() {
    const { alert = {}, ticket = {}, loading = false } = this.props;
    const { title, stage } = ticket;

    return (
      <Spin spinning={loading}>
        <Box>
          <Title>
            <TicketIcon />
            <div className="title">{title}</div>
            <Tag color={stageColors[stage]}>{stage}</Tag>
          </Title>
          <Info>
            <div className="info">
              <Row>
                <Col span={8}>
                  <span>提交人: </span>
                  {get(ticket, "submitBy", "--")}
                </Col>
                <Col span={8}>
                  <span>车辆自编号: </span>
                  {this.renderVehicleNo()}
                </Col>
                <Col span={8}>
                  <span>部门: </span>
                  {getPathname(alert.ns)}
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <span>报警时间: </span>
                  {ymdhms(ticket.submitAt, "--")}
                </Col>
                <Col span={8}>
                  <span>车牌号: </span>
                  {get(alert, "plate", "--")}
                </Col>
                <Col span={8}>
                  <span>线路: </span>
                  {get(alert, "line", "--")}
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <span>工单编号: </span>
                  {get(ticket, "id", "--")}
                </Col>
                <Col span={8}>
                  <span>vin码: </span>
                  {get(alert, "vin", "--")}
                </Col>
                <Col span={8}>
                  <span>最后更新时间: </span>
                  {ymdhms(ticket.updateAt)}
                </Col>
              </Row>
            </div>
          </Info>
        </Box>
      </Spin>
    );
  }
}

const TicketIcon = styled.img.attrs({ src: "/images/ticket-icon.png" })`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const Box = styled.div`
  background: white;
  padding: 12px 24px 24px 24px;

  .title {
    font-size: 20px;
    font-weight: 600;
    padding-right: 8px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  padding-left: 44px;
  .ant-row {
    padding: 6px 0;
  }
  .info {
    flex-grow: 2;
    font-size: 16px;
    span {
      font-weight: 600;
    }
  }
  .progress {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
    .state {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;
