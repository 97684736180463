import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import styled from "styled-components";
import IconFont from "../iconfont";

const TabPane = Tabs.TabPane;

const Badge = styled.span`
  margin-left: 4px;
  font-size: 8px;
  padding: 0px 4px;
  color: #fff;
  background: #ff6044;
  border-radius: 2px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    background: white;
    padding: 0 24px;
    margin: 0 !important;
  }
`;

const TabTitle = ({ className, title, icon, badge }) => (
  <div className={className}>
    {icon && <IconFont type={icon} />}
    {title}
    {badge && <Badge>{badge}</Badge>}
  </div>
);

export default class SubMenuTabs extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string, // tab key
        title: PropTypes.string, // 标题
        icon: PropTypes.string, // tab 图标
        badge: PropTypes.number, //徽标数
        component: PropTypes.oneOfType([PropTypes.element, PropTypes.string]), // tab下显示的组件
        disabled: PropTypes.bool,
      })
    ),
  };

  render() {
    const { className, tabs = [], ...rest } = this.props;
    return (
      <StyledTabs className={className} {...rest}>
        {tabs.map(t => {
          const { disabled, ...tRest } = t;
          return (
            <TabPane
              disabled={disabled}
              tab={<TabTitle {...tRest} />}
              key={t.key}
            >
              {t.component}
            </TabPane>
          );
        })}
      </StyledTabs>
    );
  }
}
