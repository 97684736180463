import React from "react";
import { connect } from "react-redux";
import {
  Col,
  Row,
  Layout,
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Tooltip,
} from "antd";
import styled from "styled-components";
import DocumentTitle from "react-document-title";

import { APP_TITLE, AUTH_PROVIDER } from "../../config";
import { globalSelectors } from "../../selectors";
import { globalActions } from "../../actions";
import { Content } from "../../components/layout";
import Loading from "../../components/loading";
import { LOGIN_LOCK_DDL_KEY } from "../../constants";

const FormItem = Form.Item;
const inputPrefixIconStyle = {
  fontSize: "14px",
  color: "rgba(0,0,0,.25)",
};
const LoginContainer = styled("div")`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginBox = styled("div")`
  padding: 20px 60px 30px 60px;
  background: white;
  .login-title {
    padding: 24px;
    font-size: 24px;
    font-weight: 500;
    color: #1890ff;
    text-align: center;
  }
  .login-form {
    margin: 0 auto;
    max-width: 270px;
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
  .login-form-forgot {
    max-width: 270px;
  }
  .login-form-button {
    width: 100%;
  }
`;

const UserName = ({ form, placeholder, loading }) => (
  <Form.Item>
    {form.getFieldDecorator("username", {
      rules: [{ required: true, message: "请输入用户名" }],
    })(
      <Input
        size="large"
        prefix={<Icon type="user" style={inputPrefixIconStyle} />}
        placeholder={placeholder}
        disabled={loading}
      />
    )}
  </Form.Item>
);

const Password = ({ form, placeholder, loading }) => (
  <Form.Item>
    {form.getFieldDecorator("password", {
      rules: [{ required: true, message: "请输入密码" }],
    })(
      <Input
        size="large"
        type="password"
        prefix={<Icon type="lock" style={inputPrefixIconStyle} />}
        placeholder={placeholder}
        disabled={loading}
      />
    )}
  </Form.Item>
);

const Captcha = ({ svg, onClick }) => (
  <Tooltip title="点击刷新验证码">
    <div
      dangerouslySetInnerHTML={{ __html: svg }}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  </Tooltip>
);

const CaptchaCode = ({ form, loading, captchaSvg, onClick }) => (
  <Row align="middle">
    <Col span={16}>
      <Form.Item>
        {form.getFieldDecorator("captchaCode", {
          rules: [{ required: true, message: "请输入验证码" }],
        })(
          <Input
            prefix={<Icon type="lock" style={inputPrefixIconStyle} />}
            placeholder="验证码"
            size="large"
            disabled={loading}
          />
        )}
      </Form.Item>
    </Col>

    <Col span={8} style={{ marginTop: -10 }}>
      <Captcha svg={captchaSvg} onClick={onClick} />
    </Col>
  </Row>
);

@Form.create()
@connect(state => {
  const session = globalSelectors.session(state);
  const countDown = globalSelectors.loginCountDown(state).assign;
  const { result: captchaResult = {} } = globalSelectors.captcha(state) || {};
  return {
    loading: session.loading,
    captcha: captchaResult,
    countDown,
  };
})
export default class Login extends React.PureComponent {
  handleSubmit = e => {
    e.preventDefault();
    const { from = { pathname: "/" } } = this.props.location.state || {};

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch(
          globalActions.loginV1.request(
            {
              body: {
                provider: AUTH_PROVIDER,
                ...values,
                captchaKey: this.captchaKey,
              },
            },
            { from }
          )
        );
      }
    });
  };

  getCaptcha = () => {
    this.captchaKey = Date.now();
    this.props.dispatch(
      globalActions.getCaptcha.request({ key: this.captchaKey })
    );
  };

  componentDidMount() {
    this.getCaptcha();
  }

  componentDidUpdate() {
    const { countDown } = this.props;
    if (countDown > 0) {
      setTimeout(() => {
        this.props.dispatch(globalActions.loginCountDown.set(countDown - 1));
      }, 1000);
    } else {
      localStorage.removeItem(LOGIN_LOCK_DDL_KEY);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { form, loading, captcha, countDown } = this.props;
    const { message: captchaSvg } = captcha || {};

    if (loading) return <Loading />;

    return (
      <DocumentTitle title={APP_TITLE}>
        <Layout>
          <Content>
            <LoginContainer>
              <LoginBox>
                <div>
                  <div className="login-title">{APP_TITLE}新能源监控平台</div>
                </div>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <UserName
                    form={form}
                    placeholder="请输入用户名"
                    loading={loading}
                  />
                  <Password
                    form={form}
                    placeholder="请输入密码"
                    loading={loading}
                  />
                  <CaptchaCode
                    form={form}
                    loading={loading}
                    captchaSvg={captchaSvg}
                    onClick={this.getCaptcha}
                  />

                  <FormItem>
                    {getFieldDecorator("remember", {
                      valuePropName: "checked",
                      initialValue: true,
                    })(<Checkbox>自动登录</Checkbox>)}

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={loading}
                      disabled={countDown > 0}
                    >
                      {countDown > 0 ? `登录 (${countDown})` : "登录"}
                    </Button>
                  </FormItem>
                </Form>
              </LoginBox>
            </LoginContainer>
          </Content>
        </Layout>
      </DocumentTitle>
    );
  }
}
