/**
 * 报警按照统计分析
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import ReactEcharts from "echarts-for-react";
import { sumBy } from "lodash";
import styled from "styled-components";

const Box = styled(Row)`
  .legend {
    height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    color: rgba(0, 0, 0, 0.65);
    .item {
      margin-bottom: 12px;

      label {
        width: 120px;
        .icon {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #1890ff;
          margin-right: 8px;
        }
      }
      span {
        margin-left: 8px;
      }
    }
  }
`;

export default class AlertChartByState extends PureComponent {
  get colors() {
    return ["#1890ff", "#df273e"];
  }

  getOptions = data => {
    const total = sumBy(data, "value");

    return {
      color: this.colors,
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      grid: {
        height: 280,
      },
      legend: [
        {
          orient: "vertical",
          itemGap: 18,
          icon: "circle",
          itemHeight: 10,
          y: "center",
          right: "25%",
          textStyle: {
            fontSize: 16,
            color: "#595959",
          },
          data,
        },
        {
          orient: "vertical",
          itemGap: 18,
          icon: "none",
          y: "center",
          right: "15%",
          data,
          formatter: function(name) {
            const found = data.find(d => d.name === name);

            return ((found.value / total) * 100).toFixed(2) + "%";
          },
          textStyle: {
            color: "rgba(0, 0, 0, 0.427450980392157)",
            fontSize: 16,
          },
        },
        {
          orient: "vertical",
          itemGap: 18,
          icon: "none",
          y: "center",
          right: "5%",
          data,
          formatter: function(name) {
            const found = data.find(d => d.name === name);

            return found.value + "起";
          },
          textStyle: {
            color: "rgba(0, 0, 0, 0.427450980392157)",
            fontSize: 16,
          },
        },
      ],
      series: [
        {
          name: "",
          type: "pie",
          radius: "80%",
          center: ["40%", "50%"],
          data,
          itemStyle: {
            emphasis: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  };

  render() {
    const { data = [] } = this.props;

    const option = this.getOptions(data);

    return (
      <Box>
        <Col span={24}>
          <ReactEcharts style={{ marginRight: "10%" }} option={option} />
        </Col>
      </Box>
    );
  }
}
