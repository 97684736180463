import { createApiActions, successOf, isSuccess } from "@36node/redux-api";
import { warningSchema } from "../../schemas";
import { mekong } from "../../sdk";
import { createXlsxActions } from "@36node/redux-xlsx";
import { message } from "antd";
import { put, take, select } from "redux-saga/effects";

export default function makeHistoryActions(
  apiKey,
  xlsxKey,
  selectors,
  schema = [warningSchema]
) {
  /**
   * list for table
   */
  const listRecords = createApiActions(apiKey, {
    endpoint: mekong.listDeviceFaultRecords,
    schema,
  });

  const listRecordsForExport = createApiActions(xlsxKey, {
    endpoint: mekong.listDeviceFaultRecords,
    schema,
  });

  /**
   * 车辆异常数据
   * @param {*} params
   */
  function* exportDataSouce(params = {}) {
    const apiRequest = listRecordsForExport.request;

    yield put(apiRequest(params));

    const apiResultAction = yield take(successOf(xlsxKey));

    if (isSuccess(apiResultAction)) {
      const apiResult = yield select(selectors.listRecordsForExport);
      return apiResult.result;
    } else {
      message.error("异常数据导出失败");
      return;
    }
  }

  const recordsXlsx = createXlsxActions(xlsxKey, {
    exportOpts: {
      dataSource: exportDataSouce,
    },
  });

  return {
    listRecords,
    recordsXlsx,
  };
}
