/**
 * 工单处理
 */

import React, { PureComponent } from "react";
import { Card, Icon, Form, Input, Select, Button, DatePicker } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import { get } from "lodash";
import moment from "moment";

import { globalSelectors, TicketSelectors } from "../../selectors";
import { getTicketHandleProps, TicketStages } from "../../lib/ticket";

@Form.create()
@connect(state => {
  const loginUser = globalSelectors.loginUser(state);
  const loading = TicketSelectors.eventLoading(state);
  const session = globalSelectors.session(state).result || { user: {} };

  return {
    loginUser,
    loading,
    session,
  };
})
export default class HandleForm extends PureComponent {
  state = {
    stage: this.props.defaultStage || "",
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values = {}) => {
      if (!err) {
        const { onSubmit, loginUser } = this.props;

        if (onSubmit) {
          onSubmit(values, loginUser);
          this.props.form.resetFields();
          this.setState({ stage: "" });
        }
      }
    });
  };

  handleStageChange = stage => {
    this.setState({ stage });
  };

  render() {
    const {
      loginUser = {},
      form: { getFieldDecorator },
      loading,
      session: { user = {} },
    } = this.props;

    const {
      textTitle,
      handleAtTitle,
      handleByTitle,
      handleResultTitle,
      showHandleAt,
      showHandleResult,
      submitTitle,
    } = getTicketHandleProps(this.state.stage);

    return (
      <Card title={<div style={{ fontWeight: 600 }}>报警处理</div>}>
        <Box>
          <User>
            <Icon type="user" />
            <div>{get(loginUser, "departments[0]")}</div>
          </User>

          <StyledForm>
            <Form.Item label="修改状态" {...formItemLayout}>
              {getFieldDecorator("stage", {
                initialValue: this.props.defaultStage,
                rules: [
                  {
                    required: true,
                    message: `请选择状态`,
                  },
                ],
              })(
                <Select
                  disabled={loading}
                  style={{ width: 250 }}
                  allowClear
                  placeholder="请选择工单状态"
                  onChange={this.handleStageChange}
                >
                  {TicketStages.map(s => {
                    return <Select.Option key={s}>{s}</Select.Option>;
                  })}
                </Select>
              )}
            </Form.Item>

            <Form.Item label={textTitle} {...formItemLayout}>
              {getFieldDecorator("desc", {
                rules: [
                  {
                    required: true,
                    message: `请输入${textTitle}`,
                  },
                ],
              })(<Input.TextArea disabled={loading} />)}
            </Form.Item>

            {showHandleResult && (
              <Form.Item label={handleResultTitle} {...formItemLayout}>
                {getFieldDecorator("title", {
                  rules: [
                    {
                      required: true,
                      message: `请输入${handleResultTitle}`,
                    },
                  ],
                })(<Input style={{ width: 250 }} />)}
              </Form.Item>
            )}

            {showHandleAt && (
              <Form.Item label={handleAtTitle} {...formItemLayout}>
                {getFieldDecorator("at", {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: `请输入${handleAtTitle}`,
                    },
                  ],
                })(<DatePicker showTime style={{ width: 250 }} />)}
              </Form.Item>
            )}

            <Form.Item label={handleByTitle} {...formItemLayout}>
              {getFieldDecorator("by", {
                initialValue: user.nickname,
                rules: [
                  {
                    required: true,
                    message: `请输入${handleByTitle}`,
                  },
                ],
              })(<Input disabled={loading} style={{ width: 250 }} />)}
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button
                loading={loading}
                onClick={this.handleSubmit}
                type="primary"
              >
                {submitTitle}
              </Button>
            </Form.Item>
          </StyledForm>
        </Box>
      </Card>
    );
  }
}

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled(Form)`
  flex-grow: 1;
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;

  .anticon {
    font-size: 48px;
  }
`;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 12 },
};

const formTailLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 2, offset: 13 },
};
