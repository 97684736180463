import React, { PureComponent } from "react";
import { DatePicker, Radio } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
const getOptionRange = option => {
  switch (option) {
    case "lastMonth":
      return [
        moment()
          .subtract(1, "month")
          .startOf("month"),
        moment()
          .subtract(1, "month")
          .endOf("month"),
      ];
    case "month":
      return [moment().startOf("month"), moment().endOf("day")];
    case "year":
      return [moment().startOf("year"), moment().endOf("day")];
    default:
      return [moment(), moment()];
  }
};

export class RangeOptionsPicker extends PureComponent {
  state = {
    option: "lastMonth",
    range: [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month"),
    ],
  };

  handleRangeOptionsChange = val => {
    const range = getOptionRange(val);
    this.setState({ option: val, range });
    if (this.props.onChange) {
      this.props.onChange(range);
    }
  };

  handleRangePickerChange = val => {
    this.setState({ range: val, option: undefined });
    if (this.props.onChange) {
      this.props.onChange(val);
    }
  };

  render() {
    return (
      <span>
        <Radio.Group
          value={this.state.option}
          onChange={e => this.handleRangeOptionsChange(e.target.value)}
        >
          <Radio.Button value="lastMonth">上月</Radio.Button>
          <Radio.Button value="month">本月</Radio.Button>
          <Radio.Button value="year">今年</Radio.Button>
        </Radio.Group>
        <RangePicker
          style={{ marginLeft: 12, width: 300 }}
          placeholder={["开始日期", "结束日期"]}
          allowClear={false}
          value={this.state.range}
          mode={["date", "date"]}
          showTime={false}
          onChange={this.handleRangePickerChange}
        />
      </span>
    );
  }
}
