import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";

import Layout from "../../../components/layout";
import SubMenuTabs from "../../../components/sub-menu-tabs";
import Monitor from "./monitor";
import Offsite from "./off-site";
import Warning from "./warning";
import withPermission from "../../with-permission";
import Permission from "../../../config/permission";

const { Content, Header } = Layout;

/**
 * Main Container
 */
const Full = styled.div`
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
`;

const items = [
  {
    key: "monitor",
    pathname: "/vehicle-monitor/terminal/monitor",
    title: "终端监控",
  },
  {
    key: "notOnSite",
    pathname: "/vehicle-monitor/terminal/off-site",
    title: "未接入车辆",
  },
  {
    key: "dataException",
    pathname: "/vehicle-monitor/terminal/warning",
    title: "终端异常",
  },
];

@withPermission({
  permission: Permission.vehicleMonitor.terminal,
})
@connect(state => {
  return {
    tabItems: items,
  };
})
export default class Terminal extends PureComponent {
  handleChange = key => {
    const { tabItems, history } = this.props;
    const item = tabItems.find(it => it.key === key) || tabItems[0];
    history.push(item.pathname);
  };

  getActiveKey = () => {
    const { location } = this.props;

    const paths = location.pathname.split("/").slice(0, 4);
    return paths.join("/");
  };
  render() {
    const { tabItems } = this.props;

    const item =
      tabItems.find(i => i.pathname === this.getActiveKey()) || tabItems[0];

    return (
      <Container>
        <Header
          style={{ height: "max-content", padding: 0, background: "white" }}
        >
          <SubMenuTabs
            tabs={tabItems}
            activeKey={item.key}
            onChange={this.handleChange}
            style={{
              zIndex: 2,
              height: "100%",
            }}
          />
        </Header>
        <Content style={{ height: "calc(100% - 44px)" }}>
          <Full>
            <Switch>
              <Route
                path="/vehicle-monitor/terminal/monitor"
                component={Monitor}
              />
              <Route
                path="/vehicle-monitor/terminal/off-site"
                component={Offsite}
              />
              <Route
                path="/vehicle-monitor/terminal/warning"
                component={Warning}
              />
              <Redirect
                exact
                from="/vehicle-monitor/terminal"
                to="/vehicle-monitor/terminal/monitor"
              />
            </Switch>
          </Full>
        </Content>
      </Container>
    );
  }
}
