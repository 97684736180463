import {
  FaultRecordGroupField,
  VehicleGroupField,
  MekongAPIClient,
  WorkOrderGroupField,
} from "@36node-mekong/sdk-ts";
import axios from "axios";
import { get, uniq, round } from "lodash";
import moment from "moment";

import * as config from "../config";
import { injectToken, paramsSerializer, transferResponse } from "./helpers";

const VehicleGToFaultG = {
  [VehicleGroupField.NS]: FaultRecordGroupField.NS,
  [VehicleGroupField.LINE]: FaultRecordGroupField.LINE,
  [VehicleGroupField.MODEL]: FaultRecordGroupField.DEVICE_MODEL,
  [VehicleGroupField.MANUFACTURER]: FaultRecordGroupField.DEVICE_MANUFACTURER,
  type: "type",
};

const VehicleGToTicketG = {
  [VehicleGroupField.NS]: WorkOrderGroupField.NS,
  [VehicleGroupField.LINE]: WorkOrderGroupField.LINE,
  [VehicleGroupField.MODEL]: WorkOrderGroupField.DEVICES_MODEL,
  [VehicleGroupField.MANUFACTURER]: WorkOrderGroupField.DEVICES_MANUFACTURER,
};

const genId = fields => entity => {
  const id = fields.map(field => get(entity, field)).join("-");
  return { ...entity, id };
};

export class Mekong extends MekongAPIClient {
  /**
   * 如果有自定义的方法，此处实现
   */

  /**
   * 车辆日常报表
   * 将车辆统计和报警统计合并
   * @param {import('@36node-mekong/sdk-ts').AggregateVehiclesRequest & { at_gt?: string; at_lt?: string; _faultType?: boolean; _names?: string[];}} req
   * @returns
   */
  getWarningStatistics = async (req, config) => {
    const groupV = req._group;
    const names = req._names || [1, 2, 3];

    // Falut
    const groupF = groupV
      .map(item => VehicleGToFaultG[item])
      .concat(
        req._names ? FaultRecordGroupField.NAME : FaultRecordGroupField.LEVEL
      );
    const resF = await this.aggregateFaultRecords(
      {
        _group: groupF,
        _limit: 10000,
        category: req.category,
        type: req.type,
        brokenAt_gte: req.at_gt,
        brokenAt_lte: req.at_lt,
        ns_like: req.ns_like,
      },
      config
    );
    const bodyF = resF.body.map(genId(groupF));
    const mapF = bodyF.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    if (req._faultType) {
      // 这个分支流程 只针对 报警 分类统计表，比较特殊
      // 现在我们只关心 type 和 level， hardcode
      const keys = uniq(bodyF.map(item => item.type));
      const body = keys.map(key => {
        const l1 = mapF[key + "-1"];
        const l2 = mapF[key + "-2"];
        const l3 = mapF[key + "-3"];
        return {
          id: key,
          type: key,
          level1: get(l1, "count", 0),
          level2: get(l2, "count", 0),
          level3: get(l3, "count", 0),
        };
      });
      return { body };
    }

    // 车辆
    const resV = await this.aggregateVehicles(
      {
        _group: groupV,
        _limit: 10000,
        ns_like: req.ns_like,
      },
      config
    );
    const bodyV = resV.body.map(genId(groupV));

    const body = bodyV.map(item => {
      const agg = names.reduce((acc, cur) => {
        acc[req._names ? cur : `level${cur}`] = get(
          mapF[`${item.id}-${cur}`],
          "count",
          0
        );
        return acc;
      }, {});

      return {
        ...item,
        ...agg,
        vehicleCount: item.count,
      };
    });

    return { body };
  };

  /**
   * 工单日常报表
   * 将车辆统计和工单统计合并
   * @param {import('@36node-mekong/sdk-ts').AggregateVehiclesRequest & { at_gt: string; at_lt: string; }} req
   * @returns
   */
  getTicketStatistics = async (req, config) => {
    const groupV = req._group;

    // 工单
    const groupT = groupV
      .map(item => VehicleGToTicketG[item])
      .concat(WorkOrderGroupField.STAGE);
    const resT = await this.aggregateWorkOrders(
      {
        _group: groupT,
        createAt_gte: req.at_gt,
        createAt_lte: req.at_lt,
      },
      config
    );
    const bodyT = resT.body.map(genId(groupT));
    const mapT = bodyT.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    // 车辆
    const resV = await this.aggregateVehicles(req, config);
    const bodyV = resV.body.map(genId(groupV));

    // 整合
    const body = bodyV.map(item => {
      const l1 = mapT[item.id + "-待维修"];
      const l2 = mapT[item.id + "-维修中"];
      const l3 = mapT[item.id + "-已完成"];
      return {
        ...item,
        待维修: get(l1, "count", 0),
        维修中: get(l2, "count", 0),
        已完成: get(l3, "count", 0),
        vehicleCount: item.count,
      };
    });

    return { body };
  };

  /**
   * 获取车辆快照统计分析
   * 将车辆统计和车辆快照统计合并
   * @param {import('@36node-mekong/sdk-ts').AggregateVehicleSnapshotRequest} req 请求参数
   * @param {*} config axios config
   * @returns
   */
  getSnapshotStatistics = async (req, config) => {
    const group = req._group;
    const span = moment(req.at_lte).diff(moment(req.at_gte), "day") + 1;

    // 快照
    const resT = await this.aggregateVehicleSnapshot(
      {
        _group: group,
        at_lte: req.at_lte,
        at_gte: req.at_gte,
        ns_like: req.ns_like,
      },
      config
    );
    const bodyT = resT.body.map(genId(group));
    const mapT = bodyT.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    // 车辆
    const resV =
      group[0] === "no"
        ? await this.listVehicles({
            _limit: 10000,
            sort: "no",
            ns_like: req.ns_like,
          })
        : await this.aggregateVehicles(
            { _group: group, ns_like: req.ns_like },
            config
          );
    const bodyV = resV.body.map(genId(group));

    // 整合
    const body = bodyV.map(item => {
      const t = mapT[item.id];
      const mileage = get(t, "mileage", 0);
      const consumption = get(t, "discharge", 0);
      const mileageVehicles = get(t, "vehicleCount", 0);
      const mileageAvg = round(mileage / mileageVehicles / span, 1);
      const consumptionAvg = round((consumption * 100) / mileage, 1);

      return {
        ...item,
        mileage,
        mileageAvg,
        consumption,
        consumptionAvg,
        mileageVehicles,
        vehicleCount: item.count,
        vehicles: item.count,
      };
    });

    return { body };
  };

  /**
   * 获取状态数据快照分析
   * @param {import('@36node-mekong/sdk-ts').ListStatusSnapshotsRequest} req 请求参数
   * @param {*} config axios config
   */
  getStatusHistory = async (req, config) => {
    const res = await this.listStatusSnapshots(req, config);
    const body = res.body.map(item => ({
      ...item,
      hour: moment(item.at).format("YYYY-MM-DD-HH"),
    }));

    return { body };
  };
}

const mekongAxiosInstance = axios.create({
  baseURL: config.MEKONG_ENDPOINT,
  paramsSerializer,
});
mekongAxiosInstance.interceptors.request.use(injectToken);
mekongAxiosInstance.interceptors.response.use(transferResponse);

export const mekong = new Mekong(mekongAxiosInstance);
