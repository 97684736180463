import { createApiSelector } from "@36node/redux-api";
import { createSelector } from "reselect";
import { FaultRecordStatus } from "@36node-mekong/sdk-ts";
import { get } from "lodash";

import { ANALYSIS_NS } from "../constants";
import globalSelectors from "./global";

const getAlertAnalysis = createApiSelector(ANALYSIS_NS.GET_ALERT_ANALYSIS);

const getAlertRateAnalysis = createApiSelector(
  ANALYSIS_NS.GET_ALERT_RATE_ANALYSIS
);

const getAllAlertsAnalysis = createApiSelector(
  ANALYSIS_NS.GET_ALL_ALERTS_ANALYSIS
);

const aggOnsiteVehicle = createApiSelector(ANALYSIS_NS.AGG_ONSITE_VEHICLE);
const aggFaultStatus = createApiSelector(ANALYSIS_NS.AGG_FAULT_STATUS);
const aggFaultDate = createApiSelector(ANALYSIS_NS.AGG_FAULT_DATE);
const aggFaultType = createApiSelector(ANALYSIS_NS.AGG_FAULT_TYPE);
const aggFaultName = createApiSelector(ANALYSIS_NS.AGG_FAULT_NAME);
const aggFaultModel = createApiSelector(ANALYSIS_NS.AGG_FAULT_MODEL);
const aggFaultNs = createApiSelector(ANALYSIS_NS.AGG_FAULT_NS);
const aggFaultLevel = createApiSelector(ANALYSIS_NS.AGG_FAULT_LEVEL);
const aggFaultRateNs = createApiSelector(ANALYSIS_NS.AGG_FAULT_RATE_NS);
const aggFaultRateModel = createApiSelector(ANALYSIS_NS.AGG_FAULT_RATE_MODEL);
const aggOpenTicketStage = createApiSelector(ANALYSIS_NS.AGG_OPEN_TICKET_STAGE);
const aggOrderNs = createApiSelector(ANALYSIS_NS.AGG_ORDER_NS);
const aggOrderIsFull = createApiSelector(ANALYSIS_NS.AGG_ORDER_IS_FULL);
const aggOrderStation = createApiSelector(ANALYSIS_NS.AGG_ORDER_STATION);
const aggStopOrderStation = createApiSelector(
  ANALYSIS_NS.AGG_STOP_ORDER_STATION
);
const aggStopOrderSource = createApiSelector(ANALYSIS_NS.AGG_STOP_ORDER_SOURCE);
const aggStopOrderName = createApiSelector(ANALYSIS_NS.AGG_STOP_ORDER_NAME);

const overall = createSelector(
  [aggOnsiteVehicle, aggFaultStatus, aggOpenTicketStage],
  (aggOnsiteVehicleState, aggFaultStatusState, aggOpenTicketStageState) => {
    const aggOnsiteVehicleResult = aggOnsiteVehicleState.result || [];
    const aggFaultStatusResult = aggFaultStatusState.result || [];
    const aggOpenTicketStageResult = aggOpenTicketStageState.result || [];

    const totalMileage = aggOnsiteVehicleResult.reduce(
      (acc, item) => acc + Number(item.mileage),
      0
    );
    const totalVehicle = aggOnsiteVehicleResult.reduce(
      (acc, item) => acc + Number(item.count),
      0
    );
    const totalFault = aggFaultStatusResult.reduce(
      (acc, item) => acc + Number(item.count),
      0
    );
    const brokenFault = aggFaultStatusResult.find(
      item => item.status === FaultRecordStatus.BROKEN
    ) || { count: 0 };
    const recoverFault = aggFaultStatusResult.find(
      item => item.status === FaultRecordStatus.RECOVER
    ) || { count: 0 };

    const repairingVehicle = aggOpenTicketStageResult.find(
      item => item.stage === "维修中"
    ) || { count: 0 };

    return {
      recoverRate:
        recoverFault.count / (recoverFault.count + brokenFault.count),
      vehicleHealthRate: (totalVehicle - repairingVehicle.count) / totalVehicle,
      mileage10000FaultRate: (totalFault / totalMileage) * 10000,
    };
  }
);

const findTopNs = (parents, ns) => parents.find(item => ns.includes(item.id));

const charts = createSelector(
  [
    globalSelectors.namespacesTreeNoRoot,
    aggOpenTicketStage,
    aggFaultDate,
    aggFaultType,
    aggFaultName,
    aggFaultModel,
    aggFaultNs,
    aggFaultLevel,
    aggFaultRateNs,
    aggFaultRateModel,
  ],
  (
    nsTree,
    aggOpenTicketStageState,
    aggFaultDateState,
    aggFaultTypeState,
    aggFaultNameState,
    aggFaultModelState,
    aggFaultNsState,
    aggFaultLevelState,
    aggFaultRateNsState,
    aggFaultRateModelState
  ) => {
    const aggOpenTicketStageResult = aggOpenTicketStageState.result || [];
    const TicketByStage = aggOpenTicketStageResult.map(item => ({
      name: item.stage,
      value: item.count,
    }));

    const aggFaultNsResult = aggFaultNsState.result || [];
    const AlertByNs = nsTree.map(item => {
      const name = item.name;
      const ns = item.id;

      return {
        name,
        ns,
        count: aggFaultNsResult.reduce((acc, item) => {
          if (item.ns.includes(ns)) {
            return acc + item.count;
          }
          return acc;
        }, 0),
      };
    });

    const aggFaultRateNsResult = aggFaultRateNsState.result || [];
    const AlertRateByNs = aggFaultRateNsResult.reduce((acc, item) => {
      const parent = findTopNs(nsTree, item.ns);
      if (parent) {
        const nsItem = (acc[parent.id] = acc[parent.id] || {
          name: parent.name,
          ns: parent.id,
          data: {},
        });
        const monthStr = `${item.at.year}-${item.at.month}`;
        const monthItem = (nsItem.data[monthStr] = nsItem.data[monthStr] || {
          mileage: 0,
          faultCount: 0,
        });
        monthItem.mileage += item.mileage;
        monthItem.faultCount += item.faultCount;
      }
      return acc;
    }, {});

    const aggFaultRateModelResult = aggFaultRateModelState.result || [];
    const AlertRateByModel = aggFaultRateModelResult.reduce((acc, item) => {
      const modelItem = (acc[item.model] = acc[item.model] || {
        name: item.model,
        data: {},
      });

      const monthStr = `${item.at.year}-${item.at.month}`;
      const monthItem = (modelItem.data[monthStr] = modelItem.data[
        monthStr
      ] || {
        mileage: 0,
        faultCount: 0,
      });
      monthItem.mileage += item.mileage;
      monthItem.faultCount += item.faultCount;
      return acc;
    }, {});

    return {
      TicketByStage,
      AlertByDate: aggFaultDateState.result || [],
      AlertByType: aggFaultTypeState.result || [],
      AlertByName: aggFaultNameState.result || [],
      AlertByModel: aggFaultModelState.result || [],
      AlertByNs,
      AlertByLevel: aggFaultLevelState.result || [],
      AlertRateByNs: Object.values(AlertRateByNs),
      AlertRateByModel: Object.values(AlertRateByModel),
    };
  }
);

/**
 * 充电习惯分析
 */
const chargingCharts = createSelector(
  [
    globalSelectors.namespacesTreeNoRoot,
    aggOrderNs,
    aggOrderIsFull,
    aggOrderStation,
  ],
  (nsTree, aggOrderNsState, aggOrderIsFullState, aggOrderStationState) => {
    const aggOrderNsResult = aggOrderNsState.result || [];
    const aggOrderIsFullResult = aggOrderIsFullState.result || [];
    const aggOrderStationResult = aggOrderStationState.result || [];

    const OrderByNs = nsTree.map(item => {
      const name = item.name;
      const ns = item.id;
      const vehicleCount = aggOrderNsResult.reduce((acc, item) => {
        if (item.ns.includes(ns)) {
          return acc + item.vehicleCount;
        }
        return acc;
      }, 0);
      const elec = aggOrderNsResult.reduce((acc, item) => {
        if (item.ns.includes(ns)) {
          return acc + item.elec;
        }
        return acc;
      }, 0);
      const details = aggOrderNsResult
        .reduce((acc, item) => {
          if (item.ns.includes(ns)) {
            return acc.concat(item.details);
          }
          return acc;
        }, [])
        .reduce((acc, item) => {
          acc[item.type] = (acc[item.type] || 0) + item.elec;
          return acc;
        }, {});

      return {
        name,
        ns,
        value: vehicleCount,
        elec,
        details,
      };
    });
    // .sort((a, b) => b.value - a.value);
    const OrderByNsTotal = OrderByNs.reduce((acc, item) => acc + item.value, 0);

    const OrderByIsFull = nsTree.map(item => {
      const name = item.name;
      const ns = item.id;
      const full = aggOrderIsFullResult.reduce((acc, item) => {
        if (item.ns.includes(ns) && item.isFull) {
          return acc + item.vehicleCount;
        }
        return acc;
      }, 0);
      const all = aggOrderIsFullResult.reduce((acc, item) => {
        if (item.ns.includes(ns)) {
          return acc + item.vehicleCount;
        }
        return acc;
      }, 0);

      return {
        name,
        ns,
        value: all ? Math.round((full / all) * 100) : 0,
      };
    });
    // .sort((a, b) => b.value - a.value);

    const OrderByStation = aggOrderStationResult.map(item => ({
      station: item.station,
      value: Math.round(item.elec / 1000),
    }));

    return {
      OrderByNs,
      OrderByNsTotal,
      OrderByIsFull,
      OrderByStation,
      loading:
        aggOrderNsState.loading ||
        aggOrderIsFullState.loading ||
        aggOrderStationState.loading,
    };
  }
);

/**
 * 充电习惯分析
 */
const chargeAlertCharts = createSelector(
  [aggStopOrderName, aggStopOrderSource, aggStopOrderStation],
  (
    aggStopOrderNameState,
    aggStopOrderSourceState,
    aggStopOrderStationState
  ) => {
    const aggStopOrderNameResult = aggStopOrderNameState.result || [];
    const aggStopOrderSourceResult = aggStopOrderSourceState.result || [];
    const aggStopOrderStationResult = aggStopOrderStationState.result || [];

    const StopOrderByStationTotal = aggStopOrderStationResult.reduce(
      (acc, item) => acc + item.count,
      0
    );
    const StopOrderBySource = aggStopOrderSourceResult.map(item => ({
      name: item.source,
      value: item.count,
    }));

    const StopOrderByStation = aggStopOrderStationResult.map(item => ({
      station: item.station,
      value: item.count,
    }));

    const StopOrderByName = aggStopOrderNameResult.map(item => ({
      name: get(item, "stopReason.name"),
      count: item.count,
      value: ((item.count / StopOrderByStationTotal) * 100).toFixed(0),
    }));

    const otherNameCount =
      StopOrderByStationTotal -
      StopOrderByName.reduce((acc, item) => acc + item.count, 0);

    StopOrderByName.push({
      name: "其他",
      count: otherNameCount,
      value: ((otherNameCount / StopOrderByStationTotal) * 100).toFixed(0),
    });

    return {
      StopOrderBySource,
      StopOrderByStation,
      StopOrderByName,
      loading:
        aggStopOrderStationState.loading ||
        aggStopOrderNameState.loading ||
        aggStopOrderSourceState.loading,
    };
  }
);

const AnalysisSelectors = {
  getAlertAnalysis,
  getAlertRateAnalysis,
  getAllAlertsAnalysis,
  overall,
  charts,
  chargingCharts,
  chargeAlertCharts,
};

export default AnalysisSelectors;
