import React, { PureComponent } from "react";
import { Row, Col, Table } from "antd";
import styled from "styled-components";
import ReactEcharts from "echarts-for-react";

const Box = styled(Row)`
  width: 100%;
  height: 100%;

  .ant-table-thead {
    th {
      padding: 10px 16px;
    }
  }

  .ant-table-tbody {
    td {
      padding: 10px 16px;
    }
  }

  .ant-table-thead > tr > th {
    background: #fff;
    border-bottom: 0;
  }
`;

const DotSpan = styled.span`
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background-color: ${props => props.color || "#1890FF"};
`;

const colors = [
  "#1890FF",
  "#13C2C2",
  "#2FC25B",
  "#FACC14",
  "#F04864",
  "#8543E0",
];

export class StopOrderByNameChart extends PureComponent {
  getOption = () => {
    const { data = [] } = this.props;

    const total = data.reduce((acc, cur) => acc + cur.count, 0);
    return {
      title: {
        text: "报警总数",
        subtext: total,
        textStyle: {
          fontSize: 18,
          color: "rgba(0,0,0,0.45)",
          fontWeight: 500,
        },
        subtextStyle: {
          fontSize: 24,
          color: "#000000",
          fontWeight: 500,
        },
        textAlign: "center",
        left: "49.5%",
        top: "40%",
      },
      color: colors,
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      series: [
        {
          type: "pie",
          radius: ["50%", "65%"],
          center: ["50%", "50%"],
          label: {
            position: "outer",
          },
          labelLine: {
            length: 30,
            length2: 70,
          },
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          data,
        },
      ],
    };
  };

  render() {
    const { data = [] } = this.props;

    const columns = [
      {
        title: "报警名",
        dataIndex: "name",
        render: (val, _, index) => (
          <>
            <DotSpan color={colors[index]} />
            {val}
          </>
        ),
      },
      {
        title: "占比",
        dataIndex: "value",
        render: val => `${val}%`,
      },
      {
        title: "次数",
        dataIndex: "count",
        render: val => `${val}次`,
      },
    ];

    return (
      <Box type="flex" align="top">
        <Col span={16}>
          <ReactEcharts option={this.getOption()} />
        </Col>
        <Col span={8}>
          <Table
            pagination={false}
            rowKey="name"
            columns={columns}
            dataSource={data}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Box>
    );
  }
}
