/**
 * 创建，绑定，查看工单页面的布局
 */

import styled from "styled-components";
import React from "react";
import { Button, Icon } from "antd";
import { history } from "../../lib";

export function TicketLayout({ children }) {
  const onBackClick = () => {
    history.goBack();
  };

  return (
    <Container>
      <Header>
        <Nav>
          <Button
            style={{ color: "#1890FF" }}
            type="default"
            onClick={onBackClick}
          >
            <Icon type="left" />
            返回
          </Button>
        </Nav>
      </Header>

      <Content>{children}</Content>
    </Container>
  );
}

const Container = styled.div``;

const Content = styled.div``;

const Header = styled.div`
  background: white;
`;

const Nav = styled.div`
  .ant-btn {
    border: 0;
  }
  padding: 12px 8px;
`;
