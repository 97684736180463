import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { without } from "lodash";
import moment from "moment";
import { StyledFilterForm } from "../../../components/fold-filter-form";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Table,
  Card,
  Dropdown,
  Icon,
} from "antd";
import TerminalActions from "../../../actions/terminal";
import TerminalSelectors from "../../../selectors/terminal";
import { tableStateToQuery, getPathname } from "../../../lib";
import { friendly } from "../../../lib/time";
import {
  VehicleTermStateI18N,
  VehicleTermState,
  VehicleRunStateI18N,
  VehicleRunState,
} from "../../../constants";
import VehicleStateSelect from "../../../components/vehicle-state-select";
import FilterDropdown from "../../../components/filter-dropdown";
import withPermission from "../../with-permission";
import Permission from "../../../config/permission";
import VehicleLink from "../../../components/vehicle-link";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};

const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8, offset: 6 },
};

@connect(state => {
  return TerminalSelectors.listTerminals(state);
})
@Form.create()
class FilterFrom extends PureComponent {
  handleRest = () => {
    this.props.form.resetFields();
    const initQuery = { limit: 10, offset: 0, filter: {} };
    this.listTerminals(initQuery);
  };

  componentWillUnmount() {
    this.props.dispatch(TerminalActions.listTerminals.clear());
  }

  listTerminals = newQuery => {
    const { request } = this.props;
    const finialQuery = { ...request, ...newQuery };

    this.props.dispatch(TerminalActions.listTerminals.request(finialQuery));
  };

  handleSumbit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { vin, terminalStatus } = values;
        const filter = {
          vin,
          _offset: 0,
          _limit: 10,
          at_lt: undefined,
          at_gt: undefined,
        };

        // 离线的数据
        if (terminalStatus === VehicleTermState.OFFLINE) {
          filter.status = VehicleRunState.OFFLINE;
        }

        // 在线的数据
        if (terminalStatus === VehicleTermState.ONLINE) {
          filter.status = [
            VehicleRunState.CHARGING,
            VehicleRunState.RUNNING,
            VehicleRunState.STOP,
          ];
        }

        this.listTerminals(filter);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card style={{ marginTop: 12 }}>
        <StyledFilterForm
          style={{ marginBottom: 0 }}
          onSubmit={this.handleSumbit}
        >
          <Row gutter={48} type="flex">
            <Col span={8}>
              <Form.Item label="车辆VIN码" {...formItemLayout}>
                {getFieldDecorator("vin", {})(
                  <Input allowClear placeholder="请输入车辆VIN码" />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="终端状态" {...formItemLayout}>
                {getFieldDecorator("terminalStatus")(
                  <VehicleStateSelect allowClear placeholder="请选择" />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item {...formTailLayout}>
                <Button onClick={this.handleRest}>重置</Button>

                <Button
                  style={{ marginLeft: 24 }}
                  type="primary"
                  htmlType="submit"
                >
                  筛选
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </StyledFilterForm>
      </Card>
    );
  }
}

@connect(state => {
  const tableState = TerminalSelectors.terminalsTable(state);
  const exportLoading = TerminalSelectors.listTerminalsForExport(state).loading;

  return {
    ...tableState,
    loading: tableState.loading,
    exportLoading,
  };
})
class TerminalsTable extends PureComponent {
  state = {
    hideColumns: ["plate", "department", "line"],
  };
  componentDidMount() {
    this.listTerminals(
      { current: 1, pageSize: 10 },
      {},
      {
        field: "at",
        order: "ascend",
      }
    );
  }

  showedColumns() {
    const { hideColumns } = this.state;
    const allValues = TableColumns.map(i => i.key);
    return without(allValues, ...hideColumns);
  }

  onColumnsFilterChange = values => {
    const allValues = TableColumns.map(i => i.key);
    this.setState({
      hideColumns: without(allValues, ...values),
    });
  };

  handleExport = () => {
    const filename = `终端-${moment().format("YYYY-MM-DD")}`;
    const { query } = this.props;

    this.props.dispatch(
      TerminalActions.terminalXlsx.export({
        columns: TableColumns,
        fileName: filename,
        fileType: "xlsx",
        params: {
          ...query,
          _limit: 10000,
          _offset: 0,
        },
      })
    );
  };

  renderTopActions = () => {
    return (
      <div className="top-right-actions">
        <div>
          <Dropdown
            overlay={
              <FilterDropdown
                value={this.showedColumns()}
                onChange={this.onColumnsFilterChange}
                items={TableColumns.map(c => ({ key: c.key, name: c.title }))}
              />
            }
            trigger={["click"]}
          >
            <Button>
              <Icon type="filter" /> 选择数据项
            </Button>
          </Dropdown>
        </div>

        <div>
          <Button
            loading={this.props.exportLoading}
            onClick={this.handleExport}
            type="primary"
          >
            <Icon type="export" />
            导出
          </Button>
        </div>
      </div>
    );
  };

  listTerminals = (pagination = {}, filters = {}, sort = {}) => {
    const { query } = this.props;
    if (sort.order === "ascend") sort.order = "descend";
    else sort.order = "ascend";
    const tableQuery = tableStateToQuery(pagination, filters, sort);
    const fetchQuery = { ...query, ...tableQuery };

    this.props.dispatch(TerminalActions.listTerminals.request(fetchQuery));
  };

  render() {
    const { docs = [], loading, total = 0, pagination } = this.props;
    const { hideColumns } = this.state;
    const showColumns = TableColumns.filter(c => !hideColumns.includes(c.key));

    return (
      <TableContainer
        bordered={false}
        title={
          <div>
            <span
              style={{ fontWeight: "bold", marginRight: 24, fontSize: "16px" }}
            >{`共有${total}个接入终端`}</span>
          </div>
        }
        style={{ marginTop: 12 }}
        extra={this.renderTopActions()}
      >
        <Table
          rowKey="vin"
          loading={loading}
          onChange={this.listTerminals}
          dataSource={docs}
          columns={showColumns}
          pagination={pagination}
          scroll={{ x: "max-content" }}
        />
      </TableContainer>
    );
  }
}

@withPermission({
  permission: Permission.vehicleMonitor.terminal,
})
export default class Monitor extends PureComponent {
  render() {
    return (
      <Container>
        <FilterFrom />

        <TerminalsTable />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const calcTerminalStatus = val => {
  let status = VehicleTermState.ONLINE;

  if (val === VehicleRunState.OFFLINE) {
    status = VehicleTermState.OFFLINE;
  }

  if (val === VehicleRunState.UNKNOWN) {
    status = "UNKNOWN";
  }

  return VehicleTermStateI18N[status];
};

const calcIsLocation = location => {
  if (!location) {
    return "未定位";
  }

  const [lng = 0, lat = 0] = location.split(",");

  if (lng !== 0 && lat !== 0) {
    return "已定位";
  }

  return "未定位";
};

const calcVehicleStatus = val => VehicleRunStateI18N[val];

export const TableColumns = [
  { title: "VIN码", dataIndex: "vin", key: "vin" },
  {
    title: "自编号",
    dataIndex: "no",
    key: "no",
    render: (val, record) => {
      return <VehicleLink title={val} vin={record.id} />;
    },
  },
  { title: "车牌号", dataIndex: "plate", key: "plate" },
  {
    title: "部门",
    dataIndex: "ns",
    key: "department",
    render: val => getPathname(val),
    exportFormat: val => getPathname(val),
  },
  { title: "线路", dataIndex: "line", key: "line" },
  { title: "车型简称", dataIndex: "model", key: "model" },
  { title: "厂商", dataIndex: "manufacturer", key: "manufacturer" },
  {
    title: "离线时长",
    dataIndex: "at",
    key: "at",
    sorter: true,
    defaultSortOrder: "ascend",
    render: friendly,
    exportFormat: friendly,
  },
  {
    title: "终端状态",
    render: calcTerminalStatus,
    exportFormat: calcTerminalStatus,
    dataIndex: "status",
    key: "terminalStatus",
    disableImport: true,
  },
  {
    title: "GPS是否定位",
    dataIndex: "location",
    key: "location",
    render: val => {
      const isLocation = calcIsLocation(val);

      if (isLocation === "未定位") {
        return (
          <span
            style={{
              color: "rgba(0, 0, 0, 0.447058823529412)",
            }}
          >
            <Icon style={{ marginLeft: 4 }} type="environment" />
            未定位
          </span>
        );
      }

      if (isLocation === "已定位") {
        return (
          <span
            style={{
              color: "#2FC25B",
            }}
          >
            <Icon style={{ marginLeft: 4 }} type="environment" />
            已定位
          </span>
        );
      }
      return isLocation;
    },
    exportFormat: calcIsLocation,
  },
  {
    title: "车辆最后状态",
    dataIndex: "status",
    key: "status",
    render: calcVehicleStatus,
    exportFormat: calcVehicleStatus,
  },
];

const TableContainer = styled(Card)`
  .top-right-actions {
    > div {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;
    }

    .ant-btn {
      padding-top: 5px;
      padding-bottom: 5px;
      height: 40px;
    }
  }
`;
