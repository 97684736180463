/**
 * Vehicle search tree container
 */

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { without } from "lodash";

import { globalSelectors } from "../selectors";
import { globalActions } from "../actions";
import { monitorActions } from "../actions";

import makeTree from "../selectors/vehicle-tree";
import SearchTree from "../components/search-tree";
import { MAP_MAX_VEHICLES, MAP_DATA_IN_MS } from "../config";
import { FaultCategory, FaultRecordStatus } from "@36node-mekong/sdk-ts";

const treeSelector = makeTree(globalSelectors.listVehicles);

@withRouter
@connect(state => {
  return {
    root: treeSelector(state).result,
  };
})
export default class VehiclesSearchTree extends PureComponent {
  state = {
    expandedKeys: [],
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(
      monitorActions.listVehicles.request({
        _limit: MAP_MAX_VEHICLES,
      })
    );

    dispatch(
      monitorActions.listAlerts.request({
        _limit: 100,
        status: FaultRecordStatus.BROKEN,
        category: FaultCategory.VEHICLE,
        // brokenAt_gte: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
      })
    );

    // 启动定时
    dispatch(monitorActions.mapDataCron.start(MAP_DATA_IN_MS));
  }

  componentWillUnmount() {
    // this.props.dispatch(globalActions.listVehicleCron.stop());
  }

  handleSelect = vehicleId => {
    const { history } = this.props;
    this.props.dispatch(globalActions.toggleActions.close());
    this.props.dispatch(monitorActions.getVehicle.clear());
    history.push(`/monitor/alert/~/vehicle/${vehicleId}`);
  };

  handleExpand = keys => {
    this.setState({ expandedKeys: keys });
  };

  handleNodeClick = key => {
    let newExpandKeys;
    if (this.state.expandedKeys.includes(key)) {
      newExpandKeys = without(this.state.expandedKeys, key);
    } else {
      newExpandKeys = [...this.state.expandedKeys, key];
    }

    this.setState({ expandedKeys: newExpandKeys });
  };

  handleChangeSearchKeyword = value => {
    this.props.dispatch(globalActions.keywordAssignActions.set(value));
  };

  render() {
    const { root } = this.props;

    return (
      <SearchTree
        root={root}
        onSelect={this.handleSelect}
        onSearch={this.handleChangeSearchKeyword}
        onExpand={this.handleExpand}
        expandedKeys={this.state.expandedKeys}
        onNodeClick={this.handleNodeClick}
        hideRoot
      />
    );
  }
}
