import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { Button } from "antd";
import { selectSession } from "../selectors";

const Page = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1890ff;
`;

@connect(state => {
  const session = selectSession(state) || {};
  return {
    session,
  };
})
export default class Page403 extends PureComponent {
  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.session, this.props.session) &&
      (this.props.location.state || {}).from
    ) {
      this.props.history.push(this.props.location.state.from);
    }
  }

  toLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    return (
      <Page>
        <div>403, 您无权访问该页面，请联系管理员开通相关权限</div>
        <div style={{ margin: 10 }}>
          <Button type="primary" onClick={this.toLogin}>
            进入登录页
          </Button>
        </div>
      </Page>
    );
  }
}
