import { HISTORY_NS } from "../../constants";
import batteryHistorySelectors from "../../selectors/history/battery";
import makeHistoryActions from "./base";

const batteryHistoryActions = makeHistoryActions(
  HISTORY_NS.BATTERY.LIST_WARNINGS,
  HISTORY_NS.BATTERY.WARNINGS_XLSX,
  batteryHistorySelectors
);

export default batteryHistoryActions;
