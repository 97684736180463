/**
 * 报文分析
 */

import React, { PureComponent, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";
import { NavLink } from "react-router-dom";
import ReactJson from "react-json-view";
import { LokiDirection } from "@36node-mekong/sdk-ts";

import { StyledFilterForm } from "../../components/fold-filter-form";
import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Button,
  Table,
  Card,
  Modal,
} from "antd";
import RecordCommandSelect from "../../components/record-command-select";
import TerminalActions from "../../actions/terminal";
import TerminalSelectors from "../../selectors/terminal";
import { ymdhms } from "../../lib";
import { RecordCommandI18N } from "../../constants";
import withPermission from "../with-permission";
import Permission from "../../config/permission";

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const formTailLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8, offset: 8 },
};

@connect()
@Form.create()
class FilterForm extends PureComponent {
  handleRest = () => {
    this.props.form.resetFields();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { date, startTime, endTime, vehicleId, command } = values;

        const start = `${date.format("YYYY-MM-DD")} ${startTime.format(
          "HH:mm"
        )}:00`;
        const end = `${date.format("YYYY-MM-DD")} ${endTime.format(
          "HH:mm"
        )}:59.999`;

        this.props.dispatch(
          TerminalActions.listRecords.request({
            vin: vehicleId,
            limit: 5000,
            start,
            end,
            text: command ? `"${command}"` : undefined,
            direction: LokiDirection.BACKWARD,
          })
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const format = "HH:mm";
    const startTime = moment.max(
      moment().subtract(30, "m"),
      moment().startOf("day")
    );
    return (
      <StyledFilterForm
        style={{ marginTop: 12, paddingTop: 12 }}
        onSubmit={this.handleSubmit}
      >
        <Row gutter={48} type="flex">
          <Col span={6}>
            <Form.Item label="车辆vin码" {...formItemLayout}>
              {getFieldDecorator("vehicleId", {
                rules: [
                  {
                    required: true,
                    message: "请输入车辆vin码",
                  },
                ],
              })(<Input style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>

          <Col span={32}>
            <RangePickerBox>
              <Form.Item label="采集报文时间">
                {getFieldDecorator("date", {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: "请输入日期",
                    },
                  ],
                })(<DatePicker defaultPickerValue={moment()} />)}
              </Form.Item>
              <Form.Item label="">
                {getFieldDecorator("startTime", {
                  initialValue: startTime,
                })(
                  <TimePicker
                    defaultOpenValue={moment("00:00", format)}
                    format={format}
                  />
                )}
              </Form.Item>
              <span style={{ lineHeight: "40px", marginRight: "16px" }}>
                至
              </span>
              <Form.Item label="">
                {getFieldDecorator("endTime", { initialValue: moment() })(
                  <TimePicker
                    defaultOpenValue={moment("23:59:59", format)}
                    format={format}
                  />
                )}
              </Form.Item>
            </RangePickerBox>
          </Col>
        </Row>

        <Row gutter={48} type="flex">
          <Col span={6}>
            <Form.Item label="报文类型" {...formItemLayout}>
              {getFieldDecorator("command", {})(
                <RecordCommandSelect allowClear />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...formTailLayout}>
              <Button onClick={this.handleRest}>重置</Button>
              <Button
                style={{ marginLeft: 24 }}
                type="primary"
                htmlType="submit"
              >
                筛选
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledFilterForm>
    );
  }
}

@connect(state => {
  const listRecordsState = TerminalSelectors.listRecords(state);
  return listRecordsState;
})
class RecordsTable extends PureComponent {
  render() {
    const { request, loading } = this.props;
    const result = this.props.result || [];
    const total = result.length >= 5000 ? "5000+" : result.length;
    const title = request.vin
      ? `车辆vin码: ${request.vin}, 共有${total}条报文信息`
      : "请输入 vin 进行查询";

    return (
      <TableContainer bordered={false} title={title} style={{ marginTop: 12 }}>
        <Table
          rowKey="receiveAt"
          columns={[
            ...TableColumns,
            {
              key: "origin",
              title: "原始报文",
              render: record => {
                return (
                  <NavLink
                    to="#"
                    onClick={e => {
                      this.props.dispatch(
                        TerminalActions.selectOriginRecord.set(record)
                      );
                    }}
                  >
                    查看
                  </NavLink>
                );
              },
            },
          ]}
          dataSource={result}
          loading={loading}
          scroll={{ x: "max-content" }}
        />
      </TableContainer>
    );
  }
}

/**
 * 原始报文展示modal
 */
@connect(state => {
  const selectedRecord = TerminalSelectors.selectOriginRecord(state).assign;

  return {
    selectedRecord,
  };
})
class OriginModal extends PureComponent {
  onCancel = () => {
    this.props.dispatch(TerminalActions.selectOriginRecord.set(null));
  };

  render() {
    const { selectedRecord } = this.props;

    const visible = Boolean(selectedRecord);
    return (
      <Modal
        visible={visible}
        onCancel={this.onCancel}
        footer={null}
        width={"60vw"}
        destroyOnClose
        centered
      >
        <div
          style={{
            width: "100%",
            maxHeight: "80vh",
            overflow: "auto",
            marginTop: 24,
          }}
        >
          <ReactJson
            name={false}
            src={selectedRecord}
            displayDataTypes={false}
          />
        </div>
      </Modal>
    );
  }
}

@withPermission({
  permission: Permission.vehicleMonitor.datagram,
})
export default class Datagram extends PureComponent {
  render() {
    return (
      <Container>
        <FilterForm />
        <Fragment>
          <RecordsTable />
          <OriginModal />
        </Fragment>
      </Container>
    );
  }
}

const TableColumns = [
  {
    title: "报文采集时间",
    dataIndex: "body.at",
    key: "at",
    render: val => ymdhms(val),
  },
  {
    title: "上报时间",
    dataIndex: "receiveAt",
    key: "receiveAt",
    render: ymdhms,
  },
  {
    title: "报文类型",
    dataIndex: "command",
    key: "command",
    render: val => RecordCommandI18N[val],
  },
  {
    title: "报文长度",
    dataIndex: "length",
    key: "length",
  },
];

const TableContainer = styled(Card)`
  .top-right-actions {
    > div {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;
    }
  }

  .ant-btn {
    border: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 76px);
`;

const RangePickerBox = styled.div`
  display: flex;

  .ant-form-item {
    margin-right: 16px;
  }
`;
