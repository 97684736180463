import { createApiSelector } from "@36node/redux-api";
import { createAssignSelector } from "@36node/redux-ui";

import { createSelector } from "reselect";
import { concat, get, groupBy, mapValues, maxBy, isNumber } from "lodash";
import moment from "moment";

import {
  NS,
  AlertLevelColor,
  VehicleRunStateColor,
  SESSION_NS,
} from "../constants";
import {
  alertSchema,
  warningSchema,
  mekongVehicleSchema,
  ticketSchema,
  orderSchema,
} from "../schemas";
import { calcVehicleRunState } from "../lib";
import global from "./global";

const listVehiclesWithoutNs = createApiSelector(NS.MONITOR.LIST_VEHICLES, [
  mekongVehicleSchema,
]);

const listVehicles = createSelector(
  listVehiclesWithoutNs,
  vehiclesResp => {
    const vehicles = vehiclesResp.result || [];
    const ns = localStorage.getItem(SESSION_NS);
    const result = vehicles.filter(
      v => v.ns && v.ns.id && v.ns.id.includes(ns)
    );
    return { ...vehiclesResp, result };
  }
);

const listAlerts = createApiSelector(NS.MONITOR.LIST_ALERTS, [alertSchema]);

const mapVehicleState = createAssignSelector(NS.MONITOR.VEHICLE_STATE);

const chargeVehicleTab = createAssignSelector(NS.MONITOR.CHARGING_LIST_TAB);

/**
 * 选择地图中的vehicles
 */
const mapVehicles = createSelector(
  listVehicles,
  listAlerts,
  (vehiclesResp, alertResp) => {
    const vehicles = vehiclesResp.result || [];
    const alerts = alertResp.result || [];

    return vehicles
      .filter(v => v.location)
      .map(v => {
        const runState = calcVehicleRunState(v);
        const title = v.no;
        const position = v.location.split(",");

        const vehicleAlerts = alerts.filter(a => get(a, "vehicle.id") === v.id);

        const maxLevelAlert = maxBy(vehicleAlerts, "level");

        let maxLevel = 0;
        if (maxLevelAlert) {
          maxLevel = maxLevelAlert.level;
        }

        let iconColor = AlertLevelColor[maxLevel];

        if (!iconColor) {
          iconColor = VehicleRunStateColor[runState];
        }

        return {
          ...v,
          id: v.vin,
          title,
          runState,
          position,
          iconColor,
          maxLevel,
        };
      });
  }
);

const mapVehiclesByState = createSelector(
  mapVehicles,
  mapVehicleState,
  (vehicles, state) => {
    const vehicleState = state.assign || "";
    if (vehicleState === "") return vehicles;

    let filtered = vehicles.filter(v => v.status === vehicleState);
    return filtered;
  }
);

const chargeVehiclesByTab = createSelector(
  mapVehiclesByState,
  chargeVehicleTab,
  (vehicles, assign) => {
    const tab = assign.assign;

    if (tab === "before22")
      return vehicles.filter(v => {
        const { chargeBeginAt } = v;

        const today6 = moment()
          .startOf("day")
          .add(6, "hour");

        const today22 = moment()
          .startOf("day")
          .add(22, "hour");

        return (
          chargeBeginAt &&
          moment(chargeBeginAt).isBefore(today22) &&
          moment(chargeBeginAt).isAfter(today6)
        );
      });

    return vehicles;
  }
);

const vehiclesStats = createSelector(
  listVehicles,
  global.listVehicles,
  (vehiclesResp, allVehiclesState) => {
    const vehicles = vehiclesResp.result || [];
    const total = allVehiclesState.total;
    const grouped = groupBy(vehicles, "status");
    const counts = mapValues(grouped, v => (v ? v.length : 0));
    counts.UNKNOWN = total - (counts.RUNNING + counts.STOP + counts.CHARGING);
    return counts;
  }
);

const listFaults = createApiSelector(NS.VEHICLE.LIST_FAULTS);

const listTboxRecords = createApiSelector(NS.MONITOR.LIST_TBOX_RECORDS);
const tboxHistoryRecords = createSelector(
  listTboxRecords,
  recordsResp => {
    const records = recordsResp.result || [];
    return {
      ...recordsResp,
      result: records.map(r => {
        const { body = {} } = r;
        const { alarm = {} } = body;
        const {
          engineList = [],
          mortorList = [],
          otherList = [],
          ressList = [],
          _uasList = [],
        } = alarm;
        const alarms = concat(
          engineList,
          mortorList,
          otherList,
          ressList,
          _uasList
        );

        return {
          ...body,
          alarmCodes: alarms
            .map(({ type, code, level }) =>
              (((type << 24) >>> 0) + (code << 8) + level).toString(16)
            )
            .join(","),
          key: r.vin + body.at + r.receiveAt,
          vin: r.vin,
        };
      }),
    };
  }
);

const listVehicleRecords = createSelector(
  createApiSelector(NS.MONITOR.LIST_RECORDS),
  listFaults,
  (listResp, faultsResp) => {
    const data = listResp.result || [];
    const faults = faultsResp.result || [];
    const tmp = [];

    data.forEach((item, index) => {
      const alarm = get(item, "body.alarm", {});

      const {
        ressList = [],
        mortorList = [],
        engineList = [],
        otherList = [],
        _uasList = [],
      } = alarm;

      const alerts = [
        ...ressList,
        ...mortorList,
        ...engineList,
        ...otherList,
        ..._uasList,
      ];

      const alertInfo =
        alerts.length > 0
          ? alerts
              .map(a => {
                const faultCode =
                  ((a.type << 24) >>> 0) + (a.code << 8) + a.level;
                const fault = faults.find(f => f.code === faultCode);
                return fault;
              })
              .filter(f => f && f.code)
              .map(f => `${f.code.toString(16)}-${f.name}`)
              .join(",")
          : "--";

      item = Object.assign({}, item, item.body, {
        key: index,
        alertInfo,
      });
      delete item.body;
      tmp.push(item);
    });

    return {
      ...listResp,
      result: tmp,
    };
  }
);

const listVehicleSnapshots = createApiSelector(
  NS.MONITOR.LIST_VEHICLE_SNAPSHOTS
);

const calendarSelector = createSelector(
  listVehicleSnapshots,
  snapshotsResp => {
    const snapshots = snapshotsResp.result || [];
    const loading = snapshotsResp.loading;
    // 二维矩阵代表一年的数据
    const mData = new Array(12).fill(null).map(() => new Array(31).fill(null));
    const dData = new Array(12).fill(null).map(() => new Array(31).fill(null));
    snapshots.forEach(item => {
      const { key, id } = item;
      const [, m, d] = key.split("-");
      mData[m - 1][d - 1] = {
        id,
        value: isNumber(item.mileage) ? item.mileage.toFixed(0) : undefined,
      };
      dData[m - 1][d - 1] = {
        id,
        value: isNumber(item.discharge) ? item.discharge.toFixed(0) : undefined,
      };
    });

    const mileageTotal = snapshots.reduce(
      (acc, cur) => acc.mileage + cur.mileage || 0,
      0
    );
    const mileage200 = snapshots.filter(item => item.mileage >= 200).length;
    const mileage50 =
      snapshots.filter(item => item.mileage >= 50).length - mileage200;
    const mileage0 =
      snapshots.filter(item => item.mileage >= 0).length -
      mileage50 -
      mileage200;
    const mileageNaN = snapshots.filter(item => !isNumber(item.mileage)).length;
    const mileageAvg = mileageTotal / snapshots.length;
    const dischargeTotal = snapshots.reduce(
      (acc, cur) => acc.discharge + cur.discharge || 0,
      0
    );
    const discharge200 = snapshots.filter(item => item.discharge >= 200).length;
    const discharge50 =
      snapshots.filter(item => item.discharge >= 50).length - discharge200;
    const discharge0 =
      snapshots.filter(item => item.discharge >= 0).length -
      discharge50 -
      discharge200;
    const dischargeNaN = snapshots.filter(item => !isNumber(item.discharge))
      .length;
    const dischargeAvg = dischargeTotal / snapshots.length;

    return {
      snapshots,
      loading,
      mData,
      dData,
      mileageTotal,
      mileage0,
      mileage50,
      mileage200,
      mileageNaN,
      mileageAvg,
      dischargeTotal,
      discharge0,
      discharge50,
      discharge200,
      dischargeNaN,
      dischargeAvg,
    };
  }
);

const monitorSelectors = {
  getSnapshot: createApiSelector(NS.MONITOR.GET_SNAPSHOT),
  getVehicle: createApiSelector(NS.MONITOR.GET_VEHICLE, mekongVehicleSchema),
  getVehicleState: createApiSelector(NS.MONITOR.GET_VEHICLE_STATE),
  listTboxRecords,
  tboxHistoryRecords,
  listVehicleRecords,
  listVehicleRecordsGraph: createApiSelector(NS.MONITOR.LIST_RECORDS_GRAPH),
  recordsGraphFilterSelector: createAssignSelector(
    NS.MONITOR.RECORD_GRAPH_FILTER
  ),
  listAlerts,
  listTableAlerts: createApiSelector(NS.MONITOR.LIST_TABLE_ALERTS, [
    alertSchema,
  ]),
  listStationAlerts: createApiSelector(NS.MONITOR.LIST_STATION_TABLE_ALERTS, [
    orderSchema,
  ]),
  listVehicleAlerts: createApiSelector(NS.MONITOR.LIST_VEHICLE_ALERTS, [
    alertSchema,
  ]),
  listWarnings: createApiSelector(NS.MONITOR.WARNING.LIST_WARNINGS, [
    warningSchema,
  ]),
  mapVehicleState,
  mapVehicles,
  mapVehiclesByState,
  chargeVehicleTab,
  chargeVehiclesByTab,
  vehiclesStats,
  mapState: createAssignSelector(NS.MONITOR.MAP_STATE),
  getAlertSummary: createApiSelector(NS.MONITOR.GET_ALERT_SUMMARY),
  getTicketsStats: createApiSelector(NS.MONITOR.GET_TICKETS_STATS),
  getWarningStats: createApiSelector(NS.MONITOR.STATISTICS.GET_WARNING_STATS),
  listVehicles,
  selectedVehicleSelector: createAssignSelector(
    NS.MONITOR.MAP.SELECTED_VEHICLE
  ),
  listVehicleMileages: createApiSelector(NS.MONITOR.LIST_VEHICLE_MILEAGES),
  listVehicleEnergyConsumptions: createApiSelector(
    NS.MONITOR.LIST_VEHICLE_ENERGYCOMSUMPTIONS
  ),
  listVehicleSnapshots,
  calendarSelector,
  listFaults,
  listAllOpenTickets: createApiSelector(NS.MONITOR.ALERT.LIST_OPEN_TICKETS, [
    ticketSchema,
  ]),
};

export default monitorSelectors;
