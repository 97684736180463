import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import TicketActions from "../../actions/tickets";
import { TicketLayout } from "../../components/ticket/ticket-layout";
import TicketInfo from "../../components/ticket/ticket-info";
import TicketSelectors from "../../selectors/tickets";

import styled from "styled-components";
import AlertsTable from "../../components/ticket/alerts-table";
import HandleForm from "./handle-form";
import EventsList from "../../components/ticket/events-list";
import { getPathname } from "../../lib";
import { TicketStages } from "../../lib/ticket";
@connect(state => {
  const ticket = TicketSelectors.ticket(state);

  ticket.department = getPathname(ticket.ns);

  return {
    ticket,
  };
})
export default class Detail extends PureComponent {
  getTicketId = () => {
    return get(this.props, "match.params.ticketId");
  };

  componentDidMount() {
    const ticketId = this.getTicketId();

    this.props.dispatch(
      TicketActions.getTicket.request({ workOrder: ticketId })
    );
  }

  onSubmit = (values, loginUser) => {
    const { ticket = {} } = this.props;
    const { stage, by, desc, title } = values;
    const { ns } = loginUser;

    this.props.dispatch(
      TicketActions.handleTicket.request({
        workOrder: ticket.id,
        body: {
          desc,
          at: new Date(),
          by: by,
          stage,
          title,
          ns,
        },
      })
    );

    if (stage === TicketStages[2]) {
      this.props.dispatch(
        TicketActions.closeTicket.request({
          workOrder: ticket.id,
        })
      );
    }
  };

  render() {
    const { ticket = {} } = this.props;

    const { status, relatedFaults = [] } = ticket;

    return (
      <TicketLayout>
        <TicketInfo ticket={ticket} alert={relatedFaults[0]} />
        <Divider />
        <AlertsTable alerts={relatedFaults} />
        <Divider />
        {status === "OPEN" && <HandleForm onSubmit={this.onSubmit} />}
        <Divider />
        <EventsList ticket={ticket} />
      </TicketLayout>
    );
  }
}

const Divider = styled.div`
  margin: 12px 0px;
`;
