import { isApi } from "@36node/redux-api";
import { endsWith, cloneDeep, set, isEmpty, get } from "lodash";

import { NS, SESSION_NS } from "../constants";
import { ROOT_NAMESPACE } from "../config";

const filterApiKey = [
  NS.GLOBAL.LIST_VEHICLES,
  NS.GLOBAL.LIST_LINES,
  NS.GLOBAL.LIST_NAMESPACES,
  NS.GLOBAL.GET_ALERT_SUMMARY,
  NS.GLOBAL.GET_STATION_ALERT_SUMMARY,
  NS.MONITOR.GET_TICKETS_STATS,
  NS.MONITOR.LIST_ALERTS,
  NS.MONITOR.LIST_TABLE_ALERTS,
  NS.MONITOR.LIST_VEHICLE_ALERTS,
  // NS.MONITOR.LIST_VEHICLES,
  NS.MONITOR.PILE.LIST_STATIONS,
  NS.MONITOR.PILE.LIST_ALL_STATIONS,
  NS.HISTORY.ALERT.LIST_ALERTS,
  NS.MONITOR.ALERT.LIST_OPEN_TICKETS,
  NS.HISTORY.ALERT.WARNINGS_XLSX,
  NS.HISTORY.BATTERY.LIST_WARNINGS,
  NS.HISTORY.BATTERY.WARNINGS_XLSX,
  NS.HISTORY.INSULATION.LIST_WARNINGS,
  NS.HISTORY.INSULATION.WARNINGS_XLSX,
  NS.HISTORY.TYRE.LIST_WARNINGS,
  NS.HISTORY.TYRE.WARNINGS_XLSX,
  NS.TICKET.LIST_TICKETS,
  NS.TICKET.LIST_STAGE_TICKETS,
  NS.ARCHIVES.LIET_VEHICLES,
  NS.ARCHIVES.VEHICLES_XLSX,
  // NS.TERMINAL.MONITOR.LIST_TERMINALS,
  // NS.TERMINAL.MONITOR.TERMINALS_XLSX,
  NS.VEHICLE.GET_STATISTICS,
  NS.VEHICLE.GET_STATISTICS_CRON,
  NS.REPORT.GET_WARNING_STATS,
  NS.REPORT.EXPORT_WARNING_STATS,
  NS.REPORT.GET_VEHICLE_STATS,
  NS.REPORT.EXPORT_VEHICLE_STATS,
  NS.REPORT.GET_TICKET_STATS,
  NS.REPORT.GET_OVERALL_WARNING_STATS,
  NS.REPORT.GET_OVERALL_TICKET_STATS,
  NS.REPORT.GET_OVERALL_POWER_STATS,
  NS.ANALYSIS.GET_ALERT_ANALYSIS,
  NS.ANALYSIS.GET_ALERT_RATE_ANALYSIS,
  NS.BANCI.LIST_BANCI,
  NS.BANCI.BANCI_XLSX,
  NS.REPORT.LIST_TABLE_ALERTS,
  NS.HISTORY.CHARGE_RECORDS.LIST_WARNINGS,
  NS.TICKET.STATISTICS,
  NS.MONITOR.PILE.LIST_ORDERS,
  NS.REPORT.GET_CHARGE_ORDER_AGG,
  NS.AGGREGATE.ORDER_CHARGER_PER_HOUR,
];

/**
 * Redux 中间件, 为api 请求action 增加ns 的请求query
 */
const nsFilterMiddleware = () => next => action => {
  // filter action
  if (
    isApi(action) &&
    endsWith(action.type, "REQUEST") &&
    filterApiKey.filter(key => action.key.indexOf(key) !== -1).length
  ) {
    const sessionNs = localStorage.getItem(SESSION_NS);
    const ns = sessionNs.includes(ROOT_NAMESPACE) ? sessionNs : ROOT_NAMESPACE;

    if (isEmpty(ns)) {
      return next(action);
    }

    const newPayload = cloneDeep(action.payload || {});

    if (action.key === NS.GLOBAL.LIST_NAMESPACES) {
      set(newPayload, "query.id_like", ns);
    } else {
      const ns_like = get(action.payload, "ns_like");

      // 如果没有 ns filter， 默认为登陆用户的 ns
      if (!ns_like) {
        set(newPayload, "ns_like", ns);
      }
    }

    const nextAction = {
      ...action,
      payload: newPayload,
    };

    next(nextAction);
  } else {
    next(action);
  }
};

export default nsFilterMiddleware;
