import { setWith, get } from "lodash";
import { types } from "./actions";

const timelineInit = {
  status: "STOP", // STOP RUNNING
  index: 0, // 当前数据的位置
  pace: 1, // 当前倍速
  data: [], // 时间轴数据
};

export default function timeline(state = {}, { type, payload, meta = {} }) {
  if (!meta.key) return state;
  const keyState = get(state, meta.key, timelineInit);

  switch (type) {
    case types.start:
      return setWith({ ...state }, meta.key, {
        ...keyState,
        status: "RUNNING",
      });
    case types.stop:
      return setWith({ ...state }, meta.key, {
        ...keyState,
        status: "STOP",
        index: 0,
      });
    case types.pause:
      return setWith({ ...state }, meta.key, { ...keyState, status: "STOP" });
    case types.tick:
    case types.jump:
    case types.pace:
      return setWith({ ...state }, meta.key, {
        ...keyState,
        ...payload,
      });
    case types.setData:
      return setWith({ ...state }, meta.key, {
        ...keyState,
        ...payload,
        status: "STOP",
        index: 0,
      });
    default:
      return state;
  }
}
