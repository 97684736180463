/**
 * 按公司满电率统计
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import { get } from "lodash";
import ReactEcharts from "echarts-for-react";

export class OrderByPeriodChart extends PureComponent {
  getStationName = item => {
    const { stations } = this.props;
    const station = stations.find(station => station.id === item.station);
    return station ? station.name : "未知";
  };

  getOption = () => {
    const { data = [] } = this.props;

    return {
      tooltip: {
        trigger: "axis",
        formatter: params => {
          const total = params.reduce(
            (acc, item) => acc + (item.value || 0),
            0
          );
          return params
            .map(
              item =>
                item.marker +
                item.seriesName +
                "充电量占比：" +
                (total ? (((item.value || 0) / total) * 100).toFixed(1) : "-") +
                "%"
            )
            .join("<br/>");
        },
      },
      legend: {
        data: ["谷时段", "平时段", "峰时段"],
      },
      color: ["#29a7f3", "#6ec044", "#6d76ba"],
      calculable: true,
      xAxis: [
        {
          type: "category",
          // prettier-ignore
          data: data.map(item => item.name)
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "充电量（mWh）",
          axisLabel: {
            formatter: value => Math.round(value / 1000),
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      series: [
        {
          name: "谷时段",
          type: "bar",
          barWidth: "10%",
          data: data.map(item => get(item, "details.valley")),
        },
        {
          name: "平时段",
          type: "bar",
          barWidth: "10%",
          data: data.map(item => get(item, "details.flat")),
        },
        {
          name: "峰时段",
          type: "bar",
          barWidth: "10%",
          data: data.map(item => get(item, "details.peak")),
        },
      ],
    };
  };

  render() {
    const option = this.getOption();

    return (
      <Row>
        <Col span={24}>
          <ReactEcharts option={option} />
        </Col>
      </Row>
    );
  }
}
