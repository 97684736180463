import { HISTORY_NS } from "../../constants";
import makeHistorySelectors from "./base";
import { alertSchema } from "../../schemas";

const alertHistorySelectors = makeHistorySelectors(
  HISTORY_NS.ALERT.LIST_ALERTS,
  HISTORY_NS.ALERT.WARNINGS_XLSX,
  [alertSchema]
);

export default alertHistorySelectors;
