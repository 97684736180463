import {
  put,
  fork,
  cancel,
  cancelled,
  delay,
  select,
  takeEvery,
  all,
} from "redux-saga/effects";
import moment from "moment";
import { types, isTimeline } from "./actions";

// 每一个key 对应的 task
const tasks = new Map();

/**
 * 新建时间轴
 * @param {object} instance model instance
 */
function createTask(instance) {
  return function*() {
    try {
      // 时间轴状态
      const state = yield select(instance.selector);
      const { index, pace = 1, data = [] } = state;

      for (let i = index; i < data.length; i++) {
        const cur = data[i];
        const next = data[i + 1];

        if (next) {
          const { at: curAt } = cur;
          const { at: nextAt } = next;

          const period =
            moment(nextAt).diff(moment(curAt), "milliseconds") / pace;

          yield delay(period);

          // 时间轴前进
          yield put(instance.actions.tick(i + 1));
        } else {
          // 播放结束
          yield put(instance.actions.stop());
        }
      }
    } finally {
      if (yield cancelled()) {
        console.log(`Timeline ${instance.key} stopped!`);
      }
    }
  };
}

function* watchTimeline(action) {
  const { instance, meta = {}, type } = action;
  const { key } = meta;
  if (!key || !instance) return;

  const state = yield select(instance.selector) || {};

  const { status } = state;

  switch (type) {
    case types.start:
      // 新建任务
      if (!tasks.has(key)) {
        const task = yield fork(createTask(instance));
        tasks.set(key, task);
      }
      break;
    case types.stop:
    case types.pause:
      // 取消任务
      if (tasks.has(key)) {
        yield cancel(tasks.get(key));
        tasks.delete(key);
      }
      break;
    case types.jump:
    case types.pace:
      // 重新开始任务
      if (status === "RUNNING" && tasks.has(key)) {
        yield cancel(tasks.get(key));
        tasks.delete(key);
        const task = yield fork(createTask(instance));
        tasks.set(key, task);
      }
      break;
    default:
      break;
  }
}

export default function* rootSaga() {
  yield all([takeEvery(isTimeline, watchTimeline)]);
}
