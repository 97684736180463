/**
 *按公司分布
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import styled from "styled-components";
import moment from "moment";

const Box = styled(Row)``;

export default class AlertChartByDate extends PureComponent {
  get colors() {
    return ["#df273e", "#fb6d3e", "#faac15"];
  }

  getOption = (data, startAt, endAt) => {
    const xData = [],
      yData = [];
    for (let i = moment(startAt); i <= endAt; i = i.add(1, "days")) {
      xData.push(i.format("YYYY-MM-DD"));
      yData.push(0);
    }
    if (data) {
      data.forEach(item => {
        const m = moment({
          year: item.brokenAt.year,
          month: item.brokenAt.month - 1,
          day: item.brokenAt.day,
        });
        const i = xData.findIndex(x => x === m.format("YYYY-MM-DD"));
        if (i) yData[i] = item.count;
      });
    }

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      grid: {
        left: "3%",
        right: "6%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: xData,
        },
      ],
      yAxis: [
        {
          name: "报警数",
          type: "value",
          min: 0,
        },
      ],
      series: [
        // {
        //   name: '已处理',
        //   type: 'line',
        //   stack: 'Total',
        //   smooth: true,
        //   lineStyle: {
        //     width: 0,
        //   },
        //   showSymbol: false,
        //   areaStyle: {
        //     opacity: 0.8,
        //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        //       {
        //         offset: 0,
        //         color: 'rgb(128, 255, 165)',
        //       },
        //       {
        //         offset: 1,
        //         color: 'rgb(1, 191, 236)',
        //       },
        //     ]),
        //   },
        //   data: [
        //     14, 12, 11, 12, 9, 4, 5, 14, 12, 11, 12, 9, 4, 5, 14, 12, 11, 12, 9, 4, 5, 14, 12, 11, 12, 9, 4, 5, 20,
        //     13,
        //   ],
        // },
        {
          name: "报警数",
          type: "line",
          stack: "Total",
          smooth: true,
          lineStyle: {
            width: 0,
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgb(0, 221, 255)",
              },
              {
                offset: 1,
                color: "rgb(77, 119, 255)",
              },
            ]),
          },
          data: yData,
        },
      ],
    };
  };

  render() {
    const { data = [], startAt, endAt } = this.props;

    const echartOptions = this.getOption(data, startAt, endAt);

    return (
      <Box>
        <Col span={24}>
          <ReactEcharts
            option={echartOptions}
            notMerge={true}
            lazyUpdate={true}
          />
        </Col>
      </Box>
    );
  }
}
