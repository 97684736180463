import { isEmpty } from "lodash";
import { all, put, takeLatest, take } from "redux-saga/effects";
import { pileActions } from "../actions";
import { successOf, failureOf, isSuccess } from "@36node/redux-api";
import { NS } from "../constants";
import { message } from "antd";

function* handleDeleteConnectors(action) {
  const { connectorIds = [] } = action.payload;

  if (isEmpty(connectorIds)) return;

  const hide = message.loading("正在删除所选充电枪信息", 0);
  for (const connector of connectorIds) {
    yield put(pileActions.deleteConnector.request({ connector }));

    const resultAction = yield take([
      successOf(NS.MONITOR.PILE.DELETE_CONNECTOR),
      failureOf(NS.MONITOR.PILE.DELETE_CONNECTOR),
    ]);

    if (isSuccess(resultAction)) {
    } else {
      message.error(`充电枪: ${connector} 删除失败`);
    }
  }
  message.success("充电枪信息删除完成");
  hide();

  // 重新刷新
  yield put(pileActions.listConnectors.refresh());
}

export default function* watchConnectors() {
  yield all([
    takeLatest(NS.MONITOR.PILE.DELETE_CONNECTORS, handleDeleteConnectors),
  ]);
}
