/**
 * Hoc for warning or alert table
 */

import React, { PureComponent } from "react";
import styled from "styled-components";
import { without } from "lodash";
import { Button, Card, Dropdown, Table, Icon } from "antd";
import { connect } from "react-redux";
import { tableStateToQuery } from "../../lib";
import FilterDropdown from "../../components/filter-dropdown";
import { makeMekongApiToTableSelector } from "../../selectors/api-to-table";
import moment from "moment";

function createWarningTable(
  warningName, // warningName eg.. 电池异常
  warningType, // warningType eg.. BATTERY_EXCEPTION
  columns = [], // table columns
  actions = {}, // actions
  selectors = {} // selectors
) {
  const TableContainer = styled(Card)`
    .top-right-actions {
      > div {
        display: inline-block;
        vertical-align: top;
        margin-left: 20px;
      }

      .ant-btn {
        padding-top: 5px;
        padding-bottom: 5px;
        height: 40px;
      }
    }
  `;

  const hideColumnsLocalKey = `WARNING-TABLE-${warningType}-HIDE-COLUMNS`;

  class MyTable extends PureComponent {
    state = {
      hideColumns: localStorage.getItem(hideColumnsLocalKey)
        ? JSON.parse(localStorage.getItem(hideColumnsLocalKey))
        : [],
    };

    componentDidMount() {
      this.listRecords({ current: 1, pageSize: 10 });
    }

    showedColumns() {
      const { hideColumns } = this.state;
      const allValues = columns.map(i => i.key);
      return without(allValues, ...hideColumns);
    }

    onColumnsFilterChange = values => {
      const allValues = columns.map(i => i.key);
      const newHideColumns = without(allValues, ...values);
      localStorage.setItem(hideColumnsLocalKey, JSON.stringify(newHideColumns));

      this.setState({
        hideColumns: newHideColumns,
      });
    };

    listRecords = (pagination = {}, filters, sort) => {
      const { query, defaultQuery = {} } = this.props;
      const tableQuery = tableStateToQuery(pagination, filters, sort, true);

      this.props.dispatch(
        actions.listRecords.request({
          ...defaultQuery,
          ...query,
          ...tableQuery,
        })
      );
    };

    handleExport = () => {
      // filename
      const filename = `${warningName}数据-${moment().format("YYYY-MM-DD")}`;
      const { listQuery } = this.props;

      if (actions.recordsXlsx) {
        this.props.dispatch(
          actions.recordsXlsx.export({
            columns,
            fileName: filename,
            fileType: "xlsx",
            params: {
              ...listQuery,
              _limit: 10000,
              _offset: 0,
            },
          })
        );
      }
    };

    renderTopActions = () => {
      return (
        <div className="top-right-actions">
          <div>
            <Dropdown
              overlay={
                <FilterDropdown
                  value={this.showedColumns()}
                  onChange={this.onColumnsFilterChange}
                  items={columns.map(c => ({ key: c.key, name: c.title }))}
                />
              }
              trigger={["click"]}
            >
              <Button>
                <Icon type="filter" /> 选择数据项
              </Button>
            </Dropdown>
          </div>

          <div>
            <Button onClick={this.handleExport} type="primary">
              <Icon type="export" />
              导出
            </Button>
          </div>
        </div>
      );
    };

    render() {
      const { docs = [], loading, total = 0, pagination } = this.props;
      const { hideColumns } = this.state;
      const showColumns = columns.filter(c => !hideColumns.includes(c.key));

      return (
        <TableContainer
          bordered={false}
          title={`共有${total}条${warningName}信息`}
          style={{ marginTop: 12 }}
          extra={this.renderTopActions()}
        >
          <Table
            rowKey="id"
            loading={loading}
            onChange={this.listRecords}
            dataSource={docs}
            columns={showColumns}
            pagination={pagination}
            scroll={{ x: "max-content" }}
          />
        </TableContainer>
      );
    }
  }

  const WarningTable = connect(state => {
    const selector = makeMekongApiToTableSelector(selectors.listRecords);
    const tableState = selector(state);

    const listQuery = selectors.listRecords(state).request || {};

    return {
      ...tableState,
      listQuery,
    };
  })(MyTable);

  return WarningTable;
}

export default createWarningTable;
