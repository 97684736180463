import {
  put,
  take,
  select,
  takeEvery,
  all,
  cancelled,
  fork,
  cancel,
  delay,
} from "redux-saga/effects";
import { successOf } from "@36node/redux-api";
import { isTimeline, types } from "../modules/timeline/actions";
import moment from "moment";
import gcoord from "gcoord";

import { NS } from "../constants";

let navg = null;
let pathSimplifier = null;

// watch timeline task
let task = null;

function* watchTimeline() {
  try {
    while (true) {
      const tAction = yield take(isTimeline);
      const { type, instance } = tAction;
      const state = yield select(instance.selector);

      const { index, data = [], pace } = state;

      if (type === types.jump) {
        navg.setSpeed(0);
        navg.moveToPoint(index);
      }

      if (type === types.tick || type === types.start) {
        const cur = data[index];
        const next = data[index + 1];

        if (next) {
          const { at, location } = cur;
          const { at: nextAt, location: nextLocation } = next;

          const pos1 = new window.AMap.LngLat(location.lng, location.lat);
          const pos2 = new window.AMap.LngLat(
            nextLocation.lng,
            nextLocation.lat
          );

          // 计算时差
          const at1 = moment(at);
          const at2 = moment(nextAt);

          // 时间 s
          const period = at2.diff(at1, "seconds") / pace;

          // 计算两点距离 m
          const distance = pos1.distance(pos2);

          // 计算速度 km/h
          const speed = ((distance / period) * 18) / 5;

          navg.setSpeed(speed);
          navg.moveByDistance(distance);
        } else {
          navg.setSpeed(0);
        }
      }

      if (type === types.stop) {
        navg.setSpeed(0);
        navg.moveToPoint(0);
      }

      if (type === types.pause) {
        navg.setSpeed(0);
      }
    }
  } finally {
    if (yield cancelled()) {
      console.log("Trail path watch timeline cancelled!");
    }
  }
}

/**
 *
 * @param {object} pathS
 */
export function setPathSimplifier(pathS) {
  pathSimplifier = pathS;
}

/**
 * 获取数据
 */
function* watchFetchData({ payload = {}, api, selector, timeline }) {
  if (!api || !timeline) return;
  if (payload.vin) {
    // 请求records
    const requestAction = api.request(payload);

    // 等待 pathSimplifier 传进来
    while (true) {
      if (pathSimplifier) {
        break;
      }

      yield delay(500);
    }

    yield put(requestAction);

    yield take(successOf(NS.MONITOR.LIST_TBOX_RECORDS));
    const rawData = (yield select(selector)).result || [];

    const data = rawData.map(d => d.body).filter(item => item && item.location);
    yield put(timeline.actions.setData(data));

    if (pathSimplifier && data.length === 0) {
      pathSimplifier.setData([]);
    }

    if (pathSimplifier && data.length > 0) {
      const pathData = [];
      data.forEach(d => {
        // 转成火星坐标
        const [lng, lat] = gcoord.transform(
          [d.location.lng, d.location.lat],
          gcoord.WGS84,
          gcoord.GCJ02
        );
        pathData.push([lng, lat]);
      });
      pathSimplifier.setData([
        {
          name: "轨迹回放",
          path: pathData,
        },
      ]);

      if (navg) {
        navg.destroy();
        navg = null;
      }
      // 新建巡航器 初始速度为0
      navg = pathSimplifier.createPathNavigator(0, { speed: 0, loop: true });

      navg.start();

      if (!task) {
        // 启动任务
        task = yield fork(watchTimeline);
      }
    }
  }
}

function* watchUnmount({ timeline }) {
  if (navg) {
    navg.destroy();
    navg = null;
  }

  pathSimplifier = null;

  if (task) {
    yield cancel(task);
    task = null;
  }

  yield put(timeline.actions.stop());
}

export default function* watchTrailPath() {
  yield all([
    yield takeEvery("@trail-path/fetchData", watchFetchData),
    yield takeEvery("@trail-path/unmount", watchUnmount),
  ]);
}
