import React, { PureComponent } from "react";
import styled from "styled-components";
import { sumBy } from "lodash";
import RankTable from "./rank-table";
import { Progress } from "antd";

const Box = styled.div`
  width: 100%;
  height: 100%;

  .ant-progress-bg {
    border-radius: 0 !important;
  }
  .ant-progress-inner {
    border-radius: 0 !important;
  }
`;

export default class AlertChartByName extends PureComponent {
  render() {
    const { data = [] } = this.props;

    const total = sumBy(data, "count");

    const columns = [
      {
        title: "故障",
        dataIndex: "name",
      },
      {
        title: "占比",
        dataIndex: "count",
        render: val => {
          const percent = (Number(val) * 100) / total;

          return (
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <span style={{ width: 60 }}>{percent.toFixed(2)}%</span>

              <span style={{ width: 60, marginLeft: 16 }}>
                <Progress percent={percent} showInfo={false} strokeWidth={15} />
              </span>

              <span style={{ marginLeft: 16 }}>{val}</span>
            </div>
          );
        },
      },
    ];

    return (
      <Box>
        <RankTable columns={columns} data={data} />
      </Box>
    );
  }
}
