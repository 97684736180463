/**
 * Log socket server 的 redux 状态
 */
import { WSType } from "./actions";

export const initState = {
  server: {
    // 与服务器的连接情况
    state: "DISCONNECTED",
  },
  client: {}, // 客户端信息
  logs: [], // 接收到的日志
  keyword: undefined,
};

export default function(state = initState, action) {
  const { payload, type } = action;
  if (WSType.isSuccess(type)) {
    // merge state and current payload
    return { ...state, ...payload };
  }
  return state;
}
