import React, { PureComponent } from "react";
import {
  Menu,
  // Dropdown,
  //Icon,
  Button,
  Modal,
} from "antd";
import { connect } from "react-redux";
import TicketActions from "../../actions/tickets";
import { globalSelectors } from "../../selectors";
import { get } from "lodash";
import { history } from "../../lib";
import { AlertActions } from "../../constants";

const { confirm } = Modal;

/**
 * 工单操作menu
 */
@connect(state => {
  const loginUser = globalSelectors.loginUser(state);

  return {
    handler: get(loginUser, "id"),
  };
})
export default class TicketOperators extends PureComponent {
  showIgnoreConfirm = () => {
    confirm({
      title: "确定忽略报警",
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        this.props.dispatch(
          TicketActions.ignoreAlert({
            alertId: this.props.alertId,
            action: AlertActions.IGNORE,
            actionAt: new Date(),
            actionBy: this.props.handler,
            nextAction: this.props.afterIgnore,
          })
        );
      },
      onCancel: () => {},
    });
  };

  onMenuClick = ({ key }) => {
    switch (key) {
      case "create":
        history.push(`/ticket/create?alertId=${this.props.alertId}`);
        break;
      case "bind":
        history.push(
          `/ticket/bind?alertId=${this.props.alertId}&vehicleId=${
            this.props.vehicleId
          }`
        );
        break;
      case "ignore":
        this.showIgnoreConfirm();
        break;
      default:
        break;
    }
  };

  menu = (
    <Menu onClick={e => this.onMenuClick(e)}>
      <Menu.Item key="create">创建工单</Menu.Item>
      {/* <Menu.Item key="bind">关联工单</Menu.Item> */}
      <Menu.Item key="ignore">忽略报警</Menu.Item>
    </Menu>
  );

  render() {
    return (
      <Button
        type="primary"
        size="small"
        ghost
        onClick={() => this.onMenuClick({ key: "create" })}
      >
        创建工单
      </Button>
    );

    // 暂时隐藏忽略报警选项，只保留创建工单 2020年4月7日
    // return (
    //   <Dropdown overlay={this.menu} trigger={["click"]}>
    //     <Button size="small">
    //       处理操作 <Icon type="down" />
    //     </Button>
    //   </Dropdown>
    // );
  }
}
