import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { without } from "lodash";
import moment from "moment";
import { StyledFilterForm } from "../../../components/fold-filter-form";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Table,
  Card,
  Dropdown,
  Icon,
} from "antd";
import TerminalActions from "../../../actions/terminal";
import MonitorActions from "../../../actions/monitor";
import TerminalSelectors from "../../../selectors/terminal";
import { getPathname, ymd } from "../../../lib";
import FilterDropdown from "../../../components/filter-dropdown";
import withPermission from "../../with-permission";
import Permission from "../../../config/permission";
import VehicleLink from "../../../components/vehicle-link";
import { MAP_MAX_VEHICLES } from "../../../config";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};

const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8, offset: 6 },
};

@withPermission({
  permission: Permission.vehicleMonitor.terminal,
})
@connect(state => {
  const { loading, result: docs } = TerminalSelectors.offSiteSelectors(state);

  return {
    loading,
    docs,
  };
})
@Form.create()
class TerminalsTable extends PureComponent {
  state = {
    hideColumns: ["line", "model"],
    filters: {},
  };
  componentDidMount() {
    this.fetchVehicleStates();
  }

  fetchVehicleStates = () => {
    this.props.dispatch(
      MonitorActions.listVehicles.request({
        _limit: MAP_MAX_VEHICLES,
      })
    );
  };

  showedColumns() {
    const { hideColumns } = this.state;
    const allValues = TableColumns.map(i => i.key);
    return without(allValues, ...hideColumns);
  }

  onColumnsFilterChange = values => {
    const allValues = TableColumns.map(i => i.key);
    this.setState({
      hideColumns: without(allValues, ...values),
    });
  };

  handleExport = () => {
    const filename = `未接入车辆-${moment().format("YYYY-MM-DD")}`;

    this.props.dispatch(
      TerminalActions.offSiteXlsx.export({
        columns: TableColumns,
        fileName: filename,
        fileType: "xlsx",
      })
    );
  };

  handleSumbit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { vin, no } = values;
        this.setState({ filters: { vin, no } });
      }
    });
  };

  handleRest = () => {
    this.props.form.resetFields();
    this.setState({ filters: {} });
  };

  renderTopActions = () => {
    return (
      <div className="top-right-actions">
        <div>
          <Dropdown
            overlay={
              <FilterDropdown
                value={this.showedColumns()}
                onChange={this.onColumnsFilterChange}
                items={TableColumns.map(c => ({ key: c.key, name: c.title }))}
              />
            }
            trigger={["click"]}
          >
            <Button>
              <Icon type="filter" /> 选择数据项
            </Button>
          </Dropdown>
        </div>

        <div>
          <Button
            loading={this.props.exportLoading}
            onClick={this.handleExport}
            type="primary"
          >
            <Icon type="export" />
            导出
          </Button>
        </div>
      </div>
    );
  };

  render() {
    let { docs = [], loading } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { hideColumns, filters } = this.state;
    const showColumns = TableColumns.filter(c => !hideColumns.includes(c.key));

    if (filters.vin) {
      docs = docs.filter(d => d.vin === filters.vin);
    }

    if (filters.no) {
      docs = docs.filter(d => d.no === filters.no);
    }

    return (
      <Container>
        <Card style={{ marginTop: 12 }}>
          <StyledFilterForm
            style={{ marginBottom: 0 }}
            onSubmit={this.handleSumbit}
          >
            <Row gutter={48} type="flex">
              <Col span={8}>
                <Form.Item label="车辆VIN码" {...formItemLayout}>
                  {getFieldDecorator("vin", {})(
                    <Input allowClear placeholder="请输入车辆VIN码" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="车辆自编号" {...formItemLayout}>
                  {getFieldDecorator("no", {})(
                    <Input allowClear placeholder="请输入车辆自编号" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...formTailLayout}>
                  <Button onClick={this.handleRest}>重置</Button>

                  <Button
                    style={{ marginLeft: 24 }}
                    type="primary"
                    htmlType="submit"
                  >
                    筛选
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </StyledFilterForm>
        </Card>
        <TableContainer
          bordered={false}
          title={
            <div>
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: 24,
                  fontSize: "16px",
                }}
              >{`共有${docs.length}个未接入车辆`}</span>
            </div>
          }
          style={{ marginTop: 12 }}
          extra={this.renderTopActions()}
        >
          <Table
            rowKey="vin"
            loading={loading}
            onChange={this.listTerminals}
            dataSource={docs}
            columns={showColumns}
            scroll={{ x: "max-content" }}
          />
        </TableContainer>
      </Container>
    );
  }
}

export default TerminalsTable;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

export const TableColumns = [
  { title: "VIN码", dataIndex: "vin", key: "vin" },
  {
    title: "自编号",
    dataIndex: "no",
    key: "no",
    render: (val, record) => {
      return <VehicleLink title={val} vin={record.id} />;
    },
  },
  { title: "车牌号", dataIndex: "plate", key: "plate" },
  {
    title: "营运公司",
    dataIndex: "ns",
    key: "department",
    render: val => getPathname(val),
    exportFormat: val => getPathname(val),
  },
  { title: "线路", dataIndex: "line", key: "line" },
  { title: "车型简称", dataIndex: "model", key: "model" },
  { title: "厂商", dataIndex: "manufacturer", key: "manufacturer" },
  {
    title: "上牌日期",
    render: val => (val ? ymd(val) : "--"),
    exportFormat: val => (val ? ymd(val) : ""),
    dataIndex: "plateAt",
    key: "plateAt",
  },
];

const TableContainer = styled(Card)`
  .top-right-actions {
    > div {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;
    }

    .ant-btn {
      padding-top: 5px;
      padding-bottom: 5px;
      height: 40px;
    }
  }
`;
