import { createXlsxActions } from "@36node/redux-xlsx";
import {
  successOf,
  failureOf,
  isSuccess,
  createApiActions,
} from "@36node/redux-api";
import { put, take, select } from "redux-saga/effects";
import { message } from "antd";

import { NS } from "../constants";
import { authV1Sdk, mekong } from "../sdk";
import { monitorSelectors, vehicleSelectors } from "../selectors";
import { alertSchema, userSchema } from "../schemas";

const xlsxParams = {
  history: {
    constant: NS.MONITOR.LIST_RECORDS,
    successOf: NS.MONITOR.LIST_RECORDS,
    failureOf: NS.MONITOR.LIST_RECORDS,
    errorTitle: "历史信息",
    selector: monitorSelectors.listVehicleRecords,
  },
  graph: {
    constant: NS.MONITOR.LIST_RECORDS_GRAPH,
    successOf: NS.MONITOR.LIST_RECORDS_GRAPH,
    failureOf: NS.MONITOR.LIST_RECORDS_GRAPH,
    errorTitle: "数据回放",
    selector: monitorSelectors.listVehicleRecordsGraph,
  },
  alert: {
    constant: NS.MONITOR.LIST_ALERTS,
    schema: [alertSchema],
    endpoint: mekong.listDeviceFaultRecords,
    successOf: NS.MONITOR.LIST_ALERTS,
    failureOf: NS.MONITOR.LIST_ALERTS,
    errorTitle: "报警信息",
    selector: monitorSelectors.listAlerts,
  },
};
/**
 * list vehicles records api for import
 */
const listRecordForExport = type => {
  return createApiActions(xlsxParams[type].constant, {
    schema: xlsxParams[type].schema,
    endpoint: xlsxParams[type].endpoint,
  });
};

/**
 * 车辆导出数据源
 * @param {*} params
 */
const exportDataSource = type =>
  function*(params = {}) {
    const apiRequest = listRecordForExport(type).request;
    yield put(apiRequest(params));
    const apiResultAction = yield take([
      successOf(xlsxParams[type].successOf),
      failureOf(xlsxParams[type].failureOf),
    ]);
    if (isSuccess(apiResultAction)) {
      const apiResult = yield select(xlsxParams[type].selector);
      return apiResult.result;
    } else {
      message.error(`${xlsxParams[type].errorTitle}档案导出失败`);
      return;
    }
  };

const vehiclesXlsx = type =>
  createXlsxActions(xlsxParams[type].constant, {
    exportOpts: {
      dataSource: exportDataSource(type),
    },
    importOpts: {},
  });

const listUsers = createApiActions(NS.VEHICLE.LIST_USERS, {
  endpoint: authV1Sdk.user.listUsers,
  schema: [userSchema],
});

const listStateHistory = createApiActions(NS.VEHICLE.GET_STATE_HISTORY, {
  endpoint: mekong.getStatusHistory,
});

function* exportStateSouce(params = {}) {
  const apiResult = yield select(vehicleSelectors.listStateHistory);
  return apiResult.result;
}

const stateHistoryXlsx = createXlsxActions(NS.VEHICLE.STATE_HISTORY_XLSX, {
  exportOpts: {
    dataSource: exportStateSouce,
  },
});

export default {
  vehiclesXlsx,
  listUsers,
  listStateHistory,
  stateHistoryXlsx,
};
