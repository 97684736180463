import React, { PureComponent } from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { Radio, Spin, Input, DatePicker } from "antd";
import { history } from "../../lib";
import { MAX_TICKETS_LIMIT } from "../../config";
import { Route, Switch, Redirect } from "react-router-dom";
import Kanban from "./kanban";
import List from "./list";
import { connect } from "react-redux";
import TicketSelectors from "../../selectors/tickets";
import TicketActions from "../../actions/tickets";
import Detail from "./detail";
import BindAlert from "./bind-alert";
import CreateTicket from "./create-ticket";
import withPermission from "../with-permission";
import Permission from "../../config/permission";

const { Search } = Input;
const { RangePicker } = DatePicker;

const Items = [
  {
    key: "list",
    title: "列表视图",
    pathname: "/ticket/list",
  },
  {
    key: "kanban",
    title: "看板视图",
    pathname: "/ticket/kanban",
  },
];

@withPermission({
  permission: Permission.ticket,
})
@withRouter
@connect(state => {
  const stages = TicketSelectors.stages(state);
  const loading = TicketSelectors.allLoading(state);

  const ticketQuery = TicketSelectors.ticketQuery(state).assign || {};
  const countReady = Object.keys(TicketSelectors.listStageTickets).length > 0;

  return {
    stages,
    loading,
    ticketQuery,
    countReady,
  };
})
export default class Ticket extends PureComponent {
  onSearch = value => {
    const { ticketQuery } = this.props;
    this.props.dispatch(
      TicketActions.ticketsQuery.set({
        limit: MAX_TICKETS_LIMIT,
        sort: "-updatedAt",
        ...ticketQuery,
        q: value,
      })
    );
  };

  onDateRangeChange = ([startAt, endAt]) => {
    const { ticketQuery } = this.props;

    this.props.dispatch(
      TicketActions.ticketsQuery.set({
        limit: MAX_TICKETS_LIMIT,
        sort: "-updatedAt",
        ...ticketQuery,
        createdAt_gt: startAt && startAt.startOf("day").toISOString(),
        createdAt_lt: endAt && endAt.endOf("day").toISOString(),
      })
    );
  };

  componentDidMount() {
    this.props.dispatch(
      TicketActions.ticketsQuery.set({
        limit: MAX_TICKETS_LIMIT,
        sort: "-updatedAt",
      })
    );
  }

  componentWillUpdate(nextProps, nextState) {
    const { stages = [] } = nextProps;
    if (nextProps.countReady && !this.props.countReady) {
      stages.forEach(stage => {
        this.props.dispatch(
          TicketActions.listStageTickets[stage.id].request({
            query: { ...this.props.ticketQuery, stage: stage.id },
          })
        );
      });
    }
  }

  onViewChange = e => {
    const value = e.target.value;
    history.push(value);
  };

  showTop = () => {
    const { location } = this.props;
    const { pathname } = location;

    return ["/ticket/kanban", "/ticket/list"].includes(pathname) && false;
  };

  render() {
    const { loading } = this.props;
    const { pathname } = this.props.location;

    return (
      <Container>
        {this.showTop() && (
          <Top>
            <Radio.Group
              onChange={this.onViewChange}
              value={pathname}
              buttonStyle="solid"
              style={{
                width: "20%",
              }}
            >
              {Items.map(item => {
                return (
                  <Radio.Button key={item.key} value={item.pathname}>
                    {item.title}
                  </Radio.Button>
                );
              })}
            </Radio.Group>

            <Search
              placeholder="请输入工单编号/车辆自编号/车辆vin码/报警编号"
              size="large"
              enterButton="搜索"
              style={{
                width: "30%",
              }}
              allowClear
              onSearch={this.onSearch}
            />

            <RangePicker
              allowClear
              placeholder={["开始时间", "结束时间"]}
              size="large"
              onChange={this.onDateRangeChange}
              style={{
                width: "30%",
              }}
            />
          </Top>
        )}

        <Spin spinning={loading} delay={100}>
          <Content>
            <Switch>
              <Route exact path="/ticket/kanban" component={Kanban} />
              <Route exact path="/ticket/list" component={List} />
              <Route exact path="/ticket/bind" component={BindAlert} />
              <Route exact path="/ticket/create" component={CreateTicket} />
              <Route exact path="/ticket/:ticketId/detail" component={Detail} />

              <Redirect from="/ticket" to="/ticket/list" />
            </Switch>
          </Content>
        </Spin>
      </Container>
    );
  }
}

const Content = styled.div`
  width: 100%;
  height: calc(100vh - 156px);
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  padding: 12px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 12px;
  margin-bottom: 12px;
`;
