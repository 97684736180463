import { message } from "antd";
import { all, fork, take, put, select, takeEvery } from "redux-saga/effects";
import { successOf } from "@36node/redux-api";
import { NS } from "../constants";
import monitorActions, { listUsers } from "../actions/monitor";
import { monitorSelectors } from "../selectors";

/**
 * 在listAlert 之后 读取users
 */
function* watchListAlerts() {
  while (true) {
    yield take(successOf(NS.MONITOR.LIST_ALERTS));

    const alertResp = yield select(monitorSelectors.listAlerts);
    const alerts = yield select(state => state.entities.alerts);

    const { result = [] } = alertResp;

    const handlers = result.map(r => alerts[r.id].actionBy).filter(h => h);

    if (handlers && handlers.length > 0) {
      yield put(listUsers.request({ query: { id: handlers } }));
    }
  }
}

function* onUpdateOrCreateSnapshot() {
  message.success("车辆快照更新成功");
  yield put(monitorActions.listVehicleSnapshots.refresh());
}

export default function* watchMonitors() {
  yield all([
    fork(watchListAlerts),
    takeEvery(successOf(NS.MONITOR.UPDATE_SNAP), onUpdateOrCreateSnapshot),
    takeEvery(successOf(NS.MONITOR.CREATE_SNAP), onUpdateOrCreateSnapshot),
  ]);
}
