import React, { PureComponent } from "react";
import styled from "styled-components";
import resetMap from "../common/reset-map";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import { Menu } from "antd";
import Alert from "./alert";
import Battery from "./battery";
import Insulation from "./insulation";
import Tyre from "./tyre";
import Pile from "./pile";
import { withRouter } from "react-router";
import withPermission from "../with-permission";
import Permission from "../../config/permission";
import ChargeRecords from "./charge-records";

const items = [
  {
    key: "alert",
    pathname: "/history/alert",
    title: "报警监控数据",
  },
  {
    key: "battery",
    pathname: "/history/battery",
    title: "电池异常数据",
  },
  {
    key: "insulate",
    pathname: "/history/insulation",
    title: "绝缘异常数据",
  },
  {
    key: "tyre",
    pathname: "/history/tyre",
    title: "轮胎异常数据",
  },
];

@withPermission({
  permission: Permission.history,
})
@resetMap
@withRouter
export default class History extends PureComponent {
  render() {
    const { pathname } = this.props.location;
    return (
      <Container>
        <Menu mode="horizontal" selectedKeys={[pathname]}>
          {items.map(i => (
            <Menu.Item key={i.pathname}>
              <NavLink to={i.pathname}>{i.title}</NavLink>
            </Menu.Item>
          ))}
        </Menu>

        <Switch>
          <Route exact path="/history/alert" component={Alert} />
          <Route exact path="/history/battery" component={Battery} />
          <Route exact path="/history/insulation" component={Insulation} />
          <Route exact path="/history/tyre" component={Tyre} />
          <Route exact path="/history/pile" component={Pile} />
          <Route
            exact
            path="/history/charge-records"
            component={ChargeRecords}
          />

          <Redirect from="/history" to="/history/alert" />
        </Switch>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  padding: 12px;
`;
