import { connect } from "react-redux";
import { get } from "lodash";
import { selectSession } from "../selectors";

const withSession = Component => {
  return connect(state => {
    const session = selectSession(state) || {};

    const roles = get(session, "roles");

    return {
      session,
      roles,
    };
  })(Component);
};

export default withSession;
