/**
 * 按公司满电率统计
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import ReactEcharts from "echarts-for-react";

export class StopOrderByStationChart extends PureComponent {
  get colors() {
    return ["#1890FF", "#13C2C2", "#2FC25B", "#FACC14", "#F04864", "#8543E0"];
  }

  getStationName = item => {
    const { stations } = this.props;
    const station = stations.find(station => station.id === item.station);
    return station ? station.name : "未知";
  };

  getOption = () => {
    const { data = [] } = this.props;

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: "{b} : {c}",
      },
      xAxis: [
        {
          type: "category",
          data: data.map(this.getStationName.bind(this)),
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: { interval: 0, rotate: 20 },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "报警总数",
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
      ],
      series: [
        {
          name: "Direct",
          type: "bar",
          barWidth: "30%",
          data: data.map(item => item.value),
          itemStyle: {
            color: "#29a7f3",
          },
        },
      ],
    };
  };

  render() {
    const option = this.getOption();

    return (
      <Row>
        <Col span={24}>
          <ReactEcharts option={option} />
        </Col>
      </Row>
    );
  }
}
