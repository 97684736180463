import React, { PureComponent } from "react";
import {
  ExceptionTypes,
  TyreExceptionI18N,
  BatteryExceptionI18N,
  InsulationExceptionI18N,
  TBoxExceptionI18N,
} from "../../constants";
import { Select } from "antd";

const typeNames = {
  [ExceptionTypes.TYRE_EXCEPTION]: TyreExceptionI18N,
  [ExceptionTypes.BATTERY_EXCEPTION]: BatteryExceptionI18N,
  [ExceptionTypes.INSULATION_EXCEPTION]: InsulationExceptionI18N,
  [ExceptionTypes.TBOX_EXCEPTION]: TBoxExceptionI18N,
};

export default class ExceptionNameSelect extends PureComponent {
  render() {
    const { type, ...rest } = this.props;
    const names = typeNames[type] || {};
    return (
      <Select {...rest}>
        {Object.keys(names).map(n => (
          <Select.Option key={n} value={n}>
            {names[n]}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
