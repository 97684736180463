import { Layout } from "antd";
import styled from "styled-components";
import { ZIndex } from "../../constants";

const { Header } = Layout;

export default styled(Header)`
  position: fixed;
  z-index: ${ZIndex.header};
  width: 100%;
`;
