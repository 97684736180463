import { NS } from "../constants";
import { createApiSelector } from "@36node/redux-api";
import { createExportSelector, createImportSelector } from "@36node/redux-xlsx";
import { createAssignSelector } from "@36node/redux-ui";
import { mekongVehicleSchema } from "../schemas";

/**
 * 车辆档案选择
 */

export function makeArchivesSelectors() {
  const listVehicles = createApiSelector(NS.ARCHIVES.LIET_VEHICLES, [
    mekongVehicleSchema,
  ]);

  const listVehiclesForExport = createApiSelector(NS.ARCHIVES.VEHICLES_XLSX, [
    mekongVehicleSchema,
  ]);

  const vehiclesExport = createExportSelector(NS.ARCHIVES.VEHICLES_XLSX);
  const vehiclesImport = createImportSelector(NS.ARCHIVES.VEHICLES_XLSX);
  const importModalState = createAssignSelector(NS.ARCHIVES.VEHICLES_XLSX);
  const editorFormNs = createAssignSelector(NS.ARCHIVES.EDITOR_FORM_NS);

  return {
    listVehicles,
    listVehiclesForExport,
    vehiclesImport,
    vehiclesExport,
    importModalState,
    editorFormNs,
  };
}
