import React, { PureComponent } from "react";
import { Card, Table } from "antd";
import { ymdhms } from "../../lib";

export default class AlertsTable extends PureComponent {
  columns = [
    {
      title: "报警编号",
      dataIndex: "id",
      render: val => val.slice(-8),
    },
    {
      title: "等级",
      dataIndex: "level",
      render: val => {
        switch (val) {
          case 1:
            return "一级";
          case 2:
            return "二级";
          case 3:
            return "三级";
          default:
            break;
        }
      },
    },
    {
      title: "报警码",
      dataIndex: "code",
    },
    {
      title: "报警名称",
      dataIndex: "name",
    },
    {
      title: "开始时间",
      dataIndex: "brokenAt",
      render: ymdhms,
    },
    {
      title: "次数",
      dataIndex: "count",
    },
    {
      title: "最近更新时间",
      dataIndex: "lastWarnAt",
      render: ymdhms,
    },
  ];

  render() {
    return (
      <Card title={<div style={{ fontWeight: 600 }}>报警信息</div>}>
        <Table
          loading={this.props.loading}
          rowKey="id"
          pagination={false}
          dataSource={this.props.alerts || []}
          columns={this.columns}
          scroll={{ x: "max-content" }}
        />
      </Card>
    );
  }
}
