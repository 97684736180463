/**
 * 处理archives 导入导出
 */
import { createAssignActions } from "@36node/redux-ui";
import { createXlsxActions } from "@36node/redux-xlsx";
import {
  successOf,
  failureOf,
  isSuccess,
  createApiActions,
} from "@36node/redux-api";
import { call, put, take, select } from "redux-saga/effects";
import { message } from "antd";
import { isEmpty } from "lodash";
import validate from "validate.js";

import { mekong } from "../sdk";
import { NS } from "../constants";
import { stationSelectors } from "../selectors";
import {
  connectorSchema,
  orderSchema,
  pileSchema,
  stationSchema,
  mekongVehicleSchema,
} from "../schemas";
import { ymdhms } from "../lib";

const listPiles = createApiActions(NS.MONITOR.PILE.LIST_PILES, {
  endpoint: mekong.listPiles,
  schema: [pileSchema],
});

const listPilesForExport = createApiActions(NS.MONITOR.PILE.PILES_XLSX, {
  endpoint: mekong.listPiles,
});

const listConnectors = createApiActions(NS.MONITOR.PILE.LIST_CONNECTORS, {
  endpoint: mekong.listConnectors,
  schema: [connectorSchema],
});

const updateConnector = createApiActions(NS.MONITOR.PILE.UPDATE_CONNECTOR, {
  endpoint: mekong.updateConnector,
  schema: connectorSchema,
});

const listConnectorsForExport = createApiActions(
  NS.MONITOR.PILE.CONNECTORS_XLSX,
  {
    endpoint: mekong.listConnectors,
  }
);

const deleteConnector = createApiActions(NS.MONITOR.PILE.DELETE_CONNECTOR, {
  endpoint: mekong.deleteConnector,
});

const deleteConnectors = (connectorIds = []) => {
  return {
    type: NS.MONITOR.PILE.DELETE_CONNECTORS,
    payload: {
      connectorIds,
    },
  };
};

const deletePileStation = createApiActions(NS.MONITOR.PILE.DELETE_STATION, {
  endpoint: mekong.deleteStation,
});

const listPileStations = createApiActions(NS.MONITOR.PILE.LIST_STATIONS, {
  endpoint: mekong.listStations,
  schema: [stationSchema],
});

const listAllPileStations = createApiActions(
  NS.MONITOR.PILE.LIST_ALL_STATIONS,
  {
    endpoint: mekong.listStations,
    schema: [stationSchema],
  }
);

const chargeAggregatePerHour = createApiActions(
  NS.AGGREGATE.ORDER_CHARGER_PER_HOUR,
  {
    endpoint: mekong.aggregateOrders,
  }
);

const getPileStation = createApiActions(NS.MONITOR.PILE.GET_STATION, {
  endpoint: mekong.getStation,
  schema: stationSchema,
});

const updatePileStation = createApiActions(NS.MONITOR.PILE.UPDATE_STATION, {
  endpoint: mekong.updateStation,
  schema: stationSchema,
});

const listChargeOrders = createApiActions(NS.MONITOR.PILE.LIST_ORDERS, {
  endpoint: mekong.listOrders,
  schema: [orderSchema],
});

const listChargeOrdersForExport = createApiActions(
  NS.MONITOR.PILE.ORDERS_XLSX,
  {
    endpoint: mekong.listOrders,
    schema: [orderSchema],
  }
);

const listVehicles = createApiActions(NS.MONITOR.PILE.LIST_VEHICLES, {
  schema: [mekongVehicleSchema],
  endpoint: mekong.listVehicles,
});

/**
 * 桩导出数据源
 * @param {*} params
 */
function* exportPileXlsxDataSouce(params) {
  const apiRequest = listPilesForExport.request;

  yield put(apiRequest(params));

  const apiResultAction = yield take([
    successOf(NS.MONITOR.PILE.PILES_XLSX),
    failureOf(NS.MONITOR.PILE.PILES_XLSX),
  ]);

  if (isSuccess(apiResultAction)) {
    const apiResult = yield select(stationSelectors.listPilesForExport);
    return apiResult.result;
  } else {
    message.error("导出失败");
    return;
  }
}

const pilesXlsx = createXlsxActions(NS.MONITOR.PILE.PILES_XLSX, {
  exportOpts: {
    dataSource: exportPileXlsxDataSouce,
  },
});

/**
 * 枪导出数据源
 * @param {*} params
 */
function* exportConnectorXlsxDataSouce(params = {}) {
  const apiRequest = listConnectorsForExport.request;

  yield put(apiRequest(params));

  const apiResultAction = yield take([
    successOf(NS.MONITOR.PILE.CONNECTORS_XLSX),
    failureOf(NS.MONITOR.PILE.CONNECTORS_XLSX),
  ]);

  if (isSuccess(apiResultAction)) {
    const apiResult = yield select(stationSelectors.listConnectorsForExport);
    return apiResult.result;
  } else {
    message.error("导出失败");
    return;
  }
}

/**
 * 充电枪导入数据验证
 * @param {*} records 记录
 */
function validateRecords(records = []) {
  const constraints = {
    stationNo: {
      presence: { message: "^场站编码不能为空" },
    },
    no: {
      presence: { message: "^充电枪编码不能为空" },
    },
    name: { presence: { message: "^充电枪自编码不能为空" } },
  };

  return records
    .map((r, row) => {
      const errors = validate(r, constraints, { format: "flat" });
      if (errors) {
        return {
          row,
          errors,
        };
      }
      return undefined;
    })
    .filter(r => r);
}

const importModalState = createAssignActions(NS.MONITOR.PILE.CONNECTORS_XLSX);
export const ImportModalStatus = {
  IMPORTING: "IMPORTING", // 尚未开始
  VALIDATE_FAIL: "VALIDATE_FAIL", // 验证失败
  API_FAIL: "API_FAIL", // api 请求错误
  FINISHED: "FINISHED", // 导入完成
};

/**
 * 导入前验证数据
 * @param {*} records
 */
function* beforeImport(records = []) {
  if (records.length === 0) {
    return false;
  }

  const results = validateRecords(records);

  const hasError = !isEmpty(results);

  if (hasError) {
    yield put(
      importModalState.set({
        status: ImportModalStatus.VALIDATE_FAIL,
        results,
      })
    );

    // 停止import进程
    return false;
  } else {
    yield put(
      importModalState.set({
        status: ImportModalStatus.IMPORTING,
      })
    );
  }
}

/**
 * 处理单条导入进度
 * @param {*} row 当前row
 * @param {*} record 当前record
 */
function* handleImportRecord(row, record) {
  const { stationNo, no, name } = record;

  try {
    yield call(mekong.updateConnectorByNo, { body: { stationNo, no, name } });
  } catch (err) {
    yield put(
      importModalState.set({
        status: ImportModalStatus.API_FAIL,
      })
    );
    yield put(connectorsXlsx.importPause());
  }
}

/**
 * 导入成功后，重新刷新api
 */
function* onImportFinished() {
  yield put(
    importModalState.set({
      status: ImportModalStatus.FINISHED,
    })
  );
  yield put(listConnectors.refresh());
}

const connectorsXlsx = createXlsxActions(NS.MONITOR.PILE.CONNECTORS_XLSX, {
  exportOpts: {
    dataSource: exportConnectorXlsxDataSouce,
  },
  importOpts: {
    beforeImport,
    handleRecord: handleImportRecord,
    onError: () => {
      message.error("文件读取失败，请检查导入文件");
    },
    onFinished: onImportFinished,
    workerCount: 5,
  },
});

/**
 * 订单导出数据源
 * @param {*} params
 */
function* exportChargeOrderXlsxDataSouce(params = {}) {
  const apiRequest = listChargeOrdersForExport.request;

  yield put(apiRequest(params));

  const apiResultAction = yield take([
    successOf(NS.MONITOR.PILE.ORDERS_XLSX),
    failureOf(NS.MONITOR.PILE.ORDERS_XLSX),
  ]);

  if (isSuccess(apiResultAction)) {
    const apiResult = yield select(stationSelectors.listChargeOrdersForExport);
    // eslint-disable-next-line array-callback-return
    apiResult.result.map(item => {
      item.chargeStartAt = ymdhms(item.chargeStartAt);
      item.chargeEndAt = ymdhms(item.chargeEndAt);
      item.updateAt = ymdhms(item.updateAt);
    });
    return apiResult.result;
  } else {
    message.error("导出失败");
    return;
  }
}

function* exportStationOrderXlsxDataSouce() {
  // 不重新取数据，直接取当前数据
  const apiResult = yield select(stationSelectors.selectStationOrder);
  return apiResult.result;
}

const chargeOrdersXlsx = createXlsxActions(NS.MONITOR.PILE.ORDERS_XLSX, {
  exportOpts: {
    dataSource: exportChargeOrderXlsxDataSouce,
  },
});

const chargeStationOrdersXlsx = createXlsxActions(
  NS.MONITOR.PILE.STATION_ORDERS_XLSX,
  {
    exportOpts: {
      dataSource: exportStationOrderXlsxDataSouce,
    },
  }
);

export const pileActions = {
  getPileStation,
  deletePileStation,
  listPileStations,
  listAllPileStations,
  updatePileStation,
  listPiles,
  pilesXlsx,
  listConnectors,
  updateConnector,
  deleteConnectors,
  deleteConnector,
  connectorsXlsx,
  importModalState,
  listChargeOrders,
  listVehicles,
  chargeOrdersXlsx,
  chargeStationOrdersXlsx,
  chargeAggregatePerHour,
};
