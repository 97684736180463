import { HISTORY_NS } from "../../constants";
import makeHistorySelectors from "./base";
import { chargeRecordSchema } from "../../schemas";

const chargeRecordsHistorySelectors = makeHistorySelectors(
  HISTORY_NS.CHARGE_RECORDS.LIST_WARNINGS,
  HISTORY_NS.CHARGE_RECORDS.WARNINGS_XLSX,
  [chargeRecordSchema]
);

export default chargeRecordsHistorySelectors;
