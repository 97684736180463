import React, { PureComponent } from "react";
import HTML5Backend from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { DndProvider } from "react-dnd";
import { get } from "lodash";
import TicketSelectors from "../../selectors/tickets";
import TicketActions from "../../actions/tickets";
import styled from "styled-components";
import TicketStage from "../../components/ticket/ticket-stage";
import globalSelectors from "../../selectors/global";
import { TicketEvents } from "../../constants";

@connect(state => {
  const stages = TicketSelectors.stages(state);
  const tickets = TicketSelectors.tickets(state);
  const loginUser = globalSelectors.loginUser(state);
  const stageTickets = {};
  Object.keys(TicketSelectors.listStageTickets).forEach(stage => {
    const stageTicketState = TicketSelectors.listStageTickets[stage](state);
    stageTickets[stage] = {
      tickets: stageTicketState.result || [],
      total: stageTicketState.total || 0,
    };
  });
  return {
    stages,
    tickets,
    loginUser,
    stageTickets,
  };
})
export default class Kanban extends PureComponent {
  componentDidMount() {
    this.props.dispatch(TicketActions.fetchTickets());
  }

  getStageName = stageKey => {
    return this.props.stages.find(s => s.key === stageKey).name;
  };

  ticketToStage = (stage, ticket) => {
    if (ticket && stage) {
      const { loginUser } = this.props;

      switch (stage.event) {
        case TicketEvents.STAGE: {
          const from = get(ticket, "stage.id");
          const to = stage.key;

          if (from !== to) {
            const body = {
              name: TicketEvents.STAGE,
              createdBy: loginUser.id,
              from,
              to,
            };

            this.props.dispatch(
              TicketActions.stageEvent.request(
                {
                  ticketId: ticket.id,
                  body,
                },
                {
                  successMsg: `工单${ticket.id.slice(
                    -8
                  )} 状态从 ${this.getStageName(from)} 变为 ${this.getStageName(
                    to
                  )}`,
                }
              )
            );
          }

          break;
        }
        default:
          break;
      }
    }
  };

  render() {
    const { stages = [] } = this.props;

    return (
      <DndProvider backend={HTML5Backend}>
        <Container>
          {stages.map(s => {
            return (
              <TicketStage
                ticketToStage={this.ticketToStage}
                stage={s}
                key={s.key}
              />
            );
          })}
        </Container>
      </DndProvider>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  padding-bottom: 12px;
`;
