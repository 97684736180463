import React, { PureComponent } from "react";
import { Card } from "antd";
import { calcVehicleRunState, ymdhms, history } from "../../lib";
import { DragSource } from "react-dnd";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { VehicleRunStateI18N, VehicleRunStateColor } from "../../constants";

@DragSource(
  "TICKET",
  {
    beginDrag(props) {
      return {
        ticket: props.ticket,
      };
    },
  },
  (connect, monitor) => {
    const opacity = monitor.isDragging() ? 0.5 : 1;

    return {
      opacity,
      connectDragSource: connect.dragSource(),
    };
  }
)
export default class TicketCard extends PureComponent {
  onCardClick = () => {
    const { ticket = {} } = this.props;

    history.push(`/ticket/${ticket.id}/detail`);
  };

  render() {
    const { ticket = {}, opacity, connectDragSource } = this.props;

    const { vehicle = {}, id, createdAt, updatedAt } = ticket;

    const data = vehicle;

    const runState = calcVehicleRunState(data);
    const color = VehicleRunStateColor[runState];
    const status = VehicleRunStateI18N[runState];

    const { no = "" } = vehicle;

    return connectDragSource(
      <div style={{ width: "100%", opacity }}>
        <StyledCard
          hoverable={true}
          onClick={this.onCardClick}
          title={<NavLink to="#">{`工单编号: ${id.slice(-8)}`}</NavLink>}
        >
          <Line style={{ color }}>
            车辆自编号:{no} ({status})
          </Line>

          <Line>车辆vin码: {vehicle.id}</Line>

          <Line>创建时间: {ymdhms(createdAt)}</Line>

          <Line>更新时间: {ymdhms(updatedAt)}</Line>
        </StyledCard>
      </div>
    );
  }
}

const StyledCard = styled(Card)`
  .ant-card-head {
    min-height: 40px;
    height: 40px;
  }
  .ant-card-head-title {
    padding: 8px;
  }
`;

const Line = styled.div`
  padding: 4px 0;
`;
