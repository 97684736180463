import { schema } from "normalizr";

export const namespacesSchema = new schema.Entity("namespaces");

export const userSchema = new schema.Entity("users");

export const templateSchema = new schema.Entity("templates");

export const stationSchema = new schema.Entity("stations");

export const pileSchema = new schema.Entity("piles");

export const connectorSchema = new schema.Entity("connectors");

export const orderSchema = new schema.Entity("orders", {
  station: stationSchema,
  ns: namespacesSchema,
});

export const lineSchema = new schema.Entity("lines");

export const vehicleSchema = new schema.Entity("vehicles", {
  line: lineSchema,
});

export const mekongVehicleSchema = new schema.Entity(
  "mekongVehicles",
  {},
  { idAttribute: "vin" }
);

export const sessionSchema = new schema.Entity("sessions", {
  user: userSchema,
});

export const snapshotSchema = new schema.Entity("snapshots", {
  vin: vehicleSchema,
});

export const recordSchema = new schema.Entity("records", {
  vin: vehicleSchema,
});

namespacesSchema.define({ parent: namespacesSchema });
userSchema.define({ ns: [namespacesSchema] });
vehicleSchema.define({ ns: namespacesSchema });
stationSchema.define({ ns: namespacesSchema });
lineSchema.define({ ns: namespacesSchema });
mekongVehicleSchema.define({ ns: namespacesSchema, stations: [stationSchema] });

export const warningSchema = new schema.Entity("warnings", {
  ns: namespacesSchema,
  vehicle: vehicleSchema,
});

export const exceptionSchema = new schema.Entity("exceptions", {
  ns: namespacesSchema,
  vehicle: vehicleSchema,
  vehicleLine: lineSchema,
});

export const alertSchema = new schema.Entity("alerts", {
  ns: namespacesSchema,
});

export const producerSchema = new schema.Entity(
  "producers",
  {},
  {
    idAttribute: "name",
  }
);

export const ticketStageSchema = new schema.Entity("ticketStages");

export const ticketEventSchema = new schema.Entity("ticketEvents", {
  createdBy: userSchema,
  from: ticketStageSchema,
  to: ticketStageSchema,
});

export const HandleResultSchema = new schema.Entity("handleResults", {
  ns: namespacesSchema,
});

export const ticketSchema = new schema.Entity("tickets", {
  // createdBy: userSchema,
  // updatedBy: userSchema,
  relatedFaults: [alertSchema],
  handleResults: [HandleResultSchema],
  // vehicle: vehicleSchema,
  // events: [ticketEventSchema],
  // stage: ticketStageSchema,
  ns: namespacesSchema,
});

export const banciSchema = new schema.Entity("bancis", {
  vehicle: vehicleSchema,
  line: lineSchema,
});

export const chargeRecordSchema = new schema.Entity("chargeOrder", {
  ns: namespacesSchema,
  vehicle: vehicleSchema,
  line: lineSchema,
});

export const overallWarningStatSchema = new schema.Entity(
  "overallWarningStats",
  {
    ns: namespacesSchema,
  }
);

export const overallTicketStatSchema = new schema.Entity("overallTicketStats", {
  ns: namespacesSchema,
});

export const overallPowerStatSchema = new schema.Entity("overallPowerStats", {
  ns: namespacesSchema,
});

export const operationSchema = new schema.Entity("operations", {
  user: userSchema,
});
