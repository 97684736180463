import Container from "./container";
import Content from "./content";
import Footer from "./footer";
import Header from "./header";
import Jumbotron from "./jumbotron";
import Title from "./title";
import { Layout } from "antd";
import styled from "styled-components";

const MyLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;
const { Sider } = Layout;

export default MyLayout;

export { Container, Content, Footer, Header, Jumbotron, Title, Sider };
