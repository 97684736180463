/**
 * 跳转到单车详情的组件
 */

import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";

export default class VehicleLink extends PureComponent {
  render() {
    const { title, vin } = this.props;

    return <NavLink to={`/monitor/alert/~/vehicle/${vin}`}>{title}</NavLink>;
  }
}
