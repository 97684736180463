import React, { PureComponent } from "react";
import { intersection, isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import withSession from "./with-session";

/**
 * permission hoc
 */

const withPermission = ({
  permission = [], // 允许的roles
  redirect = "/403", // 如果认证失败， 跳转的地址
}) => {
  return Component => {
    class MyComponent extends PureComponent {
      render() {
        const passed = !isEmpty(intersection(this.props.roles, permission));

        // 通过
        if (passed) {
          return <Component {...this.props} />;
        }

        // 跳转
        if (redirect) {
          return (
            <Redirect
              to={{ pathname: redirect, state: { from: this.props.location } }}
            />
          );
        }
      }
    }

    return withSession(MyComponent);
  };
};

export default withPermission;
