import { combineReducers } from "redux";
import { apiReducers } from "@36node/redux-api";
import { reduxUiReducers } from "@36node/redux-ui";
import { formReducer } from "@36node/redux-form";
import { xlsxReducer } from "@36node/redux-xlsx";
import { cronReducer } from "@36node/redux-cron";

import { timelineReducer } from "../modules/timeline";
import { reducer as ws } from "../modules/ws";

function entities(
  state = {
    users: {},
    alerts: {},
    vehicles: {},
    tickets: {},
    ticketEvents: {},
    ticketStages: {},
    namespaces: {},
    lines: {},
    warnings: {},
    sessions: {},
    producers: {},
    records: {},
    piles: {},
    stations: {},
    connectors: {},
  },
  action
) {
  if (action.payload && action.payload.entities) {
    return apiReducers.entities(state, action);
  }

  return state;
}

export default combineReducers({
  forms: formReducer,
  timelines: timelineReducer,
  ...apiReducers,
  entities,
  ...reduxUiReducers,
  xlsxs: xlsxReducer,
  ws,
  cron: cronReducer,
});
