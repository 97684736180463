import React, { PureComponent } from "react";
import styled from "styled-components";

export default class Logo extends PureComponent {
  render() {
    const { title } = this.props;
    return (
      <Box>
        <Image />
        <Title>{title || "新能源监控平台"}</Title>
      </Box>
    );
  }
}

const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0px 16px 24px;
  background: rgba(48, 60, 119, 1);
`;

const Image = styled.img.attrs({ src: "/images/logo.png" })`
  width: 32px;
  height: 32px;
`;

const Title = styled.div`
  color: white;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  height: 22px;
  line-height: 22px;
`;
