import React from "react";
import { ListStationsCountableField, StationType } from "@36node-mekong/sdk-ts";
import { hot } from "react-hot-loader/root";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import DocumentTitle from "react-document-title";
import styled from "styled-components";

import { history } from "./lib";
import ProtectedRoute from "./containers/protect";

import withTracker from "./withTracker";

import Nav from "./containers/nav";
import Logo from "./components/logo";
import Loading from "./components/loading";
import Layout, { Content, Sider } from "./components/layout";
import { APP_TITLE } from "./config";
import TopBar from "./containers/top-bar";
import Login from "./containers/login/index";
import History from "./containers/history";
import Ticket from "./containers/ticket";
import { connect } from "react-redux";
import { globalActions } from "./actions";
import Analysis from "./containers/analysis";
import VehicleMonitor from "./containers/vehicle-monitor";
import StationPage from "./containers/station";
import stationOrder from "./containers/station/station-order";
import Page403 from "./containers/page-403";
import { pileActions } from "./actions/pile";

import { globalSelectors } from "./selectors";

const OverView = Loadable({
  loader: () => import("./containers/overview"),
  loading: Loading,
});

const Monitor = Loadable({
  loader: () => import("./containers/monitor"),
  loading: Loading,
});
const MonitorTable = Loadable({
  loader: () => import("./containers/monitor/table"),
  loading: Loading,
});
// const History = Loadable({
//   loader: () => import("./containers/history"),
//   loading: Loading,
// });

// const WorkOrder = Loadable({
//   loader: () => import("./containers/work-order"),
//   loading: Loading,
// });

const ReportForm = Loadable({
  loader: () => import("./containers/report-form"),
  loading: Loading,
});

const System = Loadable({
  loader: () => import("./containers/system"),
  loading: Loading,
});

const Vehicle = Loadable({
  loader: () => import("./containers/vehicle"),
  loading: Loading,
});

const App = () => (
  <Router history={history}>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/403" component={Page403} />

      <ProtectedRoute component={withTracker(Main, {})} />
    </Switch>
  </Router>
);

const Body = styled.div`
  width: 100%;
`;

@connect(state => {
  // const producers = globalSelectors.listProducers(state).result || [];

  const topBarSearchFocused = globalSelectors.toggleSelector(state).toggle;
  return {
    // producers,
    topBarSearchFocused,
  };
})
class Main extends React.PureComponent {
  componentDidMount() {
    // 全局获取namespaces, producers, lines, vehicles
    this.props.dispatch(globalActions.listNamespaces());
    // this.props.dispatch(globalActions.listProducers.request());
    // this.props.dispatch(globalActions.listLines());
    this.props.dispatch(globalActions.listVehicles());
    this.listStations();
    // this.props.dispatch(TicketActions.listStages.request());
    this.props.dispatch(globalActions.listUsers());
  }

  listStations = () => {
    this.props.dispatch(
      pileActions.listPileStations.request(
        {
          _limit: 100,
          _count: [
            ListStationsCountableField.CONNECTOR,
            ListStationsCountableField.VEHICLE,
          ],
          type: StationType.CHARGER,
        },
        {}
      )
    );
  };

  render() {
    const { topBarSearchFocused } = this.props;

    return (
      <DocumentTitle title="新能源车辆监控平台">
        <div>
          <Layout>
            <Sider
              onClick={
                topBarSearchFocused
                  ? () => {
                      this.props.dispatch(globalActions.toggleActions.close());
                    }
                  : null
              }
              style={{ background: "rgba(39,50,102,1)" }}
            >
              <Logo title={APP_TITLE} />
              <Nav />
            </Sider>
            <Content>
              <TopBar />
              <Body
                onClick={
                  topBarSearchFocused
                    ? () => {
                        this.props.dispatch(
                          globalActions.toggleActions.close()
                        );
                      }
                    : null
                }
              >
                <Switch>
                  <Route path="/*/~/vehicle/:vehicleId" component={Vehicle} />
                  <Route path="/overview" component={OverView} />
                  <Route path="/monitor-table" component={MonitorTable} />
                  <Route path="/monitor" component={Monitor} />
                  <Route path="/history" component={History} />
                  <Route path="/station" component={StationPage} />
                  <Route
                    path="/station-order/:stationId"
                    component={stationOrder}
                  />
                  <Route path="/ticket" component={Ticket} />
                  <Route path="/analysis" component={Analysis} />
                  <Route path="/report-form" component={ReportForm} />
                  <Route path="/system" component={System} />
                  <Route path="/vehicle-monitor" component={VehicleMonitor} />
                  <Redirect from="/" to="/overview" />
                </Switch>
              </Body>
            </Content>
          </Layout>
        </div>
      </DocumentTitle>
    );
  }
}

export default (process.env.NODE_ENV === "development" ? hot(App) : App);
