import { get } from "lodash";
import { types } from "./actions";

const Instances = new Map();

class Timeline {
  constructor(key) {
    if (!key) {
      throw new Error("Timeline maker need a key!");
    }

    this._key = key;
  }

  get key() {
    return this._key;
  }

  actions = {
    setData: (data = [], meta = {}) => ({
      type: types.setData,
      payload: { data },
      meta: { key: this._key, ...meta },
      instance: this,
    }),
    start: (meta = {}) => ({
      type: types.start,
      meta: { key: this._key, ...meta },
      instance: this,
    }),
    pace: (pace, meta = {}) => ({
      type: types.pace,
      payload: { pace },
      meta: { key: this._key, ...meta },
      instance: this,
    }),
    stop: () => ({
      type: types.stop,
      meta: { key: this._key },
      instance: this,
    }),
    pause: () => ({
      type: types.pause,
      meta: { key: this._key },
      instance: this,
    }),
    jump: index => ({
      type: types.jump,
      meta: { key: this._key },
      payload: { index },
      instance: this,
    }),
    tick: index => ({
      type: types.tick,
      meta: { key: this._key },
      payload: { index },
      instance: this,
    }),
  };

  selector = state => {
    return get(state, ["timelines", this._key], null);
  };
}

const registerTimeline = key => {
  if (Instances.has(key)) {
    return Instances.get(key);
  } else {
    const newInstance = new Timeline(key);
    Instances.set(key, newInstance);
    return newInstance;
  }
};

export { default as timelineReducer } from "./reducers";
export { default as timelineSaga } from "./saga";

export default registerTimeline;
