import { createApiSelector } from "@36node/redux-api";
import { warningSchema } from "../../schemas";
import makeApiWithDepartmentSelector from "../api-with-department";

export default function makeHistorySelectors(
  apiKey,
  xlsxKey,
  schema = [warningSchema]
) {
  const listRecords = makeApiWithDepartmentSelector(
    createApiSelector(apiKey, schema)
  );
  const listRecordsForExport = makeApiWithDepartmentSelector(
    createApiSelector(xlsxKey, schema)
  );

  return {
    listRecords,
    listRecordsForExport,
  };
}
