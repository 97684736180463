/**
 * 实时 logs saga
 */

import { eventChannel } from "redux-saga";
import lookup from "socket.io-client";
import { LOG_WS } from "../../config";
import {
  take,
  put,
  call,
  all,
  takeLatest,
  select,
  fork,
} from "redux-saga/effects";
import * as cs from "../../constants";
import makeWSSelector from "./selectors";
import { WSType, isEmit } from "./actions";
import ws from "./index";

const url = new URL(LOG_WS);
const socket = lookup(url.origin, {
  path: url.pathname,
  autoConnect: false,
});

function socketIO() {
  return {
    connection: eventChannel(emitter => {
      // socket 已连接
      socket.on("connect", () => {
        emitter({
          server: {
            state: "CONNECTED",
          },
        });
      });
      // socket 断开连接
      socket.on("disconnect", () => {
        emitter({
          server: {
            state: "DISCONNECTED",
          },
        });
      });

      return () => {};
    }),
    log: eventChannel(emitter => {
      // socket 接受日志
      socket.on("log", log => {
        emitter({
          log,
        });
      });

      return () => {};
    }),
  };
}

/**
 * 连接socket
 */
function socketOpen() {
  socket.open();
}

/**
 * 关闭socket
 */
function socketClose() {
  socket.close();
}

function socketEmit(cmd, params) {
  return new Promise((resolve, reject) => {
    socket.emit(cmd, params, ret => {
      const { success, error, client } = ret;
      if (success) resolve(client);
      else reject(error);
    });
  });
}

function* logServerKeywordChange(action) {
  const { payload = {} } = action;
  const { keyword } = payload;
  yield put({
    type: WSType.success(cs.LOG_SERVER_KEYWORD_CHANGE),
    payload: { keyword },
  });
}

// 抽象需要调用 socketEmit 的 action
function* watchEmit(action) {
  while (true) {
    try {
      action = yield take(isEmit);
      const {
        type,
        pre = () => {},
        post = () => {},
        payload = {},
        meta: { event },
      } = action;
      yield call(pre);
      const client = yield call(socketEmit, event, payload);
      yield put({
        type: WSType.success(type),
        payload: { client },
      });
      yield call(post, client);
    } catch (error) {
      console.error(error);
    }
  }
}

/**
 * log 服务管理的 saga
 */
export function* logServerManagerSaga() {
  yield all([
    watchEmit(),
    takeLatest(WSType.request(cs.LOG_SERVER_SOCKET_OPEN), socketOpen),
    takeLatest(WSType.request(cs.LOG_SERVER_SOCKET_CLOSE), socketClose),
    takeLatest(
      WSType.request(cs.LOG_SERVER_KEYWORD_CHANGE),
      logServerKeywordChange
    ),
  ]);
}

/**
 * log 服务 socket 连接的 saga
 */
export function* logServerConnectionSaga() {
  const chan = yield call(socketIO);
  try {
    while (true) {
      const { server } = yield take(chan.connection);

      if (server) {
        yield put({
          type: WSType.success(cs.LOG_SERVER_CHANGE),
          payload: { server },
        });
        if (server.state === "CONNECTED") {
          yield put(ws.logServerManager.login.action());
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * log 服务 接受 log 的 saga
 */
export function* logServerReceiveSaga() {
  const chan = yield call(socketIO);

  try {
    while (true) {
      const { log } = yield take(chan.log);

      if (log) {
        // 有接受log
        const keyword = yield select(makeWSSelector(["keyword"]));
        let accept = true;
        if (keyword && keyword.trim() && log.log) {
          accept = log.log.indexOf(keyword) !== -1;
        }

        if (accept) {
          const logs = yield select(makeWSSelector(["logs"]));
          yield put({
            type: WSType.success(cs.LOG_RECEIVED),
            payload: { logs: logs.concat([log]) },
          });
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* rootSaga() {
  yield all([
    fork(logServerManagerSaga),
    fork(logServerConnectionSaga),
    fork(logServerReceiveSaga),
  ]);
}
