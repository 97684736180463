import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import { intersection, without } from "lodash";

import Layout from "../../components/layout";
import SubMenuTabs from "../../components/sub-menu-tabs";
import Alert from "./alert";
import Charging from "./charging";
import ChargeAlert from "./charge-alert";
import withPermission from "../with-permission";
import Permission from "../../config/permission";
import { Roles } from "../../constants";

const { Content, Header } = Layout;

/**
 * Main Container
 */
const Full = styled.div`
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
`;

const AllRoles = without(
  Object.values(Roles),
  Roles.PRODUCER_MONITOR,
  Roles.OPERATION_MONITOR,
  Roles.USERMANAGER,
  Roles.TERMINAL_OP
);

const SubPermission = {
  alert: without(AllRoles, Roles.PILES_MONITOR),
  charging: AllRoles,
  chargeAlert: AllRoles,
};

const items = [
  {
    key: "alert",
    pathname: "/analysis/alert",
    title: "报警分析",
    permission: SubPermission.alert,
  },
  {
    key: "charging",
    pathname: "/analysis/charging",
    title: "充电习惯分析",
    permission: SubPermission.charging,
  },
  {
    key: "chargeAlert",
    pathname: "/analysis/charge-alert",
    title: "充电桩报警分析",
    permission: SubPermission.chargeAlert,
  },
];

/**
 * 根据用户角色过滤nav item
 */
const filterItemByRole = (navItems = [], roles) => {
  // 过滤
  return navItems.filter(
    item => intersection(item.permission, roles).length > 0
  );
};

@withPermission({
  permission: Permission.analysis,
})
@connect((state, props) => {
  return {
    tabItems: filterItemByRole(items, props.roles),
  };
})
export default class Analysis extends PureComponent {
  handleChange = key => {
    const { tabItems, history } = this.props;
    const item = tabItems.find(it => it.key === key) || tabItems[0];
    history.push(item.pathname);
  };

  getActiveKey = () => {
    const { location } = this.props;

    const paths = location.pathname.split("/").slice(0, 3);
    return paths.join("/");
  };
  render() {
    const { tabItems } = this.props;

    const item =
      tabItems.find(i => i.pathname === this.getActiveKey()) || tabItems[0];

    return (
      <Container>
        <Header
          style={{ height: "max-content", padding: 0, background: "white" }}
        >
          <SubMenuTabs
            tabs={tabItems}
            activeKey={item.key}
            onChange={this.handleChange}
            style={{
              zIndex: 2,
              height: "100%",
            }}
          />
        </Header>
        <Content style={{ height: "calc(100% - 44px)" }}>
          <Full>
            <Switch>
              <Route path="/analysis/alert" component={Alert} />
              <Route path="/analysis/charging" component={Charging} />
              <Route path="/analysis/charge-alert" component={ChargeAlert} />

              <Redirect exact from="/analysis" to={tabItems[0].pathname} />
            </Switch>
          </Full>
        </Content>
      </Container>
    );
  }
}
