/**
 * 故障率分布
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import ReactEcharts from "echarts-for-react";
import styled from "styled-components";
import moment from "moment";

const Box = styled(Row)``;

export default class AlertChartFaultRate extends PureComponent {
  get colors() {
    return ["#1890FF", "#13C2C2", "#8543E0", "#FACC14", "#F04864"];
  }

  getOption = (data = [], startAt, endAt) => {
    const start = moment(startAt).startOf("month");
    const end = moment(endAt);
    const xAxisData = [];
    while (start.isBefore(end)) {
      xAxisData.push(start.format("YYYY-M"));
      start.add(1, "month");
    }

    const series = data.map(d => {
      return {
        radius: "90%",
        data: xAxisData.map(x =>
          d.data[x] ? (d.data[x].faultCount / d.data[x].mileage) * 100 : 0
        ),
        name: d.name,
        type: "line",
      };
    });

    return {
      legend: {
        x: "right",
        show: true,
      },
      grid: {
        top: "10%",
        left: "10%",
        right: "10%",
        bottom: "10%",
      },
      tooltip: {
        trigger: "axis",
        formatter: function(data) {
          return data
            .map(item => `${item.seriesName}: ${item.data.toFixed(3)}%`)
            .join("<br/>");
        },
      },
      color: this.colors,
      xAxis: {
        type: "category",
        data: xAxisData,
        axisLine: {
          lineStyle: {
            color: "#BFBFBF",
          },
        },
      },
      yAxis: {
        type: "value",
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          formatter: function(val) {
            return Number(val).toFixed(2) + "%";
          },
        },
        splitLine: {
          lineStyle: {
            color: "#cccccc",
            type: "dotted",
          },
        },
      },
      series,
    };
  };

  render() {
    const { data = [], startAt, endAt } = this.props;
    const option = this.getOption(data, startAt, endAt);

    return (
      <Box>
        <Col span={24}>
          <ReactEcharts option={option} />
        </Col>
      </Box>
    );
  }
}
