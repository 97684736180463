import React, { PureComponent } from "react";
import { Badge } from "antd";
import styled from "styled-components";

import createWarningTable from "../../history/warnings-table";
import createFilterForm from "../../history/warnings-filter-form";
import { ExceptionTypes, AlertStatusI18N } from "../../../constants";
import { ymdhms, getPathname } from "../../../lib";
import TerminalActions from "../../../actions/terminal";
import TerminalSelectors from "../../../selectors/terminal";
import VehicleLink from "../../../components/vehicle-link";

const defaultQuery = {
  category: "WARNING",
  type: "TBOX_WARNING",
  _sort: "-updateAt",
  count_gte: 10,
  status: "BROKEN",
};

export class Tyre extends PureComponent {
  render() {
    return (
      <Container>
        <FilterFrom />
        <WarningsTable defaultQuery={defaultQuery} />
      </Container>
    );
  }
}

const FilterFrom = createFilterForm({
  formName: "terminal-warning-filter-form",
  warningName: "终端异常",
  warningType: ExceptionTypes.TBOX_EXCEPTION,
  actions: TerminalActions.terminalWarningActions,
  selectors: TerminalSelectors.terminalWarningSelectors,
});

const TableColumns = [
  {
    title: "部门",
    key: "department",
    dataIndex: "ns",
    render: val => getPathname(val),
    exportFormat: val => getPathname(val),
  },
  { title: "VIN码", key: "vin", dataIndex: "vin" },
  {
    title: "自编号",
    key: "vehicleNo",
    dataIndex: "device.no",
    render: (val, record) => <VehicleLink title={val} vin={record.vin} />,
  },
  { title: "车牌号", key: "plate", dataIndex: "plate" },
  { title: "线路", key: "line", dataIndex: "line" },
  { title: "厂商", key: "manufacturer", dataIndex: "device.manufacturer" },
  { title: "车型", key: "model", dataIndex: "device.model" },
  {
    title: "异常名称",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "异常内容",
    key: "description",
    dataIndex: "description",
  },
  {
    title: "开始时间",
    key: "brokenAt",
    dataIndex: "brokenAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  {
    title: "次数",
    key: "count",
    dataIndex: "count",
    sorter: true,
  },
  {
    title: "最近报警时间",
    key: "lastWarnAt",
    dataIndex: "lastWarnAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  {
    title: "故障状态",
    key: "status",
    dataIndex: "status",
    render: val => (
      <Badge
        status={val === "RECOVER" ? "success" : "warning"}
        text={AlertStatusI18N[val]}
      />
    ),
    exportFormat: val => AlertStatusI18N[val],
  },
];

const WarningsTable = createWarningTable(
  "终端异常",
  ExceptionTypes.TBOX_EXCEPTION,
  TableColumns,
  TerminalActions.terminalWarningActions,
  TerminalSelectors.terminalWarningSelectors
);

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 108px);
`;

export default Tyre;
