import { isEmpty, pickBy } from "lodash";
import * as qs from "qs";

import { TOKEN } from "../constants";

export const getToken = () => sessionStorage.getItem(TOKEN);

export function injectToken(config) {
  const token = getToken();
  if (token) {
    const headers = config.headers || {};
    headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
}

export function transferResponse(response) {
  const body = response.data;
  const headers = response.headers;
  return { body, headers };
}

function filter(value) {
  if (typeof value === "string" && value.trim() === "") return false;
  if (typeof value === "object" && isEmpty(value)) return false;
  return true;
}

export function paramsSerializer(params) {
  return qs.stringify(pickBy(params, filter), {
    indices: false,
    skipNulls: true,
  });
}
