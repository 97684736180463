import React, { PureComponent } from "react";
import { Select } from "antd";

export class LineSelect extends PureComponent {
  render() {
    const { lines, ...rest } = this.props;
    return (
      <Select placeholder="请选择" {...rest}>
        {this.props.lines.map(line => {
          return (
            <Select.Option value={line.id} key={line.id}>
              {line.name}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}

export default LineSelect;
