import { Badge } from "antd";
import React, { PureComponent } from "react";
import styled from "styled-components";

import { AlertStatusI18N, ExceptionTypes, HISTORY_NS } from "../../constants";
import { ymdhms, getPathname } from "../../lib";
import createWarningTable from "./warnings-table";
import insulationHistoryActions from "../../actions/history/insulation";
import insulationHistorySelectors from "../../selectors/history/insulation";
import createFilterForm from "./warnings-filter-form";
import VehicleLink from "../../components/vehicle-link";

const defaultQuery = {
  category: "WARNING",
  type: "RESISTANCE_WARNING",
  _sort: "-updateAt",
};

export class Insulation extends PureComponent {
  render() {
    return (
      <Container>
        <FilterFrom />
        <WarningsTable defaultQuery={defaultQuery} />
      </Container>
    );
  }
}

const FilterFrom = createFilterForm({
  formName: HISTORY_NS.INSULATION.FILTER_FORM,
  warningName: "绝缘",
  warningType: ExceptionTypes.INSULATION_EXCEPTION,
  actions: insulationHistoryActions,
  selectors: insulationHistorySelectors,
});

const TableColumns = [
  {
    title: "部门",
    key: "department",
    dataIndex: "ns",
    render: val => getPathname(val),
    exportFormat: val => getPathname(val),
  },
  { title: "VIN码", key: "vin", dataIndex: "vin" },
  {
    title: "自编号",
    key: "vehicleNo",
    dataIndex: "device.no",
    render: (val, record) => <VehicleLink title={val} vin={record.vin} />,
  },
  { title: "车牌号", key: "plate", dataIndex: "plate" },
  { title: "线路", key: "line", dataIndex: "line" },
  { title: "厂商", key: "manufacturer", dataIndex: "device.manufacturer" },
  { title: "车型", key: "model", dataIndex: "device.model" },
  {
    title: "异常名称",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "异常内容",
    key: "description",
    dataIndex: "description",
  },
  {
    title: "开始时间",
    key: "brokenAt",
    dataIndex: "brokenAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  {
    title: "次数",
    key: "count",
    dataIndex: "count",
    sorter: true,
  },
  {
    title: "最近报警时间",
    key: "lastWarnAt",
    dataIndex: "lastWarnAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  // {
  //   title: "恢复时间",
  //   key: "recoverAt",
  //   dataIndex: "recoverAt",
  //   render: ymdhms,
  //   exportFormat: ymdhms,
  //   sorter: true,
  // },
  {
    title: "故障状态",
    key: "status",
    dataIndex: "status",
    render: val => (
      <Badge
        status={val === "RECOVER" ? "success" : "warning"}
        text={AlertStatusI18N[val]}
      />
    ),
    exportFormat: val => AlertStatusI18N[val],
  },
];

const WarningsTable = createWarningTable(
  "绝缘异常",
  ExceptionTypes.INSULATION_EXCEPTION,
  TableColumns,
  insulationHistoryActions,
  insulationHistorySelectors
);
const Container = styled.div`
  width: 100%;
  height: calc(100vh - 108px);
`;

export default Insulation;
