import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";
import zhCN from "antd/es/locale-provider/zh_CN";
import { ConfigProvider } from "antd";

import "./index.css";
import App from "./app";
import rootSaga from "./sagas";
import reducer from "./reducers";

import moment from "moment";
import "moment/locale/zh-cn";
import nsFilterMiddleware from "./middleware/ns-filter";
// import dataFilterMiddleware from "./middleware/data-filter";
moment.locale("zh-cn");

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(
      nsFilterMiddleware,
      //  dataFilterMiddleware,
      sagaMiddleware
    )
  )
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);
