import styled from "styled-components";
import React, { PureComponent } from "react";
import { Form, Row, Col, Icon, Button } from "antd";

export const StyledFilterForm = styled(Form)`
  margin-bottom: 24px;
  background-color: #fff;
  padding: 24px;
  border-radius: 2px;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .pack-filter {
    display: flex;
    justify-content: center;
    .ant-btn {
      border: 0;
    }
  }
`;

export default class FoldFilterForm extends PureComponent {
  state = {
    packFilters: false,
  };

  onPackFilters = () => {
    const { packFilters } = this.state;
    this.setState({ packFilters: !packFilters });
  };

  renderPackButton = () => {
    const { packFilters } = this.state;

    const content = packFilters ? (
      <span>
        收起筛选条件
        <Icon type="up" />
      </span>
    ) : (
      <span>
        展开筛选条件 <Icon type="down" />
      </span>
    );

    return (
      <div className="pack-filter">
        <Button onClick={this.onPackFilters} type="primary" ghost>
          {content}
        </Button>
      </div>
    );
  };

  render() {
    const {
      topFields = [],
      bottomFields = [],
      tailComponent,
      style = {},
    } = this.props;
    const { packFilters } = this.state;

    return (
      <StyledFilterForm onSubmit={this.props.handleSubmit} style={style}>
        <Row gutter={48} type="flex">
          {topFields}
          <Col span={4} offset={2}>
            {this.renderPackButton()}
          </Col>
        </Row>
        <div style={{ display: packFilters ? "block" : "none" }}>
          {bottomFields}
        </div>
        {tailComponent}
      </StyledFilterForm>
    );
  }
}
