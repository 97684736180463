/**
 * 工单列表
 */
import React, { PureComponent } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Table,
} from "antd";
import { connect } from "react-redux";
import createForm from "@36node/redux-form-antd";
import { NavLink } from "react-router-dom";
import moment from "moment";

import NsSelect from "../common/ns-select";
import TicketSelectors from "../../selectors/tickets";
import { makeMekongApiToTableSelector } from "../../selectors/api-to-table";
import TicketActions from "../../actions/tickets";
import { ymdhms, getPathname, tableStateToMekongQuery } from "../../lib";
import { AlertLevel } from "../../constants";
import { MAX_EXPORT_TICKETS_LIMIT } from "../../config";

const RangePicker = DatePicker.RangePicker;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const formTailLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 16, offset: 8 },
};

const getHandleField = (stage, field, formatter) => (_, record) => {
  const handle = record.handleResults.find(h => h.stage === stage);
  const result = handle ? handle[field] : "";
  return formatter ? formatter(result) : result;
};

const columns = [
  { title: "工单编号", dataIndex: "id", key: "id", onlyExport: true },
  { title: "车辆自编号", dataIndex: "devices.0.no", key: "vehicleNo" },
  {
    title: "部门",
    dataIndex: "ns",
    key: "ns",
    render: val => getPathname(val),
    exportFormat: val => getPathname(val),
  },
  {
    title: "报警等级",
    dataIndex: "relatedFaults.0.level",
    key: "alertLevel",
    render: val => AlertLevel[val],
    exportFormat: val => AlertLevel[val],
  },
  {
    title: "报警类型",
    dataIndex: "relatedFaults.0.type",
    key: "alertType",
    render: val => val || "--",
    exportFormat: val => val || "--",
  },
  {
    title: "报警码",
    dataIndex: "relatedFaults.0.code",
    key: "alertCode",
    render: val => val || "--",
    exportFormat: val => val || "--",
  },
  {
    title: "报警名称",
    dataIndex: "relatedFaults.0.name",
    key: "alertName",
    render: val => val || "--",
    exportFormat: val => val || "--",
  },
  {
    title: "创建账号",
    dataIndex: "submitBy",
    key: "submitBy",
  },
  {
    title: "创建人",
    dataIndex: "submitBy",
    exportFormat: getHandleField("待维修", "by"),
    onlyExport: true,
  },
  {
    title: "报警时间",
    dataIndex: "submitAt",
    key: "submitAt",
    render: ymdhms,
    exportFormat: ymdhms,
  },
  {
    title: "车辆状态",
    dataIndex: "vehicleState",
    exportFormat: getHandleField("待维修", "desc"),
    onlyExport: true,
  },
  {
    title: "故障原因",
    dataIndex: "faultReason",
    exportFormat: getHandleField("维修中", "desc"),
    onlyExport: true,
  },
  {
    title: "维修人",
    dataIndex: "repairer",
    exportFormat: getHandleField("维修中", "by"),
    onlyExport: true,
  },
  {
    title: "维修时间",
    dataIndex: "repairedAt",
    exportFormat: getHandleField("维修中", "at", ymdhms),
    onlyExport: true,
  },
  {
    title: "维修内容",
    dataIndex: "repairedContent",
    exportFormat: getHandleField("已完成", "desc"),
    onlyExport: true,
  },
  {
    title: "维修结果",
    dataIndex: "repairResult",
    exportFormat: getHandleField("已完成", "title"),
    onlyExport: true,
  },
  {
    title: "检验人",
    exportFormat: getHandleField("已完成", "by"),
    dataIndex: "surveyor",
    onlyExport: true,
  },
  {
    title: "完工时间",
    dataIndex: "finishedAt",
    exportFormat: getHandleField("已完成", "at", ymdhms),
    onlyExport: true,
  },
  {
    title: "更新时间",
    dataIndex: "updateAt",
    key: "updateAt",
    render: ymdhms,
    exportFormat: ymdhms,
  },
  {
    title: "工单状态",
    dataIndex: "stage",
    render: val => val || "--",
  },
  {
    title: "操作",
    dataIndex: "id",
    key: "operator",
    render: val => {
      return <NavLink to={`/ticket/${val}/detail`}>查看详情</NavLink>;
    },
    disableExport: true,
  },
];

const defaultQuery = {
  _limit: 10,
  _offset: 0,
  _sort: "-createAt",
};

@createForm("TICKET_FILTER_FORM")
@connect((state, props) => {
  const st = makeMekongApiToTableSelector(TicketSelectors.listTickets)(state);
  return st;
})
export default class List extends PureComponent {
  state = {
    showAll: false,
  };

  status = () => {
    const { showAll } = this.state;
    return showAll ? undefined : "OPEN";
  };

  componentDidMount() {
    this.listTickets();
  }

  listTickets = query => {
    this.props.dispatch(
      TicketActions.listTickets.request({
        ...defaultQuery,
        ...query,
        status: this.status(),
      })
    );
  };

  handleExport = () => {
    const fileName = `工单-${moment().format("YYYY-MM-DD")}`;
    const { query } = this.props;

    this.props.dispatch(
      TicketActions.listTicketsXlsx.export({
        columns,
        fileName,
        fileType: "xlsx",
        params: {
          ...query,
          _limit: MAX_EXPORT_TICKETS_LIMIT,
          _offset: 0,
        },
      })
    );
  };

  handleShowAll = e => {
    const { query } = this.props;
    this.setState({ showAll: e.target.checked });
    setTimeout(() => this.listTickets({ ...query, _offset: 0 }), 200);
  };

  handleSearch = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { submitAt, vehicleNo, ...rest } = values;
        const query = {
          offset: 0,
          ...rest,
          createAt_gte: submitAt && submitAt[0] && submitAt[0].toISOString(),
          createAt_lte: submitAt && submitAt[1] && submitAt[1].toISOString(),
          "devices.no": vehicleNo,
        };
        this.listTickets(query);
      }
    });
  };

  handleRest = () => {
    this.props.reset();
    this.listTickets({ _offset: 0 });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { query } = this.props;
    const tableQuery = tableStateToMekongQuery(pagination, filters, sorter);
    this.listTickets({ ...query, ...tableQuery });
  };

  renderExtra = () => {
    return (
      <ExtraArea>
        <Checkbox onChange={this.handleShowAll}>显示已完成工单</Checkbox>
        <Button
          type="primary"
          style={{ marginLeft: 12 }}
          onClick={this.handleExport}
        >
          导出
        </Button>
      </ExtraArea>
    );
  };

  render() {
    const {
      loading,
      getFieldDecorator,
      docs = [],
      total,
      pagination,
    } = this.props;

    return (
      <Container>
        <div className="filter">
          <Form onSubmit={this.handleSearch}>
            <Row gutter={48} type="flex">
              <Col span={8}>
                <Form.Item label="部门" {...formItemLayout}>
                  {getFieldDecorator("ns_like", {})(<NsSelect allowClear />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="车辆VIN码" {...formItemLayout}>
                  {getFieldDecorator("vin", {})(
                    <Input allowClear placeholder="请输入车辆VIN码" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="车辆自编号" {...formItemLayout}>
                  {getFieldDecorator("vehicleNo", {})(
                    <Input allowClear placeholder="请输入车辆自编号" />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={48} type="flex">
              <Col span={8}>
                <Form.Item label="工单编号" {...formItemLayout}>
                  {getFieldDecorator("id", {})(
                    <Input allowClear placeholder="请输入工单编号" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="报警时间" {...formItemLayout}>
                  {getFieldDecorator("submitAt", {})(
                    <RangePicker
                      style={{ width: "100%" }}
                      placeholder={["起始日期", "结束日期"]}
                      showTime
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...formTailLayout}>
                  <Button onClick={this.handleRest}>重置</Button>
                  <Button
                    style={{ marginLeft: 24 }}
                    type="primary"
                    htmlType="submit"
                  >
                    筛选
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>

        <Card
          title={
            <div>
              <span>工单列表</span>
              <span style={{ marginLeft: 24, fontSize: 12, fontWeight: 400 }}>
                共{total}条
              </span>
            </div>
          }
          extra={this.renderExtra()}
        >
          <Table
            loading={loading}
            rowKey="id"
            dataSource={docs}
            columns={columns.filter(c => !c.onlyExport)}
            onChange={this.handleTableChange}
            pagination={pagination}
            scroll={{ x: "max-content" }}
          />
        </Card>
      </Container>
    );
  }
}

const Container = styled.div`
  height: 100%;
  padding-bottom: 12px;

  .filter {
    background: #fff;
    padding: 24px 24px 0px 24px;
    margin-bottom: 12px;
  }
`;

const ExtraArea = styled.div`
  display: flex;
  align-items: center;
`;
