import React, { PureComponent } from "react";
import { Select, TreeSelect } from "antd";
import { connect } from "react-redux";
import { globalSelectors } from "../../selectors";

const { Option } = Select;

@connect(state => {
  const nsTree = globalSelectors.namespacesTreeNoRoot(state) || [];

  return {
    treeData: nsTree,
  };
})
export default class NsSelect extends PureComponent {
  render() {
    const { treeData = [], onlySub, ...rest } = this.props;

    if (onlySub) {
      return (
        <Select {...rest} placeholder="请选择">
          {treeData.map(n => (
            <Option value={n.id} key={n.id}>
              {n.name}
            </Option>
          ))}
        </Select>
      );
    }

    return <TreeSelect {...rest} placeholder="请选择" treeData={treeData} />;
  }
}
