import { HISTORY_NS } from "../../constants";
import insulationHistorySelectors from "../../selectors/history/insulation";
import makeHistoryActions from "./base";

const insulationHistoryActions = makeHistoryActions(
  HISTORY_NS.INSULATION.LIST_WARNINGS,
  HISTORY_NS.INSULATION.WARNINGS_XLSX,
  insulationHistorySelectors
);

export default insulationHistoryActions;
