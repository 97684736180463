import React, { PureComponent } from "react";
import { Form, Row, Col, DatePicker, Input, Button, Select } from "antd";
import createForm from "@36node/redux-form-antd";
import styled from "styled-components";
import { connect } from "react-redux";
import { get } from "lodash";

import createWarningTable from "./warnings-table";
import {
  getPathname,
  ymdhms,
  mergeQuery,
  chargeRecordStartAtDefaultRange,
  formatSec,
} from "../../lib";
import chargeRecordsActions from "../../actions/history/charge-records";
import chargeRecordsHistorySelectors from "../../selectors/history/charge-records";
import { HISTORY_NS, ChargePeriodI18N } from "../../constants";
import { globalSelectors } from "../../selectors";
import NsSelect from "../common/ns-select";
import LineSelect from "../../components/line-select";

const { Option } = Select;

/**
 * styled
 */
const Container = styled.div`
  width: 100%;
  height: calc(100vh - 108px);
`;

/**
 * constants
 */
const TableColumns = [
  {
    title: "部门",
    key: "department",
    dataIndex: "ns",
    render: val => getPathname(val),
    exportFormat: val => getPathname(val),
  },
  {
    title: "线路",
    key: "line",
    dataIndex: "line.name",
  },
  {
    title: "车型",
    key: "model",
    dataIndex: "model",
  },
  {
    title: "车辆自编号",
    key: "no",
    dataIndex: "no",
  },
  {
    title: "充电开始时间",
    key: "startAt",
    dataIndex: "startAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  {
    title: "充电结束时间",
    key: "endAt",
    dataIndex: "endAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  {
    title: "充电时长",
    key: "duration",
    dataIndex: "duration",
    sorter: true,
    render: formatSec,
    exportFormat: formatSec,
  },
  {
    title: "开始SoC",
    key: "startSoc",
    dataIndex: "startSoc",
    render: val => Math.floor(val * 100) + "%",
    exportFormat: val => Math.floor(val * 100) + "%",
  },
  {
    title: "结束SoC",
    key: "endSoc",
    dataIndex: "endSoc",
    render: val => Math.floor(val * 100) + "%",
    exportFormat: val => Math.floor(val * 100) + "%",
  },
  {
    title: "充电时段",
    key: "period",
    dataIndex: "period",
    render: val => ChargePeriodI18N[val],
    exportFormat: val => ChargePeriodI18N[val],
  },
];

/**
 * components
 */
const ChargeRecordTable = createWarningTable(
  "充电记录",
  "CHARGE_RECORDS",
  TableColumns,
  chargeRecordsActions,
  chargeRecordsHistorySelectors
);

@createForm(HISTORY_NS.CHARGE_RECORDS.FILTER_FORM)
@connect((state, props) => {
  const curNs = get(props, "fields.ns.value");
  const lines = globalSelectors.lineByNs(curNs)(state);

  const query = get(
    chargeRecordsHistorySelectors.listRecords(state),
    "request.query",
    {}
  );

  return {
    producers: globalSelectors.listProducers(state).result || [],
    lines,
    query,
  };
})
class FilterForm extends PureComponent {
  handleSumbit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const filter = { ...values };

      if (filter.date) {
        filter.startAt_gte = filter.date[0].toISOString();
        filter.startAt_lte = filter.date[1].toISOString();

        delete filter.date;
      }

      if (filter.ns) {
        filter.ns_like = filter.ns;
      }

      const newQuery = {
        offset: 0,
        filter,
      };

      this.listRecords(newQuery);
    });
  };

  listRecords = newQuery => {
    const { query } = this.props;
    const finialQuery = mergeQuery(query, newQuery);
    if (finialQuery && finialQuery.filter) finialQuery.filter.isFinished = true;

    this.props.dispatch(
      chargeRecordsActions.listRecords.request({
        query: finialQuery,
      })
    );
  };

  handleReset = () => {
    this.props.reset();

    const defaultRange = chargeRecordStartAtDefaultRange();

    this.props.changeField("date", {
      name: "date",
      value: defaultRange,
    });

    const initQuery = {
      limit: 10,
      offset: 0,
      filter: {
        startAt_gte: defaultRange[0].toISOString(),
        startAt_lte: defaultRange[1].toISOString(),
        isFinished: true,
      },
    };

    this.props.dispatch(
      chargeRecordsActions.listRecords.request({
        query: initQuery,
      })
    );
  };

  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const { getFieldDecorator } = this.props;

    const defaultRange = chargeRecordStartAtDefaultRange();

    return (
      <Form
        style={{ marginTop: 12, paddingTop: 12, background: "white" }}
        {...formItemLayout}
      >
        <Row gutter={48} type="flex">
          <Col span={7}>
            <Form.Item label="部门">
              {getFieldDecorator("ns", {})(<NsSelect allowClear />)}
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item label="线路">
              {getFieldDecorator("line", {})(
                <LineSelect allowClear lines={this.props.lines} />
              )}
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item label="车型">
              {getFieldDecorator("model", {})(
                <Input allowClear placeholder="请输入车型简称" />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48} type="flex">
          <Col span={7}>
            <Form.Item label="自编号">
              {getFieldDecorator("no", {})(
                <Input allowClear placeholder="请输入车辆自编号" />
              )}
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item label="充电日期">
              {getFieldDecorator("date", {
                initialValue: [defaultRange[0], defaultRange[1]],
              })(
                <DatePicker.RangePicker
                  allowClear
                  placeholder={["请选择", "请选择"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="充电时段">
              {getFieldDecorator("period", {})(
                <Select allowClear placeholder="请选择">
                  {Object.keys(ChargePeriodI18N).map(key => (
                    <Option value={key} key={key}>
                      {ChargePeriodI18N[key]}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={48} type="flex" style={{ paddingBottom: 24 }}>
          <Col span={7} offset={14} style={{ textAlign: "right" }}>
            <Button onClick={this.handleReset}>重置</Button>

            <Button
              style={{ marginLeft: 24 }}
              type="primary"
              onClick={this.handleSumbit}
            >
              筛选
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default class ChargeRecords extends PureComponent {
  render() {
    return (
      <Container>
        <FilterForm />
        <ChargeRecordTable />
      </Container>
    );
  }
}
