/**
 * 报警声音
 */

import { take, select } from "redux-saga/effects";
import { NS } from "../constants";
import { globalSelectors } from "../selectors";
import { successOf } from "@36node/redux-api";

export default function* watchAlertAudio() {
  const audio = new Audio("/audios/alarm.wav");
  audio.loop = true;
  while (true) {
    yield take(successOf(NS.GLOBAL.GET_ALERT_SUMMARY));

    const resp = yield select(globalSelectors.getAlertSummary);
    const result = resp.result || [];
    const level3 = result.find(r => r.level === 3) || {};
    const count = level3.count || 0;
    // 有三级报警，则打开声音
    if (count) {
      audio.play().catch(err => console.log(err));
    } else {
      audio.pause();
    }
  }
}
