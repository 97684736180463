import { createSelector } from "reselect";
import { globalSelectors, monitorSelectors } from "./index";
import { isEmpty, get, orderBy, maxBy } from "lodash";
import pinyin from "js-pinyin";
import listToTree from "../lib/list-to-tree";
import { AlertLevelColor, VehicleRunStateColor } from "../constants";

const makeSelector = vehiclesSelector =>
  createSelector(
    [vehiclesSelector, globalSelectors.namespaces, monitorSelectors.listAlerts],
    (vehicleResp, namespaces, listAlerts) => {
      const alerts = listAlerts.result || [];
      const vehicles = vehicleResp.result || [];

      if (isEmpty(vehicles) || isEmpty(namespaces)) return { children: [] };

      const nodes = [];

      // namespaces to nodes
      orderBy(namespaces, "key")
        .filter(n => Boolean(n))
        .forEach(n => {
          nodes.push({
            parent: get(n, "parent.id"),
            title: n.name,
            key: n.id,
            value: n.id,
          });
        });

      const line_nodes = [];
      vehicles
        .filter(v => v)
        .filter(v => Boolean(v.ns))
        .filter(v => Boolean(v.line))
        .forEach(v => {
          if (!line_nodes.find(n => n.key === v.line)) {
            line_nodes.push({
              parent: get(v, "ns.id"),
              title: v.line,
              key: v.line,
              value: v.line,
            });
          }
        });

      orderBy(line_nodes, l => pinyin.getCamelChars(l.title)).forEach(l => {
        nodes.push(l);
      });

      // vehicles to node
      orderBy(vehicles, "no")
        .filter(v => Boolean(v))
        .filter(v => Boolean(v.ns))
        .forEach(v => {
          const vehicleAlerts = alerts.filter(a => get(a, "vin") === v.vin);
          const maxLevelAlert = maxBy(vehicleAlerts, "level");
          const status = v.status || "UNKNOWN";

          let icon = `iconicon-${status.toLowerCase()}`;
          let color = VehicleRunStateColor[status];

          let maxLevel = 0;
          if (maxLevelAlert) {
            maxLevel = maxLevelAlert.level;
            icon = "iconicon-anquanjiankong-shishibaojing";
            color = AlertLevelColor[maxLevel];
          }

          const line = get(v, "line");

          const no = get(v, "no");
          if (line && no) {
            nodes.push({
              parent: line,
              title: no,
              key: v.vin,
              value: v.vin,
              color,
              icon,
              status,
            });
          }
        });

      const tree = listToTree(nodes, { idKey: "key" });

      return { ...vehicleResp, result: tree[0] || { children: [] } };
    }
  );

export default makeSelector;
