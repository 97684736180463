import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { get } from "lodash";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input,
  Row,
  Spin,
  Table,
} from "antd";
import createForm from "@36node/redux-form-antd";
import moment from "moment";
import { without } from "lodash";
import Highlighter from "react-highlight-words";

import { makeMekongApiToTableSelector } from "../../selectors/api-to-table";
import FilterDropdown from "../../components/filter-dropdown";
import Layout from "../../components/layout";
import VehicleLink from "../../components/vehicle-link";
import { pileActions } from "../../actions/pile";
import { stationSelectors } from "../../selectors";
import { ymdhms, suffix, getPathname } from "../../lib";

const { Content, Header } = Layout;

const toFixedWithSuffix = unit => val =>
  suffix(unit)(val ? val.toFixed(3) : val);

const title = "充电历史记录列表";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
`;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const formTailLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8, offset: 8 },
};

const StyledHeader = styled(Header)`
  background: white !important;
  padding: 0 24px !important;
`;

const StyledContent = styled(Content)`
  padding: 24px;
`;

const SearchContainer = styled(Card)`
  margin-bottom: 24px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

const defaultQuery = {
  _sort: "plate",
  _expand: ["vehicle", "station"],
  _limit: 3000,
  _offset: 0,
  abnormal: false,
};

const placeholder = val => (val === undefined ? "--" : val);

const isReissue = val => {
  return val ? "补发" : "--";
};
const isReissueFormat = val => {
  return val ? <span style={{ color: "red" }}>"补发"</span> : "--";
};

@createForm("STAION_ORDER_SEARCH_FORM")
class StationOrderPage extends PureComponent {
  state = {
    hideColumns: [],
    from: get(this.props.location, "state.from"),
    searchText: "",
    searchedColumn: "",
  };

  componentWillMount() {
    // 如果已经查询过了，就使用上一次的 day 设置
    const day =
      this.props.form.getFieldValue("day") || moment().subtract(1, "days");
    this.fetchStation();
    this.fetchVehicles();
    this.fetchChargeOrders({ day });
  }

  componentWillUnmount() {
    this.props.dispatch(pileActions.listChargeOrders.clear());
    this.props.dispatch(pileActions.getPileStation.clear());
  }

  fetchStation() {
    this.props.dispatch(
      pileActions.getPileStation.request(
        {
          station: this.props.match.params.stationId,
        },
        {}
      )
    );
  }

  fetchVehicles() {
    this.props.dispatch(
      pileActions.listVehicles.request(
        {
          stations: [this.props.match.params.stationId],
          _limit: 10000,
          _sort: "no",
        },
        {}
      )
    );
  }

  fetchChargeOrders = (query = {}) => {
    const { day, ...rest } = query;
    if (day) {
      rest.chargeStartAt_gte = moment(day)
        .hour(6)
        .startOf("hour")
        .toISOString();
      rest.chargeStartAt_lte = moment(day)
        .add(1, "day")
        .hour(6)
        .startOf("hour")
        .subtract(1, "ms")
        .toISOString();
    }
    this.props.dispatch(
      pileActions.listChargeOrders.request(
        {
          station: this.props.match.params.stationId,
          ...defaultQuery,
          ...rest,
        },
        {}
      )
    );
  };

  handleSumbit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.fetchChargeOrders(values);
      }
    });
  };

  handleRest = () => {
    this.props.reset();
    this.fetchChargeOrders();
  };

  onBack = () => {
    const { history } = this.props;
    if (this.state.from) {
      history.push(this.state.from);
    } else {
      history.go(-1);
    }
  };

  showedColumns() {
    const { hideColumns } = this.state;
    const allValues = this.TableColumns.map(i => i.key);
    return without(allValues, ...hideColumns);
  }

  onColumnsFilterChange = values => {
    const allValues = this.TableColumns.map(i => i.key);
    this.setState({
      hideColumns: without(allValues, ...values),
    });
  };

  handleExport = () => {
    // filename
    const filename = `${title}-${moment().format("YYYY-MM-DD")}`;

    this.props.dispatch(
      pileActions.chargeStationOrdersXlsx.export(
        {
          columns: this.TableColumns,
          fileName: filename,
          fileType: "xlsx",
          params: {
            useCache: true,
          },
        },
        {}
      )
    );
  };

  handleFilter = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleResetFilter = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleFilter(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleFilter(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          搜索
        </Button>
        <Button
          onClick={() => this.handleResetFilter(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          重置
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  TableColumns = [
    {
      title: "车辆自编号",
      dataIndex: "plate",
      key: "plate",
      render: (val, record) => {
        return <VehicleLink title={val} vin={record.vin} />;
      },
      ...this.getColumnSearchProps("plate"),
      sorter: (a, b) => (a.plate > b.plate ? 1 : -1),
      defaultSortOrder: "ascend",
    },
    {
      title: "VIN",
      dataIndex: "vin",
      key: "vin",
      sorter: (a, b) => (a.vin > b.vin ? 1 : -1),
      ...this.getColumnSearchProps("vin"),
    },
    {
      title: "车辆厂家",
      dataIndex: "vehicle.manufacturer",
      key: "vehicle.manufacturer",
    },
    {
      title: "部门",
      dataIndex: "ns",
      key: "department",
      render: val => getPathname(val),
      exportFormat: val => getPathname(val),
    },
    { title: "线路", dataIndex: "line", key: "line" },
    {
      title: "充电站",
      dataIndex: "station.name",
      key: "station.name",
      render: placeholder,
    },
    {
      title: "充电枪自编码",
      dataIndex: "connectorNo",
      key: "connectorNo",
      render: placeholder,
    },
    {
      title: "充电开始时间",
      dataIndex: "chargeStartAt",
      key: "chargeStartAt",
      render: val => ymdhms(val),
      exportFormat: val => ymdhms(val),
      sorter: (a, b) => (a.chargeStartAt > b.chargeStartAt ? 1 : -1),
    },
    {
      title: "充电结束时间",
      dataIndex: "chargeEndAt",
      key: "chargeEndAt",
      render: val => ymdhms(val),
      exportFormat: val => ymdhms(val),
      sorter: (a, b) => (a.chargeEndAt > b.chargeEndAt ? 1 : -1),
    },
    {
      title: "开始 SoC",
      dataIndex: "startSoc",
      key: "startSoc",
      render: suffix("%"),
    },
    {
      title: "结束 SoC",
      dataIndex: "endSoc",
      key: "endSoc",
      render: suffix("%"),
    },
    {
      title: "充电量",
      dataIndex: "elec",
      key: "elec",
      render: toFixedWithSuffix("kWh"),
    },
    {
      title: "是否补发",
      dataIndex: "isReissue",
      key: "isReissue",
      render: isReissueFormat,
      exportFormat: isReissue,
    },
  ];

  renderTopActions = () => {
    return (
      <div className="tool-bar">
        <Dropdown
          overlay={
            <FilterDropdown
              value={this.showedColumns()}
              onChange={this.onColumnsFilterChange}
              items={this.TableColumns.map(c => ({
                key: c.key,
                name: c.title,
              }))}
            />
          }
          trigger={["click"]}
        >
          <Button>
            <Icon type="filter" /> 选择数据项
          </Button>
        </Dropdown>
        <Button
          onClick={this.handleExport}
          type="primary"
          style={{ marginLeft: 10 }}
        >
          <Icon type="export" />
          导出
        </Button>
      </div>
    );
  };

  render() {
    const {
      station,
      stationLoading,
      getFieldDecorator,
      docs = [],
      total = 0,
      totalElec = 0,
      loading,
    } = this.props;

    const { hideColumns } = this.state;
    const showColumns = this.TableColumns.filter(
      c => !hideColumns.includes(c.key)
    );

    const Title = () => (
      <div>
        {title}
        <span style={{ marginLeft: 10, fontSize: 12, fontWeight: 400 }}>
          共有 {docs.length} 辆车，其中充电 {total} 辆车, 充电量 {totalElec} kWh
        </span>
      </div>
    );

    return (
      <Container>
        <StyledHeader>
          <Row type="flex" align="middle">
            <Button icon="left" type="link" onClick={this.onBack}>
              返回
            </Button>
            {station.name || "充电桩监控"}
          </Row>
        </StyledHeader>
        <StyledContent>
          <Spin spinning={stationLoading}>
            <SearchContainer>
              <Form onSubmit={this.handleSumbit}>
                <Row gutter={48} type="flex">
                  <Col span={8}>
                    <Form.Item label="选择日期" {...formItemLayout}>
                      {getFieldDecorator("day", {
                        initialValue: moment().subtract(1, "days"),
                      })(
                        <DatePicker
                          allowClear={false}
                          placeholder="请选择"
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item {...formTailLayout}>
                      <Button onClick={this.handleRest}>重置</Button>
                      <Button
                        style={{ marginLeft: 24 }}
                        type="primary"
                        htmlType="submit"
                      >
                        筛选
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </SearchContainer>
            <Card
              bordered={false}
              title={<Title />}
              extra={this.renderTopActions()}
            >
              <Table
                rowKey="id"
                loading={loading}
                // onChange={this.listChargeOrders}
                dataSource={docs}
                columns={showColumns}
                scroll={{ x: "max-content" }}
              />
            </Card>
          </Spin>
        </StyledContent>
      </Container>
    );
  }
}

export default connect(state => {
  const selector = makeMekongApiToTableSelector(
    stationSelectors.selectStationOrder
  );
  const tableState = selector(state);

  return {
    station: stationSelectors.getPileStation(state).result || {},
    stationLoading: stationSelectors.getPileStation(state).loading,
    ...tableState,
  };
})(StationOrderPage);
