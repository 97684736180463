import WeatherSDK from "@36node/weather-sdk";
import AuthV1SDK from "@36node-stargate/auth-sdk";
// import MekongSdk from "@36node-mekong/sdk-ts";

import { AUTH_BASE, WEATHER_BASE } from "../config";
import { TOKEN } from "../constants";

export { mekong } from "./mekong";

export const old_token =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOltdLCJzdWIiOiJzZXNzaW9uIiwianRpIjoiNjM1YjNjNGVkODBiMTAwMDEyMDM1OGQwIiwiYWN0aXZlIjp0cnVlLCJ1c2VyIjoiNWNkZTg4ODZhNTMwMTcwMDEyYjdjNmQyIiwibnMiOlsiL2J1cyJdLCJyb2xlcyI6W3sibnMiOiIvYnVzL3NoYW5naGFpL2ppdXNoaSIsIm5hbWUiOiJBRE1JTiJ9LHsibnMiOiIvMzZub2RlIiwibmFtZSI6IkFETUlOIn0seyJucyI6Ii9hZHZlbnR1cmVyLXRlc3QiLCJuYW1lIjoiQURNSU4ifSx7Im5zIjoiL2FkdmVudHVyZXIiLCJuYW1lIjoiVEFTS19BRE1JTiJ9LHsibnMiOiIvYWR2ZW50dXJlciIsIm5hbWUiOiJBRE1JTiJ9LHsibnMiOiIvIiwibmFtZSI6IkFETUlOIn0seyJucyI6Ii9idXMvc2hhbmdoYWkvcHVkb25nIiwibmFtZSI6IkFETUlOIn0seyJucyI6Ii9idXMvc2hhbmdoYWkvY2hvbmdtaW5nIiwibmFtZSI6IkFETUlOIn0seyJucyI6Ii9kdXNodWxpIiwibmFtZSI6IkFETUlOIn0seyJucyI6Ii9idXMvc2hhbmdoYWkvcHVkb25nLXN0YWdlIiwibmFtZSI6IkFETUlOIn1dLCJpYXQiOjE2Njc0NjkwMzUsImV4cCI6MTY5OTAwNTAzNX0.gSmFHTnhcvG7SXkCgN-4kxTqfEa1dGsNAUiCkINbErYv7909Hr3BAH6jo5Iwhmfl8fH5POs6ROncfpc1sSyU6sgxhcnd25-sfj-bECGYrkifpwEYK8Xb8CYauxmgStVp0U5LjdsnBX6nFqR9U3biXsgbC2HV_U2cMUUYwJI4SdE";

const token = sessionStorage.getItem(TOKEN) || "";

export const weatherSdk = new WeatherSDK({
  base: WEATHER_BASE,
  token: old_token,
});

export const authV1Sdk = new AuthV1SDK({
  base: AUTH_BASE,
  token,
});
// export const mekongSdk = new MekongSdk({ base: MEKONG_ENDPOINT, token });
