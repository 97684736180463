import { createApiSelector } from "@36node/redux-api";
import { createExportSelector, createImportSelector } from "@36node/redux-xlsx";
import { createSelector } from "reselect";
import { createAssignSelector } from "@36node/redux-ui";

import { NS } from "../constants";
import {
  connectorSchema,
  orderSchema,
  pileSchema,
  stationSchema,
  mekongVehicleSchema,
} from "../schemas";
import { groupBy, round } from "lodash";

export const listPileStations = createApiSelector(
  NS.MONITOR.PILE.LIST_STATIONS,
  [stationSchema]
);

export const listAllPileStations = createApiSelector(
  NS.MONITOR.PILE.LIST_ALL_STATIONS,
  [stationSchema]
);

export const selectStationMap = createSelector(
  listAllPileStations,
  allStationsState => {
    const result = allStationsState.result || [];
    return result.reduce((map, cur) => ({ ...map, [cur.id]: cur }), {});
  }
);

export const aggregateOrdersPerDay = createApiSelector(
  NS.AGGREGATE.ORDER_CHARGER_PER_HOUR
);

export const getPileStation = createApiSelector(
  NS.MONITOR.PILE.GET_STATION,
  stationSchema
);
export const listConnectors = createApiSelector(
  NS.MONITOR.PILE.LIST_CONNECTORS,
  [connectorSchema]
);
export const listPiles = createApiSelector(NS.MONITOR.PILE.LIST_PILES, [
  pileSchema,
]);
export const listPilesForExport = createApiSelector(NS.MONITOR.PILE.PILES_XLSX);
export const pilesExport = createExportSelector(NS.MONITOR.PILE.PILES_XLSX);
export const listConnectorsForExport = createApiSelector(
  NS.MONITOR.PILE.CONNECTORS_XLSX
);
export const connectorsExport = createExportSelector(
  NS.MONITOR.PILE.CONNECTORS_XLSX
);
export const connectorsImport = createImportSelector(
  NS.MONITOR.PILE.CONNECTORS_XLSX
);
export const importModalState = createAssignSelector(
  NS.MONITOR.PILE.CONNECTORS_XLSX
);
export const listPileStatistcs = createApiSelector(
  NS.MONITOR.PILE.GET_PILE_STATS
);
export const listChargeOrders = createApiSelector(NS.MONITOR.PILE.LIST_ORDERS, [
  orderSchema,
]);
export const listChargeOrdersForExport = createApiSelector(
  NS.MONITOR.PILE.ORDERS_XLSX,
  [orderSchema]
);
export const chargeOrdersExport = createExportSelector(
  NS.MONITOR.PILE.ORDERS_XLSX
);
export const listVehicles = createApiSelector(NS.MONITOR.PILE.LIST_VEHICLES, [
  mekongVehicleSchema,
]);

export const selectStationOrder = createSelector(
  listChargeOrders,
  listVehicles,
  (chargeOrderState, vehiclesState) => {
    const orders = chargeOrderState.result || [];
    const vehicles = vehiclesState.result || [];

    const result = [];
    // 订单根据车辆自编号聚合
    const ordersAgg = groupBy(orders, "plate");

    // 根据 vehicles 补全 orders
    for (let j = 0; j < vehicles.length; j++) {
      const vehicle = vehicles[j];
      const os = ordersAgg[vehicle.no];
      if (os) {
        const order = os.reduce(
          (acc, cur) => ({
            ...acc,
            ...cur,
            chargeStartAt: acc.chargeStartAt || cur.chargeStartAt,
            chargeEndAt: cur.chargeEndAt,
            startSoc: acc.startSoc || cur.startSoc,
            endSoc: cur.endSoc,
            elec: cur.elec + acc.elec,
          }),
          { elec: 0 }
        );
        order.elec = round(order.elec, 2);
        result.push(order);
      } else {
        result.push({
          id: vehicle.id,
          plate: vehicle.no,
          vin: vehicle.vin,
          ns: vehicle.ns,
          line: vehicle.line,
          vehicle,
        });
      }
    }

    const totalElec = result.reduce((acc, cur) => acc + (cur.elec || 0), 0);

    return {
      ...chargeOrderState,
      result,
      total: Object.keys(ordersAgg).length,
      totalElec: round(totalElec, 2),
    };
  }
);
