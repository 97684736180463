import React, { PureComponent } from "react";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import createWarningTable from "./warnings-table";
import alertHistoryActions from "../../actions/history/alert";
import alertHistorySelectors from "../../selectors/history/alert";
import { ExceptionTypes, AlertLevel, HISTORY_NS } from "../../constants";
import { ymdhms, getPathname } from "../../lib";
import createFilterForm from "./warnings-filter-form";
import TicketOperators from "../common/ticket-operators";
import VehicleLink from "../../components/vehicle-link";

const defaultQuery = {
  category: "VEHICLE",
  _sort: "-lastWarnAt",
};

export class Alert extends PureComponent {
  render() {
    return (
      <Container>
        <FilterFrom />

        <WarningsTable defaultQuery={defaultQuery} />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 108px);
`;

const FilterFrom = createFilterForm({
  formName: HISTORY_NS.ALERT.FILTER_FORM,
  warningName: "报警",
  warningType: ExceptionTypes.ALERT_EXCEPTION,
  actions: alertHistoryActions,
  selectors: alertHistorySelectors,
});

const TableColumns = [
  {
    title: "部门",
    key: "department",
    dataIndex: "ns",
    render: val => getPathname(val),
    exportFormat: val => getPathname(val),
  },
  { title: "VIN码", key: "vin", dataIndex: "vin" },
  {
    title: "自编号",
    key: "vehicleNo",
    dataIndex: "device.no",
    render: (val, record) => <VehicleLink title={val} vin={record.vin} />,
  },
  { title: "车牌号", key: "plate", dataIndex: "plate" },
  { title: "线路", key: "line", dataIndex: "line" },
  { title: "厂商", key: "manufacturer", dataIndex: "device.manufacturer" },
  { title: "车型", key: "model", dataIndex: "device.model" },
  {
    title: "等级",
    key: "level",
    dataIndex: "level",
    sorter: true,
    render: val => AlertLevel[val],
    exportFormat: val => AlertLevel[val],
  },
  { title: "报警类型", key: "type", dataIndex: "type", sorter: true },
  { title: "报警码", key: "code", dataIndex: "code", sorter: true },
  { title: "报警名称", key: "name", dataIndex: "name", sorter: true },
  {
    title: "开始时间",
    key: "brokenAt",
    dataIndex: "brokenAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  {
    title: "次数",
    key: "count",
    dataIndex: "count",
    sorter: true,
  },
  {
    title: "最近报警时间",
    key: "lastWarnAt",
    dataIndex: "lastWarnAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
    defaultSortOrder: "descend",
  },
  // {
  //   title: "故障状态",
  //   key: "status",
  //   dataIndex: "status",
  //   render: val => (
  //     <Badge
  //       status={val === "RECOVER" ? "success" : "warning"}
  //       text={AlertStatusI18N[val]}
  //     />
  //   ),
  //   exportFormat: val => AlertStatusI18N[val],
  // },
  {
    title: "工单编号",
    key: "workOrders",
    dataIndex: "workOrders",
    disableExport: true,
    render: val =>
      isEmpty(val)
        ? "--"
        : val.map(wo => (
          /* eslint-disable */
          <NavLink key={wo.id} to={`/ticket/${wo.id}/detail`}>
            {wo.id}
          </NavLink>
          /* eslint-disable */
        )),
    exportFormat: val => (isEmpty(val) ? "--" : val.map(wo => wo.id).join(",")),
  },
  {
    title: "操作",
    dataIndex: "id",
    key: "actions",
    disableExport: true,
    render: (val, record) => {
      if (isEmpty(record.workOrders)) {
        return (
          <TicketOperators
            afterIgnore={alertHistoryActions.listRecords.refresh()}
            alertId={val}
            vehicleId={record.vin}
          />
        );
      }

      return "--";
    },
  },
];

const WarningsTable = createWarningTable(
  "报警监控",
  ExceptionTypes.ALERT_EXCEPTION,
  TableColumns,
  alertHistoryActions,
  alertHistorySelectors
);

export default Alert;
