import * as cs from "../../constants";
import makeWSSelector from "./selectors";
import { WSType } from "./actions";
export { default as reducer } from "./reducers";
export { default as saga } from "./saga";

const WS_EVENT = {
  SUBSCRIBE: "subscribe",
  LOGIN: "login",
  RESET: "reset",
};

const CLIENT_ID_LOCAL_KEY = "LOG_SERVICE_CLIENT_ID";

function wsMaker(
  type,
  path,
  event,
  defaultPayload,
  pre = () => {},
  post = client => {}
) {
  return {
    action: type
      ? (payload = defaultPayload) => ({
          type: WSType.request(type),
          pre,
          post,
          payload,
          meta: {
            event,
          },
        })
      : () => {},
    type,
    selector: path ? makeWSSelector(path) : () => {},
  };
}

class WS {
  logServerConnection = wsMaker("", ["server"]);
  logServerReceive = wsMaker("", ["logs"]);

  logServerManager = {
    login: wsMaker(
      cs.LOG_SERVER_LOGIN,
      ["client"],
      WS_EVENT.LOGIN,
      localStorage.getItem(CLIENT_ID_LOCAL_KEY),
      () => {},
      client => {
        localStorage.setItem(CLIENT_ID_LOCAL_KEY, client.id);
      }
    ),
    vinsSubscribe: wsMaker(
      cs.LOG_VINS_SUBSCRIBE,
      ["client"],
      WS_EVENT.SUBSCRIBE,
      ""
    ),
    vinsReset: wsMaker(cs.LOG_VINS_RESET, ["client"], WS_EVENT.RESET),
    open: wsMaker(cs.LOG_SERVER_SOCKET_OPEN),
    close: wsMaker(cs.LOG_SERVER_SOCKET_CLOSE),
    keywordChange: wsMaker(cs.LOG_SERVER_KEYWORD_CHANGE, ["keyword"]),
  };
}

export const ws = new WS();
export default ws;
