import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { isEqual, without } from "lodash";
import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Button,
  Table,
  Card,
  Dropdown,
  Icon,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import { StyledFilterForm } from "../../components/fold-filter-form";
import BanciActions from "../../actions/banci";
import BanciSelectors from "../../selectors/banci";
import { tableStateToQuery, mergeQuery, ymd, ymdhms } from "../../lib";
import FilterDropdown from "../../components/filter-dropdown";
import withPermission from "../with-permission";
import Permission from "../../config/permission";

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const formTailLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8, offset: 8 },
};

@Form.create()
@connect(state => {
  return {};
})
class FilterFrom extends PureComponent {
  handleRest = () => {
    this.props.form.resetFields();
    this.props.dispatch(BanciActions.filterValues.set({}));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const filterValue = {
          ...values,
        };

        if (values.date) {
          filterValue.date = {
            $gte: values.date[0].startOf("day").toISOString(),
            $lte: values.date[1].endOf("day").toISOString(),
          };
        }

        this.props.dispatch(BanciActions.filterValues.set(filterValue));
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <StyledFilterForm
        style={{ marginTop: 12, paddingTop: 12 }}
        onSubmit={this.handleSubmit}
      >
        <Row gutter={48} type="flex" style={{ paddingTop: 12 }}>
          <Col span={8}>
            <Form.Item label="车辆" {...formItemLayout}>
              {getFieldDecorator("q", {
                rules: [
                  {
                    required: true,
                    message: "请输入 vin码/自编号/车牌号",
                  },
                ],
              })(
                <Input
                  style={{ width: "100%" }}
                  placeholder="请输入 vin码/自编号/车牌号"
                />
              )}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="日期" {...formItemLayout}>
              {getFieldDecorator("date", {
                rules: [
                  {
                    required: true,
                    message: "请输入查询日期",
                  },
                ],
              })(
                <DatePicker.RangePicker
                  showTime={false}
                  style={{ width: "100%" }}
                  placeholder={["开始日期", "结束日期"]}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item {...formTailLayout}>
              <Button onClick={this.handleRest}>重置</Button>
              <Button
                style={{ marginLeft: 24 }}
                type="primary"
                htmlType="submit"
              >
                筛选
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledFilterForm>
    );
  }
}

const TableColumns = [
  {
    title: "日期",
    dataIndex: "date",
    key: "date",
    render: val => ymd(val),
  },
  {
    title: "线路",
    dataIndex: "line.name",
    key: "line",
    render: val => val || "--",
    exportFormat: val => val || "--",
  },
  {
    title: "驾驶员",
    dataIndex: "driverName",
    key: "driverName",
  },
  {
    title: "开出时间",
    dataIndex: "departAt",
    key: "departAt",
    sorter: true,
    render: val => (val ? ymdhms(val) : "--"),
    exportFormat: val => (val ? ymdhms(val) : "--"),
  },
  {
    title: "实到时间",
    dataIndex: "arriveAt",
    key: "arriveAt",
    sorter: true,
    render: val => (val ? ymdhms(val) : "--"),
    exportFormat: val => (val ? ymdhms(val) : "--"),
  },
  {
    title: "开出站名",
    dataIndex: "departPlace",
    key: "departPlace",
    render: val => val || "--",
    exportFormat: val => val || "--",
  },
  {
    title: "到达站名",
    dataIndex: "arrivePlace",
    key: "arrivePlace",
    render: val => val || "--",
    exportFormat: val => val || "--",
  },
  {
    title: "长度",
    dataIndex: "mileage",
    key: "mileage",
    render: val => (val ? val + "km" : "--"),
    exportFormat: val => (val ? val + "km" : "--"),
  },
  {
    title: "类型",
    dataIndex: "type",
    key: "type",
    render: val => val || "--",
    exportFormat: val => val || "--",
  },
];

const TableContainer = styled(Card)`
  .top-right-actions {
    > div {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;
    }
  }

  .ant-btn {
    border: 0;
  }
`;

@connect(state => {
  const filter = BanciSelectors.filterValues(state).assign || {};
  const tableState = BanciSelectors.banciTable(state);

  return {
    filter,
    ...tableState,
  };
})
class BanciTable extends PureComponent {
  state = {
    hideColumns: [],
  };
  componentDidMount() {
    this.fetchBancis(
      { current: 1, pageSize: 10 },
      {},
      { field: "departAt", order: "ascend" }
    );
  }

  componentWillUpdate(nextProps) {
    const { filter } = nextProps;

    if (!isEqual(filter, this.props.filter)) {
      setTimeout(() => {
        this.fetchBancis({ current: 1, pageSize: 10 });
      }, 0);
    }
  }

  handleExport = () => {
    const { filter } = this.props;
    const filename = `路单数据-${filter.q}-${moment().format("YYYY-MM-DD")}`;

    const { hideColumns } = this.state;
    const showColumns = TableColumns.filter(c => !hideColumns.includes(c.key));

    this.props.dispatch(
      BanciActions.banciXlsx.export({
        columns: showColumns,
        fileName: filename,
        fileType: "xlsx",
      })
    );
  };

  showedColumns() {
    const { hideColumns } = this.state;
    const allValues = TableColumns.map(i => i.key);
    return without(allValues, ...hideColumns);
  }

  onColumnsFilterChange = values => {
    const allValues = TableColumns.map(i => i.key);
    this.setState({
      hideColumns: without(allValues, ...values),
    });
  };

  renderTopActions = () => {
    return (
      <div className="top-right-actions">
        <div>
          <Dropdown
            overlay={
              <FilterDropdown
                value={this.showedColumns()}
                onChange={this.onColumnsFilterChange}
                items={TableColumns.map(c => ({ key: c.key, name: c.title }))}
              />
            }
            trigger={["click"]}
          >
            <Button>
              <Icon type="filter" /> 选择数据项
            </Button>
          </Dropdown>
        </div>

        <div>
          <Button
            loading={this.props.exportLoading}
            onClick={this.handleExport}
            type="primary"
          >
            <Icon type="export" />
            导出
          </Button>
        </div>
      </div>
    );
  };

  fetchBancis = (pagination = {}, filters = {}, sort = {}) => {
    const { filter } = this.props;

    const tableQuery = tableStateToQuery(pagination, filters, sort);
    const finalQuery = mergeQuery(tableQuery, { filter });

    const { field, order } = sort;
    const filedIndex = TableColumns.findIndex(c => c.key === field);

    TableColumns.forEach((c, index) => {
      TableColumns[index].sortOrder = false;
    });

    if (filedIndex !== -1) {
      TableColumns[filedIndex].sortOrder = order;
    }

    this.props.dispatch(
      BanciActions.listBancis.request({
        query: finalQuery,
      })
    );
  };

  render() {
    const { docs = [], total = 0, loading, pagination } = this.props;

    const { hideColumns } = this.state;
    const showColumns = TableColumns.filter(c => !hideColumns.includes(c.key));

    return (
      <TableContainer
        bordered={false}
        title={`共${total}条记录`}
        style={{ marginTop: 12 }}
        extra={this.renderTopActions()}
      >
        <Table
          rowKey="id"
          columns={showColumns}
          dataSource={docs}
          loading={loading}
          pagination={pagination}
          onChange={this.fetchBancis}
          scroll={{ x: "max-content" }}
        />
      </TableContainer>
    );
  }
}

@withPermission({
  permission: Permission.vehicleMonitor.banci,
})
@connect(state => {
  const filters = BanciSelectors.filterValues(state).assign || {};

  return {
    filters,
  };
})
export default class Banci extends PureComponent {
  componentWillMount() {
    this.props.dispatch(BanciActions.filterValues.set({}));
  }

  get showTable() {
    const { filters = {} } = this.props;

    return filters.q && filters.date;
  }

  render() {
    return (
      <Container>
        <FilterFrom />
        {!this.showTable && <Placeholder>请选择查询车辆和查询日期</Placeholder>}
        {this.showTable && <BanciTable />}
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 76px);
`;

const Placeholder = styled.div`
  width: 100%;
  height: calc(100% - 114px);
  background: white;
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #7f7f7f;
`;
