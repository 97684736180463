import { isSuccess, successOf, failureOf } from "@36node/redux-api";
import { take, select } from "redux-saga/effects";
import { message } from "antd";
import { get } from "lodash";
import { BANCI_NS } from "../constants";
import { createAssignActions } from "@36node/redux-ui";
import BanciSelectors from "../selectors/banci";
import { createXlsxActions } from "@36node/redux-xlsx";

const filterValues = createAssignActions(BANCI_NS.FILTER_VALUES);

function* exportDataSource() {
  const listState = yield select(BanciSelectors.listBancis);

  const query = get(listState, ["request", "query"], {});

  query.limit = 10000;
  query.offset = 0;

  const apiResultAction = yield take([
    successOf(BANCI_NS.BANCI_XLSX),
    failureOf(BANCI_NS.BANCI_XLSX),
  ]);

  if (isSuccess(apiResultAction)) {
    const apiResult = yield select(BanciSelectors.listBancisForExport);
    message.success("路单信息导出成功");
    return apiResult.result;
  } else {
    message.error("路单信息导出失败");
    return;
  }
}

const banciXlsx = createXlsxActions(BANCI_NS.BANCI_XLSX, {
  exportOpts: {
    dataSource: exportDataSource,
  },
});

const BanciActions = {
  filterValues,
  banciXlsx,
};

export default BanciActions;
