import { createAssignActions } from "@36node/redux-ui";
import { TERMINAL_NS } from "../constants";
import {
  createApiActions,
  successOf,
  failureOf,
  isSuccess,
} from "@36node/redux-api";
import { put, take, select } from "redux-saga/effects";

import makeHistoryActions from "./history/base";
import { mekong } from "../sdk";
import { mekongVehicleSchema } from "../schemas";
import { createXlsxActions } from "@36node/redux-xlsx";
import { message } from "antd";
import TerminalSelectors from "../selectors/terminal";

const recordFilterValues = createAssignActions(
  TERMINAL_NS.DEBUG.RECORD_FILTER_VALUES
);

const listRecords = createApiActions(TERMINAL_NS.DEBUG.LIST_RECORDS, {
  endpoint: mekong.listTboxRecords,
});

// 选择展示原始报文的 record
const selectOriginRecord = createAssignActions(
  TERMINAL_NS.DEBUG.SELECT_ORIGIN_RECORD
);

const listTerminals = createApiActions(TERMINAL_NS.MONITOR.LIST_TERMINALS, {
  endpoint: mekong.listVehicleStates,
  schema: [mekongVehicleSchema],
});

const terminalFilterValues = createAssignActions(
  TERMINAL_NS.MONITOR.TERMINAL_FILTER_VALUES
);

const listTerminalsForExport = createApiActions(
  TERMINAL_NS.MONITOR.TERMINALS_XLSX,
  {
    endpoint: mekong.listVehicleStates,
    schema: [mekongVehicleSchema],
  }
);

function* exportDataSource(params = {}) {
  const apiReqeust = listTerminalsForExport.request;
  yield put(apiReqeust(params));

  const apiResultAction = yield take([
    successOf(TERMINAL_NS.MONITOR.TERMINALS_XLSX),
    failureOf(TERMINAL_NS.MONITOR.TERMINALS_XLSX),
  ]);

  if (isSuccess(apiResultAction)) {
    const apiResult = yield select(TerminalSelectors.listTerminalsForExport);

    message.success("终端信息导出成功");
    return apiResult.result;
  } else {
    message.error("终端信息导出失败");
    return;
  }
}

function* exportOffSiteDataSource(params = {}) {
  const apiResult = yield select(TerminalSelectors.offSiteSelectors);
  message.success("未接入车辆导出成功");
  return apiResult.result;
}

const terminalXlsx = createXlsxActions(TERMINAL_NS.MONITOR.TERMINALS_XLSX, {
  exportOpts: {
    dataSource: exportDataSource,
  },
});

const offSiteXlsx = createXlsxActions(
  TERMINAL_NS.MONITOR.TERMINALS_OFFSITE_XLSX,
  {
    exportOpts: {
      dataSource: exportOffSiteDataSource,
    },
  }
);

const terminalWarningActions = makeHistoryActions(
  TERMINAL_NS.MONITOR.LIST_EXCEPTION,
  TERMINAL_NS.MONITOR.EXCEPTIONS_XLSX,
  TerminalSelectors.terminalWarningSelectors
);

const TerminalActions = {
  recordFilterValues,
  listRecords,
  selectOriginRecord,
  listTerminals,
  terminalFilterValues,
  listTerminalsForExport,
  terminalXlsx,
  terminalWarningActions,
  offSiteXlsx,
};

export default TerminalActions;
