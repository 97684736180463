import React, { PureComponent } from "react";
import { Card, Col, Row, Spin } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";
import { OrderGroupField, StationType } from "@36node-mekong/sdk-ts";
import { get, isEmpty } from "lodash";

import AnalysisActions from "../../actions/analysis";
import { pileActions } from "../../actions/pile";
import { AnalysisSelectors, stationSelectors } from "../../selectors";

import { RangeOptionsPicker } from "./components";
import {
  StopOrderBySourceChart,
  StopOrderByNameChart,
  StopOrderByStationChart,
} from "./charts";

class ChartCard extends PureComponent {
  renderTitle = () => {
    const { title } = this.props;
    return (
      <div
        style={{
          fontSize: 16,
          fontWeight: 650,
        }}
      >
        {title}
      </div>
    );
  };

  render() {
    const { height = 340 } = this.props;
    return (
      <Card
        bodyStyle={{ padding: "12px", height, overflow: "hidden" }}
        title={this.renderTitle()}
      >
        {this.props.children}
      </Card>
    );
  }
}

@connect(state => {
  const chargeAlertCharts = AnalysisSelectors.chargeAlertCharts(state);
  const stations =
    get(stationSelectors.listAllPileStations(state), "result") || [];

  return {
    chargeAlertCharts,
    stations,
  };
})
export default class ChargeAlert extends PureComponent {
  componentDidMount() {
    this.fetch(
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    );
  }

  fetch = (startAt, endAt) => {
    if (isEmpty(this.props.stations)) {
      this.props.dispatch(
        pileActions.listAllPileStations.request(
          {
            type: StationType.CHARGER,
            _limit: 100,
            _offset: 0,
          },
          {}
        )
      );
    }

    this.props.dispatch(
      AnalysisActions.aggStopOrderSource.request({
        _group: [OrderGroupField.SOURCE],
        _sort: "-count",
        at_gte: startAt.toISOString(),
        at_lte: endAt.toISOString(),
        stopAbnormal: true,
      })
    );

    this.props.dispatch(
      AnalysisActions.aggStopOrderStation.request({
        _group: [OrderGroupField.STATION],
        _sort: "-count",
        at_gte: startAt.toISOString(),
        at_lte: endAt.toISOString(),
        stopAbnormal: true,
      })
    );

    this.props.dispatch(
      AnalysisActions.aggStopOrderName.request({
        _group: [OrderGroupField.STOP_REASON_NAME],
        _limit: 5,
        _sort: "-count",
        at_gte: startAt.toISOString(),
        at_lte: endAt.toISOString(),
        stopAbnormal: true,
      })
    );
  };

  handleRangeChange = ([start, end]) => {
    this.fetch(start, end);
  };

  render() {
    const { chargeAlertCharts, stations } = this.props;
    return (
      <Spin spinning={chargeAlertCharts.loading}>
        <Container>
          <Row className="row" gutter={24}>
            <Col span={24}>
              <Card>
                <FlexBox>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 650,
                    }}
                  >
                    充电桩报警分析
                  </div>
                  <RangeOptionsPicker onChange={this.handleRangeChange} />
                </FlexBox>
              </Card>
            </Col>
          </Row>
          <Row className="row" gutter={24}>
            <Col span={24}>
              <ChartCard title="充电中断原因排名">
                <StopOrderByNameChart
                  data={chargeAlertCharts.StopOrderByName}
                />
              </ChartCard>
            </Col>
          </Row>

          <Row className="row" gutter={24}>
            <Col span={12}>
              <ChartCard title="报警按服务商平台分布">
                <StopOrderBySourceChart
                  data={chargeAlertCharts.StopOrderBySource}
                />
              </ChartCard>
            </Col>

            <Col span={12}>
              <ChartCard title="报警按场站分布">
                <StopOrderByStationChart
                  data={chargeAlertCharts.StopOrderByStation}
                  stations={stations}
                />
              </ChartCard>
            </Col>
          </Row>
        </Container>
      </Spin>
    );
  }
}

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 24px 12px 0 12px;

  > .row {
    margin-bottom: 26px;
  }
`;
