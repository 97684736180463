import { createAssignSelector } from "@36node/redux-ui";
import { createApiSelector } from "@36node/redux-api";
import { createSelector } from "reselect";

import { TERMINAL_NS } from "../constants";
import { exceptionSchema, mekongVehicleSchema } from "../schemas";
import makeHistorySelectors from "./history/base";
import makeApiToTableSelector, {
  makeMekongApiToTableSelector,
} from "./api-to-table";
import global from "./global";
import monitor from "./monitor";

const recordFilterValues = createAssignSelector(
  TERMINAL_NS.DEBUG.RECORD_FILTER_VALUES
);

const listRecords = createApiSelector(TERMINAL_NS.DEBUG.LIST_RECORDS);

const selectOriginRecord = createAssignSelector(
  TERMINAL_NS.DEBUG.SELECT_ORIGIN_RECORD
);

const recordsTable = makeApiToTableSelector(listRecords);

const listTerminals = createApiSelector(TERMINAL_NS.MONITOR.LIST_TERMINALS, [
  mekongVehicleSchema,
]);

const terminalsTable = makeMekongApiToTableSelector(listTerminals);

const terminalFilterValues = createAssignSelector(
  TERMINAL_NS.MONITOR.TERMINAL_FILTER_VALUES
);

const listTerminalsForExport = createApiSelector(
  TERMINAL_NS.MONITOR.TERMINALS_XLSX,
  [mekongVehicleSchema]
);

const listExceptions = createApiSelector(TERMINAL_NS.MONITOR.LIST_EXCEPTION, [
  exceptionSchema,
]);
const exceptionsTable = makeMekongApiToTableSelector(listExceptions);
const listExceptionsForExport = createApiSelector(
  TERMINAL_NS.MONITOR.EXCEPTIONS_XLSX,
  [exceptionSchema]
);

const terminalWarningSelectors = makeHistorySelectors(
  TERMINAL_NS.MONITOR.LIST_EXCEPTION,
  TERMINAL_NS.MONITOR.EXCEPTIONS_XLSX
);

const offSiteSelectors = createSelector(
  global.listVehicles,
  monitor.listVehicles,
  (listVehiclesState, monitorState) => {
    const vehicles = listVehiclesState.result || [];
    const offSiteVehicles = vehicles.filter(v => !v.status);
    const loading = listVehiclesState.loading || monitorState.loading;

    return { loading, result: offSiteVehicles };
  }
);

const TerminalSelectors = {
  recordFilterValues,
  listRecords,
  recordsTable,
  selectOriginRecord,
  terminalsTable,
  terminalFilterValues,
  listTerminalsForExport,
  listTerminals,
  listExceptions,
  exceptionsTable,
  listExceptionsForExport,
  terminalWarningSelectors,
  offSiteSelectors,
};

export default TerminalSelectors;
