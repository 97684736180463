import { createApiActions } from "@36node/redux-api";
import { createAssignActions } from "@36node/redux-ui";
import { createCronActions } from "@36node/redux-cron";
import { put, select } from "redux-saga/effects";
import { createXlsxActions } from "@36node/redux-xlsx";

import { NS, VehicleRunState } from "../constants";
import {
  alertSchema,
  userSchema,
  mekongVehicleSchema,
  ticketSchema,
  orderSchema,
} from "../schemas";
import { authV1Sdk, mekong } from "../sdk";
import { monitorSelectors } from "../selectors";
import { getVehicleExportData } from "../lib";

export { archivesActions } from "./archives";

export const listUsers = createApiActions(NS.MONITOR.LIST_USERS, {
  endpoint: authV1Sdk.user.listUsers,
  schema: [userSchema],
});

const getVehicle = createApiActions(NS.MONITOR.GET_VEHICLE, {
  schema: mekongVehicleSchema,
  endpoint: mekong.getVehicle,
});

const getVehicleState = createApiActions(NS.MONITOR.GET_VEHICLE_STATE, {
  endpoint: mekong.getVehicleState,
});
const listVehicles = createApiActions(NS.MONITOR.LIST_VEHICLES, {
  endpoint: mekong.listVehicleStates,
  schema: [mekongVehicleSchema],
});
const listAlerts = createApiActions(NS.MONITOR.LIST_ALERTS, {
  schema: [alertSchema],
  endpoint: mekong.listDeviceFaultRecords,
});

const listVehicleAlerts = createApiActions(NS.MONITOR.LIST_VEHICLE_ALERTS, {
  schema: [alertSchema],
  endpoint: mekong.listDeviceFaultRecords,
}); // 单车详情list alerts

// 实时报警列表查询alerts
const listTableAlerts = createApiActions(NS.MONITOR.LIST_TABLE_ALERTS, {
  schema: [alertSchema],
  endpoint: mekong.listDeviceFaultRecords,
});

const listStationTableAlters = createApiActions(
  NS.MONITOR.LIST_STATION_TABLE_ALERTS,
  {
    // 充电站报警列表查询alerts
    schema: [orderSchema],
    endpoint: mekong.listOrders,
  }
);

const stateVehiclesXlsx = createXlsxActions(NS.MONITOR.CHARGING_VEHICLES_XLSX, {
  exportOpts: {
    dataSource: function*() {
      const vehicleState = (yield select(monitorSelectors.mapVehicleState))
        .assign;

      if (vehicleState === VehicleRunState.CHARGING) {
        return yield select(monitorSelectors.chargeVehiclesByTab);
      }

      return yield select(monitorSelectors.mapVehiclesByState);
    },
  },
});

const vehicleMileagesXlsx = createXlsxActions(
  NS.MONITOR.VEHICLE_MILEAGES_XLSX,
  {
    exportOpts: {
      dataSource: function*() {
        const source = yield select(monitorSelectors.calendarSelector);
        return getVehicleExportData(source.mData);
      },
    },
  }
);

const vehicleEnergyConsumptionsXlsx = createXlsxActions(
  NS.MONITOR.VEHICLE_ENERGYCOMSUMPTIONS_XLSX,
  {
    exportOpts: {
      dataSource: function*() {
        const source = yield select(monitorSelectors.calendarSelector);
        return getVehicleExportData(source.dData);
      },
    },
  }
);

const tboxHistoryXlsx = createXlsxActions(NS.MONITOR.LIST_TBOX_HISTORY, {
  exportOpts: {
    dataSource: function*() {
      const apiResult = yield select(monitorSelectors.tboxHistoryRecords);
      return apiResult.result;
    },
  },
  importOpts: {},
});

const updateSnapshot = createApiActions(NS.MONITOR.UPDATE_SNAP, {
  endpoint: mekong.updateVehicleSnapshot,
});

const createSnapshot = createApiActions(NS.MONITOR.CREATE_SNAP, {
  endpoint: mekong.createVehicleSnapshot,
});

const monitorActions = {
  getVehicle,
  getVehicleState,
  getVehicleCron: createCronActions(NS.MONITOR.GET_VEHICLE_CRON, {
    onTick: function*() {
      yield put(getVehicleState.refresh());
      yield put(listVehicleAlerts.refresh());
    },
  }),
  listVehicles,
  listAlerts, // 地图获取alerts
  listTableAlerts,
  listStationTableAlters, // 充电站报警列表查询alerts
  listTableAlertsCron: createCronActions(NS.MONITOR.LIST_TABLE_ALERTS_CRON, {
    onTick: function*() {
      yield put(listTableAlerts.refresh());
      yield put(listStationTableAlters.refresh());
    },
  }),
  listVehicleAlerts, // 单车详情list alerts
  mapDataCron: createCronActions(NS.MONITOR.MAP_DATA_CRON, {
    onTick: function*() {
      yield put(listVehicles.refresh());
      yield put(listAlerts.refresh());
    },
  }), // 地图数据实时获取定时任务
  mapState: createAssignActions(NS.MONITOR.MAP_STATE), // 地图状态， 包括 center， zoom
  listTboxRecords: createApiActions(NS.MONITOR.LIST_TBOX_RECORDS, {
    endpoint: mekong.listTboxRecords,
  }),
  tboxHistoryXlsx,
  setRecordsGraphFilter: createAssignActions(NS.MONITOR.RECORD_GRAPH_FILTER),
  setSelectedVehicle: createAssignActions(NS.MONITOR.MAP.SELECTED_VEHICLE),
  listVehicleSnapshots: createApiActions(NS.MONITOR.LIST_VEHICLE_SNAPSHOTS, {
    endpoint: mekong.listVehicleSnapshots,
  }),
  listUsers,
  stateVehiclesXlsx,
  vehicleMileagesXlsx,
  vehicleEnergyConsumptionsXlsx,
  listAllOpenTickets: createApiActions(NS.MONITOR.ALERT.LIST_OPEN_TICKETS, {
    endpoint: mekong.listWorkOrders,
    schema: [ticketSchema],
  }),
  updateSnapshot,
  createSnapshot,
};

export default monitorActions;
