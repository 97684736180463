import { createSelector } from "reselect";
import globalSelectors from "./global";

/**
 * 选择 api 中的 entities 并带上 部门信息
 */
export default function makeApiWithDepartmentSelector(apiSelector) {
  return createSelector(
    apiSelector,
    globalSelectors.namespaces,
    (apiRet, namespaces) => {
      const results = apiRet.result || [];
      const entities = results.map((r = {}) => {
        const rNs = namespaces.find(n => n.id === r.ns) || {};
        const department = rNs.pathname;

        return {
          ...r,
          department,
        };
      });

      return {
        ...apiRet,
        result: entities,
      };
    }
  );
}
