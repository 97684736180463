import { createApiActions } from "@36node/redux-api";
import { createAssignActions } from "@36node/redux-ui";
import { createToggleActions } from "@36node/redux-ui";
import { put } from "redux-saga/effects";
import { createCronActions } from "@36node/redux-cron";

import { NS } from "../constants";
import {
  userSchema,
  namespacesSchema,
  mekongVehicleSchema,
  operationSchema,
} from "../schemas";

import { weatherSdk, authV1Sdk, mekong } from "../sdk";
import { ROOT_NAMESPACE } from "../config";

export { archivesActions } from "./archives";

const listNsApi = createApiActions(NS.GLOBAL.LIST_NAMESPACES, {
  schema: [namespacesSchema],
  endpoint: authV1Sdk.namespace.listNamespaces,
});

const listUsers = createApiActions(NS.GLOBAL.LIST_USERS, {
  schema: [userSchema],
  endpoint: authV1Sdk.user.listUsers,
});

const listVehiclesApi = createApiActions(NS.GLOBAL.LIST_VEHICLES, {
  schema: [mekongVehicleSchema],
  endpoint: mekong.listVehicles,
});

const getAlertSummary = createApiActions(NS.GLOBAL.GET_ALERT_SUMMARY, {
  endpoint: mekong.aggregateFaultRecords,
});

const getStationAlertSummary = createApiActions(
  NS.GLOBAL.GET_STATION_ALERT_SUMMARY,
  {
    endpoint: mekong.listOrders,
  }
);

const listVehicles = (root = ROOT_NAMESPACE) => {
  return listVehiclesApi.request({
    _limit: 10000,
    _select: [
      "ns",
      "line",
      "alarmLevel",
      "chargeStatus",
      "no",
      "repairing",
      "state",
      "overall",
      "reportedAt",
    ],
    ns_like: root,
  });
};
/**
 * global actions
 */
export const globalActions = {
  /**
   * 全局获取ns, 自动参数
   */
  listNamespaces: (root = ROOT_NAMESPACE) => {
    return listNsApi.request({
      query: {
        limit: 10000,
        id_like: root,
      },
    });
  },

  listUsers: (root = ROOT_NAMESPACE) => {
    return listUsers.request({
      query: {
        limit: 10000,
        ns_like: "bus",
      },
    });
  },

  listVehicles,
  getAlertSummary,
  getStationAlertSummary,
  alertSummaryCron: createCronActions(NS.GLOBAL.ALERT_SUMMARY_CRON, {
    onTick: function* () { // eslint-disable-line
      yield put(getAlertSummary.refresh());
      yield put(getStationAlertSummary.refresh());
    },
  }),
  listVehicleCron: createCronActions(NS.GLOBAL.LIST_VEHICLES_CRON, {
    onTick: function* () { // eslint-disable-line
      yield put(listVehicles());
    },
  }),

  refreshSession: createApiActions(NS.GLOBAL.REFRESH, {
    endpoint: authV1Sdk.session.getSession,
    reduxPath: "session",
  }),
  // login: createApiActions(NS.GLOBAL.LOGIN, {
  //   endpoint: authSdk.session.createSession,
  //   reduxPath: "session",
  // }),
  loginV1: createApiActions(NS.GLOBAL.LOGIN, {
    endpoint: authV1Sdk.session.createSession,
    reduxPath: "session",
  }),
  logout: createApiActions(NS.GLOBAL.LOGOUT, {
    endpoint: authV1Sdk.session.deleteSession,
    reduxPath: "session",
  }),
  keywordAssignActions: createAssignActions(NS.GLOBAL.CHANGE_TREE_KEYWORD),
  toggleActions: createToggleActions(NS.GLOBAL.TOGGLE_FOCUS_SEARCH),
  getWeather: createApiActions(NS.GLOBAL.GET_WEATHER, {
    endpoint: weatherSdk.weather.getWeather,
  }),
  getCaptcha: createApiActions(NS.GLOBAL.GET_CAPTCHA, {
    endpoint: authV1Sdk.captcha.getCaptcha,
    reduxPath: "captcha",
  }),
  loginCountDown: createAssignActions(NS.GLOBAL.LOGIN_COUNT_DOWN),
};

/**
 * system actions
 */
export const systemActions = {
  listNamespaces: createApiActions(NS.SYSTEM.LIST_NAMESPACES, {
    schema: [namespacesSchema],
    endpoint: authV1Sdk.namespace.listNamespaces,
  }),
  createNamespace: createApiActions(NS.SYSTEM.CREATE_NAMESPACE, {
    schema: namespacesSchema,
    endpoint: authV1Sdk.namespace.createNamespace,
  }),
  getNamespace: createApiActions(NS.SYSTEM.GET_NAMESPACE, {
    schema: namespacesSchema,
    endpoint: authV1Sdk.namespace.getNamespace,
  }),
  updateNamespace: createApiActions(NS.SYSTEM.UPDATE_NAMESPACE, {
    schema: namespacesSchema,
    endpoint: authV1Sdk.namespace.updateNamespace,
  }),
  deleteNamespace: createApiActions(NS.SYSTEM.DELETE_NAMESPACE, {
    endpoint: authV1Sdk.namespace.deleteNamespace,
  }),
  listUsers: createApiActions(NS.SYSTEM.LIST_USERS, {
    schema: [userSchema],
    endpoint: authV1Sdk.user.listUsers,
  }),
  listChargerOperators: createApiActions(NS.SYSTEM.LIST_CHARGER_OPERATORS, {
    schema: [userSchema],
    endpoint: authV1Sdk.user.listUsers,
  }),
  createUser: createApiActions(NS.SYSTEM.CREATE_USER, {
    schema: userSchema,
    endpoint: authV1Sdk.user.createUser,
  }),
  getUser: createApiActions(NS.SYSTEM.GET_USER, {
    schema: userSchema,
    endpoint: authV1Sdk.user.getUser,
  }),
  updateUser: createApiActions(NS.SYSTEM.UPDATE_USER, {
    schema: userSchema,
    endpoint: authV1Sdk.user.updateUser,
  }),
  deleteUser: createApiActions(NS.SYSTEM.DELETE_USER, {
    endpoint: authV1Sdk.user.deleteUser,
  }),
  listOperations: createApiActions(NS.SYSTEM.LIST_OPERATIONS, {
    endpoint: mekong.listOperations,
    schema: [operationSchema],
  }),
};

/**
 * report actions
 */
export { default as reportActions } from "./report";
export { default as monitorActions } from "./monitor";
export { default as vehicleActions } from "./vehicle";
export { default as AnalysisActions } from "./analysis";
export { pileActions } from "./pile";
