/**
 * 历史数据模块 特殊saga
 */

import { all, fork, take, put, select } from "redux-saga/effects";
import { successOf, createApiSelector } from "@36node/redux-api";
import { HISTORY_NS } from "../constants";
import { listUsers } from "../actions/history/alert";

const listAlertSelector = createApiSelector(HISTORY_NS.ALERT.LIST_ALERTS);

/**
 * 在listAlert 之后 读取users
 */
function* watchListAlerts() {
  while (true) {
    yield take(successOf(HISTORY_NS.ALERT.LIST_ALERTS));

    const alertResp = yield select(listAlertSelector);
    const alerts = yield select(state => state.entities.alerts);

    const { result = [] } = alertResp;

    const handlers = result.map(r => alerts[r].actionBy).filter(h => h);

    if (handlers && handlers.length > 0) {
      yield put(listUsers.request({ query: { id: handlers } }));
    }
  }
}

export default function* watchHistory() {
  yield all([fork(watchListAlerts)]);
}
