/**
 * 排名table
 */

import React, { PureComponent } from "react";
import { Table } from "antd";
import styled from "styled-components";

const StyledTable = styled(Table)`
  span.rank {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 10px;
    background-color: #f0f2f5;
    &.marked {
      width: 20px;
      height: 20px;
      background-color: #314659;
      color: #ffffff;
    }
  }

  .ant-table-column-title {
    font-size: 12px;
    color: #969696;
  }
`;

export default class RankTable extends PureComponent {
  render() {
    const { data = [], markCount = 3, columns = [], scroll } = this.props;

    const dataSource = data.map((d, index) => ({
      ...d,
      row: index + 1,
    }));

    const finalColumns = [
      {
        title: "排名",
        dataIndex: "row",
        render: val => {
          return (
            <span className={"rank" + (val <= markCount ? " marked" : "")}>
              {val}
            </span>
          );
        },
      },
      ...columns,
    ];

    return (
      <StyledTable
        pagination={false}
        rowKey="row"
        columns={finalColumns}
        dataSource={dataSource}
        scroll={scroll}
      />
    );
  }
}
