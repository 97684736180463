import React, { PureComponent } from "react";
import { Select } from "antd";
import { VehicleTermState, VehicleTermStateI18N } from "../../constants";

export class VehicleStateSelect extends PureComponent {
  render() {
    return (
      <Select {...this.props}>
        {Object.keys(VehicleTermState).map(s => (
          <Select.Option key={s} value={s}>
            {VehicleTermStateI18N[s]}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default VehicleStateSelect;
