import { startsWith } from "lodash";
const prefix = "@@timeline";

export const types = {
  setData: `${prefix}/SET_DATA`, // 设置时间轴数据
  start: `${prefix}/START`, // 进度开始
  pause: `${prefix}/PAUSE`, // 进度暂停
  stop: `${prefix}/STOP`, // 进度停止
  jump: `${prefix}/JUMP`, // 进度跳转
  tick: `${prefix}/TICK`, // 时间轴前进事件
  pace: `${prefix}/PACE`, // 设置倍速
};

export function isTimeline(action) {
  return startsWith(action.type, prefix);
}
