import { failureOf, successOf, isFailure } from "@36node/redux-api";
import { call, delay, fork, put, takeLatest } from "redux-saga/effects";
import { message } from "antd";
import moment from "moment";

import { history } from "../lib";
import {
  LOGIN_URL,
  NS,
  SESSION_ID,
  TOKEN,
  ROLES,
  SESSION_NS,
  USER_ID,
  LOGIN_LOCK_DDL_KEY,
} from "../constants";
import { LOGIN_FAIL_LOCK_SECONDS } from "../config";
import { globalActions } from "../actions";
import { weatherSdk, authV1Sdk, old_token, mekong } from "../sdk";
import { get } from "lodash";

const reloginRequest = globalActions.refreshSession.request;

function* login({ payload = {}, meta = {} }) {
  const { result = {} } = payload;

  if (!result.id) {
    throw new Error("missing session id");
  }
  if (!result.token) {
    throw new Error("missing session token");
  }

  // 检查登陆用户的ns 是否包含 ROOT_NAMESPACE
  // const checkRolePass = !isEmpty(
  //   get(result, "user.roles", [])
  //     .map(r => r.ns)
  //     .filter(n => startsWith(n, ROOT_NAMESPACE))
  // );

  // // 禁止登陆
  // if (!checkRolePass) {
  //   message.error("Authentication failed!");
  //   return;
  // }

  // session id is a refresh token for jwt
  // store in localstorage and session storage
  localStorage.setItem(SESSION_ID, result.id);
  localStorage.setItem(ROLES, JSON.stringify(result.roles));
  localStorage.setItem(SESSION_NS, result.ns);
  sessionStorage.setItem(TOKEN, result.token);
  sessionStorage.setItem(USER_ID, get(result, "user.id", ""));

  // set sdk token
  const sdks = [weatherSdk];
  sdks.forEach(sdk => (sdk.token = old_token));

  [authV1Sdk].forEach(sdk => (sdk.token = result.token));

  // const role =
  //   result.roles.find(r => new RegExp(ROOT_NAMESPACE).test(r.ns)) || {};
  // global alert summary can have dept filter now
  // yield put(
  //   globalActions.getAlertSummary.request({
  //     query: {
  //       filter: {
  //         ns: { $regex: new RegExp(result.user.ns, "g") },
  //       },
  //     },
  //   })
  // );
  // monitor alert summary can have dept filter now
  // 当厂商账号登录时需做额外处理
  // const producer = PRODUCERS_NAMESPACES.filter(
  //   v => result.ns.indexOf(v.ns) !== -1
  // );

  // yield put(
  //   monitorActions.getAlertSummary.request({
  //     query: {
  //       filter: {
  //         ns: { $regex: new RegExp(result.user.ns, "g") },
  //         vehicleProducer: producer.length ? producer[0].ns : undefined,
  //       },
  //     },
  //   })
  // );

  // go back where we from
  const { from = { pathname: "/" } } = meta;

  if (history.location.pathname === LOGIN_URL) {
    yield call(history.push, from);
  }
}

function* logout() {
  localStorage.removeItem(SESSION_ID);
  sessionStorage.removeItem(TOKEN);
  localStorage.removeItem(ROLES);
  localStorage.removeItem(SESSION_NS);
  sessionStorage.removeItem(USER_ID);
  yield call(history.push, LOGIN_URL);
}

function* reLogin(from) {
  while (true) {
    const sessionId = localStorage.getItem(SESSION_ID);
    if (sessionId && history.location.pathname !== LOGIN_URL) {
      yield put(reloginRequest({ sessionId }, { from }));
    }
    yield delay(10 * 60 * 1000);
  }
}

function* flashError({ error, type }) {
  // message.error(error.msg || error.message || "API Request Error");
  if (error.status === 401 && type !== failureOf(NS.GLOBAL.LOGIN)) {
    yield logout();
  }
  if (error.status === 404 && type === failureOf(NS.GLOBAL.REFRESH)) {
    yield logout();
  }
}

function* recordLogin({ payload = {}, meta = {} }) {
  const session = payload.result;
  if (!session) return;
  yield mekong.createOperation({
    body: {
      name: "login",
      method: "POST",
      path: "/auth/v1/sessions",
      user: session.user.id,
      domain: "session",
      params: { session: session.id },
    },
  });
}

let loginErrorCount = 0;
function* countLoginError() {
  loginErrorCount++;
  if (loginErrorCount > 5) {
    loginErrorCount = 0;
    message.error(`失败次数太多，请${LOGIN_FAIL_LOCK_SECONDS}秒之后再试`);
    yield put(globalActions.loginCountDown.set(LOGIN_FAIL_LOCK_SECONDS));
    // 设置登录锁定截止时间
    localStorage.setItem(
      LOGIN_LOCK_DDL_KEY,
      moment()
        .add(LOGIN_FAIL_LOCK_SECONDS, "second")
        .toISOString()
    );
  } else {
    message.error("用户名或密码错误");
  }
}

export default function* watchSession() {
  const from = history.location || "/";

  // 检查是否有登录锁定时间
  if (localStorage.getItem(LOGIN_LOCK_DDL_KEY)) {
    const ddl = moment(localStorage.getItem(LOGIN_LOCK_DDL_KEY));
    const now = moment();
    // 未到ddl
    if (ddl.isAfter(now)) {
      yield put(globalActions.loginCountDown.set(ddl.diff(now, "second")));
    }
  }

  // refresh jwt token
  yield fork(reLogin, from);
  yield takeLatest(successOf(NS.GLOBAL.LOGIN), login);
  yield takeLatest(successOf(NS.GLOBAL.LOGIN), recordLogin);
  yield takeLatest(successOf(NS.GLOBAL.REFRESH), login);
  yield takeLatest(successOf(NS.GLOBAL.LOGOUT), logout);
  yield takeLatest(failureOf(NS.GLOBAL.LOGIN), countLoginError);
  yield takeLatest(isFailure, flashError);
}
