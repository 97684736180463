import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import TicketSelectors from "../../selectors/tickets";
import TicketActions from "../../actions/tickets";
import { Select, Button } from "antd";
import { TicketLayout } from "../../components/ticket/ticket-layout";
import TicketInfo from "../../components/ticket/ticket-info";
import { withRouter } from "react-router-dom";
import qs from "qs";
import { trimStart } from "lodash";
import styled from "styled-components";
import { globalSelectors } from "../../selectors";
import { TicketEvents } from "../../constants";
import AlertsTable from "../../components/ticket/alerts-table";
import EventsList from "../../components/ticket/events-list";
import { getPathname } from "../../lib";

/**
 *  工单选择
 */
@connect(state => {
  const listRep = TicketSelectors.listTickets(state);

  const tickets = listRep.result || [];

  return {
    tickets,
  };
})
class TicketsSelect extends PureComponent {
  onChange = value => {
    this.props.dispatch(TicketActions.selectedTicket.set(value));
  };

  render() {
    const { tickets = [] } = this.props;
    return (
      <Select
        style={{ width: 250, margin: "0 24px" }}
        placeholder="请选择关联工单编号"
        onChange={this.onChange}
      >
        {tickets.map(t => {
          return <Select.Option key={t.id}>{t.id.slice(-8)}</Select.Option>;
        })}
      </Select>
    );
  }
}

const Title = styled.div`
  display: flex;
  align-items: center;

  padding: 12px 0;

  .text {
    font-size: 20px;
    font-weight: 600;
  }
`;

@withRouter
@connect((state, props) => {
  const curTicket = TicketSelectors.selectdTicket(state).assign;

  const tickets = TicketSelectors.listTickets(state).result || [];

  const { location } = props;
  const query = qs.parse(trimStart(location.search, "?"));

  const loginUser = globalSelectors.loginUser(state);

  const bindLoading = TicketSelectors.bindEvent(state).loading || false;

  let ticket;

  if (curTicket) {
    ticket = tickets.find(t => t.id === curTicket);
    ticket.department = getPathname(ticket.ns);
  }

  return {
    curTicket,
    tickets,
    ...query,
    loginUser,
    bindLoading,
    ticket,
  };
})
export default class BindAlert extends PureComponent {
  componentDidMount() {
    const { alertId, vehicleId } = this.props;

    this.props.dispatch(TicketActions.initBindAlert({ alertId, vehicleId }));
  }

  componentWillUnmount() {
    this.props.dispatch(TicketActions.selectedTicket.set(undefined));
  }

  handleBind = () => {
    const { curTicket, alertId, loginUser } = this.props;

    this.props.dispatch(
      TicketActions.bindEvent.request(
        {
          ticketId: curTicket,
          body: {
            name: TicketEvents.BIND_ALERT,
            alerts: [alertId],
            createdBy: loginUser.id,
          },
        },
        {
          successMsg: `报警 ${alertId.slice(
            -8
          )} 已成功绑定至工单 ${curTicket.slice(-8)}`,
        }
      )
    );
  };

  renderTitle = () => {
    const { curTicket, bindLoading } = this.props;
    return (
      <Title>
        <div className="text">工单编号:</div>
        <TicketsSelect />

        <Button
          loading={bindLoading}
          onClick={this.handleBind}
          type="primary"
          disabled={!curTicket}
        >
          确认关联
        </Button>
      </Title>
    );
  };

  render() {
    const { ticket } = this.props;

    return (
      <TicketLayout>
        <TicketInfo title={this.renderTitle()} ticket={ticket} />
        {ticket && (
          <Fragment>
            <Divider />
            <AlertsTable alerts={ticket.alerts.filter(a => a)} />
            <Divider />
            <EventsList ticket={ticket} />
          </Fragment>
        )}
      </TicketLayout>
    );
  }
}

const Divider = styled.div`
  margin: 12px 0px;
`;
