/**
 *按公司分布
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import ReactEcharts from "echarts-for-react";
import { sumBy, orderBy } from "lodash";
import styled from "styled-components";

const Box = styled(Row)``;

export default class AlertChartByLevel extends PureComponent {
  get colors() {
    return ["#df273e", "#fb6d3e", "#faac15"];
  }

  getOption = (data = []) => {
    const total = sumBy(data, "value");
    return {
      color: this.colors,
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      legend: [
        {
          orient: "vertical",
          itemGap: 18,
          icon: "circle",
          itemHeight: 10,
          y: "center",
          right: "25%",
          textStyle: {
            fontSize: 16,
            color: "#595959",
          },
          data,
        },
        {
          orient: "vertical",
          itemGap: 18,
          icon: "none",
          y: "center",
          right: "15%",
          data,
          formatter: function(name) {
            const found = data.find(d => d.name === name);

            return ((found.value / total) * 100).toFixed(2) + "%";
          },
          textStyle: {
            color: "rgba(0, 0, 0, 0.427450980392157)",
            fontSize: 16,
          },
        },
        {
          orient: "vertical",
          itemGap: 18,
          icon: "none",
          y: "center",
          right: "5%",
          data,
          formatter: function(name) {
            const found = data.find(d => d.name === name);

            return found.value + "起";
          },
          textStyle: {
            color: "rgba(0, 0, 0, 0.427450980392157)",
            fontSize: 16,
          },
        },
      ],
      series: [
        {
          name: "",
          type: "pie",
          radius: ["60%", "85%"],
          center: ["30%", "50%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data,
        },
      ],
    };
  };

  render() {
    const { data = [] } = this.props;

    const option = this.getOption(orderBy(data, "level", "desc"));

    return (
      <Box>
        <Col span={24}>
          <ReactEcharts option={option} />
        </Col>
      </Box>
    );
  }
}
