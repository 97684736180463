import React from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import styled from "styled-components";
import IconFont from "../components/iconfont";
import { startsWith, uniq, intersection, isEmpty } from "lodash";

import Permission from "../config/permission";
import withSession from "./with-session";

const { SubMenu } = Menu;

@withRouter
@withSession
export default class Nav extends React.Component {
  getSelectedKey = () => {
    const { pathname } = this.props.location;
    let selectd = items.find(i => startsWith(pathname, i.path));

    if (!selectd) {
      return;
    }

    if (selectd.children) {
      selectd = selectd.children.find(i => startsWith(pathname, i.path));
    }

    if (selectd) {
      return [selectd.path];
    }
  };

  getOpenKey = () => {
    const { pathname } = this.props.location;

    let opened = items.find(i => startsWith(pathname, i.path));

    if (!opened) return;

    return [opened.title];
  };

  componentWillUpdate(nextProps) {
    // if (nextProps.user.roles && !isEqual(this.props.user, nextProps.user)) {
    //   if (this.checkTerminalOp(nextProps.user.roles)) {
    //     this.props.history.push("/system/equipment/realtime");
    //   }
    // }
  }

  /**
   * 根据用户角色过滤nav item
   */
  filterItemByRole = (items = [], roles) => {
    const navItems = items.map(item => {
      if (item.children) {
        // 有children 的 item, permission 是子item的合集
        item.permission = uniq(
          [].concat(...item.children.map(c => c.permission))
        );
      }
      return item;
    });

    // 过滤
    return navItems
      .map(item => {
        if (isEmpty(intersection(item.permission, roles))) return null;

        if (item.children) {
          return {
            ...item,
            children: this.filterItemByRole(item.children, roles),
          };
        }

        return item;
      })
      .filter(Boolean);
  };

  render() {
    const finalItems = this.filterItemByRole(items, this.props.roles);

    return (
      <StyledMenu
        defaultOpenKeys={this.getOpenKey()}
        mode="inline"
        theme="dark"
        selectedKeys={this.getSelectedKey()}
      >
        {finalItems.map(item => {
          if (item.children) {
            return (
              <SubMenu
                key={item.title}
                title={
                  <span>
                    <IconFont
                      style={{ fontSize: 14, color: "#FFFFFF" }}
                      type={item.icon}
                    />
                    <span>{item.title}</span>
                  </span>
                }
              >
                {item.children.map(c => {
                  return (
                    <Menu.Item key={c.path}>
                      <NavLink to={c.path}>
                        <span>{c.title}</span>
                      </NavLink>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          }

          return (
            <Menu.Item key={item.path}>
              <NavLink to={item.path}>
                <IconFont
                  style={{ fontSize: 14, color: "#FFFFFF" }}
                  type={item.icon}
                />
                <span>{item.title}</span>
              </NavLink>
            </Menu.Item>
          );
        })}
      </StyledMenu>
    );
  }
}

const items = [
  {
    path: "/overview",
    title: "服务概览",
    icon: "iconicon-caidan-fuwugailan",
    permission: Permission.overview,
  },
  {
    path: "/monitor",
    title: "安全监控",
    icon: "iconicon-caidan-anquanjiankong",
    permission: Permission.monitor,
  },
  {
    path: "/history",
    title: "数据查询",
    icon: "iconicon-caidan-jiankonglishi",
    permission: Permission.history,
  },
  {
    path: "/ticket",
    title: "工单中心",
    icon: "iconicon-caidan-gongdanzhongxin",
    permission: Permission.ticket,
  },
  {
    path: "/station",
    title: "充电桩管理",
    icon: "iconicon-anquanjiankong-chongdianzhuang",
    permission: Permission.pile,
  },
  {
    path: "/report-form",
    title: "日常报表",
    icon: "iconicon-caidan-richangbaobiao",
    permission: Permission.reportForm,
  },
  {
    path: "/analysis",
    title: "数据分析",
    icon: "iconshujufenxi",
    permission: Permission.analysis,
  },
  {
    path: "/vehicle-monitor",
    title: "车辆监控",
    icon: "iconicon-caidan-cheliangjiance",
    children: [
      {
        title: "终端管理",
        path: "/vehicle-monitor/terminal",
        permission: Permission.vehicleMonitor.terminal,
      },
      {
        title: "报文分析",
        path: "/vehicle-monitor/datagram",
        permission: Permission.vehicleMonitor.datagram,
      },
      // {
      //   title: "路单查询",
      //   path: "/vehicle-monitor/banci",
      //   permission: Permission.vehicleMonitor.banci,
      // },
    ],
  },
  {
    title: "系统设置",
    icon: "iconicon-caidan-xitonggongju",
    path: "/system",
    children: [
      {
        path: "/system/archives",
        title: "车辆档案",
        permission: Permission.system.archives,
      },
      {
        path: "/system/users",
        title: "用户管理",
        permission: Permission.system.users,
      },
      {
        path: "/system/namespaces",
        title: "组织架构",
        permission: Permission.system.namespaces,
      },
      {
        path: "/system/operation",
        title: "业务日志",
        permission: Permission.system.operations,
      },
      // {
      //   path: "/system/template",
      //   title: "模板配置",
      //   permission: Permission.system.settings,
      // },
      // {
      //   path: "/system/settings",
      //   title: "设置",
      //   permission: Permission.system.settings,
      // },
    ],
  },
];

const StyledMenu = styled(Menu)`
  background: rgba(39, 50, 102, 1);
  padding-top: 20px;

  .ant-menu-sub {
    background: rgba(39, 50, 102, 1) !important;
    box-shadow: none !important;
  }

  .ant-menu-item-selected {
    background: #1660fd !important;
  }
`;
