import { Roles } from "../constants";
import { without } from "lodash";

/**
 * 角色权限配置
 */

const AllRoles = without(Object.values(Roles), Roles.USERMANAGER);

const Permission = {
  overview: [...without(AllRoles, Roles.TERMINAL_OP), Roles.USERMANAGER], // 服务概览
  monitor: AllRoles, // 安全监控
  history: without(AllRoles, Roles.PILES_MONITOR), // 数据查询
  ticket: without(AllRoles, Roles.TERMINAL_OP), // 工单中心
  pile: [
    Roles.CALT_MONITOR,
    Roles.OPERATION_MONITOR,
    Roles.MAINTENANCE_MONITOR,
    Roles.PILES_MONITOR,
    Roles.ADMIN,
    Roles.AUDIT_ADMIN,
  ], // 充电桩管理
  reportForm: without(AllRoles, Roles.TERMINAL_OP), // 日常报表
  analysis: [
    Roles.CALT_MONITOR,
    Roles.MAINTENANCE_MONITOR,
    Roles.PILES_MONITOR,
    Roles.ADMIN,
    Roles.AUDIT_ADMIN,
  ], //  数据分析
  vehicle: without(AllRoles, Roles.PILES_MONITOR), // 单车详情
  vehicleMonitor: {
    terminal: without(AllRoles, Roles.PILES_MONITOR), // 终端监控
    datagram: without(AllRoles, Roles.PILES_MONITOR), //报文分析
    banci: without(AllRoles, Roles.PILES_MONITOR), // 路单查询
  },
  system: {
    archives: without(AllRoles, Roles.OPERATION_MONITOR, Roles.PILES_MONITOR), // 车辆档案
    users: [Roles.ADMIN, Roles.MAINTENANCE_MONITOR, Roles.USERMANAGER], // 用户管理
    namespaces: [Roles.ADMIN, Roles.MAINTENANCE_MONITOR, Roles.USERMANAGER], // 组织架构
    operations: [Roles.ADMIN, Roles.AUDIT_ADMIN], // 日志
    settings: [Roles.ADMIN, Roles.MAINTENANCE_MONITOR, Roles.USERMANAGER], // 设置
  },
};

export default Permission;
