import { get } from "lodash";

import { initState } from "./reducers";

const makeStateSelector = path => state => get(state.ws || initState, [path]);

/**
 *
 * @param {string} path state path
 * @returns {function(object) => object} selector
 */
const makeWSSelector = path => {
  return makeStateSelector(path);
};

export default makeWSSelector;
