import { createApiActions } from "@36node/redux-api";
import { ANALYSIS_NS } from "../constants";
import { mekong } from "../sdk";

const aggOnsiteVehicle = createApiActions(ANALYSIS_NS.AGG_ONSITE_VEHICLE, {
  endpoint: mekong.aggregateVehicleStates,
});

const aggFaultStatus = createApiActions(ANALYSIS_NS.AGG_FAULT_STATUS, {
  endpoint: mekong.aggregateFaultRecords,
});

const aggFaultDate = createApiActions(ANALYSIS_NS.AGG_FAULT_DATE, {
  endpoint: mekong.aggregateFaultRecords,
});

const aggFaultType = createApiActions(ANALYSIS_NS.AGG_FAULT_TYPE, {
  endpoint: mekong.aggregateFaultRecords,
});

const aggFaultName = createApiActions(ANALYSIS_NS.AGG_FAULT_NAME, {
  endpoint: mekong.aggregateFaultRecords,
});

const aggFaultModel = createApiActions(ANALYSIS_NS.AGG_FAULT_MODEL, {
  endpoint: mekong.aggregateVehicleSnapshot,
});

const aggFaultNs = createApiActions(ANALYSIS_NS.AGG_FAULT_NS, {
  endpoint: mekong.aggregateFaultRecords,
});

const aggFaultLevel = createApiActions(ANALYSIS_NS.AGG_FAULT_LEVEL, {
  endpoint: mekong.aggregateFaultRecords,
});

const aggFaultRateNs = createApiActions(ANALYSIS_NS.AGG_FAULT_RATE_NS, {
  endpoint: mekong.aggregateVehicleSnapshot,
});

const aggFaultRateModel = createApiActions(ANALYSIS_NS.AGG_FAULT_RATE_MODEL, {
  endpoint: mekong.aggregateVehicleSnapshot,
});

const aggOpenTicketStage = createApiActions(ANALYSIS_NS.AGG_OPEN_TICKET_STAGE, {
  endpoint: mekong.aggregateWorkOrders,
});

const aggOrderNs = createApiActions(ANALYSIS_NS.AGG_ORDER_NS, {
  endpoint: mekong.aggregateOrders,
});

const aggOrderIsFull = createApiActions(ANALYSIS_NS.AGG_ORDER_IS_FULL, {
  endpoint: mekong.aggregateOrders,
});

const aggOrderStation = createApiActions(ANALYSIS_NS.AGG_ORDER_STATION, {
  endpoint: mekong.aggregateOrders,
});

const aggStopOrderStation = createApiActions(
  ANALYSIS_NS.AGG_STOP_ORDER_STATION,
  {
    endpoint: mekong.aggregateOrders,
  }
);

const aggStopOrderSource = createApiActions(ANALYSIS_NS.AGG_STOP_ORDER_SOURCE, {
  endpoint: mekong.aggregateOrders,
});

const aggStopOrderName = createApiActions(ANALYSIS_NS.AGG_STOP_ORDER_NAME, {
  endpoint: mekong.aggregateOrders,
});

const AnalysisActions = {
  aggOnsiteVehicle,
  aggFaultStatus,
  aggFaultDate,
  aggFaultType,
  aggFaultName,
  aggFaultModel,
  aggFaultNs,
  aggFaultLevel,
  aggFaultRateNs,
  aggFaultRateModel,
  aggOpenTicketStage,
  aggOrderNs,
  aggOrderIsFull,
  aggOrderStation,
  aggStopOrderStation,
  aggStopOrderSource,
  aggStopOrderName,
};

export default AnalysisActions;
