import { createApiSelector } from "@36node/redux-api";
import { BANCI_NS } from "../constants";
import { createAssignSelector } from "@36node/redux-ui";
import makeApiToTableSelector from "./api-to-table";
import { banciSchema } from "../schemas";

const listBancis = createApiSelector(BANCI_NS.LIST_BANCI, [banciSchema]);

const filterValues = createAssignSelector(BANCI_NS.FILTER_VALUES);

const banciTable = makeApiToTableSelector(listBancis);

const listBancisForExport = createApiSelector(BANCI_NS.BANCI_XLSX, [
  banciSchema,
]);

const BanciSelectors = {
  listBancis,
  filterValues,
  banciTable,
  listBancisForExport,
};

export default BanciSelectors;
