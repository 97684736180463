import { endsWith, startsWith } from "lodash";

/**
 * 判断是否是 socket emit action
 * @param {Action} action 结果 action
 */
export function isEmit(action) {
  return (
    action.meta &&
    !!action.meta.event &&
    startsWith(action.type, PREFIX) &&
    endsWith(action.type, REQUEST)
  );
}

export class WSType {
  constructor(base) {
    this.base = base;
  }

  /**
   * 构造一个 request type
   * @param {string} base
   */
  static request(base) {
    return `${PREFIX}/${base || this.base}_${REQUEST}`;
  }

  /**
   * 构造一个 success type
   * @param {string} base
   */
  static success(base) {
    return `${PREFIX}/${base || this.base}_${SUCCESS}`;
  }

  /**
   * 构造一个 failure type
   * @param {string} base
   */
  static failure(base) {
    return `${PREFIX}/${base || this.base}_${FAILURE}`;
  }

  /**
   * 判断一个 request type 是否是 ws type
   * @param {string} base
   */
  static isWS(requestType) {
    return startsWith(requestType, PREFIX);
  }

  /**
   * 判断一个 request type 是否是请求中的 type
   * @param {string} base
   */
  static isRequest(requestType) {
    return WSType.isWS(requestType) && endsWith(requestType, REQUEST);
  }

  /**
   * 判断一个 request type 是否是成功的 type
   * @param {string} base
   */
  static isSuccess(requestType) {
    return WSType.isWS(requestType) && endsWith(requestType, SUCCESS);
  }

  /**
   * 判断一个 request type 是否是失败的 type
   * @param {string} base
   */
  static isFailure(requestType) {
    return WSType.isWS(requestType) && endsWith(requestType, FAILURE);
  }
}

/**
 * @typedef {Object} Meta
 * @property {string} event 对应 socket emit 的事件
 */
/**
 * @typedef {Object} Action
 * @property {string} type action type
 * @property {Object} payload action payload
 * @property {Meta} meta meta
 * @property {function} pre hook before calling socket emit
 * @property {function} post hook after calling socket emit
 */

export const PREFIX = "@@ws";
export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
