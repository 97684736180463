import { select } from "redux-saga/effects";
import { createApiActions } from "@36node/redux-api";
import { createXlsxActions } from "@36node/redux-xlsx";
import { sortBy, sumBy } from "lodash";
import moment from "moment";
import { mekong } from "../sdk";
import { FaultCategory } from "@36node-mekong/sdk-ts";

import * as cs from "../constants";

import { reportSelectors, globalSelectors } from "../selectors";
import {
  alertSchema,
  overallWarningStatSchema,
  overallTicketStatSchema,
  overallPowerStatSchema,
} from "../schemas";

// 里程和能耗统计

const getVehicleStats = createApiActions(cs.NS.REPORT.GET_VEHICLE_STATS, {
  endpoint: mekong.getSnapshotStatistics,
});

function* exportVehicleDataSource(params = {}) {
  const { groupKey, at_gt, at_lt } = params;
  const apiResult = yield select(reportSelectors.getVehicleStat);
  const nsMap = yield select(globalSelectors.namespaceMap);
  const span = (moment(at_lt).diff(moment(at_gt), "day") || 0) + 1;

  let records = apiResult.result || [];

  if (groupKey === "ns") {
    records = records.map(r => {
      const ns = nsMap[r.ns];

      if (ns) {
        r.groupKey = ns.pathname;
      }

      return r;
    });
  } else if (groupKey === "id" || groupKey === "line") {
    records = records.map(r => {
      const ns = nsMap[r.ns] || {};
      r.nsName = ns.pathname;
      r.groupKey = r[groupKey];
      return r;
    });
  } else if (groupKey === "producer") {
    records = records.map(r => {
      r.groupKey = r.manufacturer;
      return r;
    });
  } else {
    records = records.map(r => {
      r.groupKey = r[groupKey];
      return r;
    });
  }
  if (groupKey === "id") {
    return records;
  }
  let total = {
    id: "total",
    calculatedRecord: true,
    vehicleCount: sumBy(records, "vehicleCount"),
    vehicles: sumBy(records, "vehicles"),
    bold: true,
  };

  if (groupKey === "line") {
    total.nsName = "合计";
  } else {
    total.groupKey = "合计";
  }

  total.mileage = sumBy(records, "mileage");
  total.consumption = sumBy(records, "consumption");
  total.mileageVehicles = sumBy(records, "mileageVehicles");
  total.mileageAvg = total.mileage / total.mileageVehicles / span;
  total.consumptionAvg = (100 * total.consumption) / total.mileage;

  return records.concat(total);
}

const vehicleXlsx = createXlsxActions(cs.NS.REPORT.EXPORT_VEHICLE_STATS, {
  exportOpts: { dataSource: exportVehicleDataSource },
});

// 报警和异常统计
const getWarningsStatistics = createApiActions(cs.NS.REPORT.GET_WARNING_STATS, {
  endpoint: mekong.getWarningStatistics,
});

const getOverallWarningStats = createApiActions(
  cs.NS.REPORT.GET_OVERALL_WARNING_STATS,
  {
    endpoint: mekong.getWarningStatistics,
    schema: [overallWarningStatSchema],
  }
);

function* exportWarningDataSource(params = {}) {
  const { type, groupKey } = params;
  let mapRecord;
  let recordTotal;
  if (type === "alert") {
    mapRecord = reportSelectors.mapAlertRecord;
    recordTotal = reportSelectors.recordAlertTotal;
  } else if (type === "battery") {
    mapRecord = reportSelectors.mapBatteryWarningRecord;
    recordTotal = reportSelectors.recordBatteryWarningTotal;
  } else if (type === "insulation") {
    mapRecord = reportSelectors.mapInsulationWarningRecord;
    recordTotal = reportSelectors.recordInsulationWarningTotal;
  } else if (type === "tire") {
    mapRecord = reportSelectors.mapTireWarningRecord;
    recordTotal = reportSelectors.recordTireWarningTotal;
  } else if (type === "pile") {
    mapRecord = reportSelectors.mapPileWarningRecord;
    recordTotal = reportSelectors.recordPileWarningTotal;
  }

  const apiResult = yield select(reportSelectors.getWarningsStatistics);
  const data = apiResult.result;

  const treeData = reportSelectors.dataMap(sortBy(data, ["group"]), {
    groupKey,
    mapRecord,
    recordTotal: records => recordTotal(records, groupKey),
    linear: true,
  });
  const totalData = recordTotal(treeData, groupKey);
  return treeData.concat(totalData);
}

const warningXlsx = createXlsxActions(cs.NS.REPORT.GET_OVERALL_WARNING_STATS, {
  exportOpts: {
    dataSource: exportWarningDataSource,
  },
});

const warningOverallXlsx = createXlsxActions(
  cs.NS.REPORT.EXPORT_WARNING_OVERALL_STATS,
  {
    exportOpts: {
      dataSource: function*() {
        const { result = [], request = {} } = yield select(
          reportSelectors.getOverallWarningsStats
        );

        if (request.category === FaultCategory.VEHICLE) {
          return [
            ...result,
            {
              ns: "合计",
              calculatedRecord: true,
              vehicleCount: sumBy(result, "vehicleCount"),
              level1: sumBy(result, "level1"),
              level2: sumBy(result, "level2"),
              level3: sumBy(result, "level3"),
            },
          ];
        } else {
          let nameKeys = [];
          if (request.type === "BATTERY_WARNING")
            nameKeys = Object.values(cs.BatteryExceptionI18N);
          else if (request.type === "RESISTANCE_WARNING")
            nameKeys = Object.values(cs.InsulationExceptionI18N);
          else if (request.type === "TYRE_WARNING")
            nameKeys = Object.values(cs.TyreExceptionI18N);

          let total = {
            ns: "合计",
            calculatedRecord: true,
            vehicleCount: sumBy(result, "vehicleCount"),
          };
          nameKeys.forEach(key => {
            total[key] = sumBy(result, key);
          });
          return [...result, total];
        }
      },
    },
  }
);

const listTableAlerts = createApiActions(cs.NS.REPORT.LIST_TABLE_ALERTS, {
  schema: [alertSchema],
  endpoint: mekong.listDeviceFaultRecords,
}); // 实时报警列表查询alerts

// 工单统计
const getTicketsStatistics = createApiActions(cs.NS.REPORT.GET_TICKET_STATS, {
  endpoint: mekong.getTicketStatistics,
});

function* exportTicketDataSource(params = {}) {
  const { groupKey } = params;
  let mapRecord = reportSelectors.mapTicketRecord;
  let recordTotal = reportSelectors.recordTicketTotal;

  const apiResult = yield select(reportSelectors.getTicketsStatistics);
  const data = apiResult.result;

  const treeData = reportSelectors.dataMap(sortBy(data, ["group"]), {
    groupKey,
    mapRecord,
    recordTotal: records => recordTotal(records, groupKey),
    linear: true,
  });
  const totalData = recordTotal(treeData, groupKey);
  return treeData.concat(totalData);
}

// 工单导出
const ticketXlsx = createXlsxActions(cs.NS.REPORT.GET_OVERALL_TICKET_STATS, {
  exportOpts: {
    dataSource: exportTicketDataSource,
  },
});

const getOverallTicketStats = createApiActions(
  cs.NS.REPORT.GET_OVERALL_TICKET_STATS,
  {
    endpoint: mekong.getTicketStatistics,
    schema: [overallTicketStatSchema],
  }
);

const ticketOverallXlsx = createXlsxActions(
  cs.NS.REPORT.EXPORT_TICKET_OVERALL_STATS,
  {
    exportOpts: {
      dataSource: function*() {
        const { result = [] } = yield select(
          reportSelectors.getOverallTicketStats
        );

        let total = {
          ns: "合计",
          calculatedRecord: true,
          vehicleCount: sumBy(result, "vehicleCount"),
        };

        let keys = [];
        if (result && result.length) {
          const { id, line, model, ns, vehicleCount, ...rest } = result[0];
          keys = Object.keys(rest);
        }
        keys.forEach(k => {
          total[k] = sumBy(result, k);
        });

        return [...result, total];
      },
    },
  }
);

const getOverallPowerStats = createApiActions(
  cs.NS.REPORT.GET_OVERALL_POWER_STATS,
  {
    endpoint: mekong.getSnapshotStatistics,
    schema: [overallPowerStatSchema],
  }
);

const powerOverallXlsx = createXlsxActions(
  cs.NS.REPORT.EXPORT_POWER_OVERALL_STATS,
  {
    exportOpts: {
      dataSource: function*() {
        const { result = [], request = {} } = yield select(
          reportSelectors.getOverallPowerStats
        );

        const filterData = result.filter(r => r.ns && r.line && r.model);
        const formatRet = filterData.map(r => ({
          ...r,
          mileage: r.mileage.toFixed(2),
          consumption: r.consumption.toFixed(2),
          mileageAvg: r.mileageAvg.toFixed(2),
          consumptionAvg: r.consumptionAvg.toFixed(2),
        }));

        let total = {
          ns: "合计",
          calculatedRecord: true,
          vehicleCount: sumBy(filterData, "vehicleCount"),
        };

        total.mileage = sumBy(filterData, "mileage").toFixed(2) || 0;
        total.consumption = sumBy(filterData, "consumption").toFixed(2);

        const span =
          moment(request.at_lte).diff(moment(request.at_gte), "day") + 1;
        const vehicles = sumBy(filterData, "mileageVehicles") || 0;
        total.mileageAvg =
          vehicles > 0 ? (total.mileage / vehicles / span).toFixed(2) : 0;
        total.consumptionAvg =
          total.mileage > 0
            ? ((100 * total.consumption) / total.mileage).toFixed(2)
            : 0;

        return [...formatRet, total];
      },
    },
  }
);

// 充电报表
const getChargeOrderAgg = createApiActions(cs.NS.REPORT.GET_CHARGE_ORDER_AGG, {
  endpoint: mekong.aggregateOrders,
});

function* exportChargeOrderDataSource(params = {}) {
  const { groupName } = params;
  const { data } = yield select(state =>
    reportSelectors.selectChargeOrderAgg(state, params)
  );
  const totalData = reportSelectors.genChargeOrderTotalRecord(groupName, data);
  return [...data, totalData];
}
const chargeOrderXlsx = createXlsxActions(cs.NS.REPORT.GET_CHARGE_ORDER_AGG, {
  exportOpts: {
    dataSource: exportChargeOrderDataSource,
  },
});

const reportActions = {
  getVehicleStats,
  vehicleXlsx,
  warningOverallXlsx,
  getWarningsStatistics,
  getOverallWarningStats,
  warningXlsx,
  listTableAlerts,
  getTicketsStatistics,
  ticketXlsx,
  getOverallTicketStats,
  ticketOverallXlsx,
  getOverallPowerStats,
  powerOverallXlsx,
  getChargeOrderAgg,
  chargeOrderXlsx,
};

export default reportActions;
