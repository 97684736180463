/**
 * 工单相关saga
 */
import {
  all,
  fork,
  take,
  put,
  select,
  takeEvery,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  successOf,
  failureOf,
  isSuccess,
  createApiSelector,
  requestOf,
} from "@36node/redux-api";
import { message } from "antd";
import { uniq, get, isEmpty, values, startsWith, endsWith } from "lodash";
import { createSelector } from "reselect";
import TicketActions from "../actions/tickets";
import TicketSelectors from "../selectors/tickets";
import { history, mergeTicketEvents, ymdhms } from "../lib";
import { globalActions, monitorActions } from "../actions";
import { mekong } from "../sdk";
import { ticketSchema } from "../schemas";
import { TICKETS_NS, TicketState } from "../constants";
import { createXlsxActions } from "@36node/redux-xlsx";

function* handleApiResult(
  base,
  successMsg,
  failureMsg,
  hideLoading,
  afterSuccess //成功后的action
) {
  const action = yield take([successOf(base), failureOf(base)]);

  if (hideLoading) {
    hideLoading();
  }

  if (isSuccess(action)) {
    if (successMsg) {
      message.success(successMsg);
    }

    if (afterSuccess) {
      yield call(afterSuccess);
    }

    return true;
  } else {
    message.error(failureMsg);
    return false;
  }
}

function* handleIgnoreAlert(action) {
  const { payload = {} } = action;

  const { request, nextAction } = payload;

  const hide = message.loading("报警处置中...", 0);
  if (request) {
    yield put(TicketActions.updateAlarm.request(request));
  }

  // 处理更新结果
  const success = yield call(
    handleApiResult,
    TICKETS_NS.UPDATE_ALERT,
    "报警处置成功",
    "报警处置失败",
    hide
  );

  // 重新获取summary
  yield put(monitorActions.getAlertSummary.refresh());

  // 重新获取实时监控的报警数据
  yield put(monitorActions.listTableAlerts.refresh());

  if (success && nextAction) {
    yield put(nextAction);
  }
}

const getTicketSelector = createApiSelector(TICKETS_NS.GET_TICKET);

function* watchGetTicketsSuccess() {
  while (true) {
    yield take(successOf(TICKETS_NS.GET_TICKET));

    const getResp = yield select(getTicketSelector);

    const tickets = yield select(state => state.entities.tickets);

    const ticket = tickets[getResp.result];

    // 获取相关资源
    if (ticket) {
      // 获取createBy
      yield put(TicketActions.getUser.request({ userId: ticket.createdBy }));
      // 获取alerts
      if (ticket.alerts) {
        for (const alertId of ticket.alerts) {
          yield put(TicketActions.getAlert.request({ alertId }));
        }
      }

      // 获取ticket event 的 相关user
      if (ticket.events && ticket.events.length > 0) {
        const ticketEvents = yield select(state => state.entities.ticketEvents);

        const events = ticket.events.map(e => ticketEvents[e]);

        yield put(
          TicketActions.listEventsUsers.request({
            query: {
              filter: {
                id: uniq(events.map(e => e.createdBy).filter(u => u)),
              },
            },
          })
        );
      }

      // 初始化 handle form
      yield put(
        TicketActions.handleForm.reset({
          stage: ticket.stage,
        })
      );
    }
  }
}

/**
 * 代理 listTickets， 增加全局搜索
 */
function* handleFetchTickets(action) {
  const { query = {} } = action.payload;

  // 组合全局搜索
  const ticketQuery = yield select(TicketSelectors.ticketQuery) || {};

  const { assign = {} } = ticketQuery;

  yield put(
    TicketActions.listTickets.request({
      ...query,
      ...assign,
    })
  );
}

function* watchStageEvent() {
  while (true) {
    const action = yield take(requestOf(TICKETS_NS.STAGE_EVENT));

    const ticketId = get(action, "payload.ticketId");

    const meta = action.meta || {};

    const { successMsg, showLoading = true } = meta;

    let hide;

    if (showLoading) {
      hide = message.loading("操作进行中...", 0);
    }

    yield call(
      handleApiResult,
      TICKETS_NS.STAGE_EVENT,
      successMsg,
      "操作失败",
      hide,
      function*() {
        yield put(TicketActions.getTicket.request({ ticketId }));
      }
    );
  }
}

function* watchCommentEvent() {
  while (true) {
    const action = yield take(requestOf(TICKETS_NS.COMMENT_EVENT));

    const ticketId = get(action, "payload.ticketId");

    const meta = action.meta || {};

    const { successMsg } = meta;

    yield call(
      handleApiResult,
      TICKETS_NS.COMMENT_EVENT,
      successMsg,
      "操作失败",
      null,
      function*() {
        yield put(TicketActions.getTicket.request({ ticketId }));
      }
    );
  }
}

function* watchBindEvent() {
  while (true) {
    const action = yield take(requestOf(TICKETS_NS.BIND_EVENT));
    const meta = action.meta || {};
    const { successMsg } = meta;

    const { payload = {} } = action;

    const { ticketId } = payload;

    yield call(
      handleApiResult,
      TICKETS_NS.BIND_EVENT,
      successMsg,
      "操作失败",
      null,
      function() {
        // 操作成功 跳转到工单详情页面

        if (ticketId) {
          history.replace(`/ticket/${ticketId}/detail`);
        }
      }
    );
  }
}

function* watchCreateTicket() {
  while (true) {
    const action = yield take(requestOf(TICKETS_NS.CREATE_TICKET));
    const meta = action.meta || {};
    const { successMsg } = meta;

    yield call(
      handleApiResult,
      TICKETS_NS.CREATE_TICKET,
      successMsg,
      "工单创建失败",
      null,
      function*() {
        // 操作成功 返回
        const createRep = yield select(TicketSelectors.createTicket);
        const ticket = createRep.result || {};

        history.replace(`/ticket/${ticket.id}/detail`);

        // 重新获取summary
        // yield put(monitorActions.getAlertSummary.refresh());
      }
    );
  }
}

function* handleInitBindAlert(action) {
  const { payload = {} } = action;
  const { vehicleId, alertId } = payload;

  // 获取 tickets
  yield put(
    TicketActions.listTickets.request({
      query: {
        filter: {
          vehicle: vehicleId,
          state: TicketState.OPEN,
        },
      },
    })
  );

  const resultAction = yield take([
    successOf(TICKETS_NS.LIST_TICKETS),
    failureOf(TICKETS_NS.LIST_TICKETS),
  ]);

  // 获取成功
  if (isSuccess(resultAction)) {
    const result = resultAction.payload.result || [];

    if (result.length === 0) {
      //没有结果 跳转到 create中
      message.info("当前车辆暂无其他工单，已转到创建工单界面");
      return history.replace("/ticket/create?alertId=" + alertId);
    } else {
      const tickets = result.map(r => resultAction.payload.entities.tickets[r]);

      const alerts = [];

      tickets.forEach(t => {
        alerts.push(...t.alerts);
      });

      // 获取工单绑定的所有 alerts
      yield put(
        TicketActions.listAlerts.request({
          query: {
            filter: {
              id: alerts,
            },
          },
        })
      );
    }
  } else {
    message.error("工单获取失败");
  }
}

function* handleInitCreateTicket(action) {
  const { payload = {} } = action;
  const { alertId } = payload;

  // 获取alert
  yield put(TicketActions.getAlert.request({ faultRecord: alertId }));

  yield call(
    handleApiResult,
    TICKETS_NS.GET_ALERT,
    undefined,
    "报警获取失败",
    null,
    function*() {}
  );
}

function* onListStagesSuccess() {
  const stages = yield select(state => state.entities.ticketStages);
  const stageIds = Object.keys(stages);
  if (!isEmpty(stageIds)) {
    stageIds.forEach(function(s) {
      if (!TicketSelectors.listStageTickets[s]) {
        TicketSelectors.listStageTickets[s] = createApiSelector(
          `${TICKETS_NS.LIST_STAGE_TICKETS}_${s}`,
          [ticketSchema]
        );
      }
      if (!TicketActions.listStageTickets[s]) {
      }

      const xlsxKey = `${TICKETS_NS.LIST_STAGE_TICKETS_EXPORT}_${s}`;

      if (!TicketSelectors.listStageTicketsForExport[s]) {
        TicketSelectors.listStageTicketsForExport[s] = createSelector(
          createApiSelector(xlsxKey, [ticketSchema]),
          listState => {
            const { result = [] } = listState;

            const newResult = result.map(ticket => {
              const { events = [] } = ticket;
              const merged = mergeTicketEvents(events);

              const createEvent = merged.find(
                e => e.name === "MERGED_CREATE"
              ) || {
                handleBy: "--",
              };

              // 创建者
              const creator = createEvent.handleBy;
              const vehicleState = createEvent.content;

              // 维修中
              const repairingEvent = merged.find(
                e => get(e, "to.name") === "维修中"
              ) || {
                handleBy: "",
              };
              // 维修人
              const repairer = repairingEvent.handleBy;
              // 维修时间
              const repairedAt = repairingEvent.handleAt
                ? ymdhms(repairingEvent.handleAt)
                : "";
              // 故障原因
              const faultReason = repairingEvent.content;

              // 已完成
              const finishedEvent = merged.find(
                e => get(e, "to.name") === "已完成"
              ) || {
                handleBy: "",
              };

              // 检验人
              const surveyor = finishedEvent.handleBy;
              // 完成时间
              const finishedAt = finishedEvent.handleAt
                ? ymdhms(finishedEvent.handleAt)
                : "";
              // 维修内容
              const repairedContent = finishedEvent.content;
              // 维修结果
              const repairResult = finishedEvent.handleResult;

              return {
                ...ticket,
                creator,
                repairer,
                repairedAt,
                surveyor,
                finishedAt,
                faultReason,
                repairedContent,
                repairResult,
                vehicleState,
              };
            });

            return {
              ...listState,
              result: newResult,
            };
          }
        );
      }

      if (!TicketActions.listStageTicketsForExport[s]) {
      }

      if (!TicketActions.ticketsXlsx[s]) {
        TicketActions.ticketsXlsx[s] = createXlsxActions(xlsxKey, {
          exportOpts: {
            dataSource: function*(params = {}) {
              const { query } = params;
              const apiRequest =
                TicketActions.listStageTicketsForExport[s].request;

              yield put(apiRequest({ query }));
              const apiResultAction = yield take(successOf(xlsxKey));

              // 获取报警
              if (isSuccess(apiResultAction)) {
                const tickets = values(
                  get(apiResultAction, "payload.entities.tickets", {})
                );

                const alerts = tickets.reduce((acc, cur) => {
                  acc.push(...cur.alerts);
                  return acc;
                }, []);

                if (!isEmpty(alerts)) {
                  yield put(
                    TicketActions.listAlertsForExports.request({
                      body: { id: alerts },
                    })
                  );
                }

                // 等待获取成功
                yield take(successOf(TICKETS_NS.LIST_ALERTS_FOR_EXPORTS));

                const apiResult = yield select(
                  TicketSelectors.listStageTicketsForExport[s]
                );

                return apiResult.result;
              } else {
                message.error("异常数据导出失败");
                return;
              }
            },
          },
        });
      }
    });
  }
}

function* handleStageTicketsSuccess(action) {
  const result = action.payload.result || [];
  const tickets = result.map(r => action.payload.entities.tickets[r]);

  const alerts = [];

  tickets.forEach(t => {
    alerts.push(...t.alerts);
  });

  // 获取工单绑定的所有 alerts
  yield put(
    TicketActions.listAlerts.request({
      query: {
        filter: {
          id: alerts,
        },
      },
    })
  );
}

function* onTicketCreateSuccess(action) {
  const ticket = action.payload.entities.tickets[action.payload.result];
  const faults = get(ticket, "relatedFaults", []);

  // 更新故障的工单
  for (const faultId of faults) {
    yield call(mekong.updateDeviceFaultRecord, {
      faultRecord: faultId,
      body: {
        mute: true,
      },
    });
  }

  // 更新顶部的报警统计
  yield put(globalActions.getAlertSummary.refresh());
}

export default function* watchTickets() {
  yield all([
    takeEvery(TICKETS_NS.IGNORE_ALERT, handleIgnoreAlert),
    takeLatest(TICKETS_NS.FETCH_TICKETS, handleFetchTickets),
    takeLatest(
      ({ type }) =>
        startsWith(type, "@API/TICKETS.LIST_STAGE_TICKETS_") &&
        endsWith(type, "SUCCESS") &&
        type.indexOf("EXPORT") === -1,
      handleStageTicketsSuccess
    ),
    takeEvery(TICKETS_NS.INIT_BIND_ALERT, handleInitBindAlert),
    takeEvery(TICKETS_NS.INIT_CREATE_TICKET, handleInitCreateTicket),
    takeEvery(successOf(TICKETS_NS.LIST_STATGES), onListStagesSuccess),
    takeEvery(successOf(TICKETS_NS.CREATE_TICKET), onTicketCreateSuccess),
    // fork(watchListTicketsSuccess),
    fork(watchStageEvent),
    fork(watchCommentEvent),
    fork(watchBindEvent),
    // fork(watchTicketQueryChange),
    fork(watchGetTicketsSuccess),
    fork(watchCreateTicket),
  ]);
}
