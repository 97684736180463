import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import ReactEcharts from "echarts-for-react";
import RankTable from "./rank-table";

const Box = styled(Row)`
  width: 100%;
  height: 100%;

  .ant-table-thead {
    th {
      padding: 10px 16px;
    }
  }

  .ant-table-tbody {
    td {
      padding: 10px 16px;
    }
  }
`;

export default class AlertChartByModel extends PureComponent {
  getOption = (data = []) => {
    const xAxisData = data.map(d => (d.model ? d.model.substr(0, 3) : d.model));
    const finalData = data.map(d => ({
      name: d.model,
      value: d.faultCount,
    }));

    return {
      color: ["#1890FF"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      grid: {
        left: 24,
        right: 24,
        top: 24,
        bottom: 10,
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: xAxisData,
          axisTick: {
            interval: 0,
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: "#BFBFBF",
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          boundaryGap: false,
          axisTick: { show: false },
          axisLine: { show: false },
          minInterval: 1,
          splitLine: {
            lineStyle: {
              color: "#cccccc",
              type: "dotted",
            },
          },
        },
      ],
      series: [
        {
          name: "报警数",
          type: "bar",
          barWidth: "60%",
          data: finalData,
        },
      ],
    };
  };

  render() {
    const { data = [] } = this.props;

    const columns = [
      {
        title: "车型",
        dataIndex: "model",
      },
      {
        title: "车辆数",
        dataIndex: "vehicleCount",
      },
      {
        title: "报警数",
        dataIndex: "faultCount",
      },
      {
        title: "万公里故障率",
        key: "alertMileageRate",
        render: (_, record) =>
          ((record.faultCount * 1000000) / record.mileage).toFixed(2) + "%",
      },
      {
        title: "总里程(万公里)",
        dataIndex: "mileage",
        render: val => (Number(val) / 10000).toFixed(1),
      },
    ];

    return (
      <Box type="flex" align="top">
        <Col span={12}>
          <ReactEcharts style={{ height: 480 }} option={this.getOption(data)} />
        </Col>
        <Col span={12}>
          <RankTable columns={columns} data={data} scroll={{ y: 420 }} />
        </Col>
      </Box>
    );
  }
}
