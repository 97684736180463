import { createApiSelector } from "@36node/redux-api";
import { createAssignSelector } from "@36node/redux-ui";
import { createToggleSelector } from "@36node/redux-ui";
import { get } from "lodash";

import { NS } from "../constants";
import {
  userSchema,
  templateSchema,
  namespacesSchema,
  ticketSchema,
  ticketStageSchema,
  operationSchema,
} from "../schemas";
import { makeArchivesSelectors } from "./archives";
import * as stationSelectors from "./station";

export const selectSession = state =>
  state.apis.session && state.apis.session.result;
export const selectRedirect = state => state.session.meta.redirect;

export const systemSelectors = {
  createNamespace: createApiSelector(
    NS.SYSTEM.CREATE_NAMESPACE,
    namespacesSchema
  ),
  getNamespace: createApiSelector(NS.SYSTEM.GET_NAMESPACE, namespacesSchema),
  updateNamespace: createApiSelector(
    NS.SYSTEM.UPDATE_NAMESPACE,
    namespacesSchema
  ),
  deleteNamespace: createApiSelector(NS.SYSTEM.DELETE_NAMESPACE),
  listUsers: createApiSelector(NS.SYSTEM.LIST_USERS, [userSchema]),
  listChargerOperators: createApiSelector(NS.SYSTEM.LIST_CHARGER_OPERATORS, [
    userSchema,
  ]),
  createUser: createApiSelector(NS.SYSTEM.CREATE_USER, userSchema),
  getUser: createApiSelector(NS.SYSTEM.GET_USER, userSchema),
  updateUser: createApiSelector(NS.SYSTEM.UPDATE_USER, userSchema),
  deleteUser: createApiSelector(NS.SYSTEM.DELETE_USER),
  listRealtimeLogs: createApiSelector(NS.SYSTEM.LIST_REALTIME_LOGS),
  listHistoryLogs: createApiSelector(NS.SYSTEM.LIST_HISTORY_LOGS),
  listTemplates: createApiSelector(NS.SYSTEM.LIST_TEMPLATES, [templateSchema]),
  createTemplate: createApiSelector(NS.SYSTEM.CREATE_TEMPLATE, templateSchema),
  getTemplate: createApiSelector(NS.SYSTEM.GET_TEMPLATE, templateSchema),
  updateTemplate: createApiSelector(NS.SYSTEM.UPDATE_TEMPLATE, templateSchema),
  deleteTemplate: createApiSelector(NS.SYSTEM.DELETE_TEMPLATE),
  listOperations: createApiSelector(NS.SYSTEM.LIST_OPERATIONS, [
    operationSchema,
  ]),
};

export const keywordAssignSelector = createAssignSelector("TREE_KEYWORD");
export const toggleSelector = createToggleSelector("MONITOR_FOCUS_SEARCH");

export const archivesSelectors = makeArchivesSelectors();

export const vehicleSelectors = {
  listTickets: createApiSelector(NS.VEHICLE.LIST_TICKTS, [ticketSchema]),
  listStages: createApiSelector(NS.VEHICLE.LIST_STAGES, [ticketStageSchema]),
  getStatistics: createApiSelector(NS.VEHICLE.GET_STATISTICS),
  listStateHistory: createApiSelector(NS.VEHICLE.GET_STATE_HISTORY),
  listStateHistoryXLSX: createApiSelector(NS.VEHICLE.STATE_HISTORY_XLSX),
  getStatisticsCron: state => get(state, "cron.vehicle.getStatisticsCron"),
};

export { default as globalSelectors } from "./global";
export { default as reportSelectors } from "./report";
export { default as monitorSelectors } from "./monitor";
export { default as AnalysisSelectors } from "./analysis";
export { default as TicketSelectors } from "./tickets";
export { stationSelectors };
