import React, { Component } from "react";
import { connect } from "react-redux";
import { monitorActions } from "../../actions";

export default function resetMap(WrapComponent) {
  class ResetMap extends Component {
    componentWillMount() {
      this.props.dispatch(monitorActions.setSelectedVehicle.set(""));
    }

    render() {
      return <WrapComponent />;
    }
  }

  return connect()(ResetMap);
}
