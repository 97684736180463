import { HISTORY_NS } from "../../constants";
import pileHistorySelectors from "../../selectors/history/pile";
import makeHistoryActions from "./base";

const pileHistoryActions = makeHistoryActions(
  HISTORY_NS.PILE.LIST_WARNINGS,
  HISTORY_NS.PILE.WARNINGS_XLSX,
  pileHistorySelectors
);

export default pileHistoryActions;
