import { all, put, takeEvery } from "redux-saga/effects";
import { successOf } from "@36node/redux-api";
import { NS } from "../constants";
import { vehicleActions } from "../actions";
import { get } from "lodash";

function* watchListTicketsSuccess(action = {}) {
  const { payload = {} } = action;

  const tickets = get(payload, ["entities", "tickets"]);

  if (!tickets) return;

  const users = Object.values(tickets)
    .map(t => t.createdBy)
    .filter(u => Boolean(u));

  if (users.length > 0) {
    // 获取用户
    yield put(
      vehicleActions.listUsers.request({
        query: {
          filter: {
            id: users,
          },
        },
      })
    );
  }
}

export default function* watchVehicle() {
  yield all([
    takeEvery(successOf(NS.VEHICLE.LIST_TICKTS), watchListTicketsSuccess),
  ]);
}
