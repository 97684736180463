import {
  isArray,
  isObjectLike,
  isPlainObject,
  map,
  transform,
  set,
  camelCase,
  isEmpty,
  mergeWith,
  sumBy,
  get,
  trimStart,
  isNil,
} from "lodash";
import moment from "moment";

import history from "./history";
import { VehicleTermState, TicketEvents } from "../constants";

export { history };

/**
 * add rounding function to Number class
 * @param {Number} number 需要定义精度的数
 * @param {Number} precision 精度，例如 0.1
 */
export function round(number, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(number * multiplier) / multiplier;
}

export const suffix = (suffix, placeholder = "--") => val => {
  if (isNil(val)) return placeholder;
  return `${val}${suffix}`;
};

/**
 * A delay promise
 * @param {Number} ms delay miliseconds
 */
export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Format time
 * @param {Date} date
 */
export function ymdhms(date, placeholder = "--") {
  if (date) return moment(date).format("YYYY-MM-DD HH:mm:ss");
  else return placeholder;
}

export function ymd(date) {
  return moment(date).format("YYYY-MM-DD");
}

/**
 * Combine date and time
 * @param {Date} date
 * @param {Date} time
 */
export function combine(date, time) {
  const m = moment(date);
  m.hour(time.hour());
  m.minute(time.minute());
  m.second(time.second());
  return m;
}

function createIteratee(converter, self) {
  return (result, value, key) =>
    set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

function createHumps(keyConverter) {
  return function humps(node) {
    if (isArray(node)) return map(node, humps);
    if (isPlainObject(node))
      return transform(node, createIteratee(keyConverter, humps));
    return node;
  };
}

/**
 * Make object keys camelcase
 */
export const humps = createHumps(camelCase);

/**
 * try to parse json string
 * if error log it
 *
 * @param {string} jsonStr string tobe parsed
 */
export function tryParseJson(jsonStr) {
  let result;
  try {
    if (jsonStr) result = JSON.parse(jsonStr);
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
}

// 千分位数字
export function toThousands(num) {
  return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
}

export function sayHello() {
  const now = new Date();
  const hour = now.getHours();

  let hello = "";
  if (hour < 6) {
    hello = "凌晨好!";
  } else if (hour < 9) {
    hello = "早上好!";
  } else if (hour < 12) {
    hello = "上午好!";
  } else if (hour < 14) {
    hello = "中午好!";
  } else if (hour < 17) {
    hello = "下午好!";
  } else if (hour < 19) {
    hello = "傍晚好!";
  } else if (hour < 22) {
    hello = "晚上好!";
  } else {
    hello = "夜里好!";
  }

  return hello;
}

export function parseSorter(sorter) {
  if (sorter && !isEmpty(sorter)) {
    return sorter.order === "descend" ? `-${sorter.field}` : `${sorter.field}`;
  }
  return "";
}

export function listToTree(arr) {
  // 构造一个新数组，防止改变原有数组
  const res = arr.map(item => ({
    ...item,
    children: [],
    key: item.id,
    value: item.id,
    title: item.name,
  }));
  const m = res.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});
  res.forEach(item => {
    if (!item.parent) return;
    const p = m[item.parent];
    if (!p) return;
    p.children.push(item);
    item.isChild = true;
  });
  return res.filter(item => !item.isChild);
}

/**
 * 合并query， 如果source 中有 undefined， 则也强制合并到 query中
 */
export function mergeQuery(query, source) {
  return mergeWith(query, source, (objValue, sourceValue) => {
    return sourceValue;
  });
}

export const withCentigrade = val => val + "℃";

export const withOhm = val => val + "KΩ";

export const withHpa = val => val + "hPa";

export const withKpa = val => val + "kPa";

/**
 * 对一个数组进行聚合
 * @param {[object]} records
 * @param {object} aggs
 */
export const aggregation = (records = [], aggs = {}) => {
  const ret = {};

  for (const m of Object.keys(aggs)) {
    switch (aggs[m]) {
      case "sum":
        ret[m] = sumBy(records, m);
        break;
      case "avg":
        ret[m] = sumBy(records, m) / records.length;
        break;
      default:
        break;
    }
  }

  return ret;
};

export const dataSourceWithTotal = (
  records = [],
  aggs = {},
  key = "groupKey"
) => {
  const totals = aggregation(records, aggs);

  totals[key] = "合计";
  totals["id"] = "total";
  totals["bold"] = true;

  return [...records, totals];
};

export const vehicleStatusColor = data => {
  const ALARM_COLOR = {
    1: "#FAAD14",
    2: "#FF7A45",
    3: "#F5222D",
  };
  const STATUS_COLOR = {
    ONLINE: "#52C41A",
    OFFLINE: "#919191",
    UNKNOWN: "#919191",
    CHARGING: "#13C2C2",
    REPAIRING: "#D46B08",
  };
  let color = STATUS_COLOR.ONLINE;
  if (data.repairing) {
    color = STATUS_COLOR.REPAIRING;
  } else if (data.level) {
    color = ALARM_COLOR[data.level];
  } else if (get(data, "vehicle.chargeStatus") === "PARK_CHARGING") {
    color = STATUS_COLOR.CHARGING;
  } else if (!data.online) {
    color = STATUS_COLOR.OFFLINE;
  } else if (!data.onsite) {
    color = STATUS_COLOR.UNKNOWN;
  }
  return color;
};

export const vehicleStatusText = data => {
  if (data.repairing) {
    return "维修中";
  } else if (data.level) {
    return data.level + "级报警";
  } else if (get(data, "vehicle.chargeStatus") === "PARK_CHARGING") {
    return "充电中";
  } else if (!data.online) {
    return "停驶中";
  }

  return "运行中";
};

const nsPathname = ns => {
  if (ns.parent) {
    const parent = nsPathname(ns.parent);
    if (parent === "") {
      return ns.name;
    } else {
      return parent + "/" + ns.name;
    }
  } else {
    return "";
  }
};

export const getPathname = ns => {
  if (!ns) return "--";

  if (!ns.parent) return ns.name;

  return nsPathname(ns);
};

export const getParentPathname = ns => {
  if (!ns) return "--";
  if (!ns.parent) return "--";
  return nsPathname(ns.parent);
};

export const findNs = (ns = "", tree) => {
  const find = tree.find(v => ns.startsWith(v.key));
  if (find) {
    if (find.children && find.children.length) return findNs(ns, find.children);
    return find.pathname;
  }
  return "";
};

/**
 * 计算车辆终端状态
 * @param {object} vehicle
 */
export const calcVehicleTermState = (vehicle = {}) => {
  const { onsite, at, departAt } = vehicle;

  if (!onsite || !at) return VehicleTermState.UNKNOWN;

  const tenMinAgo = moment().subtract(10, "minute");

  if (moment(at).isBefore(tenMinAgo)) {
    // 无最近一次路单出发时刻 ||
    // 离线时刻晚于最近一次的路单出发时刻
    // 判断为离线
    if (!departAt || moment(departAt).isBefore(moment(at))) {
      return VehicleTermState.OFFLINE;
    }

    // 离线时刻早于最近路单出发时刻
    // 判断为异常
    if (moment(departAt).isAfter(moment(at))) {
      return VehicleTermState.ABNORMAL;
    }
  }

  return VehicleTermState.ONLINE;
};

/**
 * 计算车辆运营状态
 * @param {*} vehicleState
 */
export const calcVehicleRunState = (vehicleState = {}) => {
  const status = get(vehicleState, "status");
  return status;
};

/**
 * 将list api 的返回转换为table 所需要的数据结构
 * @param {*} state
 */
export function apiStateToTable(state, opts = {}) {
  const { defaultLimit = 10, defaultOffset = 0 } = opts;

  const docs = state.result || [];
  const loading = state.loading;
  const query = state.request.query || {};
  const total = state.total;
  const { limit = defaultLimit, offset = defaultOffset } = query;

  const pagination = {
    current: Math.floor(offset / limit + 1),
    pageSize: limit,
    total,
  };

  return {
    docs,
    loading,
    query,
    total,
    pagination,
  };
}

/**
 *  table state to query
 * @param {*} pagination
 * @param {*} filters
 * @param {*} sort
 * @param {*} defaultPageSize
 * @param {*} defaultPage
 */
export function tableStateToQuery(
  pagination = {},
  filters = {},
  sort = {},
  mergeFilter = true,
  defaultPageSize = 10,
  defaultPage = 1
) {
  const query = {};

  if (!isEmpty(pagination)) {
    const { current = defaultPage, pageSize = defaultPageSize } = pagination;
    query._limit = pageSize;
    query._offset = (current - 1) * pageSize;
  }

  if (!isEmpty(filters)) {
    if (mergeFilter) {
      Object.keys(filters).forEach(k => {
        query[k] = filters[k];
      });
    } else {
      query.filter = { ...filters };
    }
  }

  if (!isEmpty(sort)) {
    const { field, order } = sort;
    query._sort = order ? (order === "ascend" ? "" : "-") + field : undefined;
  }

  return query;
}

export function tableStateToMekongQuery(
  pagination = {},
  filters = {},
  sort = {},
  mergeFilter = true,
  defaultPageSize = 10,
  defaultPage = 1
) {
  const query = {};

  if (!isEmpty(pagination)) {
    const { current = defaultPage, pageSize = defaultPageSize } = pagination;
    query._limit = pageSize;
    query._offset = (current - 1) * pageSize;
  }

  if (!isEmpty(filters)) {
    if (mergeFilter) {
      Object.keys(filters).forEach(k => {
        query[k] = filters[k];
      });
    } else {
      query._filter = { ...filters };
    }
  }

  if (!isEmpty(sort)) {
    const { field, order } = sort;
    query._sort = (order === "ascend" ? "" : "-") + field;
  }

  return query;
}

/**
 * 扁平化ns
 * @param {*} ns
 */
export const flattenNs = ns => {
  const splited = trimStart(ns, "/").split("/");

  const flatted = [];
  splited.reduce((acc, cur) => {
    flatted.push(`/${acc.join("/")}${acc.length ? "/" : ""}${cur}`);
    acc.push(cur);
    return acc;
  }, []);

  return flatted;
};

export const chargeRecordStartAtDefaultRange = () => {
  const yesterday = ymd(moment().subtract(1, "day"));
  return [moment(yesterday).startOf("day"), moment(yesterday).endOf("day")];
};

// 格式化单车详情中里程/能耗数据
export const convertVehicleData = value => {
  // 在能耗数据中，mileage字段与value字段值相同
  const d = value.filter(item => item.mileage > 0);
  let data = new Array(12).fill(null).map(() => new Array(31).fill(null));
  if (d.length) {
    d.forEach(item => {
      const month = moment(item.at).format("MM") - 1;
      const day = moment(item.at).format("DD") - 1;
      data[month][day] = { value: parseInt(item.mileage, 10) };
    });
  }
  return data;
};

// 生成单车详情中里程/能耗导出数据
export const getVehicleExportData = data => {
  return data.map((value, i) => {
    let ret = {};
    ret.name = `${i + 1}月`;
    value.forEach((day, j) => {
      if (day) ret[`${j + 1}`] = day.value;
    });
    return ret;
  });
};

// 将 时长(秒) 转换为 时分秒
export const formatSec = val =>
  moment()
    .startOf("day")
    .add(val, "second")
    .format("HH:mm:ss");

/**
 * 合并相关event
 * @param {*} events
 */
export function mergeTicketEvents(events = []) {
  const newEvents = [];

  for (let i = 0; i < events.length; i++) {
    const cur = events[i];
    const next = events[i + 1];

    if (
      cur.name === TicketEvents.CREATE &&
      next &&
      next.name === TicketEvents.COMMENT &&
      moment(next.createdAt).diff(moment(cur.createdAt), "second") < 2
    ) {
      newEvents.push({
        ...cur,
        name: "MERGED_CREATE",
        content: next.content,
      });
      i++;
      continue;
    }

    if (
      cur.name === TicketEvents.COMMENT &&
      next &&
      next.name === TicketEvents.STAGE &&
      moment(next.createdAt).diff(moment(cur.createdAt), "second") < 2
    ) {
      newEvents.push({
        ...cur,
        name: "MERGED_COMMENT",
        from: next.from,
        to: next.to,
      });
      i++;
      continue;
    }

    newEvents.push(cur);
  }

  return newEvents;
}
