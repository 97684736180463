import React, { PureComponent } from "react";
import { connect } from "react-redux";
import createForm from "@36node/redux-form-antd";
import { isEmpty } from "lodash";

import { globalSelectors } from "../../selectors";
import FoldFilterForm from "../../components/fold-filter-form";
import { Button, Form, Col, DatePicker, Input, Row } from "antd";
import ExceptionNameSelect from "../../components/exception-name-select";
import { get } from "lodash";
import ProducerSelect from "../../components/producer-select";
import AlertTypeSelect from "../../components/alert-type-select";
import StatusSelect from "../../components/status-select";
import { ExceptionTypes, AlertLevel } from "../../constants";
import NsSelect from "../common/ns-select";

const RangePicker = DatePicker.RangePicker;

export default function createFilterForm({
  formName,
  warningName, // eg... 电池
  warningType, // eg.. BATTERY_EXCEPTION
  actions = {},
  selectors = {},
}) {
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };

  const formTailLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8, offset: 8 },
  };

  class MyFrom extends PureComponent {
    handleSumbit = e => {
      e.preventDefault();

      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const {
            brokenAt,
            vehicleNo,
            vehicleProducer,
            vehicleModelBrief,
            ...rest
          } = values;
          const newQuery = {
            _offset: 0,
            ...rest,
          };
          newQuery.brokenAt_gte = !isEmpty(brokenAt)
            ? brokenAt[0].startOf("day").format("YYYY-MM-DD HH:mm:ss")
            : undefined;
          newQuery.brokenAt_lte = !isEmpty(brokenAt)
            ? brokenAt[1].endOf("day").format("YYYY-MM-DD HH:mm:ss")
            : undefined;
          newQuery["device.no"] = vehicleNo;
          newQuery["device.manufacturer"] = vehicleProducer;
          newQuery["device.model"] = vehicleModelBrief;
          this.listRecords(newQuery);
        }
      });
    };

    handleRest = () => {
      this.props.reset();
    };

    listRecords = newQuery => {
      const { query } = this.props;

      this.props.dispatch(
        actions.listRecords.request({
          ...query,
          ...newQuery,
        })
      );
    };

    render() {
      const { getFieldDecorator } = this.props;
      const isAlert = warningType === "ALERT_EXCEPTION";
      const isException = [
        ExceptionTypes.BATTERY_EXCEPTION,
        ExceptionTypes.TYRE_EXCEPTION,
        ExceptionTypes.INSULATION_EXCEPTION,
        ExceptionTypes.TBOX_EXCEPTION,
      ].includes(warningType);

      return (
        <FoldFilterForm
          handleSumbit={this.handleSumbit}
          style={{ marginTop: 12, paddingTop: 12 }}
          topFields={[
            isAlert && (
              <Col span={6} key="level">
                <Form.Item label="报警等级" {...formItemLayout}>
                  {getFieldDecorator("level", {})(
                    <StatusSelect
                      allowClear
                      placeholder="请选择"
                      status={AlertLevel}
                    />
                  )}
                </Form.Item>
              </Col>
            ),
            isException && (
              <Col span={6} key="code">
                <Form.Item label={`${warningName}异常`} {...formItemLayout}>
                  {getFieldDecorator("code", {})(
                    <ExceptionNameSelect
                      type={warningType}
                      allowClear
                      placeholder="请选择"
                    />
                  )}
                </Form.Item>
              </Col>
            ),
            <Col span={6} key="vehicleNo">
              <Form.Item label="车辆自编号" {...formItemLayout}>
                {getFieldDecorator("vehicleNo", {})(
                  <Input allowClear placeholder="请输入车辆自编号" />
                )}
              </Form.Item>
            </Col>,
            <Col span={6} key="startedAt">
              <Form.Item label="开始时间" {...formItemLayout}>
                {getFieldDecorator("brokenAt", {})(
                  <RangePicker
                    style={{ width: "150%" }}
                    placeholder={["起始日期", "结束日期"]}
                  />
                )}
              </Form.Item>
            </Col>,
          ]}
          bottomFields={[
            isAlert && (
              <Row gutter={48} type="flex" key="row0">
                <Col span={6}>
                  <Form.Item label="报警类型" {...formItemLayout}>
                    {getFieldDecorator("type", {})(
                      <AlertTypeSelect allowClear />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="报警码" {...formItemLayout}>
                    {getFieldDecorator("code", {})(
                      <Input allowClear placeholder="请输入报警码" />
                    )}
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="报警名称" {...formItemLayout}>
                    {getFieldDecorator("name_like", {})(
                      <Input allowClear placeholder="请输入报警名称" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ),
            <Row gutter={48} type="flex" key="row1">
              <Col span={6}>
                <Form.Item label="厂商" {...formItemLayout}>
                  {getFieldDecorator("vehicleProducer", {})(
                    <ProducerSelect
                      allowClear
                      placeholder="请选择"
                      producers={this.props.producers}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="车型简称" {...formItemLayout}>
                  {getFieldDecorator("vehicleModelBrief", {})(
                    <Input allowClear placeholder="请输入车型简称" />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="车牌号" {...formItemLayout}>
                  {getFieldDecorator("plate", {})(
                    <Input allowClear placeholder="请输入车辆车牌号" />
                  )}
                </Form.Item>
              </Col>
            </Row>,
            <Row key="row2" gutter={48} type="flex">
              <Col span={6}>
                <Form.Item label="部门" {...formItemLayout}>
                  {getFieldDecorator("ns_like", {})(<NsSelect allowClear />)}
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="线路" {...formItemLayout}>
                  {getFieldDecorator("line", {})(
                    <Input allowClear placeholder="请输入线路" />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="VIN码" {...formItemLayout}>
                  {getFieldDecorator("vin", {})(
                    <Input allowClear placeholder="请输入VIN码" />
                  )}
                </Form.Item>
              </Col>
            </Row>,
          ]}
          tailComponent={
            <Row type="flex" justify="end">
              <Col span={10}>
                <Form.Item {...formTailLayout}>
                  <Button onClick={this.handleRest}>重置</Button>

                  <Button
                    style={{ marginLeft: 24 }}
                    type="primary"
                    onClick={this.handleSumbit}
                  >
                    筛选
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          }
        />
      );
    }
  }

  const withRedux = connect((state, props) => {
    const curNs = get(props, "fields.ns.value");
    const lines = globalSelectors.lineByNs(curNs)(state);
    const query = get(selectors.listRecords(state), "request", {});

    return {
      producers: globalSelectors.listProducers(state).result || [],
      lines,
      query,
    };
  })(MyFrom);

  return createForm(formName)(withRedux);
}
