import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AnalysisActions from "../../actions/analysis";
import moment from "moment";
import styled from "styled-components";
import { get } from "lodash";

import { SYSTEM_START_DATE } from "../../config";
import { Row, Col, Popover, Card } from "antd";
import AnalysisSelectors from "../../selectors/analysis";
import AlertChartByState from "../../components/charts/alert-analysis/by-state";
import AlertChartByDate from "../../components/charts/alert-analysis/by-date";
import AlertChartByType from "../../components/charts/alert-analysis/by-type";
import AlertChartByName from "../../components/charts/alert-analysis/by-name";
import AlertChartByModel from "../../components/charts/alert-analysis/by-mode";
import AlertChartByNs from "../../components/charts/alert-analysis/by-ns";
import { AlertLevel } from "../../constants";
import AlertChartByLevel from "../../components/charts/alert-analysis/by-level";
import AlertChartFaultRate from "../../components/charts/alert-analysis/rate-line";
import {
  FaultRecordGroupField,
  WorkOrderGroupField,
  WorkOrderStatus,
  VehicleSnapshotGroupField,
} from "@36node-mekong/sdk-ts";

const AlertAnalysisCharts = {
  // 按处置情况图表
  ALERT_BY_STATE_30_DAYS: {
    valueKey: "TicketByStage",
    Component: AlertChartByState,
  },
  // 近30天报警次数
  ALERT_30_DAYS: {
    valueKey: "AlertByDate",
    Component: AlertChartByDate,
  },
  // 近30天报警按类型
  ALERT_BY_TYPE_30_DAYS: {
    valueKey: "AlertByType",
    format: (value = []) => {
      return value.map(v => ({
        name: v.type,
        value: v.count,
      }));
    },
    Component: AlertChartByType,
  },

  // 近30天报警数量按名称
  ALERT_BY_NAME_30_DAYS: {
    valueKey: "AlertByName",
    Component: AlertChartByName,
  },

  // 近30天报警按车型
  ALERT_BY_MODEL_BRIEF_30_DAYS: {
    valueKey: "AlertByModel",
    Component: AlertChartByModel,
  },

  // 近30天报警按公司
  ALERT_BY_NS_30_DAYS: {
    valueKey: "AlertByNs",
    Component: AlertChartByNs,
    format: (value = [], tree) => {
      return value.map(v => ({
        name: v.name,
        value: v.count,
      }));
    },
  },

  // 近30天报警按等级
  ALERT_BY_LEVEL_30_DAYS: {
    valueKey: "AlertByLevel",
    Component: AlertChartByLevel,
    format: (value = []) => {
      return value
        .filter(v => Boolean(v.level))
        .map(v => {
          return {
            name: AlertLevel[v.level],
            value: v.count,
            level: v.level,
          };
        });
    },
  },

  // 月故障率按公司
  ALERT_FAULT_RATE_BY_COMPANY: {
    valueKey: "AlertRateByNs",
    Component: AlertChartFaultRate,
  },

  // 月故障率按车型
  ALERT_FAULT_RATE_BY_MODEL: {
    valueKey: "AlertRateByModel",
    Component: AlertChartFaultRate,
  },
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 24px 12px 0 12px;

  > .row {
    margin-bottom: 26px;
  }
`;

const OverallCardBox = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${props => props.bkgColor};
  color: ${props => props.color};
  border-left: 3px solid ${props => props.color};

  padding: 20px 0;

  .title {
    font-size: 14px;
    line-height: 22px;
  }

  .value {
    font-size: 24px;
    line-height: 42px;
  }
`;

class OverallCard extends PureComponent {
  renderContent = () => {
    const { bkgColor, color, title, value, format } = this.props;

    let valueText = value;
    if (format) {
      valueText = format(value);
    }
    return (
      <OverallCardBox bkgColor={bkgColor} color={color}>
        <div className="title">{title}</div>
        <div className="value">{valueText}</div>
      </OverallCardBox>
    );
  };
  render() {
    const { tip } = this.props;

    if (tip) {
      return (
        <Popover title={null} content={tip} trigger="hover">
          {this.renderContent()}
        </Popover>
      );
    } else {
      return this.renderContent();
    }
  }
}

class ChartCard extends PureComponent {
  renderTitle = () => {
    const { title } = this.props;
    return (
      <div
        style={{
          fontSize: 16,
          fontWeight: 650,
        }}
      >
        {title}
      </div>
    );
  };

  render() {
    const { height = 340 } = this.props;
    return (
      <Card
        bodyStyle={{ padding: "12px", height, overflow: "hidden" }}
        title={this.renderTitle()}
      >
        {this.props.children}
      </Card>
    );
  }
}

@connect(state => {
  const overall = AnalysisSelectors.overall(state);
  const charts = AnalysisSelectors.charts(state);

  return {
    overall,
    charts,
  };
})
export default class Alert extends PureComponent {
  startAt = moment()
    .subtract(30, "days")
    .startOf("day");

  endAt = moment();

  renderOverall() {
    const { overall } = this.props;

    const cfgs = [
      {
        title: "安全监控",
        color: "#1890FF",
        bkgColor: "rgba(230, 247, 255, 1)",
        value: moment().diff(moment(SYSTEM_START_DATE), "days"),
        format: val => `${val}天`,
        tip: (
          <div style={{ width: 200 }}>
            <span style={{ fontWeight: 600 }}>安全监控：</span>
            <span>从系统正式上线之日起对天数进行累加，截止到昨天。</span>
          </div>
        ),
      },
      {
        title: "故障恢复率",
        color: "#FACC14",
        bkgColor: "rgba(250, 204, 20, 0.0823529411764706)",
        value: overall.recoverRate,
        format: val => `${(Number(val) * 100).toFixed(2)}%`,
        tip: (
          <div style={{ width: 200 }}>
            <span style={{ fontWeight: 600 }}>故障恢复率：</span>
            <span>自动恢复的报警占比。</span>
          </div>
        ),
      },
      {
        title: "车辆完好率",
        color: "#52C41A",
        bkgColor: "rgba(246, 255, 237, 1)",
        value: overall.vehicleHealthRate,
        format: val => `${(Number(val) * 100).toFixed(2)}%`,
        tip: (
          <div style={{ width: 200 }}>
            <span style={{ fontWeight: 600 }}>车辆完好率：</span>
            <span>当前维修中的车辆除以总车辆数。</span>
          </div>
        ),
      },
      {
        title: "万公里报警率",
        color: "#D9001B",
        bkgColor: "rgba(217, 0, 27, 0.0745098039215686)",
        value: overall.mileage10000FaultRate,
        format: val => `${Number(val).toFixed(2)}%`,
        tip: (
          <div style={{ width: 200 }}>
            <span style={{ fontWeight: 600 }}>万公里报警率：</span>
            <span>
              截止到昨日的所有时间段内，发生的报警总数，除以接入车辆的总里程，乘以10000，得到的百分比。
            </span>
          </div>
        ),
      },
    ];

    return (
      <Row className="row" gutter={36}>
        {cfgs.map((p, index) => {
          return (
            <Col span={6} key={index}>
              <OverallCard {...p} />
            </Col>
          );
        })}
      </Row>
    );
  }

  componentDidMount() {
    // 获取 alert 统计
    this.props.dispatch(
      AnalysisActions.aggFaultStatus.request({
        _group: [FaultRecordGroupField.STATUS],
        category: "VEHICLE",
      })
    );

    this.props.dispatch(
      AnalysisActions.aggFaultDate.request({
        _group: [
          FaultRecordGroupField.BROKEN_AT_YEAR,
          FaultRecordGroupField.BROKEN_AT_MONTH,
          FaultRecordGroupField.BROKEN_AT_DAY,
        ],
        _sort: "brokenAt",
        category: "VEHICLE",
        brokenAt_gte: this.startAt.toISOString(),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggFaultType.request({
        _group: [FaultRecordGroupField.TYPE],
        _sort: "-count",
        category: "VEHICLE",
        brokenAt_gte: this.startAt.toISOString(),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggFaultName.request({
        _group: [FaultRecordGroupField.NAME],
        _sort: "-faultCount",
        _limit: 6,
        category: "VEHICLE",
        brokenAt_gte: this.startAt.toISOString(),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggFaultModel.request({
        _group: [VehicleSnapshotGroupField.MODEL],
        _sort: "-faultCount",
        _limit: 10,
        key_gte: this.startAt.format("YYYY-MM-DD"),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggFaultNs.request({
        _group: [FaultRecordGroupField.NS],
        _sort: "-faultCount",
        category: "VEHICLE",
        brokenAt_gte: this.startAt.toISOString(),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggFaultLevel.request({
        _group: [FaultRecordGroupField.LEVEL],
        _sort: "-level",
        category: "VEHICLE",
        brokenAt_gte: this.startAt.toISOString(),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggFaultRateNs.request({
        _group: [
          VehicleSnapshotGroupField.AT_YEAR,
          VehicleSnapshotGroupField.AT_MONTH,
          VehicleSnapshotGroupField.NS,
        ],
        _sort: "-at",
        at_gte: moment()
          .subtract(1, "year")
          .format("YYYY-MM-DD"),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggFaultRateModel.request({
        _group: [
          VehicleSnapshotGroupField.AT_YEAR,
          VehicleSnapshotGroupField.AT_MONTH,
          VehicleSnapshotGroupField.MODEL,
        ],
        _sort: "-at",
        at_gte: moment()
          .subtract(1, "year")
          .format("YYYY-MM-DD"),
      })
    );

    // 统计 open ticket stage
    this.props.dispatch(
      AnalysisActions.aggOpenTicketStage.request({
        _group: [WorkOrderGroupField.STAGE],
        status: WorkOrderStatus.OPEN,
        createAt_gte: this.startAt.toISOString(),
      })
    );

    // 统计入网车辆
    this.props.dispatch(AnalysisActions.aggOnsiteVehicle.request({}));
  }

  renderAlertChart = (chartOption = {}, extra) => {
    const { alertAnalysis } = this.props;
    const { valueKey, format, Component } = chartOption;

    const item = alertAnalysis.find(a => a.name === valueKey);

    if (!item) {
      return null;
    }

    const data = format ? format(item.value, extra) : item.value;

    return <Component data={data} />;
  };

  renderChart = (chartOption = {}, extra) => {
    const { charts } = this.props;
    const { valueKey, format, Component } = chartOption;
    const item = get(charts, valueKey);
    const data = format ? format(item, extra) : item;
    return <Component data={data} {...extra} />;
  };

  render() {
    return (
      <Container>
        {this.renderOverall()}
        <Row className="row" gutter={24}>
          <Col span={12}>
            <ChartCard title="处置情况">
              {this.renderChart(AlertAnalysisCharts.ALERT_BY_STATE_30_DAYS)}
            </ChartCard>
          </Col>

          <Col span={12}>
            <ChartCard title="近30天报警次数">
              {this.renderChart(AlertAnalysisCharts.ALERT_30_DAYS, {
                startAt: this.startAt,
                endAt: this.endAt,
              })}
            </ChartCard>
          </Col>
        </Row>

        <Row className="row" gutter={24}>
          <Col span={12}>
            <ChartCard title="近30天三级报警类型分布">
              {this.renderChart(AlertAnalysisCharts.ALERT_BY_TYPE_30_DAYS)}
            </ChartCard>
          </Col>

          <Col span={12}>
            <ChartCard title="近30天三级报警数量排名">
              {this.renderChart(AlertAnalysisCharts.ALERT_BY_NAME_30_DAYS)}
            </ChartCard>
          </Col>
        </Row>

        <Row className="row" gutter={24}>
          <Col span={24}>
            <ChartCard title="近30天发生三级报警车型排名" height={520}>
              {this.renderChart(
                AlertAnalysisCharts.ALERT_BY_MODEL_BRIEF_30_DAYS
              )}
            </ChartCard>
          </Col>
        </Row>

        <Row className="row" gutter={24}>
          <Col span={12}>
            <ChartCard title="近30天发生三级报警按公司分布">
              {this.renderChart(AlertAnalysisCharts.ALERT_BY_NS_30_DAYS)}
            </ChartCard>
          </Col>

          <Col span={12}>
            <ChartCard title="近30天报警按等级分布">
              {this.renderChart(AlertAnalysisCharts.ALERT_BY_LEVEL_30_DAYS)}
            </ChartCard>
          </Col>
        </Row>

        <Row className="row" gutter={24}>
          <Col span={12}>
            <ChartCard title="月故障率按公司分布">
              {this.renderChart(
                AlertAnalysisCharts.ALERT_FAULT_RATE_BY_COMPANY,
                {
                  startAt: moment().subtract(1, "year"),
                  endAt: this.endAt,
                }
              )}
            </ChartCard>
          </Col>

          <Col span={12}>
            <ChartCard title="月故障率按车型分布">
              {this.renderChart(AlertAnalysisCharts.ALERT_FAULT_RATE_BY_MODEL, {
                startAt: moment().subtract(1, "year"),
                endAt: this.endAt,
              })}
            </ChartCard>
          </Col>
        </Row>
      </Container>
    );
  }
}
