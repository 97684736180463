/**
 * 定义 工单相关的 action
 */
import {
  createApiActions,
  successOf,
  failureOf,
  isSuccess,
} from "@36node/redux-api";
import { createXlsxActions } from "@36node/redux-xlsx";
import { createAssignActions } from "@36node/redux-ui";
import { createFormActions } from "@36node/redux-form";
import { put, take, select } from "redux-saga/effects";
import { message } from "antd";

import { TICKETS_NS, AlertStatus } from "../constants";
import { authV1Sdk, mekong } from "../sdk";
import { ticketSchema, alertSchema, userSchema } from "../schemas";
import { TicketSelectors } from "../selectors";

const listTickets = createApiActions(TICKETS_NS.LIST_TICKETS, {
  endpoint: mekong.listWorkOrders,
  schema: [ticketSchema],
});

const getTicket = createApiActions(TICKETS_NS.GET_TICKET, {
  endpoint: mekong.getWorkOrder,
  schema: ticketSchema,
});

const getUser = createApiActions(TICKETS_NS.GET_USER, {
  endpoint: authV1Sdk.user.getUser,
  schema: userSchema,
});

// listStageTickets 的内容在获取了stage以后saga里添加
const listStageTickets = {};
const listStageTicketsForExport = {};
const ticketsXlsx = {};

/**
 * 获取相关users
 */
const listUsers = createApiActions(TICKETS_NS.LIST_USERS, {
  endpoint: authV1Sdk.user.listUsers,
  schema: [userSchema],
});

const listEventsUsers = createApiActions(TICKETS_NS.LIST_EVENTS_USERS, {
  endpoint: authV1Sdk.user.listUsers,
  schema: [userSchema],
});

const ticketsQuery = createAssignActions(TICKETS_NS.TICKETS_QUERY);

const getAlert = createApiActions(TICKETS_NS.GET_ALERT, {
  endpoint: mekong.getDeviceFaultRecord,
  schema: alertSchema,
});

const createTicket = createApiActions(TICKETS_NS.CREATE_TICKET, {
  endpoint: mekong.createWorkOrder,
  schema: ticketSchema,
});

const handleTicket = createApiActions(TICKETS_NS.HANDLE_TICKET, {
  endpoint: mekong.handleWorkOrder,
  schema: ticketSchema,
});

const closeTicket = createApiActions(TICKETS_NS.CLOSE_TICKET, {
  endpoint: mekong.closeWorkOrder,
  schema: ticketSchema,
});

/**
 * 绑定工单相关action
 */

// 初始化绑定界面
const initBindAlert = ({ vehicleId, alertId }) => {
  return {
    type: TICKETS_NS.INIT_BIND_ALERT,
    payload: {
      vehicleId,
      alertId,
    },
  };
};

// 初始化新建界面
const initCreateTicket = ({ alertId }) => {
  return {
    type: TICKETS_NS.INIT_CREATE_TICKET,
    payload: {
      alertId,
    },
  };
};

// 工单列表中选择的工单
const selectedTicket = createAssignActions(TICKETS_NS.SELETED_TICKET);

// 列表的UI state
const listUIState = createAssignActions(TICKETS_NS.LIST_UI_STATE);

/**
 * event 创建form
 */
const handleForm = createFormActions(TICKETS_NS.HANDLE_FORM);

const fetchTickets = (req = {}) => {
  const { query = {} } = req;

  return {
    type: TICKETS_NS.FETCH_TICKETS,
    payload: {
      query,
    },
  };
};

/**
 * 忽略报警
 */
const ignoreAlert = ({ alertId, action, actionBy, actionAt, nextAction }) => {
  return {
    type: TICKETS_NS.IGNORE_ALERT,
    payload: {
      request: {
        alertId,
        body: { state: AlertStatus.CLOSED, action, actionBy, actionAt },
      },
      nextAction,
    },
  };
};

const listTicketsForExport = createApiActions(
  TICKETS_NS.LIST_TICKETS_FOR_EXPORT,
  {
    endpoint: mekong.listWorkOrders,
    schema: [ticketSchema],
  }
);

const listTicketsXlsx = createXlsxActions(TICKETS_NS.LIST_TICKETS_XLSX, {
  exportOpts: {
    dataSource: function*(params = {}) {
      const apiRequest = listTicketsForExport.request;
      yield put(apiRequest(params));

      const apiResultAction = yield take([
        successOf(TICKETS_NS.LIST_TICKETS_FOR_EXPORT),
        failureOf(TICKETS_NS.LIST_TICKETS_FOR_EXPORT),
      ]);

      if (isSuccess(apiResultAction)) {
        const apiResult = yield select(TicketSelectors.listTicketsForExport);
        return apiResult.result;
      } else {
        message.error("车辆状态导出失败");
        return;
      }
    },
  },
});

const TicketActions = {
  listTickets,
  getTicket,
  handleTicket,
  closeTicket,
  getUser,
  ignoreAlert,
  listStageTickets,
  listStageTicketsForExport,
  ticketsXlsx,
  listTicketsXlsx,
  listUsers,
  ticketsQuery,
  fetchTickets,
  handleForm,
  listEventsUsers,
  initBindAlert,
  getAlert,
  selectedTicket,
  initCreateTicket,
  createTicket,
  listUIState,
};

export default TicketActions;
