import { createSelector } from "reselect";
import { startsWith } from "lodash";

/**
 * select lines by js
 */

const makeLinesByNsSelector = lineSelector => ns =>
  createSelector(
    lineSelector,
    lines => {
      lines = lines.result || [];
      if (!ns) return lines;

      return lines.filter(l => {
        const { ns: lineNs } = l;
        const nsId = typeof lineNs === "object" ? lineNs.id : lineNs;

        return startsWith(nsId, ns);
      });
    }
  );

export default makeLinesByNsSelector;
