import React, { PureComponent } from "react";
import { Select } from "antd";
import { RecordCommands, RecordCommandI18N } from "../../constants";

export default class RecordCommandSelect extends PureComponent {
  render() {
    return (
      <Select {...this.props} placeholder="请选择">
        {Object.values(RecordCommands).map(t => {
          return (
            <Select.Option value={t} key={t}>
              {RecordCommandI18N[t]}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
