import React from "react";
import { Tree, Input } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import uncontrollable from "uncontrollable";
import { VehicleRunState, ZIndex } from "../../constants";
import IconFont from "../iconfont";

/**
 * @typedef {Object} Node
 * @property {string} key
 * @property {string} title
 * @property {Node[]} children
 */

const TreeNode = Tree.TreeNode;
const Search = Input.Search;
const SearchTreeBox = styled.div`
  display: flex;
  max-height: calc(100vh - 64px);
  width: 100%;
  flex-direction: column;
`;
const Top = styled.div`
  margin-bottom: 5px;
  flex: none;
`;
const TreeBox = styled.div`
  flex: 1;
  overflow: auto;
  z-index: ${ZIndex.searchTree};

  .ant-tree-title,
  i {
    color: white;
  }

  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
  }
`;
const Leaf = styled(TreeNode)`
  margin-left: -18px !important;
`;

/**
 * SearchTree
 */
class SearchTree extends React.PureComponent {
  autoExpandParent = false;
  static propTypes = {
    /** Node */
    root: PropTypes.object,
    /** 选择 */
    onSelect: PropTypes.func,
    /** 搜索的关键词，一旦设置这个，就会变成受控组件 */
    keyword: PropTypes.string,
    /** 搜索关键词改变时候的回调 */
    onSearch: PropTypes.func,
    /** 展开的节点，一旦设置这个，就会变成受控组件 */
    expandedKeys: PropTypes.arrayOf(PropTypes.string),
    /** 展开节点变化时的回调 */
    onExpand: PropTypes.func,
  };

  handleChange = e => {
    const { value } = e.target;
    if (this.props.onSearch) {
      this.autoExpandParent = true;
      this.props.onSearch(value);
    }
  };

  handleSelect = (keys, node, extra) => {
    if (this.props.onSelect) {
      if (keys && keys.length > 0) {
        this.props.onSelect(keys[0]);
      } else {
        console.error("selected key undefined", keys);
      }
    }
  };

  handleExpand = keys => {
    if (this.props.onExpand) {
      this.autoExpandParent = false;
      this.props.onExpand(keys);
    }
  };

  _renderLeafTitle = item => {
    const { color, icon, title } = item;

    return (
      <span style={{ color }}>
        {title}&nbsp;
        {icon && <IconFont type={icon} style={{ color }} />}
      </span>
    );
  };

  renderParentTitle = (title, key) => {
    return (
      <span
        onClick={e => {
          if (this.props.onNodeClick) {
            this.props.onNodeClick(key);
          }
        }}
      >
        {title}
      </span>
    );
  };

  render() {
    const { root = {}, keyword = "", expandedKeys = [], hideRoot } = this.props;
    const { children: nodes = [] } = root;

    const ckeys = new Set();
    const hasKeyword = keyword && keyword.length > 2;
    // let kw = keyword.toLowerCase();

    // 当前搜索条件下的数量
    let curCount = 0;
    let runningCount = 0;

    const loop = nodes => {
      return nodes
        .map(item => {
          let title = item.title;
          let match = false;
          if (hasKeyword) {
            const found =
              item.title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
              item.key === keyword ||
              item.id === keyword;

            if (found) {
              match = true;
              title = (
                <Leaf key={item.key} title={this._renderLeafTitle(item)} />
              );
            }
          }

          // 如果要支持点击线路或者公司展开，需要在 title 里响应 handleSelect
          if (item.children) {
            const children = loop(item.children);
            if (children && children.length) {
              return (
                <TreeNode
                  key={item.key}
                  title={this.renderParentTitle(title, item.key)}
                  selectable={false}
                >
                  {children}
                </TreeNode>
              );
            }
          }

          // 有搜索词，但是没有命中当前节点，并且不是根节点
          if (hasKeyword && !match && item.parent) return null;

          // 如果命中搜索词，把 父亲加入展开队列
          if (hasKeyword && match) {
            ckeys.add(item.parent);
          }

          if (item.status) {
            curCount++;
          }
          if (item.status === VehicleRunState.RUNNING) {
            runningCount++;
          }

          return <Leaf key={item.key} title={this._renderLeafTitle(item)} />;
        })
        .filter(is => is);
    };

    const children = loop(nodes);
    const expKeys = this.autoExpandParent ? Array.from(ckeys) : expandedKeys;

    const renderNodes = () => {
      if (hideRoot) {
        return children;
      } else {
        return (
          <TreeNode
            key={root.key}
            title={this.renderParentTitle(root.title, root.key)}
            selectable={false}
          >
            {children}
          </TreeNode>
        );
      }
    };

    return (
      <SearchTreeBox>
        <Top>
          <Search
            style={{ padding: "10px 4px" }}
            placeholder="车辆自编号或vin码"
            onChange={this.handleChange}
            value={keyword}
          />
        </Top>
        <TreeBox>
          <div className="search-result">
            <div>查询结果</div>
            <div style={{ marginLeft: 12 }}>
              ( <span style={{ color: "#00AB37" }}>{runningCount}</span>/
              {curCount} )
            </div>
          </div>
          <Tree
            style={{ padding: "0 4px" }}
            autoExpandParent={this.autoExpandParent}
            onSelect={this.handleSelect}
            expandedKeys={expKeys}
            selectedKeys={[]}
            onExpand={this.handleExpand}
          >
            {renderNodes()}
          </Tree>
        </TreeBox>
      </SearchTreeBox>
    );
  }
}

export default uncontrollable(SearchTree, {
  keyword: "onSearch",
  expandedKeys: "onExpand",
});
