import React, { PureComponent } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";

import Layout from "../../components/layout";
import resetMap from "../common/reset-map";
import Banci from "./banci";
import Datagram from "./datagram";
import Terminal from "./terminal";

const { Content } = Layout;

@resetMap
export default class extends PureComponent {
  render() {
    return (
      <Container>
        <Content>
          <Switch>
            <Route path="/vehicle-monitor/banci" component={Banci} />
            <Route path="/vehicle-monitor/datagram" component={Datagram} />
            <Route path="/vehicle-monitor/terminal" component={Terminal} />

            <Redirect
              exact
              from="/vehicle-monitor"
              to="/vehicle-monitor/banci"
            />
          </Switch>
        </Content>
      </Container>
    );
  }
}

const Container = styled.div`
  margin: 0 12px;
`;
