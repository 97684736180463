import React, { PureComponent } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { List, Card, Spin } from "antd";
import { DropTarget } from "react-dnd";
import TicketSelectors from "../../selectors/tickets";
import TicketActions from "../../actions/tickets";
import TicketCard from "./ticket-card";

/**
 * tickets 泳道
 */
@DropTarget(
  "TICKET",
  {
    drop(props, monitor) {
      const { stage, ticketToStage } = props;
      const { ticket } = monitor.getItem();

      // ticket to stage 回调函数
      if (ticketToStage) {
        ticketToStage(stage, ticket);
      }
    },
  },
  connect => {
    return {
      connectDropTarget: connect.dropTarget(),
    };
  }
)
@connect((state, props) => {
  const { stage } = props;

  const ticketsState = stage.id
    ? TicketSelectors.listStageTickets[stage.id]
      ? TicketSelectors.listStageTickets[stage.id](state)
      : {}
    : {};
  const tickets = ticketsState.result || [];
  const total = ticketsState.total || 0;
  const ticketQuery = TicketSelectors.ticketQuery(state).assign || {};
  const loading = ticketsState.loading;
  return {
    tickets,
    total,
    ticketQuery,
    loading,
  };
})
export default class TicketStage extends PureComponent {
  state = {
    loading: false,
    hasMore: true,
    offset: 0,
  };

  fetchData = () => {
    const { ticketQuery, stage } = this.props;
    const offset = this.state.offset + ticketQuery.limit;
    this.props.dispatch(
      TicketActions.listStageTickets[stage.id].request(
        {
          query: {
            ...ticketQuery,
            offset,
            stage: stage.id,
          },
        },
        { append: true }
      )
    );
    this.setState({ offset });
  };

  handleInfiniteOnLoad = () => {
    this.setState({ loading: true });
    this.fetchData();
  };

  initData = () => {
    const { ticketQuery, stage } = this.props;
    this.props.dispatch(
      TicketActions.listStageTickets[stage.id].request({
        query: {
          ...ticketQuery,
          offset: 0,
          stage: stage.id,
        },
      })
    );
    this.setState({
      loading: false,
      hasMore: true,
      offset: 0,
    });
  };

  componentDidMount() {
    const { stage } = this.props;
    if (TicketActions.listStageTickets[stage.id]) {
      this.initData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(
        JSON.stringify(this.props.ticketQuery),
        JSON.stringify(prevProps.ticketQuery)
      )
    ) {
      this.initData();
    }

    if (!isEqual(this.props.stage.id, prevProps.stage.id)) {
      this.initData();
    }

    // 新数据加载完后
    if (!this.props.loading && prevProps.loading) {
      if (this.props.tickets.length >= this.props.total) {
        this.setState({
          hasMore: false,
          loading: false,
        });
        return;
      }
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      stage = {},
      tickets = [],
      loading,
      connectDropTarget,
      total = 0,
    } = this.props;
    const { name = "" } = stage;

    return connectDropTarget(
      <div style={{ height: "100%" }}>
        <StageBox title={name + `(${total})`}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={this.handleInfiniteOnLoad}
            hasMore={!this.state.loading && this.state.hasMore}
            loader={
              <div className="loader" key={0}>
                {" "}
              </div>
            }
            useWindow={false}
          >
            <List
              loading={loading}
              dataSource={tickets}
              split={false}
              renderItem={item => {
                return (
                  <List.Item>
                    <TicketCard ticket={item} />
                  </List.Item>
                );
              }}
            >
              {this.state.loading && this.state.hasMore && (
                <div className="demo-loading-container">
                  <Spin />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </StageBox>
      </div>
    );
  }
}

const StageBox = styled(Card)`
  height: 100%;
  flex-flow: 1;
  min-width: 300px;
  cursor: pointer;

  .ant-card-body {
    padding: 12px;
    height: calc(100% - 60px);
    overflow: auto;
  }

  background: white;
`;
