import { all, put, takeLatest } from "redux-saga/effects";
import { pileActions } from "../actions";
import { successOf } from "@36node/redux-api";
import { NS } from "../constants";
import { message } from "antd";

function* handleDeleteStation() {
  message.success("充电站删除成功");
  yield put(pileActions.listPileStations.refresh());
}

export default function* watchStations() {
  yield all([
    takeLatest(successOf(NS.MONITOR.PILE.DELETE_STATION), handleDeleteStation),
  ]);
}
