import { createSelector } from "reselect";

/**
 * 将api state 转换成 antd table所需要的
 * @param {import("reselect").Selector} apiSelector
 * @param {Number} defaultLimit
 * @param {Number} defaultOffset
 */
export default function makeApiToTableSelector(
  apiSelector,
  defaultLimit = 10,
  defaultOffset = 0
) {
  return createSelector(
    apiSelector,
    state => {
      const docs = state.result || [];
      const loading = state.loading;
      const query = state.request.query || {};
      const total = state.total;
      const { limit = defaultLimit, offset = defaultOffset } = query;

      const pagination = {
        current: Math.floor(offset / limit + 1),
        pageSize: limit,
        total,
      };

      return {
        docs,
        loading,
        query,
        total,
        pagination,
      };
    }
  );
}

/**
 * 将api state 转换成 antd table所需要的
 * @param {import("reselect").Selector} apiSelector
 * @param {Number} defaultLimit
 * @param {Number} defaultOffset
 */
export function makeMekongApiToTableSelector(
  apiSelector,
  defaultLimit = 10,
  defaultOffset = 0
) {
  return createSelector(
    apiSelector,
    state => {
      const docs = state.result || [];
      const loading = state.loading;
      const query = state.request || {};
      const total = state.total;
      const { _limit = defaultLimit, _offset = defaultOffset } = query;

      const pagination = {
        current: Math.floor(_offset / _limit + 1),
        pageSize: _limit,
        total,
      };

      return {
        ...state,
        docs,
        loading,
        query,
        total,
        pagination,
      };
    }
  );
}
