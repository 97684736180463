/**
 * 按公司满电率统计
 */

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import ReactEcharts from "echarts-for-react";

export class OrderFullRateByNsChart extends PureComponent {
  get colors() {
    return ["#1890FF", "#13C2C2", "#2FC25B", "#FACC14", "#F04864", "#8543E0"];
  }

  getOption = () => {
    const { data = [] } = this.props;
    const colors = this.colors;

    return {
      xAxis: {
        max: 100,
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
      },
      yAxis: {
        type: "category",
        data: data.map(i => i.name),
        inverse: true,
      },
      series: [
        {
          name: "X",
          type: "bar",
          barWidth: 30,
          data: data,
          label: {
            show: true,
            position: "right",
            formatter: "{c}%",
          },
          itemStyle: {
            normal: {
              // 定制显示（按顺序）
              color: params => colors[params.dataIndex],
            },
          },
        },
      ],
    };
  };

  render() {
    const option = this.getOption();

    return (
      <Row>
        <Col span={24}>
          <ReactEcharts option={option} />
        </Col>
      </Row>
    );
  }
}
