import { isNil } from "lodash";
import moment from "moment";

/**
 * Format time
 * @param {Date} date
 */
export function ymdhms(date) {
  if (isNil(date)) return date;
  if (isNaN(Date.parse(date))) return date;
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

/**
 * Format time
 * @param {Date} date
 */
export function ymd(date) {
  if (isNil(date)) return date;
  if (isNaN(Date.parse(date))) return date;
  return moment(date).format("YYYY-MM-DD");
}

export function friendly(date) {
  return moment(date).fromNow();
}
