import React from "react";
import styled from "styled-components";
import { get } from "lodash";

import { Format } from "../../components/format";

export const FaultLevelUiMap = {
  1: { label: "一级", s: "一", color: "ffd478", value: 1 },
  2: { label: "二级", s: "二", color: "ffae5f", value: 2 },
  3: { label: "三级", s: "三", color: "ff5227", value: 3 },
};
export const toFaultLevelStr = level =>
  get(FaultLevelUiMap, [level, "s"], level);

const StyledDiv = styled.div`
  width: 24px;
  height: 24px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  background-color: #${props => get(FaultLevelUiMap, [props.level, "color"], "000")};
`;

export function FaultLevelGlob({ level }) {
  return level > 0 ? (
    <Format value={level} formatter={toFaultLevelStr}>
      {v => <StyledDiv level={level}>{v}</StyledDiv>}
    </Format>
  ) : (
    ""
  );
}
