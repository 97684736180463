import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Table,
  Card,
  DatePicker,
  Dropdown,
  Button,
  Icon,
  Form,
  Row,
  Col,
  Input,
} from "antd";
import moment from "moment";
import { without } from "lodash";
import createForm from "@36node/redux-form-antd";
import styled from "styled-components";

import { tableStateToMekongQuery, ymdhms } from "../../lib";
import { SourceNameI18N } from "../../constants";
import { pileActions } from "../../actions/pile";
import { stationSelectors } from "../../selectors";
import { makeMekongApiToTableSelector } from "../../selectors/api-to-table";
import FilterDropdown from "../../components/filter-dropdown";
import VehicleLink from "../../components/vehicle-link";
import StatusSelect from "../../components/status-select";
import { PileStationSelect } from "../../components/station-select";

const RangePicker = DatePicker.RangePicker;

const title = "充电异常记录列表";

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const formTailLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8, offset: 8 },
};

const SearchContainer = styled(Card)`
  margin-bottom: 24px !important;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;

const defaultQuery = {
  _sort: "-chargeEndAt",
  _expand: ["vehicle", "station"],
  abnormal: true,
  ns_like: "bus",
};

@createForm("ABNORMAL_CHARGE_ORDER_SEARCH_FORM")
class AbnormalOrderListPage extends PureComponent {
  state = {
    hideColumns: [],
  };

  componentWillMount() {
    this.fetchChargeOrders({ _limit: 10, _offset: 0 });
  }

  componentWillUnmount() {
    this.props.dispatch(pileActions.listChargeOrders.clear());
  }

  fetchChargeOrders = (query = {}) => {
    if (query.chargeEndAt && query.chargeEndAt.length === 2) {
      this.props.dispatch(
        pileActions.listChargeOrders.request(
          {
            ...defaultQuery,
            chargeEndAt_gte: query.chargeEndAt[0].toISOString(),
            chargeEndAt_lte: query.chargeEndAt[1].toISOString(),
            ...query,
          },
          {}
        )
      );
    } else {
      this.props.dispatch(
        pileActions.listChargeOrders.request(
          {
            ...defaultQuery,
            ...query,
          },
          {}
        )
      );
    }
  };

  listChargeOrders = (pagination = {}, filters = {}, sort = {}) => {
    const { query } = this.props;
    const tableQuery = tableStateToMekongQuery(pagination, filters, sort);
    this.fetchChargeOrders({ ...query, ...tableQuery });
  };

  handleExport = () => {
    // filename
    const filename = `${title}-${moment().format("YYYY-MM-DD")}`;
    const { query } = this.props;

    this.props.dispatch(
      pileActions.chargeOrdersXlsx.export(
        {
          columns: TableColumns,
          fileName: filename,
          fileType: "xlsx",
          params: {
            ...query,
            _limit: 10000,
            _offset: 0,
            _expand: ["vehicle", "station"],
            abnormal: true,
          },
        },
        {}
      )
    );
  };

  handleSumbit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.fetchChargeOrders({ _limit: 10, _offset: 0, ...values });
      }
    });
  };

  handleRest = () => {
    this.props.reset();
    this.fetchChargeOrders({ _limit: 10, _offset: 0 });
  };

  showedColumns() {
    const { hideColumns } = this.state;
    const allValues = TableColumns.map(i => i.key);
    return without(allValues, ...hideColumns);
  }

  onColumnsFilterChange = values => {
    const allValues = TableColumns.map(i => i.key);
    this.setState({
      hideColumns: without(allValues, ...values),
    });
  };

  renderTopActions = () => {
    return (
      <div className="tool-bar">
        <Dropdown
          overlay={
            <FilterDropdown
              value={this.showedColumns()}
              onChange={this.onColumnsFilterChange}
              items={TableColumns.map(c => ({ key: c.key, name: c.title }))}
            />
          }
          trigger={["click"]}
        >
          <Button>
            <Icon type="filter" /> 选择数据项
          </Button>
        </Dropdown>
        <Button
          onClick={this.handleExport}
          type="primary"
          style={{ marginLeft: 10 }}
        >
          <Icon type="export" />
          导出
        </Button>
      </div>
    );
  };

  render() {
    const {
      docs = [],
      loading,
      total = 0,
      pagination,
      getFieldDecorator,
    } = this.props;
    const { hideColumns } = this.state;
    const showColumns = TableColumns.filter(c => !hideColumns.includes(c.key));

    const Title = () => (
      <div>
        {title}
        <span style={{ marginLeft: 10, fontSize: 12, fontWeight: 400 }}>
          共有 {total} 条记录
        </span>
      </div>
    );

    return (
      <React.Fragment>
        <SearchContainer>
          <Form onSubmit={this.handleSumbit}>
            <Row gutter={48} type="flex">
              <Col span={8}>
                <Form.Item label="服务商平台" {...formItemLayout}>
                  {getFieldDecorator("source", {})(
                    <StatusSelect
                      allowClear
                      placeholder="请选择"
                      status={SourceNameI18N}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="异常时间" {...formItemLayout}>
                  {getFieldDecorator("chargeEndAt", {})(
                    <RangePicker placeholder={["起始日期", "结束日期"]} />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="充电站" {...formItemLayout}>
                  {getFieldDecorator("station", {})(
                    <PileStationSelect allowClear />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={48} type="flex">
              <Col span={8}>
                <Form.Item label="充电枪编码" {...formItemLayout}>
                  {getFieldDecorator("connectorNo", {})(
                    <Input allowClear placeholder="请输入充电枪编码" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="车辆自编号" {...formItemLayout}>
                  {getFieldDecorator("plate", {})(
                    <Input allowClear placeholder="请输入车辆自编号" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="VIN码" {...formItemLayout}>
                  {getFieldDecorator("vin", {})(
                    <Input allowClear placeholder="请输入车架号" />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={48} type="flex">
              <Col offset={16} span={8}>
                <Form.Item {...formTailLayout}>
                  <Button onClick={this.handleRest}>重置</Button>
                  <Button
                    style={{ marginLeft: 24 }}
                    type="primary"
                    htmlType="submit"
                  >
                    筛选
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SearchContainer>
        <Card
          bordered={false}
          title={<Title />}
          extra={this.renderTopActions()}
        >
          <Table
            rowKey="id"
            loading={loading}
            onChange={this.listChargeOrders}
            dataSource={docs}
            columns={showColumns}
            pagination={pagination}
            scroll={{ x: "max-content" }}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default connect((state, props) => {
  const selector = makeMekongApiToTableSelector(
    stationSelectors.listChargeOrders
  );
  const tableState = selector(state);

  return {
    ...tableState,
  };
})(AbnormalOrderListPage);

const getSourceName = name => SourceNameI18N[name];

export const TableColumns = [
  {
    title: "异常编号",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "异常内容",
    dataIndex: "abnormalReason",
    key: "abnormalReason",
  },
  {
    title: "服务商平台",
    dataIndex: "source",
    key: "source",
    render: getSourceName,
    exportFormat: getSourceName,
  },
  {
    title: "充电站",
    dataIndex: "station.name",
    key: "station.name",
  },
  {
    title: "充电枪编码",
    dataIndex: "connectorNo",
    key: "connectorNo",
  },
  {
    title: "VIN码",
    dataIndex: "vin",
    key: "vin",
  },
  {
    title: "车辆自编号",
    dataIndex: "plate",
    key: "plate",
    render: (val, record) => {
      return <VehicleLink title={val} vin={record.vin} />;
    },
  },
  {
    title: "异常时间",
    dataIndex: "chargeEndAt",
    key: "chargeEndAt",
    render: ymdhms,
    sorter: true,
    defaultSortOrder: "descend",
  },
];
