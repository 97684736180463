import { sumBy, uniq, take, sortBy, uniqBy, round, orderBy, get } from "lodash";
import { createApiSelector } from "@36node/redux-api";
import { createSelector } from "reselect";
import moment from "moment";

import {
  NS,
  AlertTypeFilter,
  BatteryExceptionI18N,
  InsulationExceptionI18N,
  TyreExceptionI18N,
  SourceNameI18N,
} from "../constants";
import globalSelectors from "./global";
import { findNs } from "../lib";
import {
  alertSchema,
  overallWarningStatSchema,
  overallTicketStatSchema,
  overallPowerStatSchema,
} from "../schemas";
import { selectStationMap } from "./station";

const warningsStatisticsApi = createApiSelector(NS.REPORT.GET_WARNING_STATS);
const getWarningsStatistics = createSelector(
  warningsStatisticsApi,
  globalSelectors.namespaceMap,
  (apiSate, nsMap) => {
    const result = (apiSate.result || []).map(r => {
      const item = { ...r };
      const data = [
        { name: "一级报警", value: item.level1 },
        { name: "二级报警", value: item.level2 },
        { name: "三级报警/已完成", value: item.level3 },
        { name: "新能源车辆总数", value: item.vehicleCount },
      ];

      if (item.ns) {
        item.ns = (nsMap[item.ns] || {}).pathname || "未知";
        data.push({
          name: "部门",
          value: item.ns,
        });
      }

      // 电池异常
      Object.values(BatteryExceptionI18N).forEach(key => {
        if (item[key]) {
          data.push({
            name: key,
            value: item[key],
          });
        }
      });

      // 绝缘异常
      Object.values(InsulationExceptionI18N).forEach(key => {
        if (item[key]) {
          data.push({
            name: key,
            value: item[key],
          });
        }
      });

      // 轮胎异常
      Object.values(TyreExceptionI18N).forEach(key => {
        if (item[key]) {
          data.push({
            name: key,
            value: item[key],
          });
        }
      });

      const groups = get(apiSate, "request._group", []);
      const groupKey = groups[0];
      const value = get(item, groupKey) || item.id;

      return {
        key: item.id,
        name: value,
        group: value,
        data,
      };
    });
    return { ...apiSate, result };
  }
);

const exportWarningsStatistics = createApiSelector(
  NS.REPORT.EXPORT_WARNING_STATS
);

const getOverallWarningsStats = createApiSelector(
  NS.REPORT.GET_OVERALL_WARNING_STATS,
  [overallWarningStatSchema]
);

const getValueOrDefault = (record, key, defaultValue) => {
  if (record.data.find(r => r.name === key)) {
    return record.data.find(r => r.name === key).value;
  } else {
    return defaultValue;
  }
};

const getSiblingRecords = (parentGroupName, records) => {
  if (parentGroupName === null) {
    return records;
  }
  return records.filter(record => record.name === parentGroupName);
};

const hasSiblingRecords = (parentGroupName, records) => {
  if (parentGroupName === null) {
    return true;
  }
  const siblingRecords = records.filter(
    record => record.name === parentGroupName
  );
  return siblingRecords.length > 0;
};

const getSubGroupName = (record, depth) => {
  return take(record.name.split("/"), depth).join("/");
};

const hasSubRecords = (parentGroupName, records, depth) => {
  if (parentGroupName === null) {
    return true;
  }

  const subRecords = records
    .filter(record => getSubGroupName(record, depth - 1) === parentGroupName)
    .filter(
      record => getSubGroupName(record, depth).split("/").length === depth
    );
  return subRecords.length > 0;
};

const getSubRecords = (parentGroupName, records, depth) => {
  if (parentGroupName === null) {
    return records;
  }
  const subRecords = records
    .filter(record => getSubGroupName(record, depth - 1) === parentGroupName)
    .filter(
      record => getSubGroupName(record, depth).split("/").length === depth
    );
  return subRecords;
};

const getSubGroups = (parentGroupName, records, depth, recordTotal) => {
  if (
    !hasSubRecords(parentGroupName, records, depth) &&
    !hasSiblingRecords(parentGroupName, records)
  ) {
    return [];
  }
  let subGroups = uniq(
    getSubRecords(parentGroupName, records, depth).map(record =>
      getSubGroupName(record, depth)
    )
  )
    .filter(subGroupName => hasSubRecords(subGroupName, records, depth + 1))
    .map(subGroupName => {
      const item = {
        id: subGroupName,
        group: subGroupName,
        name: subGroupName,
      };
      return item;
    });
  subGroups = subGroups.map(subGroup => {
    const children = getSubGroups(
      subGroup.name,
      records,
      depth + 1,
      recordTotal
    );
    const total = recordTotal(children);
    subGroup = { ...total, ...subGroup };
    subGroup.children = children;
    return subGroup;
  });
  const siblingRecords = uniq(
    getSubRecords(parentGroupName, records, depth).map(record =>
      getSubGroupName(record, depth)
    )
  )
    .filter(subGroupName => hasSiblingRecords(subGroupName, records))
    .map(subGroupName => getSiblingRecords(subGroupName, records))
    .reduce((a, b) => a.concat(b), []);

  subGroups = subGroups.concat(siblingRecords);

  if (subGroups.length === 0) {
    return siblingRecords;
  } else {
    return subGroups;
  }
};

const buildTreeGroups = (records, recordTotal) => {
  return getSubGroups(null, records, 1, recordTotal);
};

const dataMap = (data, { groupKey, mapRecord, recordTotal, linear }) => {
  const result = (data || []).map(row => mapRecord(row, groupKey));

  if (groupKey === "company" && !linear) {
    return buildTreeGroups(result, recordTotal);
  } else if (groupKey === "type") {
    // 过滤部分报警类型
    return result.filter(v => -1 === AlertTypeFilter.indexOf(v.name));
  } else {
    return result;
  }
};

const mapAlertRecord = (record, groupKey) => {
  const item = {};
  item.id = record.key;
  item.group = record.group;
  item.name = record.name;
  if (groupKey === "line" && record.data.find(r => r.name === "部门")) {
    item.company = getValueOrDefault(record, "部门", 0);
  }
  if (
    groupKey !== "type" &&
    record.data.find(r => r.name === "新能源车辆总数")
  ) {
    item.vehicleCount = getValueOrDefault(record, "新能源车辆总数", 0);
  } else {
    item.vehicleCount = "";
  }
  item.level3AlarmIgnored = getValueOrDefault(record, "三级报警/已忽略", 0);
  item.level3AlarmDoing = getValueOrDefault(record, "三级报警/处理中", 0);
  item.level3AlarmDone = getValueOrDefault(record, "三级报警/已完成", 0);
  item.level3AlarmTotal =
    item.level3AlarmIgnored + item.level3AlarmDoing + item.level3AlarmDone;
  item.level2AlarmTotal = getValueOrDefault(record, "二级报警", 0);
  item.level1AlarmTotal = getValueOrDefault(record, "一级报警", 0);

  item.calculatedRecord = false;

  return item;
};

const recordAlertTotal = (records, groupKey) => {
  const item = {};
  item.id = "合计";
  item.group = "合计";
  item.name = "合计";
  if (groupKey !== "type") {
    item.vehicleCount = sumBy(records, "vehicleCount");
  } else {
    item.vehicleCount = "";
  }

  if (groupKey === "line") {
    item.name = "";
    item.company = "合计";
  }

  item.level3AlarmIgnored = sumBy(records, "level3AlarmIgnored");
  item.level3AlarmDoing = sumBy(records, "level3AlarmDoing");
  item.level3AlarmDone = sumBy(records, "level3AlarmDone");
  item.level3AlarmTotal = sumBy(records, "level3AlarmTotal");
  item.level2AlarmTotal = sumBy(records, "level2AlarmTotal");
  item.level1AlarmTotal = sumBy(records, "level1AlarmTotal");

  item.calculatedRecord = true;

  return item;
};

const mapBatteryWarningRecord = (record, groupKey) => {
  const item = {};
  item.id = record.key;
  item.group = record.group;
  item.name = record.name;
  if (groupKey === "line" && record.data.find(r => r.name === "部门")) {
    item.company = getValueOrDefault(record, "部门", "");
  }
  if (
    groupKey !== "type" &&
    record.data.find(r => r.name === "新能源车辆总数")
  ) {
    item.vehicleCount = getValueOrDefault(record, "新能源车辆总数", 0);
  } else {
    item.vehicleCount = "";
  }
  item.socLowerThan30Percent = getValueOrDefault(
    record,
    BatteryExceptionI18N.SOC_BELOW_30,
    0
  );
  item.socLowerThan20Percent = getValueOrDefault(
    record,
    BatteryExceptionI18N.SOC_BELOW_20,
    0
  );
  item.batteryTemperature40To55 = getValueOrDefault(
    record,
    BatteryExceptionI18N.BATTERY_TEMP_OVER_45,
    0
  );
  item.batteryTemperatureHigherThan55 = getValueOrDefault(
    record,
    BatteryExceptionI18N.BATTERY_TEMP_OVER_55,
    0
  );

  item.calculatedRecord = false;

  return item;
};

const recordBatteryWarningTotal = (records, groupKey) => {
  const item = {};
  item.id = "合计";
  item.group = "合计";
  item.name = "合计";
  if (groupKey !== "type") {
    item.vehicleCount = sumBy(records, "vehicleCount");
  } else {
    item.vehicleCount = "";
  }

  if (groupKey === "line") {
    item.name = "";
    item.company = "合计";
  }

  item.socLowerThan30Percent = sumBy(records, "socLowerThan30Percent");
  item.socLowerThan20Percent = sumBy(records, "socLowerThan20Percent");
  item.batteryTemperature40To55 = sumBy(records, "batteryTemperature40To55");
  item.batteryTemperatureHigherThan55 = sumBy(
    records,
    "batteryTemperatureHigherThan55"
  );

  item.calculatedRecord = true;

  return item;
};

const mapInsulationWarningRecord = (record, groupKey) => {
  const item = {};
  item.id = record.key;
  item.group = record.group;
  item.name = record.name;
  if (groupKey === "line" && record.data.find(r => r.name === "部门")) {
    item.company = getValueOrDefault(record, "部门", "");
  }
  if (
    groupKey !== "type" &&
    record.data.find(r => r.name === "新能源车辆总数")
  ) {
    item.vehicleCount = getValueOrDefault(record, "新能源车辆总数", 0);
  } else {
    item.vehicleCount = "";
  }
  item.insulationWarning500To1M = getValueOrDefault(
    record,
    InsulationExceptionI18N.RESS_BELOW_100,
    0
  );
  item.insulationWarningLowerThan500 = getValueOrDefault(
    record,
    InsulationExceptionI18N.RESS_BELOW_1,
    0
  );

  item.calculatedRecord = false;

  return item;
};

const recordInsulationWarningTotal = (records, groupKey) => {
  const item = {};
  item.id = "合计";
  item.group = "合计";
  item.name = "合计";
  if (groupKey !== "type") {
    item.vehicleCount = sumBy(records, "vehicleCount");
  } else {
    item.vehicleCount = "";
  }

  if (groupKey === "line") {
    item.name = "";
    item.company = "合计";
  }

  item.insulationWarning500To1M = sumBy(records, "insulationWarning500To1M");
  item.insulationWarningLowerThan500 = sumBy(
    records,
    "insulationWarningLowerThan500"
  );
  item.maintenanceInsulationWarning = sumBy(
    records,
    "maintenanceInsulationWarning"
  );

  item.calculatedRecord = true;

  return item;
};

const mapTireWarningRecord = (record, groupKey) => {
  const item = {};
  item.id = record.key;
  item.group = record.group;
  item.name = record.name;
  if (groupKey === "line" && record.data.find(r => r.name === "部门")) {
    item.company = getValueOrDefault(record, "部门", "");
  }
  if (
    groupKey !== "type" &&
    record.data.find(r => r.name === "新能源车辆总数")
  ) {
    item.vehicleCount = getValueOrDefault(record, "新能源车辆总数", 0);
  } else {
    item.vehicleCount = "";
  }
  item.tirePressureTooLow = getValueOrDefault(record, "胎压过低", 0);
  item.tirePressureTooHigh = getValueOrDefault(record, "胎压过高", 0);
  item.tireTemperatureTooHigh = getValueOrDefault(record, "胎温过高", 0);
  item.sensorError = getValueOrDefault(record, "传感器故障", 0);

  item.calculatedRecord = false;

  return item;
};

const recordTireWarningTotal = (records, groupKey) => {
  const item = {};
  item.id = "合计";
  item.group = "合计";
  item.name = "合计";
  if (groupKey !== "type") {
    item.vehicleCount = sumBy(records, "vehicleCount");
  } else {
    item.vehicleCount = "";
  }

  if (groupKey === "line") {
    item.name = "";
    item.company = "合计";
  }

  item.tirePressureTooLow = sumBy(records, "tirePressureTooLow");
  item.tirePressureTooHigh = sumBy(records, "tirePressureTooHigh");
  item.tireTemperatureTooHigh = sumBy(records, "tireTemperatureTooHigh");
  item.sensorError = sumBy(records, "sensorError");

  item.calculatedRecord = true;

  return item;
};

const mapPileWarningRecord = (record, groupKey) => {
  const item = {};
  item.id = record.key;
  item.group = record.group;
  item.name = record.name;
  if (groupKey === "line" && record.data.find(r => r.name === "部门")) {
    item.company = getValueOrDefault(record, "部门", "");
  }
  if (
    groupKey === "station" &&
    record.data.find(r => r.name === "充电桩总数")
  ) {
    item.count = getValueOrDefault(record, "充电桩总数", 0);
  } else {
    item.count = "";
  }
  item.amout = getValueOrDefault(record, "充电量", 0);
  item.lowAmout = getValueOrDefault(record, "谷时段充电量", 0);
  item.mediumAmout = getValueOrDefault(record, "平时段充电量", 0);
  item.highAmout = getValueOrDefault(record, "峰时段充电量", 0);
  item.percent = item.lowAmout
    ? `${(
        (item.lowAmout * 100) /
        (item.lowAmout + item.mediumAmout + item.highAmout)
      ).toFixed(1)}%`
    : "";

  item.calculatedRecord = false;

  return item;
};

const recordPileWarningTotal = (records, groupKey) => {
  const item = {};
  item.id = "合计";
  item.group = "合计";
  item.name = "合计";
  if (groupKey === "station") {
    item.count = sumBy(records, "count");
  } else {
    item.count = "";
  }

  if (groupKey === "line") {
    item.name = "";
    item.company = "合计";
  }

  item.amout = sumBy(records, "amout");
  item.lowAmout = sumBy(records, "lowAmout");
  item.mediumAmout = sumBy(records, "mediumAmout");
  item.highAmout = sumBy(records, "highAmout");
  item.percent = item.lowAmout
    ? `${(
        (item.lowAmout * 100) /
        (item.lowAmout + item.mediumAmout + item.highAmout)
      ).toFixed(1)}%`
    : "";

  item.calculatedRecord = true;

  return item;
};

const mapTicketRecord = (record, groupKey) => {
  const item = {};
  item.id = record.key;
  item.group = record.group;
  item.name = record.name;
  if (groupKey === "line" && record.data.find(r => r.name === "部门")) {
    item.company = getValueOrDefault(record, "部门", 0);
  }
  if (record.data.find(r => r.name === "新能源车辆总数")) {
    item.vehicleCount = getValueOrDefault(record, "新能源车辆总数", 0);
  } else {
    item.vehicleCount = "";
  }

  // 剔除 新能源车辆总数 和 部门 数据项
  record.data
    .filter(r => r.name !== "新能源车辆总数" || r.name !== "部门")
    .forEach(r => {
      item[r.name] = r.value || 0;
    });

  item.calculatedRecord = false;

  return item;
};

const recordTicketTotal = (records, groupKey) => {
  const item = {};
  item.id = "合计";
  item.group = "合计";
  item.name = "合计";
  item.vehicleCount = sumBy(records, "vehicleCount");

  if (groupKey === "line") {
    item.name = "";
    item.company = "合计";
  }

  // 剔除 新能源车辆总数 和 部门 数据项
  let keys = [];
  if (records && records.length) {
    const {
      id,
      group,
      name,
      vehicleCount,
      calculatedRecord,
      company,
      children,
      ...data
    } = records[0];
    keys = Object.keys(data).filter(
      r => r !== "新能源车辆总数" || r !== "部门"
    );
  }

  keys.forEach(k => {
    item[k] = sumBy(records, k);
  });

  item.calculatedRecord = true;
  return item;
};

const getWarningStatisticsWarningTableRecords = (
  state,
  props,
  mapRecord,
  recordTotal
) => {
  const getWarningStatState = getWarningsStatistics(state);
  const data = getWarningStatState.result || [];
  const loading = getWarningStatState.loading;

  const { groupKey } = props;
  const treeData = dataMap(sortBy(data, ["group"]), {
    groupKey,
    mapRecord,
    recordTotal: records => recordTotal(records, groupKey),
  });

  const total = data.length;

  return { data: treeData, loading, total };
};

const getWarningStatisticsAlertTableRecords = (state, props) => {
  return getWarningStatisticsWarningTableRecords(
    state,
    props,
    mapAlertRecord,
    recordAlertTotal
  );
};

const getWarningStatisticsBatteryWarningTableRecords = (state, props) => {
  return getWarningStatisticsWarningTableRecords(
    state,
    props,
    mapBatteryWarningRecord,
    recordBatteryWarningTotal
  );
};

const getWarningStatisticsInsulationWarningTableRecords = (state, props) => {
  return getWarningStatisticsWarningTableRecords(
    state,
    props,
    mapInsulationWarningRecord,
    recordInsulationWarningTotal
  );
};

const getWarningStatisticsTireWarningTableRecords = (state, props) => {
  return getWarningStatisticsWarningTableRecords(
    state,
    props,
    mapTireWarningRecord,
    recordTireWarningTotal
  );
};

const getWarningStatisticsPileWarningTableRecords = (state, props) => {
  return getWarningStatisticsWarningTableRecords(
    state,
    props,
    mapPileWarningRecord,
    recordPileWarningTotal
  );
};

const getVehicleStat = createApiSelector(NS.REPORT.GET_VEHICLE_STATS);
const exportVehicleStat = createApiSelector(NS.REPORT.EXPORT_VEHICLE_STATS);

const groupByNs = (nsTree = [], records = [], span = 1) => {
  // 在ns tree 上赋值
  const assignValue = (origin = {}) => {
    let ns = { ...origin };
    const { children = [] } = ns;
    const record = records.find(r => r.ns === ns.id);

    if (record) {
      ns = {
        ...ns,
        ...record,
      };
    } else {
      ns.ns = ns.id;
      ns.vehicleCount = 0;
      ns.vehicles = 0;
      ns.mileage = 0;
      ns.consumption = 0;
      ns.mileageVehicles = 0;
    }

    if (children.length > 0) {
      ns.children = ns.children.map(c => assignValue(c));

      // 聚合
      ns.vehicleCount = sumBy(ns.children, "vehicleCount");
      ns.vehicles = ns.vehicleCount;
      ns.mileage = sumBy(ns.children, "mileage");
      ns.consumption = sumBy(ns.children, "consumption");
      ns.mileageVehicles = sumBy(ns.children, "mileageVehicles");
      ns.mileageAvg = ns.mileage / ns.mileageVehicles / span;
      ns.consumptionAvg = (100 * ns.consumption) / ns.mileage;

      // 有孩子， 则加粗
      ns.bold = true;
    } else {
      delete ns.children;
    }

    ns.groupKey = ns.pathname;

    return ns;
  };

  return nsTree.map(n => assignValue(n));
};

const vehicleGroupTable = createSelector(
  globalSelectors.namespacesTreeNoRoot,
  globalSelectors.listLines,
  getVehicleStat,
  (nsTree = [], linesStat = [], vehicleStat = {}) => {
    const { _group = [], at_gte, at_lte } = vehicleStat.request;
    const records = vehicleStat.result || [];
    const lines = linesStat.result || [];
    let dataSource = records;
    const total = vehicleStat.total;
    const loading = vehicleStat.loading;
    const span = (moment(at_lte).diff(moment(at_gte), "day") || 0) + 1;

    const groupKey = _group[0] || "";
    if (!groupKey) return {};
    if (groupKey === "ns") {
      dataSource = groupByNs(nsTree, records, span);
    } else if (groupKey === "line") {
      dataSource = dataSource.map(d => ({
        ...d,
        groupKey: d[groupKey],
        nsName: findNs(d.ns, nsTree),
      }));
    } else if (groupKey === "id") {
      const sortNsTree = orderBy(nsTree, d => d.id.length, "asc");
      dataSource = dataSource.map(d => ({
        ...d,
        lineName: (lines.find(x => x.id === d.line) || {}).name,
        nsName: findNs(d.ns, sortNsTree),
      }));
    } else if (groupKey !== "overall") {
      dataSource = dataSource.map(d => {
        d.groupKey = d[groupKey];
        d.nsName = findNs(d.ns, nsTree);
        return d;
      });
    }

    if (groupKey !== "overall") {
      // 按名称排序，确保company顺序与其他页面保持一致
      dataSource = orderBy(dataSource, d => d.name);
      // 按长度排序，确保待分配在最下面
      dataSource = orderBy(dataSource, d => d.id.length, "asc");
    }

    const getFilters = (field, records = []) => {
      const filters = records
        .map(row => {
          const filter = {
            text: row[field],
            value: row[field],
          };

          if (row.children) {
            filter.children = getFilters(field, row.children).filter(
              f => f.value && f.text
            );
          }
          return filter;
        })
        .filter(f => f.value && f.text);

      return sortBy(uniqBy(filters, filter => filter.value && filter.text), [
        "value",
      ]);
    };

    const filters = {
      nsName: getFilters("nsName", dataSource),
      pathname: getFilters("pathname", dataSource),
      line: getFilters("line", dataSource),
      model: getFilters("model", dataSource),
      manufacturer: getFilters("manufacturer", dataSource),
    };

    return {
      dataSource,
      total,
      loading,
      filters,
      group: _group,
    };
  }
);

const listTableAlerts = createApiSelector(NS.REPORT.LIST_TABLE_ALERTS, [
  alertSchema,
]);

const ticketsStatisticsApi = createApiSelector(NS.REPORT.GET_TICKET_STATS);
const getTicketsStatistics = createSelector(
  ticketsStatisticsApi,
  globalSelectors.namespaceMap,
  (apiSate, nsMap) => {
    const result = (apiSate.result || []).map(r => {
      const item = { ...r };
      const data = [
        { name: "待维修", value: item.待维修 },
        { name: "维修中", value: item.维修中 },
        { name: "已完成", value: item.已完成 },
        { name: "新能源车辆总数", value: item.vehicleCount },
      ];

      if (item.ns) {
        item.ns = (nsMap[item.ns] || {}).pathname || "未知";
        data.push({
          name: "部门",
          value: item.ns,
        });
      }

      const groups = get(apiSate, "request._group", []);
      const groupKey = groups[0];
      const value = get(item, groupKey) || item.id;

      return {
        key: item.id,
        name: value,
        group: value,
        data,
      };
    });
    return { ...apiSate, result };
  }
);

const getTicketsStatisticsRecords = (state, props, mapRecord, recordTotal) => {
  const getTicketStatState = getTicketsStatistics(state);
  const data = getTicketStatState.result || [];
  const loading = getTicketStatState.loading;

  const { groupKey } = props;
  const treeData = dataMap(sortBy(data, ["group"]), {
    groupKey,
    mapRecord,
    recordTotal: records => recordTotal(records, groupKey),
  });

  const total = data.length;

  return { data: treeData, loading, total };
};

// 工单 selector
const getTicketsStatSelector = (state, props) => {
  return getTicketsStatisticsRecords(
    state,
    props,
    mapTicketRecord,
    recordTicketTotal
  );
};

const exportTicketsStatistics = createApiSelector(
  NS.REPORT.EXPORT_TICKET_STATS
);

const getOverallTicketStats = createApiSelector(
  NS.REPORT.GET_OVERALL_TICKET_STATS,
  [overallTicketStatSchema]
);

const getOverallPowerStats = createApiSelector(
  NS.REPORT.GET_OVERALL_POWER_STATS,
  [overallPowerStatSchema]
);

/**
 * 以下是充电桩统计
 */

/**
 * 充电桩统计
 */
const genTotal = (sumKeys = []) => (groupName, records) => {
  const item = {};
  item[groupName] = "合计";
  item.calculatedRecord = true;
  sumKeys.forEach(key => {
    item[key] = round(sumBy(records, key), 3);
  });
  return item;
};

const chargeOrderSumKeys = [
  "count",
  "vehicleCount",
  "topPeak",
  "peak",
  "valley",
  "flat",
  "elec",
];
const genChargeOrderTotalRecord = genTotal(chargeOrderSumKeys);
const prepareChargeOrderRecords = (nsMap, stationMap) => record => {
  const { details, ...rest } = record;
  rest.flat = 0;
  rest.peak = 0;
  rest.topPeak = 0;
  rest.valley = 0;
  details.forEach(detail => {
    rest[detail.type] = round(detail.elec, 3);
  });
  if (record.ns) rest.name = (nsMap[record.ns] || {}).pathname || record.ns;
  if (record.station)
    rest.station = (stationMap[record.station] || {}).name || record.station;
  if (record.source)
    rest.source = SourceNameI18N[record.source] || record.source;

  return rest;
};
const selectChargeOrderAggState = createApiSelector(
  NS.REPORT.GET_CHARGE_ORDER_AGG
);
const mapNs = {
  杨高公司: 1,
  上南公司: 2,
  金高公司: 3,
  南汇公司: 4,
};
const selectChargeOrderAgg = createSelector(
  selectChargeOrderAggState,
  globalSelectors.namespaceMap,
  selectStationMap,
  (_, props = {}) => props,
  (chargeOrderAggState, nsMap, stationMap, props) => {
    const { groupKeys = [], groupName } = props;
    const { result = [] } = chargeOrderAggState;
    const data = sortBy(result, groupKeys)
      .map(prepareChargeOrderRecords(nsMap, stationMap))
      .filter(d => d[groupName]); // 防止 groupName 切换时，数据还没刷新而报错
    let treeTableData = groupKeys.includes("ns")
      ? buildTreeGroups(data, genChargeOrderTotalRecord.bind(null, groupName))
      : data;
    if (groupKeys.includes("ns")) {
      treeTableData = orderBy(treeTableData, d => mapNs[d.name]);
    }
    return { ...chargeOrderAggState, result: treeTableData, data };
  }
);

const reportSelectors = {
  getWarningsStatistics,
  getOverallWarningsStats,
  exportWarningsStatistics,
  getWarningStatisticsPileWarningTableRecords,
  getWarningStatisticsTireWarningTableRecords,
  getWarningStatisticsInsulationWarningTableRecords,
  getWarningStatisticsBatteryWarningTableRecords,
  getWarningStatisticsAlertTableRecords,
  recordPileWarningTotal,
  dataMap,
  mapAlertRecord,
  recordAlertTotal,
  mapBatteryWarningRecord,
  recordBatteryWarningTotal,
  mapInsulationWarningRecord,
  recordInsulationWarningTotal,
  mapTireWarningRecord,
  recordTireWarningTotal,
  mapPileWarningRecord,
  getVehicleStat,
  vehicleGroupTable,
  exportVehicleStat,
  listTableAlerts,
  mapTicketRecord,
  recordTicketTotal,
  getTicketsStatistics,
  getTicketsStatSelector,
  exportTicketsStatistics,
  getOverallTicketStats,
  getOverallPowerStats,
  selectChargeOrderAgg,
  genChargeOrderTotalRecord,
};

export default reportSelectors;
