import React, { PureComponent } from "react";
import createWarningTable from "./warnings-table";
import pileHistoryActions from "../../actions/history/pile";
import pileHistorySelectors from "../../selectors/history/pile";
import styled from "styled-components";
import { ExceptionTypes, ChargeTypeI18N, HISTORY_NS } from "../../constants";
import { ymdhms } from "../../lib";
import createFilterForm from "./warnings-filter-form";

export class Pile extends PureComponent {
  render() {
    return (
      <Container>
        <FilterFrom />

        <WarningsTable />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 108px);
`;

const FilterFrom = createFilterForm({
  formName: HISTORY_NS.PILE.FILTER_FORM,
  warningName: "充电桩",
  warningType: ExceptionTypes.PILE_EXCEPTION,
  actions: pileHistoryActions,
  selectors: pileHistorySelectors,
});

const TableColumns = [
  { title: "部门", key: "department", dataIndex: "department" },
  { title: "线路", key: "line", dataIndex: "line" },
  { title: "自编号", key: "vehicleNo", dataIndex: "vehicleNo" },
  { title: "充电站", key: "station", dataIndex: "station" },
  { title: "充电桩编码", key: "pileNo", dataIndex: "pileNo" },
  {
    title: "充电类型",
    key: "type",
    dataIndex: "type",
    render: x => ChargeTypeI18N[x],
    exportFormat: x => ChargeTypeI18N[x],
  },
  {
    title: "充电开始时间",
    key: "startAt",
    dataIndex: "startAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  {
    title: "充电结束时间",
    key: "endAt",
    dataIndex: "endAt",
    render: ymdhms,
    exportFormat: ymdhms,
    sorter: true,
  },
  {
    title: "开始SoC",
    key: "startSoc",
    dataIndex: "startSoc",
    render: x => `${x}%`,
    exportFormat: x => `${x}%`,
  },
  {
    title: "结束SoC",
    key: "endSoc",
    dataIndex: "endSoc",
    render: x => `${x}%`,
    exportFormat: x => `${x}%`,
  },
  {
    title: "充电量",
    key: "chargingAmount",
    dataIndex: "chargingAmount",
    render: x => `${x}kwh`,
    exportFormat: x => `${x}kwh`,
  },
];

const WarningsTable = createWarningTable(
  "充电记录",
  ExceptionTypes.PILE_EXCEPTION,
  TableColumns,
  pileHistoryActions,
  pileHistorySelectors
);

export default Pile;
