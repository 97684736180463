import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { get, isEmpty } from "lodash";
import { StationType } from "@36node-mekong/sdk-ts";

import { stationSelectors } from "../../selectors";
import { pileActions } from "../../actions/pile";

export default class StationSelect extends PureComponent {
  render() {
    const { ...rest } = this.props;
    const status = {
      杨高充电站: "杨高充电站",
      上南充电站: "上南充电站",
      金高充电站: "金高充电站",
      南汇充电站: "南汇充电站",
    };
    return (
      <Select placeholder="请选择" {...rest}>
        {Object.keys(status).map(key => {
          return (
            <Select.Option value={key} key={key}>
              {status[key]}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}

@connect((state, props) => {
  const stations =
    get(stationSelectors.listAllPileStations(state), "result") || [];

  return {
    stations,
  };
})
export class PileStationSelect extends PureComponent {
  componentDidMount() {
    if (isEmpty(this.props.stations)) {
      this.props.dispatch(
        pileActions.listAllPileStations.request(
          {
            type: StationType.CHARGER,
            _limit: 100,
            _offset: 0,
          },
          {}
        )
      );
    }
  }

  render() {
    const { stations, ...rest } = this.props;
    return (
      <Select placeholder="请选择" {...rest}>
        {stations.map(station => {
          return (
            <Select.Option value={station.id} key={station.id}>
              {station.name}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
