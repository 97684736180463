/**
 * init dotenv
 *
 * .env: Default.
 * .env.local: Local overrides. This file is loaded for all environments except test.
 * .env.development, .env.test, .env.production: Environment-specific settings.
 * .env.development.local, .env.test.local, .env.production.local: Local overrides of environment-specific settings.
 *
 * Available settings
 *
 * APP_PORT=9527
 * APP_BASE_PATH=/v1
 * APP_JWT_PUBLIC_KEY=`a public key string`
 */

/**
 *
 * @param {string} name envrionment name
 * @param {string} init default value
 * @returns {string} value
 */
function env(name, init) {
  // @ts-ignore
  const runtimes = window._runtime_ || {};
  const key = `REACT_APP_${name.toUpperCase()}`;
  const buildtimeValue = process.env[key];

  //@ts-ignore
  const runtimeValue = runtimes[key];
  const value = runtimeValue || buildtimeValue || init;

  if (value === undefined) {
    throw new Error(`environment ${name} is missing`);
  }

  return value;
}

/**
 * APP
 */
export const VERSION = env("VERSION", "0.0.0");
export const BUS_OP_BASE = env("BUS_OP_BASE", "");
export const BUS_CORE_BASE = env("BUS_CORE_BASE", "");
export const BUS_REALTIME_BASE = env("BUS_REALTIME_BASE", "");
export const BUS_LOG_BASE = env("BUS_LOG_BASE", "");
export const BUS_CHART_BASE = env("BUS_CHART_BASE", "/api/chart/v0");
export const BUS_PILE_BASE = env("BUS_PILE_BASE", "/api/pile/v0");
export const AUTH_BASE = env(
  "AUTH_BASE",
  "https://api.stargate.36node.com/auth/v1"
);
export const AUTH_PROVIDER = env("AUTH_PROVIDER", "63553f67b831380013e24db2");
export const AMAP_KEY = env("AMAP_KEY", "76bc843ad59ac45ccd1e20b2a79f4694");
export const LOG_WS = env("LOG_WS", "");

export const APP_TITLE = env("APP_TITLE", "");
export const WEATHER_BASE = env("WEATHER_BASE", "");
export const WEATHER_LOCATION = env("WEATHER_LOCATION", "上海");
export const ROOT_NAMESPACE = env("ROOT_NAMESPACE", "bus");

// 定时获取报警统计的 interval
export const ALERT_SUMMARY_IN_MS = parseInt(
  env("ALERT_SUMMARY_IN_MS", 1000 * 60 * 2)
);

// 车辆搜索树定时获取的 interval
export const GLOBAL_LIST_VEHICLES_IN_MS = parseInt(
  env("GLOBAL_LIST_VEHICLES_IN_MS", "60000")
);

// 单车详情界面刷新数据 interval
export const VEHICLE_UPDATE_IN_MS = parseInt(
  env("VEHICLE_UPDATE_IN_MS", "10000")
);

// 服务概览统计数据刷新数据 interval
export const OVERVIEW_CRON_IN_MS = parseInt(
  env("OVERVIEW_CRON_IN_MS", "60000")
);

// 报警监控页面列表定时刷新 interval
export const MONITOR_TABLE_FETCH_IN_MS = parseInt(
  env("MONITOR_TABLE_FETCH_IN_MS", "60000")
);

// 地图数据刷新数据 interval
export const MAP_DATA_IN_MS = parseInt(env("MAP_DATA_IN_MS", 1000 * 60 * 2));

// 地图最大获取的车辆数
export const MAP_MAX_VEHICLES = parseInt(env("MAP_MAX_VEHICLES", "10000"));

// 地图最大获取的报警数
export const MAP_MAX_ALERTS = parseInt(env("MAP_MAX_ALERTS", "10000"));

// 实时报警时效，在这个时效内的报警属于实时报警
export const REALTIME_ALERT_AGING_H = parseInt(
  env("REALTIME_ALERT_AGING_H", "24")
);

// Google Analytics 跟踪ID，用于统计页面使用情况
export const GA_UA_ID = env("GA_UA_ID", "UA-148225358-1");

// icon font script url
export const ICON_FONT_SCRIPT_URL =
  "//at.alicdn.com/t/c/font_1177004_ee0s6g4puc8.js";

// 系统启用日期， 用于显示监控天数
export const SYSTEM_START_DATE = env("SYSTEM_START_DATE", "2019-08-10");

export const MAX_TICKETS_LIMIT = parseFloat(env("MAX_TICKETS_LIMIT", "100"));
export const MAX_EXPORT_TICKETS_LIMIT = parseFloat(
  env("MAX_EXPORT_TICKETS_LIMIT", "1000")
);

// 报警监控历史刷新数据 interval
export const ALERT_HISTORY_IN_MS = parseInt(
  env("ALERT_HISTORY_IN_MS", "10000")
);

/**
 * 厂商ns对应 用于在厂商账号登录时的内容筛选
 */
export const PRODUCERS_NAMESPACES = env(
  "PRODUCERS_NAMESPACES",
  "申龙:/bus/producer/shenlong;申沃:/bus/producer/shenwo;万象:/bus/producer/wanxiang;宇通:/bus/producer/yutong;青年:/bus/producer/qingnian"
)
  .split(";")
  .map(i => ({ name: i.split(":")[0], ns: i.split(":")[1] }));

export const MEKONG_ENDPOINT = env(
  "MEKONG_ENDPOINT",
  "https://pudong.36node.com/api"
);

/**
 * mqtt
 */
export const MQTT_URL = env(
  "MQTT_URL",
  "wss://emqx.z-ctyun-stage.36node.com:8084/mqtt"
);
export const MQTT_CONSUMER_PREFIX = env("MQTT_CONSUMER_PREFIX", "");

// 登录失败后锁定的时间，单位为秒
export const LOGIN_FAIL_LOCK_SECONDS = parseInt(
  env("LOGIN_FAIL_LOCK_SECONDS", `${5 * 60}`),
  10
);
