import React, { PureComponent } from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";

import Layout from "../../components/layout";
import SubMenuTabs from "../../components/sub-menu-tabs";

import StationListPage from "./station-list";
import OrderListPage from "./order-list";
import AbnormalOrderListPage from "./abnormal-order-list";
import AbnormalStopListPage from "./abnormal-stop-list";

const { Content, Header } = Layout;

/**
 * Main Container
 */
const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
`;

const tabItems = [
  {
    key: "list",
    pathname: "/station/list",
    title: "充电站管理",
  },
  {
    key: "charge-order",
    pathname: "/station/charge-order",
    title: "充电历史记录",
  },
  {
    key: "stop-abnormal-order",
    pathname: "/station/stop-abnormal-order",
    title: "充电报警记录",
  },
  {
    key: "charge-abnormal-order",
    pathname: "/station/charge-abnormal-order",
    title: "充电异常记录",
  },
];

export default class StationPage extends PureComponent {
  handleChange = key => {
    const { history } = this.props;
    const item = tabItems.find(it => it.key === key) || tabItems[0];
    history.push(item.pathname);
  };

  getActiveKey = () => {
    const { location } = this.props;

    const paths = location.pathname.split("/").slice(0, 3);
    return paths.join("/");
  };
  render() {
    const item =
      tabItems.find(i => i.pathname === this.getActiveKey()) || tabItems[0];

    return (
      <Container>
        <Header
          style={{ height: "max-content", padding: 0, background: "white" }}
        >
          <SubMenuTabs
            tabs={tabItems}
            activeKey={item.key}
            onChange={this.handleChange}
            style={{
              zIndex: 2,
              height: "100%",
            }}
          />
        </Header>
        <Content style={{ height: "calc(100% - 44px)", padding: 24 }}>
          <Switch>
            <Route path="/station/list" component={StationListPage} />
            <Route path="/station/charge-order" component={OrderListPage} />
            <Route
              path="/station/charge-abnormal-order"
              component={AbnormalOrderListPage}
            />
            <Route
              path="/station/stop-abnormal-order"
              component={AbnormalStopListPage}
            />
            <Redirect exact from="/station" to="/station/list" />
          </Switch>
        </Content>
      </Container>
    );
  }
}
