import React, { PureComponent } from "react";
import { Card, Col, Row, Spin } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";
import {
  OrderCountableField,
  OrderGroupField,
  StationType,
} from "@36node-mekong/sdk-ts";
import { get, isEmpty } from "lodash";

import AnalysisActions from "../../actions/analysis";
import { pileActions } from "../../actions/pile";
import { AnalysisSelectors, stationSelectors } from "../../selectors";
import ChartByNs from "../../components/charts/alert-analysis/by-ns";

import { RangeOptionsPicker } from "./components";
import {
  OrderFullRateByNsChart,
  OrderByStationChart,
  OrderByPeriodChart,
} from "./charts";

class ChartCard extends PureComponent {
  renderTitle = () => {
    const { title } = this.props;
    return (
      <div
        style={{
          fontSize: 16,
          fontWeight: 650,
        }}
      >
        {title}
      </div>
    );
  };

  render() {
    const { height = 340 } = this.props;
    return (
      <Card
        bodyStyle={{ padding: "12px", height, overflow: "hidden" }}
        title={this.renderTitle()}
      >
        {this.props.children}
      </Card>
    );
  }
}

@connect(state => {
  const chargingCharts = AnalysisSelectors.chargingCharts(state);
  const stations =
    get(stationSelectors.listAllPileStations(state), "result") || [];

  return {
    chargingCharts,
    stations,
  };
})
export default class Charging extends PureComponent {
  componentDidMount() {
    this.fetch(
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    );
  }

  fetch = (startAt, endAt) => {
    if (isEmpty(this.props.stations)) {
      this.props.dispatch(
        pileActions.listAllPileStations.request(
          {
            type: StationType.CHARGER,
            _limit: 100,
            _offset: 0,
          },
          {}
        )
      );
    }

    this.props.dispatch(
      AnalysisActions.aggOrderNs.request({
        _group: [OrderGroupField.NS],
        _count: [
          OrderCountableField.ELEC,
          OrderCountableField.DETAILS,
          OrderCountableField.VEHICLE,
        ],
        at_gte: startAt.toISOString(),
        at_lte: endAt.toISOString(),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggOrderIsFull.request({
        _group: [OrderGroupField.IS_FULL, OrderGroupField.NS],
        _count: [OrderCountableField.VEHICLE],
        at_gte: startAt.toISOString(),
        at_lte: endAt.toISOString(),
      })
    );

    this.props.dispatch(
      AnalysisActions.aggOrderStation.request({
        _group: [OrderGroupField.STATION],
        _count: [OrderCountableField.ELEC],
        at_gte: startAt.toISOString(),
        at_lte: endAt.toISOString(),
      })
    );
  };

  handleRangeChange = ([start, end]) => {
    this.fetch(start, end);
  };

  render() {
    const { chargingCharts, stations } = this.props;
    return (
      <Spin spinning={chargingCharts.loading}>
        <Container>
          <Row className="row" gutter={24}>
            <Col span={24}>
              <Card>
                <FlexBox>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 650,
                    }}
                  >
                    充电习惯分析
                  </div>
                  <RangeOptionsPicker onChange={this.handleRangeChange} />
                </FlexBox>
              </Card>
            </Col>
          </Row>
          <Row className="row" gutter={24}>
            <Col span={12}>
              <ChartCard title="新能源车数量分布">
                <ChartByNs
                  data={chargingCharts.OrderByNs}
                  unit="辆"
                  title="车辆总数"
                  subTitle={chargingCharts.OrderByNsTotal}
                />
              </ChartCard>
            </Col>

            <Col span={12}>
              <ChartCard title="满充车辆占比">
                <OrderFullRateByNsChart data={chargingCharts.OrderByIsFull} />
              </ChartCard>
            </Col>
          </Row>

          <Row className="row" gutter={24}>
            <Col span={12}>
              <ChartCard title="各充电站充电量排名">
                <OrderByStationChart
                  data={chargingCharts.OrderByStation}
                  stations={stations}
                />
              </ChartCard>
            </Col>

            <Col span={12}>
              <ChartCard title="各时段充电量占比">
                <OrderByPeriodChart data={chargingCharts.OrderByNs} />
              </ChartCard>
            </Col>
          </Row>
        </Container>
      </Spin>
    );
  }
}

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 24px 12px 0 12px;

  > .row {
    margin-bottom: 26px;
  }
`;
