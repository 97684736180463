import React, { PureComponent } from "react";
import { Select } from "antd";
import * as cs from "../../constants";

export class AlertTypeSelect extends PureComponent {
  render() {
    const { type, ...rest } = this.props;
    const names = cs.AlertTypes;
    return (
      <Select placeholder="请选择" {...rest}>
        {names.map(n => (
          <Select.Option key={n} value={n}>
            {n}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default AlertTypeSelect;
