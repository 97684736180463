/**
 * 车辆档案相关saga
 */
import { isEmpty } from "lodash";
import { all, put, takeLatest, take } from "redux-saga/effects";
import { archivesActions } from "../actions";
import { successOf, failureOf, isSuccess } from "@36node/redux-api";
import { NS } from "../constants";
import { message } from "antd";

function* handleDeleteArchives(action) {
  const { vehicleIds = [] } = action.payload;

  if (isEmpty(vehicleIds)) return;

  const hide = message.loading("正在删除所选车辆", 0);
  for (const vehicle of vehicleIds) {
    yield put(archivesActions.deleteVehicle.request({ vehicle }));

    const resultAction = yield take([
      successOf(NS.ARCHIVES.DELETE_VEHICLE),
      failureOf(NS.ARCHIVES.DELETE_VEHICLE),
    ]);

    if (isSuccess(resultAction)) {
    } else {
      message.error(`车辆: vehicleId 删除失败`);
    }
  }
  message.success("车辆删除完成");
  hide();

  // 重新刷新
  yield put(archivesActions.listVehicles.refresh());
}

function* handleCreateVehicle() {
  message.success("车辆创建成功");
  yield put(archivesActions.listVehicles.refresh());
}

function handleUpdateVehicle() {
  message.success("车辆更新成功");
}

export default function* watchArchives() {
  yield all([
    takeLatest(NS.ARCHIVES.DELETE_ARCHIVES, handleDeleteArchives),
    takeLatest(successOf(NS.ARCHIVES.CREATE_VEHICLE), handleCreateVehicle),
    takeLatest(successOf(NS.ARCHIVES.UPDATE_VEHICLE), handleUpdateVehicle),
  ]);
}
