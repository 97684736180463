import React, { PureComponent } from "react";
import { Select } from "antd";

const producers = ["万象", "宇通", "申沃", "申龙"];

export default class ProducerSelect extends PureComponent {
  render() {
    return (
      <Select placeholder="请选择" {...this.props}>
        {producers.map(producer => {
          return (
            <Select.Option value={producer} key={producer}>
              {producer}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
