import { HISTORY_NS } from "../../constants";
import makeHistoryActions from "./base";
import chargeRecordsHistorySelectors from "../../selectors/history/charge-records";
import { chargeRecordSchema } from "../../schemas";

const chargeRecordsActions = makeHistoryActions(
  HISTORY_NS.CHARGE_RECORDS.LIST_WARNINGS,
  HISTORY_NS.CHARGE_RECORDS.WARNINGS_XLSX,
  chargeRecordsHistorySelectors,
  [chargeRecordSchema]
);

export default chargeRecordsActions;
